import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../services/alert.service';
import { OrderService } from '../../../services/order.service';
import { OrderFullSizeDto } from '../../../../../../shared/dtos';

@Component({
  selector: 'app-delete-order',
  templateUrl: './delete-order.component.html',
  styleUrls: ['./delete-order.component.scss'],
})
export class DeleteOrderComponent implements OnInit {
  @Input() order: OrderFullSizeDto = new OrderFullSizeDto();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  orderId: number = 0;

  constructor(
    private alertSrv: AlertService,
    private orderSrv: OrderService,
    private router: Router
  ) {}

  onDelete() {
    if (this.orderId.toString() !== this.order.id.toString()) {
      this.alertSrv.alert.next({
        text: 'Bitte gibt die Bestellungsnummer ein!',
        linkText: '',
      });
    } else {
      this.orderSrv.delete(this.order.id).subscribe(
        () => {
          this.alertSrv.alert.next({
            text: 'Die Bestellung wurde gelöscht.',
            linkText: '',
          });
          this.orderSrv.updateListTrigger.next();
          this.router.navigate(['/orders']);
        },
        () => {
          this.alertSrv.alert.next({
            text: 'Beim Löschen ist etwas schief gelaufen.',
            linkText: '',
          });
        }
      );
    }
  }

  onClose() {
    this.close.emit();
  }

  ngOnInit(): void {}
}
