import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderFullSizeDto } from '../../../../../shared/dtos';
import { MenuService } from '../../services/menu.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
})
export class OrderListComponent {
  @Output() orderSelect: EventEmitter<OrderFullSizeDto> = new EventEmitter();
  @Input() selectedOrderId: number = -1;
  @Input() orders: OrderFullSizeDto[] = [];
  @Input() showStatus: boolean = false;
  loading = false;

  constructor(
    private menuSrv: MenuService,
    private orderSrv: OrderService,
  ) {}

  onOpenOrderTab(order: OrderFullSizeDto) {
    this.menuSrv.addTabAndOpen({
      text: `Bestellung ${order.id}`,
      route: ['orders', order?.id.toString()!],
      exact: true,
    });
  }

  onOrderSelect(order: OrderFullSizeDto) {
    this.orderSelect.emit(order);
  }
}
