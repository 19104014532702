import { Expose, Type } from "class-transformer";
import { IsArray, IsBoolean, IsDateString, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested } from "class-validator";
import { UpdatePartDto } from "./update-part.dto";

export class UpdateOrderDto {
  @IsNumber()
  @IsOptional()
  @Expose()
  fkCamp?: number;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkType?: number;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkStatus?: number;

  @IsBoolean()
  @IsOptional()
  @Expose()
  isFlexible?: boolean;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkCustomer?: number;

  @IsNumber()
  @IsOptional()
  @Expose()
  bigBoxCount?: number;

  @IsNumber()
  @IsOptional()
  @Expose()
  smallBoxCount?: number;

  @IsNumber()
  @IsOptional()
  @Expose()
  palletCount?: number;

  @IsNumber()
  @IsOptional()
  @Expose()
  individualPartCount?: number;

  @IsDateString()
  @IsOptional()
  @Expose()
  requiredDate?: Date;

  @IsDateString()
  @IsOptional()
  @Expose()
  additionalDate?: Date;

  @IsString()
  @IsOptional()
  @Expose()
  notes?: string;

  @IsBoolean()
  @IsOptional()
  @Expose()
  prio?: boolean;

  @IsArray()
  @Type(() => UpdatePartDto)
  @IsOptional()
  @Expose()
  parts?: UpdatePartDto[];
}