<!-- Description list -->
<div class="mt-6 px-4 sm:px-6 lg:px-8">
  <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
    <div class="sm:col-span-1" *ngFor="let data of articleData">
        <dt class="text-sm font-medium text-gray-500">{{ data.key }}</dt>
        <dd class="mt-1 text-sm text-gray-900">{{ data.val }}</dd>
    </div>
  </dl>
</div>

<!-- Unterartikel -->
<div
  *ngIf="article.subArticles && article.subArticles.length > 0"
  class="mt-8 px-4 pb-12 sm:px-6 lg:px-8"
>
  <h2 class="text-sm font-medium text-gray-500">Unterartikel</h2>
  <div class="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-2">
    <div
      *ngFor="let subArticle of article.subArticles"
      class="
        relative
        rounded-lg
        border border-gray-300
        bg-white
        px-6
        py-5
        shadow-sm
        flex
        items-center
        space-x-3
        hover:border-gray-400
        focus-within:ring-2
        focus-within:ring-offset-2
        focus-within:ring-pink-500
      "
    >
      <div class="flex-1 min-w-0">
        <a class="focus:outline-none">
          <span class="absolute inset-0" aria-hidden="true"></span>
          <p class="text-sm font-medium text-gray-900">
            {{ subArticle.name }}
          </p>
          <p class="text-sm text-gray-500 truncate">
            Unterartikel-Nr.: {{ subArticle.id }}
          </p>
        </a>
      </div>
    </div>
  </div>
</div>
