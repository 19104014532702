<app-loader *ngIf="loading"></app-loader>
<div *ngIf="!loading" class="px-4 sm:px-6 lg:px-8 overflow-y-auto">
  <div class="overflow-y-auto mt-4">
    <ul role="list flex flex-col ">
      <li *ngFor="let message of order.messages; let last = last">
        <div class="relative pb-8">
          <span *ngIf="!last" class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
          <div class="relative flex items-start space-x-3">
            <div>
              <div class="relative px-1">
                <div class="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                  <svg *ngIf="!message?.content" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                    viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <svg *ngIf="message?.content" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                    viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                  </svg>
                </div>
              </div>
            </div>
            <div *ngIf="!message.content || message.content?.length === 0" class="min-w-0 flex-1 py-1.5">
              <div class="text-sm text-gray-500">
                <button (click)="onPersonSelect(message.person)" class="font-medium text-gray-900">
                  {{ message.person.firstName }} {{ message.person.lastName }}
                </button>
                änderte den Status auf
                <span href="#" class="font-medium text-gray-900">{{
                  message.status.name
                  }}</span>
                <span class="ml-1 whitespace-nowrap">{{ message.created | date: "dd.MM.yyyy, HH:mm" }} Uhr</span>
              </div>
            </div>
            <div class="min-w-0 flex-1" *ngIf="message?.content">
              <div>
                <div class="text-sm">
                  <button (click)="onPersonSelect(message.person)" class="font-medium text-gray-900">
                    {{ message.person.firstName }} {{ message.person.lastName }}
                  </button>
                </div>
                <div class="flex space-x-2 items-center">
                  <p class="mt-0.5 text-sm text-gray-500">
                    kommentierte am
                    {{ message.created | date: "dd.MM.yyyy, HH:mm" }} Uhr
                  </p>
                  <ng-container *ngIf="authSrv.authContext?.person?.id === message.person.id">
                    <button *ngIf="editId !== message.id" (click)="editId = message.id" class="text-gray-600">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                      </svg>
                    </button>
                    <button *ngIf="editId === message.id" (click)="editId = -1" class="text-gray-600">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </button>
                    <button (click)="onDelete(message)" class="text-gray-600">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </button>
                  </ng-container>
                </div>
              </div>
              <div class="mt-2 text-sm text-gray-700">
                <p *ngIf="editId !== message.id">
                  {{ message.content }}
                </p>
                <div *ngIf="editId === message.id" class="mt-6 flex items-center justify-end space-x-4">
                  <textarea [(ngModel)]="message.content" (keydown.meta.enter)="onUpdate(message)" id="comment"
                    name="comment" rows="3"
                    class="shadow-sm block w-full focus:ring-gray-900 focus:border-gray-900 sm:text-sm border border-gray-300 rounded-md"
                    placeholder="Speichern"></textarea>

                  <button (click)="onUpdate(message)" type="submit"
                    class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-900 hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
                    Speichern
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="min-w-0 flex-1 mt-4 mb-2">
      <form action="#">
        <div>
          <textarea [(ngModel)]="newContent" (keydown.meta.enter)="onSend()" id="comment" name="comment"
            rows="3"
            class=" shadow-sm block w-full focus:ring-gray-900 focus:border-gray-900 sm:text-sm border border-gray-300 rounded-md"
            placeholder="Hinweis hinzufügen"></textarea>
        </div>
        <div class="mt-6 flex items-center justify-end space-x-4">
          <button *ngIf="order.fkStatus === 2 || order.fkStatus === 3" (click)="onTakeOver()"
            class="button-animation border-gray-200 inline-flex items-center justify-center px-4 py-2 border text-sm font-medium rounded-md shadow-sm bg-white text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100">
            Bestellung übernehmen
          </button>
          <button (click)="onSend()" type="submit"
            class="button-animation inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-900 hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
            Hinweis hinzufügen
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
