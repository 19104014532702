import { IsArray, IsDateString, IsNumber, IsOptional, IsString, ValidateNested } from "class-validator";
import { ArticleFullSizeDto } from "./article.full-size.dto";
import { SizeFullSizeDto } from "./size.full-size.dto";
import { Exclude, Expose, Type } from "class-transformer";

export class CategoryFullSizeDto {
  @IsNumber()
  @Expose()
  id: number = 0;

  @IsDateString()
  @Exclude()
  createdAt: Date;

  @IsDateString()
  @Exclude()
  updatedAt: Date;

  @IsString()
  @IsOptional()
  @Expose()
  category: string | null = "";

  @IsString()
  @Expose()
  nameDe: string = "";

  @IsArray()
  @Type(() => ArticleFullSizeDto)
  @ValidateNested({ each: true })
  @Expose()
  articles: ArticleFullSizeDto[] = [];

  @IsArray()
  @Type(() => SizeFullSizeDto)
  @ValidateNested({ each: true })
  @Expose()
  sizes: SizeFullSizeDto[] = [];
}
