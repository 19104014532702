<div class="h-screenExceptMenu overflow-y-auto flex">
  <div
    class="
      overflow-y-auto
      w-96
      flex-grow-0 flex-shrink-0
      h-full
      flex flex-col
      border-r border-gray-300
    "
  >
    <app-article-search
      class="mx-4 mt-3"
      [showOnlyAddable]="true"
      (articlesChanged)="onSearchUpdate($event)"
    ></app-article-search>
    <div class="overflow-y-auto">
      <app-loader *ngIf="loading"></app-loader>
      <app-article-list
        *ngIf="!loading"
        mode="select-sub"
        [articles]="filteredArticles"
        (articleSelect)="onArticleSelect($event)"
        (subArticleSelect)="onSubArticleSelect($event)"
      ></app-article-list>
    </div>
  </div>
  <div
    class="
      flex flex-col flex-grow
      justify-between
      border-r border-gray-300
      overflow-y-auto
    "
  >
    <div class="p-6 overflow-y-auto">
      <h2 class="text-xl font-bold text-gray-900 uppercase">
        Karton registrieren
      </h2>
      <app-article-filter
        (genderSelected)="selectedGender = $event; filterArticles()"
        (categorySelected)="selectedCategory = $event; filterArticles()"
        (sizeSelected)="selectedSize = $event; filterArticles()"
      ></app-article-filter>
    </div>
    <div class="p-6 border-t border-gray-300">
      <app-box-register-form
        class="h-full"
        [article]="selectedArticle"
        [subArticle]="selectedSubArticle"
        (boxCreated)="onBoxCreate($event)"
      ></app-box-register-form>
    </div>
  </div>
  <div
    class="
      overflow-y-auto
      w-80
      h-full
      flex-col flex-grow-0 flex-shrink-0
      hidden
      lg:flex
    "
  >
    <h3 class="p-4 font-bold uppercase text-lg">
      Zuletzt registrierte Kartons
    </h3>
    <div class="overflow-y-auto h-full">
      <app-box-list></app-box-list>
    </div>
  </div>
</div>
