import { IsBoolean, IsNumber, IsObject, ValidateNested } from "class-validator";
import { CampFullSizeDto } from "../order/camp.full-size.dto";
import { PersonFullSizeDto } from "./person.full-size.dto";
import { Expose, Type } from "class-transformer";


export class CustomerFullSizeDto {

  @IsNumber()
  @Expose()
  id: number = 0;

  @IsNumber()
  @Expose()
  fkPerson: number = 0;

  @IsNumber()
  @Expose()
  fkCamp: number = 0;

  @IsObject()
  @Type(() => PersonFullSizeDto)
  @ValidateNested()
  @Expose()
  person: PersonFullSizeDto = new PersonFullSizeDto();

  @IsObject()
  @Type(() => CampFullSizeDto)
  @ValidateNested()
  @Expose()
  camp: CampFullSizeDto = new CampFullSizeDto();

  @IsBoolean()
  @Expose()
  collapsed: boolean = false;
}
