import {Injectable} from '@angular/core';
import {HhHttpService} from "./hh-http.service";
import {Observable} from "rxjs";
import { HttpEvent } from "@angular/common/http";
import {map} from "rxjs/operators";
import AutocompletePrediction = google.maps.places.AutocompletePrediction;
import Place = google.maps.Place;

/**
 * The service is responsible for the communication with the Google Maps API via the backend.
 *
 * @author Julius Behrendt
 */
@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
  constructor(private httpSrv: HhHttpService) {
  }

  /**
   * Searches for autocomplete options.
   *
   * @param input the input being searched for
   */
  getPlaceSuggestions(input: string): Observable<AutocompletePrediction> {
    return this.httpSrv.get<AutocompletePrediction>(`maps/suggestions?input=${input}`);
  }

  /**
   * Retrieves the details for a specific place.
   *
   * @param placeId the ID of the place to retrieve details for
   */
  getPlaceDetails(placeId: string): Observable<Place> {
    return this.httpSrv.get<Place>(`maps/details?placeId=${placeId}`);
  }

  /**
   * Checks whether the address is valid.
   *
   * @param body the address to be validated
   * @returns the boolean (as Observable) whether it is valid
   */
  validateAddress(body: any): Observable<boolean> {
    return this.httpSrv.post<boolean>('maps/validate', body).pipe(
      map((event: HttpEvent<boolean>) => {
        return (event as any);
      })
    );
  }
}
