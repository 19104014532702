import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreateMessageDto, MessageFullSizeDto } from '../../../../shared/dtos';
import { HhHttpService } from './hh-http.service';
import { TransformerWrapper } from '../../../../shared/src/transformer';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private httpSrv: HhHttpService) {}

  findById(id: number): Observable<MessageFullSizeDto> {
    return this.httpSrv.get<MessageFullSizeDto>(`message/${id}`);
  }

  update(message: MessageFullSizeDto): Observable<MessageFullSizeDto> {
    return this.httpSrv
      .put<MessageFullSizeDto>(`message`, message)
      .pipe(map((elem) => Object.assign(new MessageFullSizeDto(), elem)));
  }

  create(message: CreateMessageDto): Observable<MessageFullSizeDto> {
    return this.httpSrv
      .post<MessageFullSizeDto>(`message`, message)
      .pipe(map((elem) => TransformerWrapper.plainToInstanceExclude(MessageFullSizeDto, elem)));
  }

  delete(message: MessageFullSizeDto) {
    return this.httpSrv.delete(`message/${message.id}`);
  }
}
