import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  alert: BehaviorSubject<{ text: string; linkText: string; linkAction?: () => void }> =
    new BehaviorSubject<{ text: string; linkText: string, linkAction?: () => void }>({
      text: '',
      linkText: '',
    });

  constructor() {}
}
