<div class="h-screenExceptMenu flex overflow-hidden bg-white">
  <div class="flex flex-col min-w-0 flex-1 overflow-hidden">
    <div class="flex-1 relative z-0 flex overflow-hidden">
      <div
        class="
          h-screenExceptMenu
          flex
          justify-center
          items-center
          cursor-pointer
          lg:hidden
        "
        (click)="menuShown = !menuShown"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          [ngClass]="{ 'transform rotate-180': menuShown }"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </div>
      <aside
        class="
          lg:flex
          flex-col flex-shrink-0
          w-96
          max-w-fullExceptFold
          border-r border-gray-200
          print:hidden
          animate-slideInFromLeft
          lg:animate-none
        "
        [ngClass]="{ hidden: !menuShown, flex: menuShown }"
      >
        <div class="px-6 pt-6 pb-4 border-b border-gray-200">
          <div class="flex justify-between items-center">
            <div>
              <h2 class="text-xl font-bold text-gray-900 uppercase">Gruppen</h2>
            </div>
          </div>
        </div>
        <!-- Directory list -->
        <nav class="flex-1 min-h-0 overflow-y-auto" aria-label="Directory">
          <div class="relative">
            <app-loader *ngIf="loading"></app-loader>
            <!-- List -->
            <app-group-list
              *ngIf="!loading"
              mode="select"
              [selectedGroupId]="selectedGroupId"
              (groupSelected)="onGroupSelect($event); menuShown = false"
              [groups]="groups"
            ></app-group-list>
          </div>
        </nav>
      </aside>
      <div class="flex flex-col h-full flex-grow overflow-y-auto">
        <router-outlet name="group"></router-outlet>
      </div>
    </div>
  </div>
</div>
