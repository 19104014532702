import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ArticleFullSizeDto } from '../../../../../shared/dtos';
import { BoxFullSizeDto } from '../../../../../shared/dtos';
import { PalletFullSizeDto } from '../../../../../shared/dtos';

@Component({
  selector: 'app-article-warehouse',
  templateUrl: './article-warehouse.component.html',
  styleUrls: ['./article-warehouse.component.scss'],
})
export class ArticleWarehouseComponent implements OnInit, OnChanges {
  @Input() article: ArticleFullSizeDto = new ArticleFullSizeDto();
  @Output() articleChange: EventEmitter<ArticleFullSizeDto> = new EventEmitter();
  selectedPallet: PalletFullSizeDto = new PalletFullSizeDto();
  selectedBox: BoxFullSizeDto = new BoxFullSizeDto();

  constructor() {}

  onPalletSelect(pallet: PalletFullSizeDto) {
    this.selectedPallet = pallet;
    this.selectedBox = new BoxFullSizeDto();
  }

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.selectedPallet = new PalletFullSizeDto();
    this.selectedBox = new BoxFullSizeDto();
  }
}
