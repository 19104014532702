import {
  IsArray,
  IsBoolean,
  IsDateString,
  IsNumber,
  IsObject,
  IsOptional,
  IsString,
  ValidateNested,
} from "class-validator";
import { PersonFullSizeDto } from "../people/person.full-size.dto";
import { CampFullSizeDto } from "./camp.full-size.dto";
import { MessageFullSizeDto } from "./message.full-size.dto";
import { OrganisationFullSizeDto } from "./organisation.full-size.dto";
import { PartFullSizeDto } from "./part.full-size.dto";
import { StatusFullSizeDto } from "./status.full-size.dto";
import { TypeFullSizeDto } from "./type.full-size.dto";
import { Exclude, Expose, Type } from "class-transformer";
import { CustomerFullSizeDto } from "../people/customer.full-size.dto";

export class CollectionStatus {

  @IsNumber()
  @Expose()
  notCollected: number;

  @IsNumber()
  @Expose()
  partiallyCollected: number;

  @IsNumber()
  @Expose()
  fullyCollected: number;
}

export class OrderFullSizeDto {
  @IsNumber()
  @Expose()
  id: number = 0;

  @IsDateString()
  @Exclude()
  createdAt: Date;

  @IsDateString()
  @Exclude()
  updatedAt: Date;

  @IsBoolean()
  @Expose()
  approved: boolean = false;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkOrganisation: number | null = null;

  @IsDateString()
  @Expose()
  created: Date = new Date();

  @IsString()
  @Expose()
  creatorString: string | null = null;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkCreator: number | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  collectorString: string | null = null;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkCollector: number | null = null;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkPacker: number | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  packerString: string | null = null;

  @IsNumber()
  @Expose()
  fkStatus: number = 0;

  @IsNumber()
  @Expose()
  fkType: number = 0;

  @IsBoolean()
  @Expose()
  isFlexible: boolean = false;

  @IsNumber()
  @Expose()
  fkCamp: number = 0;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkWarehouse: number | null = null;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkCustomer: number;

  @Expose()
  requiredDate: string | Date = new Date();

  @IsDateString()
  @IsOptional()
  @Expose()
  additionalDate: Date | null = null;

  @IsNumber()
  @IsOptional()
  @Expose()
  palletCount: number | null = null;

  @IsNumber()
  @IsOptional()
  @Expose()
  bigBoxCount: number | null = null;

  @IsNumber()
  @IsOptional()
  @Expose()
  smallBoxCount: number | null = null;

  @IsNumber()
  @IsOptional()
  @Expose()
  individualPartCount: number | null = null;

  @IsString()
  @Expose()
  notes: string = "";

  @IsBoolean()
  @Expose()
  prio: boolean = false;

  @IsArray()
  @Type(() => MessageFullSizeDto)
  @ValidateNested({ each: true })
  @Expose()
  messages: MessageFullSizeDto[] = [];

  @IsObject()
  @Type(() => CampFullSizeDto)
  @ValidateNested()
  @Expose()
  camp: CampFullSizeDto = new CampFullSizeDto();

  @IsObject()
  @Type(() => StatusFullSizeDto)
  @ValidateNested()
  @Expose()
  status: StatusFullSizeDto = new StatusFullSizeDto();

  @IsObject()
  @Type(() => TypeFullSizeDto)
  @ValidateNested()
  @Expose()
  type: TypeFullSizeDto = new TypeFullSizeDto();

  @IsObject()
  @Type(() => OrganisationFullSizeDto)
  @ValidateNested()
  @Expose()
  organisation: OrganisationFullSizeDto = new OrganisationFullSizeDto();

  @IsArray()
  @Type(() => PartFullSizeDto)
  @ValidateNested({ each: true })
  @Expose()
  parts: PartFullSizeDto[] = [];

  @IsObject()
  @Type(() => CustomerFullSizeDto)
  @ValidateNested()
  @Expose()
  customer: CustomerFullSizeDto = new CustomerFullSizeDto();

  @IsObject()
  @IsOptional()
  @Type(() => PersonFullSizeDto)
  @ValidateNested()
  @Expose()
  creator?: PersonFullSizeDto | null = new PersonFullSizeDto();

  @IsObject()
  @IsOptional()
  @Type(() => PersonFullSizeDto)
  @ValidateNested()
  @Expose()
  collector?: PersonFullSizeDto | null = new PersonFullSizeDto();

  @IsObject()
  @IsOptional()
  @Type(() => PersonFullSizeDto)
  @ValidateNested()
  @Expose()
  packer?: PersonFullSizeDto | null = new PersonFullSizeDto();

  @Expose()
  location: { lat: number; lng: number } = { lat: 0, lng: 0 };

  @IsObject()
  @Type(() => CollectionStatus)
  @ValidateNested()
  @Expose()
  collectionStatus: CollectionStatus = {
    notCollected: 0,
    partiallyCollected: 0,
    fullyCollected: 0,
  };

  @IsObject()
  @Expose()
  @Type(() => CollectionStatus)
  @ValidateNested()
  collectionStatusPercent: CollectionStatus = {
    notCollected: 0,
    partiallyCollected: 0,
    fullyCollected: 0,
  };
}
