import { Expose } from "class-transformer";
import { IsBoolean, IsNumber, IsOptional, Min } from "class-validator";
import { IsNullable } from "../../IsNullable";

export class CreateBoxDto {

  @IsNumber()
  @Expose()
  fkArticle: number;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkSubArticle?: number;

  @IsNumber()
  @IsOptional()
  @Expose()
  weight?: number;

  @IsNumber()
  @Expose()
  @Min(1)
  articleCount: number;

  @IsBoolean()
  @Expose()
  newGoods: boolean = false;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkPallet?: number;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkPart?: number;
}