<div class="h-full">
  <div
    *ngIf="!article || !article.id"
    class="flex justify-center items-center h-full text-center w-full"
  >
    Bitte wähle einen Artikel zum Registrieren aus.
  </div>
  <div *ngIf="article.id" class="flex flex-col">
    <div class="flex items-center space-x-4">
      <div class="flex flex-col">
        <div class="mt-2">
          Bitte gib die Menge der Artikel in dem Karton ein:
        </div>

        <div class="mt-3 flex-1 w-72">
          <label
            for="articleCount"
            class="block text-sm font-medium text-gray-700"
          >
            Anzahl Artikel
          </label>
          <input
            name="articleCount"
            type="number"
            min="1"
            class="ip mt-1 w-full"
            [(ngModel)]="newBox.articleCount"
          />
        </div>

        <div class="mt-3 flex-1 w-72">
          <label for="weight" class="block text-sm font-medium text-gray-700">
            Gewicht (in Gramm)
          </label>
          <input
            name="weight"
            type="number"
            class="ip mt-1 w-full"
            [(ngModel)]="newBox.weight"
          />
        </div>
        <div class="flex items-center mt-4 space-x-2">
          <span class="text-sm font-medium text-gray-700">Gebrauchtspende</span>
          <button
            (click)="onToggleNewGoods()"
            [ngClass]="{ 'bg-primary': newBox.newGoods }"
            type="button"
            class="
              bg-gray-200
              relative
              inline-flex
              flex-shrink-0
              h-6
              w-11
              border-2 border-transparent
              rounded-full
              cursor-pointer
              transition-colors
              ease-in-out
              duration-200
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-primary
            "
            role="switch"
            aria-checked="false"
          >
            <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
            <span
              [ngClass]="{ 'translate-x-5': newBox.newGoods }"
              aria-hidden="true"
              class="
                translate-x-0
                pointer-events-none
                inline-block
                h-5
                w-5
                rounded-full
                bg-white
                shadow
                transform
                ring-0
                transition
                ease-in-out
                duration-200
              "
            ></span>
          </button>
          <span class="text-sm font-medium text-gray-700">Neuware</span>
        </div>
      </div>
      <div class="flex flex-col w-full items-center">
        <div class="flex flex-col p-4 shadow-md rounded-md">
          <h3 class="font-bold uppercase text-lg">Auswahl</h3>

          <span class="font-medium text-gray-800"
            >{{
              !subArticle || !subArticle.id ? article.nameDe : subArticle.name
            }}
          </span>
          <span class="text-gray-600"
            >{{ article.gender?.nameDe }},
            {{ article.size?.sizeName }}</span
          >
        </div>
      </div>
    </div>
    <div class="flex w-full space-x-4">
      <button
        (click)="onRegister({ print: true })"
        class="
          button-animation
          mt-4
          bg-primary
          text-white
          uppercase
          font-bold
          py-2
          px-4
          rounded-lg
          shadow-md
          cursor-pointer
          w-2/3
        "
      >
        Karton registrieren + Label drucken
      </button>
      <button
        (click)="onRegister()"
        class="
          button-animation
          mt-4
          bg-white
          text-primary
          uppercase
          font-bold
          py-2
          px-4
          rounded-lg
          shadow-md
          cursor-pointer
          w-2/3
        "
      >
        nur registrieren
      </button>
    </div>
  </div>
</div>
