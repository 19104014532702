import { IsArray, IsBoolean, IsDateString, IsNumber, IsObject, IsOptional, Validate, ValidateNested } from "class-validator";
import { ArticleFullSizeDto } from "./article.full-size.dto";
import { BlockFullSizeDto } from "./block.full-size.dto";
import { PalletFullSizeDto } from "./pallet.full-size.dto";
import { Exclude, Expose, Type } from "class-transformer";

export class HashtagRowFullSizeDto {

  @IsNumber()
  @Expose()
  id: number = 0;

  @IsDateString()
  @Exclude()
  createdAt: Date;

  @IsDateString()
  @Exclude()
  updatedAt: Date;

  @IsNumber()
  @Expose()
  row: number = 0;

  @IsNumber()
  @Expose()
  length: number = 0;

  @IsBoolean()
  @Expose()
  blocked: boolean = false;

  @IsObject()
  @ValidateNested()
  @Type(() => ArticleFullSizeDto)
  @Expose()
  article: ArticleFullSizeDto | null = new ArticleFullSizeDto();

  @IsNumber()
  @Expose()
  fkArticle: number | null = 0;

  @IsObject()
  @ValidateNested()
  @Type(() => BlockFullSizeDto)
  @Expose()
  block: BlockFullSizeDto = new BlockFullSizeDto();

  @IsNumber()
  @Expose()
  fkBlock: number;

  @IsArray()
  @ValidateNested({each: true})
  @Type(() => PalletFullSizeDto)
  @Expose()
  pallets: PalletFullSizeDto[] = [];
}
