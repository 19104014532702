<ul class="animate-fadeIn">
  <li *ngFor="let person of persons" class="flex flex-col px-4 py-4 sm:px-6 hover:bg-gray-200 cursor-pointer"
    (click)="onPersonSelect(person)" [ngClass]="{
      'bg-gray-100': selectedPersonId === person.id,
    }">
    <div class="flex justify-between items-center">
      <div class="flex flex-col w-full">
        <div class="flex space-x-2 w-full justify-between">
          <span class="text-sm font-medium text-primary line-clamp-1">
            {{ person.firstName }} {{ person.lastName }}</span>
          <button tooltip="in neuem Tab öffnen" (click)="onOpenPersonTab(person); $event.stopPropagation()">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg>
          </button>
        </div>

        <span class="text-sm text-gray-500 line-clamp-1">{{
          person.eMail_1 || person.eMail_2
          }}</span>
        <span class="text-sm text-gray-500 line-clamp-1">{{
          person.phone_1 || person.phone_2
          }}</span>
      </div>
      <div *ngIf="person.personGroups && person.personGroups.length > 0">
        !
      </div>
    </div>
  </li>
</ul>
