import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BoxFullSizeDto } from '../../../../../shared/dtos';
import { OrderFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { BoxService } from '../../services/box.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-order-pack',
  templateUrl: './order-pack.component.html',
  styleUrls: ['./order-pack.component.scss'],
})
export class OrderPackComponent implements OnInit {
  loading: boolean = true;
  order: OrderFullSizeDto = new OrderFullSizeDto();
  boxIdInput: string = '';
  packType = 'number';
  lastBoxes: BoxFullSizeDto[] = [];
  orderId: number = 0;
  @ViewChild('boxInput') boxInput!: ElementRef;

  constructor(
    private router: ActivatedRoute,
    private orderSrv: OrderService,
    private alertSrv: AlertService,
    private boxSrv: BoxService
  ) {}

  onBoxAdd() {
    let boxId = 0;

    // accommodate for different links that were generated over the years
    if (this.boxIdInput.includes('boxID=')) {
      boxId = parseInt(this.boxIdInput.split('boxID=')[1]);
    } else if (this.boxIdInput.includes('boxID%3D'))  {
      boxId = parseInt(this.boxIdInput.split('boxID%3D')[1]);
    } else if (this.boxIdInput.includes('external/boxes/'))  { // <- current link
      boxId = parseInt(this.boxIdInput.split('external/boxes/')[1]);
    } else {
      boxId = parseInt(this.boxIdInput);
    }

    if (this.boxIdInput.length > 0) {
      this.orderSrv.addBox(this.order.id, boxId).subscribe(
        () => {
          this.update();
          this.boxSrv.findById(boxId).subscribe(
            (box) => {
              this.alertSrv.alert.next({
                text: `Karton ${box.id} hinzugefügt.`,
                linkText: '',
              });
              this.lastBoxes.push(box);
              this.reset();
            },
            (err) => {
              this.alertSrv.alert.next({
                text: `Karton ${boxId} konnte nicht hinzugefügt werden.`,
                linkText: '',
              });
              this.reset();
            }
          );
        },
        () => {
          this.alertSrv.alert.next({
            text: `Karton ${boxId} konnte nicht hinzugefügt werden.`,
            linkText: '',
          });
          this.reset();
        }
      );
    } else {
      this.alertSrv.alert.next({
        text: `Bitte das Feld ausfüllen!`,
        linkText: '',
      });
    }
  }

  reset() {
    this.boxIdInput = '';
    this.boxInput.nativeElement.focus();
  }

  update() {
    if (this.orderId) {
      this.orderSrv.findById(this.orderId).subscribe((order) => {
        this.order = order;
        this.loading = false;
      });
    }
  }

  ngOnInit(): void {
    this.router.params.subscribe((params) => {
      this.orderId = params.orderId;
      this.update();
    });
  }
}
