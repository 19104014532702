import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PersonFullSizeDto } from '../../../../../shared/dtos';
import { PersonService } from '../../services/person.service';

@Component({
  selector: 'app-person-search',
  templateUrl: './person-search.component.html',
  styleUrls: ['./person-search.component.scss'],
})
export class PersonSearchComponent implements OnInit, OnDestroy {
  @Output() personsChanged: EventEmitter<PersonFullSizeDto[]> = new EventEmitter();
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  q: string = '';
  inputChange: Subject<string> = new Subject();
  updateTriggerSubscription: Subscription = new Subscription();

  constructor(private personSrv: PersonService) {}

  onSearch(q: string) {
    this.inputChange.next(q);
    this.loading.emit(true);
  }

  onSearchReset() {
    this.loading.emit(true);
    this.personSrv.findAll().subscribe((persons) => {
      this.personsChanged.emit(persons);
      this.loading.emit(false);
    });
    this.q = '';
  }

  triggerUpdate(q: string) {
    if (q.length > 0) {
      return this.personSrv.findByName(q);
    } else {
      this.q = '';
      return this.personSrv.findAll();
    }
  }

  ngOnInit(): void {

    this.updateTriggerSubscription = this.personSrv.updateTrigger.subscribe(() => {
      this.inputChange.next(this.q);
    });

    this.inputChange
      .pipe(
        debounceTime(200),
        switchMap((q) => {
          this.loading.emit(true);
          if (q.length > 0) {
            return this.personSrv.findByName(q);
          } else {
            this.q = '';
            return this.personSrv.findAll();
          }
        })
      )
      .subscribe(
        (persons) => {
          this.personsChanged.emit(persons);
          this.loading.emit(false);
        },
        (err) => {
          this.loading.emit(false);
        }
      );
  }

  ngOnDestroy(): void {
    this.updateTriggerSubscription.unsubscribe();
  }
}
