import { IsNumber, IsObject, ValidateNested } from "class-validator";
import { GroupFullSizeDto } from "./group.full-size.dto";
import { RightFullSizeDto } from "./right.full-size.dto";
import { Expose, Type } from "class-transformer";


export class GroupRightFullSizeDto {

  @IsNumber()
  @Expose()
  id: number = 0;

  @IsNumber()
  @Expose()
  fkGroup: number = 0;

  @IsNumber()
  @Expose()
  fkRight: number = 0;

  @IsObject()
  @Type(() => GroupFullSizeDto)
  @ValidateNested()
  @Expose()
  group: GroupFullSizeDto = new GroupFullSizeDto();

  @IsObject()
  @Type(() => RightFullSizeDto)
  @ValidateNested()
  @Expose()
  right: RightFullSizeDto = new RightFullSizeDto();
}
