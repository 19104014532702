import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArticleService } from 'src/app/services/article.service';
import { ArticleFullSizeDto, SubArticleFullSizeDto } from '../../../../../shared/dtos';

@Component({
  selector: 'app-show-articles',
  templateUrl: './show-articles.component.html',
  styleUrls: ['./show-articles.component.scss'],
})
export class ShowArticlesComponent implements OnInit {
  articles: ArticleFullSizeDto[] = [];
  selectedArticle: ArticleFullSizeDto | null = null;
  selectedSubArticle: SubArticleFullSizeDto | null = null;
  loading: boolean = false;
  menuShown: boolean = true;
  selectedArticleId: number = -1;

  constructor(
    private articleSrv: ArticleService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  onArticleSelect(article: ArticleFullSizeDto) {
    this.selectedArticleId = article.id;
    this.router.navigate([
      '/articles',
      { outlets: { article: [article.id.toString()] } },
    ]);
  }

  getRowsString(article: ArticleFullSizeDto) {
    return article.hashtagRows.map((row) => {
      return row.block.name + row.row;
    });
  }

  updateArticles()  {
    this.loading = true;
    this.articleSrv.findAll().subscribe((articles) => {
      this.loading = false;
      this.articles = articles;
    });
  }

  ngOnInit(): void {
    this.route.children[0]?.params.subscribe((params) => {
      if (!params['articleId']) {
        return;
      }
      this.selectedArticleId = +params['articleId'];
    });

    this.updateArticles();
  }
}
