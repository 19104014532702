import { Injectable } from '@angular/core';
import { GroupFullSizeDto } from '../../../../shared/dtos';
import { HhHttpService } from './hh-http.service';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  constructor(private httpSrv: HhHttpService) {}

  findByIdWithPersons(groupId: number) {
    return this.httpSrv.get<GroupFullSizeDto>(`group/${groupId}`);
  }

  findAll() {
    return this.httpSrv.get<GroupFullSizeDto[]>(`group`);
  }
}
