{
  "name": "frontend",
  "version": "2.1.2",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build:prod": "ng build --configuration production",
    "build:dev": "ng build --configuration development",
    "preview": "http-server ./dist/frontend",
    "watch": "ng build --watch --configuration local",
    "analyze": "source-map-explorer dist/frontend/**/*.js",
    "test": "ng test",
    "cypress": "env CYPRESS_BASE_URL=\"http://localhost:4200\" npx cypress open"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.4",
    "@angular/common": "^18.2.4",
    "@angular/compiler": "^18.2.4",
    "@angular/core": "^18.2.4",
    "@angular/forms": "^18.2.4",
    "@angular/google-maps": "^18.2.4",
    "@angular/platform-browser": "^18.2.4",
    "@angular/platform-browser-dynamic": "^18.2.4",
    "@angular/router": "^18.2.4",
    "@angular/service-worker": "^18.2.4",
    "@elastic/apm-rum-angular": "^3.0.4",
    "autoprefixer": "^10.4.20",
    "class-validator": "^0.14.1",
    "dayjs": "^1.11.13",
    "keycloak-angular": "^16.0.1",
    "keycloak-js": "^25.0.1",
    "postcss": "^8.4.47",
    "print-js": "^1.6.0",
    "reflect-metadata": "^0.2.2",
    "rxjs": "~7.8.1",
    "tslib": "^2.7.0",
    "zone.js": "~0.14.10",
    "cypress": "^13.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.4",
    "@angular/cli": "^18.2.4",
    "@angular/compiler-cli": "^18.2.4",
    "@tailwindcss/forms": "^0.5.9",
    "@types/google.maps": "^3.58.0",
    "@types/jasmine": "~5.1.4",
    "@types/node": "^22.5.5",
    "install": "^0.13.0",
    "jasmine-core": "~5.3.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "npm": "^10.8.3",
    "source-map-explorer": "^2.5.3",
    "tailwindcss": "^3.4.11",
    "typescript": "^5.4.0"
  }
}