import { Component, Input, OnInit } from '@angular/core';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  alert: { text: string; linkText: string, linkAction?: () => void } = { text: '', linkText: '' };
  constructor(private alertSrv: AlertService) {}

  ngOnInit(): void {
    this.alertSrv.alert.asObservable().subscribe((alert) => {
      this.alert = alert;
    });
  }

  onClose() {
    this.alertSrv.alert.next({ text: '', linkText: '' });
  }
}
