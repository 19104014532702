import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BoxFullSizeDto } from '../../../../../shared/dtos';
import { PalletFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { PalletService } from '../../services/pallet.service';

@Component({
  selector: 'app-routing-pallet',
  templateUrl: './routing-pallet.component.html',
  styleUrls: ['./routing-pallet.component.scss'],
})
export class RoutingPalletComponent implements OnInit {
  loading: boolean = false;
  pallet: PalletFullSizeDto = new PalletFullSizeDto();
  box: BoxFullSizeDto = new BoxFullSizeDto();

  constructor(
    private router: ActivatedRoute,
    private palletSrv: PalletService,
    private alertSrv: AlertService
  ) {}

  ngOnInit(): void {
    this.router.params.subscribe((params) => {
      if (params.palletId) {
        this.loading = true;
        this.palletSrv.findById(params.palletId).subscribe(
          (pallet) => {
            this.loading = false;
            this.pallet = pallet;
          },
          (err) => {
            this.loading = false;
            this.alertSrv.alert.next({
              text: `Palette ${params.palletId} konnte nicht gefunden werden.`,
              linkText: '',
            });
          }
        );
      }
    });
  }
}
