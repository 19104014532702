import {
  Component,
  EventEmitter,
  Input, OnInit,
  Output
} from '@angular/core';
import { BoxFullSizeDto } from '../../../../../shared/dtos';
import { PartFullSizeDto } from '../../../../../shared/dtos';
import { PartService } from '../../services/part.service';
import { BoxService } from '../../services/box.service';

@Component({
  selector: 'app-order-part-details',
  templateUrl: './order-part-details.component.html',
  styleUrls: ['./order-part-details.component.scss'],
})
export class OrderPartDetailsComponent implements OnInit {
  @Input() part: PartFullSizeDto = new PartFullSizeDto();
  @Output() partChange: EventEmitter<PartFullSizeDto> = new EventEmitter<PartFullSizeDto>();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  loading: boolean = false;
  selectedBox: BoxFullSizeDto = new BoxFullSizeDto();
  view: string = 'article';

  constructor(
    private partSrv: PartService,
    private boxSrv: BoxService,
  ) { }

  onClose() {
    this.close.emit();
  }

  onBoxSelect(box: BoxFullSizeDto) {
    this.selectedBox = box;
  }

  update() {
    this.loading = true;
    this.selectedBox = new BoxFullSizeDto();
    this.partSrv.findById(this.part.id).subscribe((part) => {
      this.part = part;
      this.loading = false;
    });
  }

  ngOnInit(): void {
    this.boxSrv.boxDeleteTrigger.subscribe(() => this.update());
    this.update();
  }
}
