import { Exclude, Expose, Type } from "class-transformer";
import { ArticleFullSizeDto } from "./article.full-size.dto";
import { BoxFullSizeDto } from "./box.full-size.dto";
import { HashtagRowFullSizeDto } from "./hashtag-row.full-size.dto";
import { WarehouseFullSizeDto } from "./warehouseLager.full-size.dto";
import { IsArray, IsBoolean, IsDateString, IsNumber, IsObject, IsOptional, ValidateNested } from "class-validator";


export class PalletFullSizeDto {

  @IsNumber()
  @Expose()
  id: number = 0;

  @IsDateString()
  @Exclude()
  createdAt: Date;

  @IsDateString()
  @Exclude()
  updatedAt: Date;

  @IsNumber()
  @IsOptional()
  @Expose()
  boxCount: number | null = 0;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkHashtagRow: number | null = null;

  @IsBoolean()
  @IsOptional()
  @Expose()
  isClosed: boolean | null = false;

  @IsNumber()
  @Expose()
  fkArticle: number = 0;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkWarehouse: number | null = null;

  @IsDateString()
  @Expose()
  created: Date = new Date();

  @IsNumber()
  @Expose()
  visible: boolean;

  @IsArray()
  @Type(() => BoxFullSizeDto)
  @ValidateNested({ each: true })
  @Expose()
  boxes: BoxFullSizeDto[] = [];

  @IsArray()
  @Type(() => BoxFullSizeDto)
  @ValidateNested({ each: true })
  @Expose()
  boxes2: BoxFullSizeDto[] = [];

  @IsObject()
  @Type(() => ArticleFullSizeDto)
  @ValidateNested()
  @Expose()
  article: ArticleFullSizeDto = new ArticleFullSizeDto();

  @IsObject()
  @Type(() => HashtagRowFullSizeDto)
  @ValidateNested()
  @Expose()
  hashtagRow: HashtagRowFullSizeDto = new HashtagRowFullSizeDto();

  @IsObject()
  @Type(() => WarehouseFullSizeDto)
  @ValidateNested()
  @Expose()
  warehouse: WarehouseFullSizeDto | null = new WarehouseFullSizeDto();
}
