<div class="lg:border-b lg:border-gray-200">
  <nav aria-label="Progress">
    <ol role="list" class="rounded-md overflow-hidden lg:flex lg:rounded-none">
      <!-- Status 1: bestellt -->
      <li class="relative overflow-hidden lg:flex-1">
        <div class="border border-gray-200 overflow-hidden border-b-0 rounded-t-md lg:border-0">
          <button [disabled]="mode !== 'edit'" [ngClass]="{ 'cursor-default': mode !== 'edit' }" class="group w-full"
            (click)="setOrderStatus(1)">
            <span [ngClass]="{
                'bg-primary': statusId === 1,
                'group-hover:bg-gray-200': statusId > 1 && mode === 'edit'
              }" class="absolute top-0 left-0 w-1 h-full bg-transparent lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
              aria-hidden="true"></span>
            <span class="px-6 py-2.5 flex items-center text-sm font-medium w-full">
              <span class="flex-shrink-0">
                <span [ngClass]="{
                    'border-primary': statusId === 1,
                    'border-gray-500': statusId < 1,
                    'border-2': statusId <= 1,
                    'bg-primary': statusId > 1
                  }" class="w-10 h-10 flex items-center justify-center rounded-full">
                  <span [ngClass]="{
                      'text-primary': statusId === 1,
                      'text-gray-300': statusId < 1
                    }">
                    <span *ngIf="statusId <= 1">01</span>
                    <!-- Heroicon name: solid/check -->
                    <svg *ngIf="statusId > 1" class="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                  </span>
                </span>
              </span>
              <div class="mt-0.5 ml-4 min-w-0 flex flex-col items-start">
                <span [ngClass]="{
                    'text-primary': statusId === 1,
                    'text-gray-500': statusId < 1
                  }" class="text-xs font-semibold tracking-wide uppercase">bestellt</span>
                <span class="text-sm font-medium text-gray-500">{{ order.created | date: "dd.MM.yyyy" }}&nbsp;</span>
                <span class="text-xs font-medium text-gray-400 truncate">
                  {{ order.creator?.firstName }} {{ order.creator?.lastName }}
                </span>
              </div>
            </span>
          </button>
        </div>
      </li>

      <!-- Status 2: wird gepackt -->
      <li class="relative overflow-hidden lg:flex-1">
        <div class="border border-gray-200 overflow-hidden lg:border-0">
          <button [disabled]="mode !== 'edit'" [ngClass]="{ 'cursor-default': mode !== 'edit' }"
            (click)="setOrderStatus(2)" class="group w-full" aria-current="step">
            <span [ngClass]="{
                'bg-primary': statusId === 2,
                'group-hover:bg-gray-200':
                  (statusId > 2 || statusId === 1) && mode === 'edit'
              }" class="absolute top-0 left-0 w-1 h-full lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
              aria-hidden="true"></span>
            <span class="px-6 py-2.5 flex items-center text-sm font-medium lg:pl-9 w-full">
              <span class="flex-shrink-0">
                <span [ngClass]="{
                    'border-primary': statusId === 2,
                    'border-gray-300': statusId < 2,
                    'border-2': statusId <= 2,
                    'bg-primary': statusId > 2
                  }" class="w-10 h-10 flex items-center justify-center rounded-full">
                  <span [ngClass]="{
                      'text-primary': statusId === 2,
                      'text-gray-500': statusId < 2
                    }">
                    <span *ngIf="statusId <= 2">02</span>
                    <svg *ngIf="statusId > 2" class="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                  </span>
                </span>
              </span>
              <div class="mt-0.5 ml-4 min-w-0 flex flex-col items-start">
                <span [ngClass]="{
                    'text-primary': statusId === 2,
                    'text-gray-500': statusId < 2
                  }" class="text-xs font-semibold tracking-wide uppercase">packen</span>
                <span class="text-sm font-medium text-gray-500">{{
                  getLastStatusMessage(2)?.created | date: "dd.MM.yyyy"
                  }}&nbsp;</span>
                <div class="text-xs font-medium text-gray-400 truncate">
                  {{ order.collector?.firstName }}
                  <ng-container *ngIf="!order.collectorString">{{
                    order.collector?.lastName
                    }}</ng-container>
                  <span *ngIf="order.collectorString">
                    + {{ order.collectorString }}
                  </span>
                </div>
              </div>
            </span>
          </button>

          <!-- Separator -->
          <div class="hidden absolute top-0 left-0 w-3 inset-0 lg:block" aria-hidden="true">
            <svg class="h-full w-full text-gray-300" viewBox="0 0 12 82" fill="none" preserveAspectRatio="none">
              <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vector-effect="non-scaling-stroke" />
            </svg>
          </div>
        </div>
      </li>

      <!-- Status 3: fertig im Lager -->
      <li class="relative overflow-hidden lg:flex-1">
        <div class="flex items-center border border-gray-200 overflow-hidden border-t-0 rounded-b-md lg:border-0">
          <!-- Upcoming Step -->
          <button [disabled]="mode !== 'edit'" [ngClass]="{ 'cursor-default': statusId === 1 || mode !== 'edit' }"
            (click)="setOrderStatus(3)" class="group w-full">
            <span [ngClass]="{
                'bg-primary': statusId === 3,
                'group-hover:bg-gray-200':
                  (statusId > 3 || statusId === 2) && mode === 'edit'
              }" class="absolute top-0 left-0 w-1 h-full bg-transparent lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
              aria-hidden="true"></span>
            <span class="px-6 py-2.5 flex items-center text-sm font-medium lg:pl-9 w-full">
              <span class="flex-shrink-0">
                <span [ngClass]="{
                    'border-primary': statusId >= 3,
                    'border-gray-300': statusId < 3,
                    'border-2': statusId < 3,
                    'bg-primary': statusId >= 3
                  }" class="w-10 h-10 flex items-center justify-center rounded-full">
                  <span [ngClass]="{
                      'text-primary': statusId >= 3,
                      'text-gray-500': statusId < 3
                    }">
                    <span *ngIf="statusId < 3">03</span>
                    <svg *ngIf="statusId >= 3" class="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                  </span>
                </span>
              </span>
              <div class="mt-0.5 ml-4 min-w-0 flex flex-col items-start">
                <span [ngClass]="{
                    'text-primary': statusId === 3,
                    'text-gray-500': statusId < 3
                  }" class="text-xs font-semibold tracking-wide uppercase whitespace-nowrap">fertig im Lager</span>
                <span class="text-sm font-medium text-gray-500">{{
                  getLastStatusMessage(3)?.created | date: "dd.MM.yyyy"
                  }}&nbsp;</span>
                <span class="text-xs font-medium text-gray-400 truncate">
                  {{ order.packer?.firstName }}
                  {{ order.packer?.lastName }}
                </span>
              </div>
            </span>
          </button>

          <!-- Separator -->
          <div class="hidden absolute top-0 left-0 w-3 inset-0 lg:block" aria-hidden="true">
            <svg class="h-full w-full text-gray-300" viewBox="0 0 12 82" fill="none" preserveAspectRatio="none">
              <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vector-effect="non-scaling-stroke" />
            </svg>
          </div>
        </div>
      </li>

      <!-- Status 4: ausgegeben -->
      <li class="relative overflow-hidden lg:flex-1">
        <div class="border border-gray-200 overflow-hidden border-t-0 rounded-b-md lg:border-0">
          <!-- Upcoming Step -->
          <button [disabled]="mode !== 'edit'" [ngClass]="{ 'cursor-default': statusId < 3 || mode !== 'edit' }"
            (click)="setOrderStatus(4)" class="group w-full">
            <span [ngClass]="{
                'bg-primary': statusId === 4,
                'group-hover:bg-gray-200': statusId === 3 && mode === 'edit'
              }" class="absolute top-0 left-0 w-1 h-full bg-transparent lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
              aria-hidden="true"></span>
            <span class="px-6 py-2.5 flex items-center text-sm font-medium lg:pl-9 w-full">
              <span class="flex-shrink-0">
                <span [ngClass]="{
                    'border-primary': statusId === 4,
                    'border-gray-300': statusId < 4,
                    'border-2': statusId <= 4,
                    'bg-primary': statusId >= 4
                  }" class="w-10 h-10 flex items-center justify-center rounded-full">
                  <span [ngClass]="{
                      'text-primary': statusId === 4,
                      'text-gray-500': statusId < 4
                    }">
                    <span *ngIf="statusId < 4">04</span>
                    <svg *ngIf="statusId >= 4" class="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                  </span>
                </span>
              </span>
              <div class="mt-0.5 ml-4 min-w-0 flex flex-col items-start">
                <span [ngClass]="{
                    'text-primary': statusId === 4,
                    'text-gray-500': statusId < 4
                  }" class="text-xs font-semibold tracking-wide uppercase">ausgegeben</span>
                <span class="text-sm font-medium text-gray-500 truncate">{{
                  getLastStatusMessage(4)?.created | date: "dd.MM.yyyy"
                  }}&nbsp;</span>
                <span class="text-xs">&nbsp;</span>
              </div>
            </span>
          </button>

          <!-- Separator -->
          <div class="hidden absolute top-0 left-0 w-3 inset-0 lg:block" aria-hidden="true">
            <svg class="h-full w-full text-gray-300" viewBox="0 0 12 82" fill="none" preserveAspectRatio="none">
              <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vector-effect="non-scaling-stroke" />
            </svg>
          </div>
        </div>
      </li>
    </ol>
  </nav>
</div>

<app-collector [(order)]="order" (close)="collectorModalActive = false; update()" *ngIf="collectorModalActive">
</app-collector>
<app-packer [(order)]="order" (close)="packerModalActive = false; update()" *ngIf="packerModalActive"></app-packer>
<app-sender [(order)]="order" (close)="senderModalActive = false; update()" *ngIf="senderModalActive"></app-sender>

<!-- Modal resetStatus -->

<div *ngIf="resetStatusModalId > 0" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title"
  role="dialog" aria-modal="true">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
    <div
      class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
      <div>
        <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
          <!-- Heroicon name: outline/check -->
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M12.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0019 16V8a1 1 0 00-1.6-.8l-5.333 4zM4.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0011 16V8a1 1 0 00-1.6-.8l-5.334 4z" />
          </svg>
        </div>
        <div class="mt-3 text-center sm:mt-5">
          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
            Bestellungsstatus zurücksetzen
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              Möchtest du den Status der Bestellung
              <span class="font-medium">{{ order.id }}</span> zurücksetzen?
            </p>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <button type="button" (click)="resetStatus(resetStatusModalId)"
          class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:col-start-2 sm:text-sm">
          Status zurücksetzen
        </button>
        <button type="button" (click)="resetStatusModalId = -1"
          class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:mt-0 sm:col-start-1 sm:text-sm">
          Abbrechen
        </button>
      </div>
    </div>
  </div>
</div>
