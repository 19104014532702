<div *ngIf="authSrv.authContext?.person" class="flex gap-2 items-center">
  <ng-container *ngFor="let route of secondaryRoutes">
    <div class="
        whitespace-nowrap
        justify-between
        select-none
        flex
        px-2
        py-2 rounded-md
        bg-gray-600
        hover:bg-gray-700
        w-48
        text-ellipsis
        transition
        duration-3000
        cursor-pointer
        space-x-2
      " (click)="onTabsChange(route)" [ngClass]="{
        'font-bold': route.bold,
        'bg-gray-700': activeRoute === route.route
      }">
      <span class="truncate max-w-32">{{ route.text }}</span>
      <button (click)="onCloseTab(route.route); $event.stopPropagation()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
  </ng-container>
</div>
