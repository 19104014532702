<ng-container *ngIf="!loggedIn">
  <button class="button-animation mt-4 rounded-md bg-primary px-2 py-3 text-white font-bold uppercase"
          (click)="login()">Login
  </button>
</ng-container>
<ng-container *ngIf="loggedIn">
  <button class="button-animation mt-4 rounded-md bg-primary px-2 py-3 text-white font-bold uppercase"
          (click)="logout()">Logout
  </button>
</ng-container>
