import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GroupFullSizeDto } from '../../../../../shared/dtos';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss'],
})
export class GroupListComponent implements OnInit {
  @Input() selectedGroupId: number = -1;
  @Input() groups: GroupFullSizeDto[] = [];
  @Input() mode: string = '';
  @Output() groupsChange: EventEmitter<GroupFullSizeDto[]> = new EventEmitter();
  @Output() groupSelected: EventEmitter<GroupFullSizeDto> = new EventEmitter();

  constructor(private menuSrv: MenuService) {}

  onGroupSelect(group: GroupFullSizeDto) {
    this.groupSelected.emit(group);
  }

  onOpenGroupTab(group: GroupFullSizeDto) {
    this.menuSrv.addTabAndOpen({
      text: `${group.name}`,
      route: ['groups', group?.id.toString()!],
      exact: true,
    });
  }

  ngOnInit(): void {}
}
