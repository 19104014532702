import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ArticleFullSizeDto, GenderLagerFullSizeDto } from '../../../../../shared/dtos';
import { CategoryFullSizeDto } from '../../../../../shared/dtos';
import { GenderFullSizeDto } from '../../../../../shared/dtos';
import { SizeFullSizeDto } from '../../../../../shared/dtos';
import { OrderFullSizeDto } from '../../../../../shared/dtos';
import { PartFullSizeDto } from '../../../../../shared/dtos';
import { ArticleService } from '../../services/article.service';

@Component({
  selector: 'app-compose-order-parts',
  templateUrl: './compose-order-parts.component.html',
  styleUrls: ['./compose-order-parts.component.scss'],
})
export class ComposeOrderPartsComponent implements OnInit, OnChanges {
  @Input() order: OrderFullSizeDto = new OrderFullSizeDto();
  @Output() orderChange: EventEmitter<OrderFullSizeDto> = new EventEmitter<OrderFullSizeDto>();

  @Input() selectedGender: GenderLagerFullSizeDto = new GenderLagerFullSizeDto();
  @Input() selectedCategory: CategoryFullSizeDto = new CategoryFullSizeDto();
  @Input() selectedSize: SizeFullSizeDto = new SizeFullSizeDto();

  articles: ArticleFullSizeDto[] = [];
  filteredArticles: ArticleFullSizeDto[] = [];

  selectedArticles: ArticleFullSizeDto[] = [];
  loading: boolean = false;

  constructor(private articleSrv: ArticleService) {}

  filterArticles() {
    this.filteredArticles = this.articles.filter((article) => {
      return (
        (this.selectedGender.id === 0 ||
          article.gender?.id === this.selectedGender.id) &&
        (this.selectedCategory.id === 0 ||
          article.category?.id === this.selectedCategory.id) &&
        (this.selectedSize.id === 0 ||
          article.size?.id === this.selectedSize.id)
      );
    });
  }

  onSearchUpdate(articles: ArticleFullSizeDto[]) {
    this.articles = articles;
    this.filterArticles();
  }

  onPartAdd(part: PartFullSizeDto) {
    const index = this.order.parts.findIndex(
      (part2) =>
        part2.article.id === part.article.id &&
        part2.fkSubArticle === part.fkSubArticle
    );

    if (index === -1) {
      part.fkOrder = this.order.id;
      part.order.id = this.order.id;
      this.order.parts.push(part);
    } else {
      this.order.parts[index].articleCount += part.articleCount;
    }
  }

  ngOnInit(): void {
    this.loading = true;
    this.articleSrv.findAll().subscribe((articles) => {
      this.loading = false;
      this.articles = articles;
      this.filteredArticles = articles;
    });
  }

  ngOnChanges() {
    this.filterArticles();
  }
}
