import { IsArray, IsBoolean, IsDateString, IsNumber, IsOptional, IsString, ValidateNested } from "class-validator";
import { BlockFullSizeDto } from "./block.full-size.dto";
import { PalletFullSizeDto } from "./pallet.full-size.dto";
import { Exclude, Expose, Type } from "class-transformer";


export class WarehouseFullSizeDto {

  @IsNumber()
  @Expose()
  id: number = 0;

  @IsDateString()
  @Exclude()
  createdAt: Date;

  @IsDateString()
  @Exclude()
  updatedAt: Date;

  @IsString()
  @IsOptional()
  @Expose()
  name: string | null = '';

  @IsString()
  @IsOptional()
  @Expose()
  description: string | null = '';

  @IsString()
  @IsOptional()
  @Expose()
  link: string | null = '';

  @IsNumber()
  @IsOptional()
  @Expose()
  size: number | null = 0;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkAddress: number | null = null;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkOrganisation: number | null = null;

  @IsBoolean()
  @IsOptional()
  @Expose()
  usedForStock: boolean | null = false;

  @IsArray()
  @ValidateNested({each: true})
  @Type(() => BlockFullSizeDto)
  @Expose()
  blocks: BlockFullSizeDto[] = [];

  @IsArray()
  @ValidateNested({each: true})
  @Type(() => PalletFullSizeDto)
  @Expose()
  pallets: PalletFullSizeDto[] = [];

}
