<div class="max-w-4xl mx-auto h-screenExceptMenu">
  <h1 class="pt-4 uppercase font-bold text-3xl">persönliche Einstellungen</h1>

  <div class="mt-2">
    <div class="flex flex-col space-y-2">
      <label for="warehouse" class="mt-3 text-sm font-medium text-gray-700"
        >aktuellen Lagerort wählen</label
      >
      <select
        [(ngModel)]="selectedWarehouseId"
        (ngModelChange)="onWarehouseSelect()"
        id="warehouse"
        class="ip mt-1"
      >
        <option [value]="{ id: 0 }" disabled>Lagerort auswählen</option>
        <option *ngFor="let warehouse of warehouses" [value]="warehouse.id">
          {{ warehouse.name }}
        </option>
      </select>

      <button
        (click)="onSave()"
        class="
          uppercase
          self-start
          text-sm
          font-bold
          py-2
          px-4
          rounded-lg
          shadow-md
          cursor-pointer
          bg-primary
          text-white
          hover:bg-primaryDark
        "
      >
        Speichern
      </button>
    </div>
  </div>
</div>
