import { IsArray, IsNumber, IsOptional, IsString, ValidateNested } from "class-validator";
import { ArticleFullSizeDto } from "./article.full-size.dto";
import { Expose, Type } from "class-transformer";

export class GenderLagerFullSizeDto {

  @IsNumber()
  @Expose()
  id: number = 0;

  @IsString()
  @Expose()
  gender: string = '';

  @IsString()
  @IsOptional()
  @Expose()
  nameDe: string | null = '';

  @IsString()
  @Expose()
  nameEn: string = '';

  @IsString()
  @Expose()
  nameKur: string = '';

  @IsString()
  @Expose()
  symbol: string = '';

  @IsArray()
  @Type(() => ArticleFullSizeDto)
  @ValidateNested({ each: true })
  @Expose()
  articles: ArticleFullSizeDto[] = [];
}
