import { Expose, Type } from "class-transformer";
import { IsArray, IsBoolean, IsDateString, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested } from "class-validator";
import { CreatePartDto } from "./create-part.dto";

export class CreateOrderDto {
  @IsNotEmpty()
  @IsNumber()
  @Expose()
  fkCamp: number;

  @IsNotEmpty()
  @IsNumber()
  @Expose()
  fkType: number;

  @IsBoolean()
  @Expose()
  isFlexible: boolean = false;

  @IsNumber()
  @Expose()
  fkCustomer: number;

  @IsDateString()
  @Expose()
  requiredDate: string | Date = new Date();

  @IsDateString()
  @IsOptional()
  @Expose()
  additionalDate: Date | null = null;

  @IsString()
  @Expose()
  notes: string = "";

  @IsBoolean()
  @Expose()
  prio: boolean = false;

  @IsArray()
  @Type(() => CreatePartDto)
  @Expose()
  parts: CreatePartDto[];
}