import { Injectable } from '@angular/core';
import { ArticleGroupFullSizeDto } from '../../../../shared/dtos';
import { HhHttpService } from './hh-http.service';

@Injectable({
  providedIn: 'root',
})
export class ArticleGroupService {
  constructor(private httpSrv: HhHttpService) {}

  findAll() {
    return this.httpSrv.get<ArticleGroupFullSizeDto[]>(`article-group`);
  }
}
