<div class="h-screenExceptMenu overflow-y-auto animate-fadeIn">
  <div class="h-full overflow-y-auto">
    <div class="max-w-7xl mx-auto">
      <div class="p-8 pb-4 text-4xl uppercase font-bold text-darkGray" *ngIf="loggedIn">
        Moin {{authSrv.authContext?.person?.firstName}}!
      </div>
      <div class="md:mx-8" *ngIf="authSrv.hasRight('maintain orders for own')">
        <div class="text-gray-600 font-medium max-w-prose mx-8 md:mx-0">
          hier hast du deine aktuellen Bestellungen im Überblick. Wenn du Rat suchst, stehen wir dir per Mail und per
          Telefon zur Verfügung.
        </div>
        <app-routing-dashboard-external></app-routing-dashboard-external>
      </div>
      <div *ngIf="authSrv.hasRight('view customers') || authSrv.hasRight('generic storage actions')" class="text-gray-600 font-medium max-w-prose mx-8">
        bitte wähle aus den folgenden Punkten:
      </div>
    </div>
    <div class="max-w-7xl mx-auto">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 m-8">
        <ng-container *ngFor="let route of routes">
          <button *ngIf="
            (route.rightText && authSrv.hasRight(route.rightText)) || !route.rightText
          " class="
            button-animation
            text-left
            flex flex-col
            bg-primary
            rounded-lg
            shadow-md
            p-4
          " [routerLink]="route.route">
            <div class="text-white text-xl font-bold uppercase">
              {{ route.text }}
            </div>
            <div class="text-primaryLightXX">{{ route.subText }}</div>
          </button>
        </ng-container>
      </div>
      <div *ngIf="authSrv.hasRight('see sent items')" class="flex flex-col items-center text-darkGray ">
        <div class="text-4xl font-bold">
          {{ sentItemsCount }}
        </div>
        <div class="text-md sm:text-xl font-medium pb-4">
          Artikel an Unterkünfte verschickt!
        </div>
      </div>
    </div>
  </div>
</div>
