import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { GroupFullSizeDto, PersonGroupFullSizeDto, UpdatePersonDto } from '../../../../../shared/dtos';
import { PersonFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { GroupService } from '../../services/group.service';
import { PersonGroupService } from '../../services/person-group.service';
import { PersonService } from '../../services/person.service';
import { TransformerWrapper } from '../../../../../shared/src/transformer';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-person-groups',
  templateUrl: './person-groups.component.html',
  styleUrls: ['./person-groups.component.scss'],
})
export class PersonGroupsComponent implements OnInit, OnChanges {
  @Input() updateDirectly: boolean = true;
  @Input() person: PersonFullSizeDto = new PersonFullSizeDto();
  @Output() personChange: EventEmitter<PersonFullSizeDto> = new EventEmitter<PersonFullSizeDto>();
  groups: GroupFullSizeDto[] = [];
  personGroups: GroupFullSizeDto[] = [];

  constructor(
    private groupSrv: GroupService,
    private personSrv: PersonService,
    private alertSrv: AlertService,
    public authSrv: AuthService,
    private personGroupSrv: PersonGroupService
  ) {}

  onGroupAdd(group: GroupFullSizeDto) {
    if (
      this.person.personGroups.findIndex(
        (personGroup) => personGroup.fkGroup === group.id
      ) === -1
    ) {
      const personGroup = new PersonGroupFullSizeDto();
      personGroup.fkPerson = this.person.id;
      personGroup.person.id = this.person.id;

      personGroup.fkGroup = group.id;
      personGroup.group = group;

      this.person.personGroups.push(personGroup);
      this.updatePersonGroups();
      if (this.updateDirectly) {
        this.personSrv.addGroup(this.person.id, group.id).subscribe();
      }
    }
  }

  onGroupRemove(group: GroupFullSizeDto) {
    const index = this.person.personGroups.findIndex(
      (personGroup) => personGroup.fkGroup === group.id
    );
    if (index >= 0) {
      this.person.personGroups.splice(index, 1);
      this.updatePersonGroups();
      if (this.updateDirectly) {
        this.personSrv.removeGroup(this.person.id, group.id).subscribe();
      }
    }
  }

  update() {
    if (this.person.id) {
      this.personSrv.findById(this.person.id).subscribe((person) => {
        this.person = person;
        this.updatePersonGroups();
      });
    } else {
      this.personGroups = this.person.personGroups.map(
        (personGroup) => personGroup.group
      );
    }
  }

  saveChanges() {
    this.personSrv.update(this.person.id, TransformerWrapper.plainToInstanceExclude(UpdatePersonDto, this.person)).subscribe(
      (person) => {
        this.person = person;
        this.alertSrv.alert.next({
          text: 'Änderung gespeichert.',
          linkText: '',
        });
      },
      (err) => {
        this.alertSrv.alert.next({
          text: 'Änderung konnte nicht gespeichert werden.',
          linkText: '',
        });
      }
    );
  }

  updatePersonGroups() {
    if (this.person.personGroups) {
      this.personGroups = this.person.personGroups.map(
        (personGroup) => personGroup.group
      );
    }
  }

  ngOnInit(): void {
    this.groupSrv.findAll().subscribe((groups) => {
      this.groups = groups;
    });
  }

  ngOnChanges() {
    this.update();
  }
}
