import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RightFullSizeDto } from '../../../../../shared/dtos';

@Component({
  selector: 'app-right-list',
  templateUrl: './right-list.component.html',
  styleUrls: ['./right-list.component.scss'],
})
export class RightListComponent implements OnInit {
  @Input() rights: RightFullSizeDto[] = [];
  @Input() mode: string = '';
  @Output() rightsChange: EventEmitter<RightFullSizeDto[]> = new EventEmitter();
  @Output() rightSelected: EventEmitter<RightFullSizeDto> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
