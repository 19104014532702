<div class="mt-1 flex flex-col">
  <button
    (click)="onSave()"
    class="
      mt-2
      uppercase
      text-sm
      font-bold
      py-2
      px-4
      rounded-lg
      shadow-md
      cursor-pointer
      bg-white
      hover:bg-gray-100
      text-primary
      active:bg-primary active:text-white
    "
  >
    Auf neue Palette
  </button>
</div>
