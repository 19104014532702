<div class="mt-2 flex space-x-4">
  <div class="flex-1 min-w-0">
    <label for="search" class="sr-only">Suchen</label>
    <div class="relative rounded-md shadow-sm">
      <div
        class="
          absolute
          inset-y-0
          left-0
          pl-3
          flex
          items-center
          pointer-events-none
        "
      >
        <!-- Heroicon name: solid/search -->
        <svg
          class="h-5 w-5 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <input
        type="search"
        name="search"
        id="search"
        [(ngModel)]="q"
        (keyup)="onSearch(q)"
        class="
          focus:ring-primary focus:border-primary
          block
          w-full
          pl-10
          sm:text-sm
          border-gray-300
          rounded-md
        "
        placeholder="Suchen"
      />
    </div>
  </div>
</div>
