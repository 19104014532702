<app-loader *ngIf="loading"></app-loader>
<div *ngIf="!loading" class="h-screenExceptMenu flex flex-col overflow-y-hidden animate-fadeIn">
  <div class="px-4 mt-6">
    <div class="flex space-x-2">
      <h1 class="text-2xl font-bold uppercase">
        {{
          order.id ? "Bestellung " + order.id : "Neue Bestellung erstellen"
        }}
      </h1>
      <button class="text-gray-900 disabled:text-gray-500 disabled:cursor-not-allowed" tooltip="Bestellung speichern"
              (click)="onSave()" [disabled]="!order.fkCamp || !order.fkCustomer">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 " fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"/>
        </svg>
      </button>
      <button tooltip="Änderungen verwerfen" (click)="onNavigateBack()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-900" fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
      </button>
    </div>
  </div>
  <!-- Tabs -->
  <div *ngIf="showOrderDetails" class="mt-6 sm:mt-2 2xl:mt-5 flex-shrink-0">
    <div class="border-b border-gray-200">
      <div class="px-4 sm:px-6 lg:px-8">
        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
          <!-- Current: "border-blue-500 text-gray-900", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
          <a (click)="onSetView('details')" class="
              cursor-pointer
              text-gray-500
              whitespace-nowrap
              py-4
              px-1
              border-b-2
              font-medium
              text-sm
            " [ngClass]="{
              'border-primary text-gray-900': view === 'details'
            }" aria-current="page">
            Details
          </a>
          <a *ngIf="order.fkStatus <= 2" (click)="onSetView('filter')" class="
              cursor-pointer
              text-gray-500
              whitespace-nowrap
              py-4
              px-1
              border-b-2
              font-medium
              text-sm
            " [ngClass]="{
              'border-primary text-gray-900': view === 'filter'
            }" aria-current="page">
            Artikelfilter
          </a>
        </nav>
      </div>
    </div>
  </div>
  <div class="px-4 border-b border-gray-300 pb-4 h-80 overflow-y-auto" *ngIf="view === 'filter'">
    <app-article-filter (genderSelected)="selectedGender = $event" (categorySelected)="selectedCategory = $event"
                        (sizeSelected)="selectedSize = $event"></app-article-filter>
  </div>
  <div *ngIf="view === 'details'" class="px-4 mt-6 pb-4 border-b border-gray-300">
    <div *ngIf="showOrderDetails">
      <div class="flex-shrink-0 flex space-x-4 mt-2 w-full grow">
        <div class="grow w-full max-w-md">
          <div (clickOutside)="campSearchActive = false">
            <label id="listbox-label" class="block text-sm font-medium text-gray-700">
              für Organisation
            </label>
            <div class="mt-1 relative">
              <input [(ngModel)]="campStr" (click)="onCampSearchInit()" (ngModelChange)="onCampSearch(campStr)"
                     type="text" class="
                  bg-white
                  relative
                  w-full
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  pl-3
                  pr-10
                  py-2
                  text-left
                  cursor-default
                  focus:outline-none
                  focus:ring-1
                  focus:ring-primary
                  focus:border-primary
                  sm:text-sm
                " aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label"/>
              <div class="absolute inset-y-0 left-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <app-loader *ngIf="campLoading" [fixedHeight]="false" class="h-6"></app-loader>
              </div>
              <button type="button"
                      class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                        d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                        clip-rule="evenodd"/>
                </svg>
              </button>
              <!--
        Select popover, show/hide based on select state.

        Entering: ""
          From: ""
          To: ""
        Leaving: "transition ease-in duration-100"
          From: "opacity-100"
          To: "opacity-0"
      -->
              <ul *ngIf="campSearchActive" class="
                  absolute
                  z-10
                  mt-1
                  w-full
                  bg-white
                  shadow-lg
                  max-h-60
                  rounded-md
                  pt-1
                  text-base
                  ring-1 ring-black ring-opacity-5
                  overflow-auto
                  focus:outline-none
                  sm:text-sm
                " tabindex="-1" role="listbox" aria-labelledby="listbox-label"
                  aria-activedescendant="listbox-option-3">
                <!--
          Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

          Highlighted: "text-white bg-primary", Not Highlighted: "text-gray-900"
        -->

                <li (click)="onCampSelect(camp)" *ngFor="let camp of camps" class="
                    text-gray-900
                    cursor-default
                    select-none
                    relative
                    py-2
                    px-3
                    hover:bg-gray-100
                  " id="listbox-option-0" role="option">
                  <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                  <div class="flex gap-2 items-center justify-between w-full">
                    <div class="flex flex-col">
                      <span class="font-bold line-clamp-1">{{ camp.name }}</span>
                      <span class="font-normal text-gray-700 line-clamp-1">{{ camp.street }} {{ camp.streetNumber }}</span>
                      <span class="font-normal text-gray-700 line-clamp-1">{{ camp.postCode }} {{ camp.city }}</span>
                    </div>
                    <button tooltip="in neuem Tab öffnen" (click)="onOpenCampTab(camp); $event.stopPropagation()">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-700" fill="none"
                           viewBox="0 0 24 24"
                           stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"/>
                      </svg>
                    </button>
                  </div>


                  <!--
            Checkmark, only display for selected option.

            Highlighted: "text-white", Not Highlighted: "text-primary"
          -->
                  <span class="
                      text-primary
                      absolute
                      inset-y-0
                      right-0
                      flex
                      items-center
                      pr-4
                    ">
                  </span>
                </li>
                <li class="p-2.5 bg-gray-100" *ngIf="camps.length === 0 && !campLoading">
                  Keine Orga gefunden!
                </li>
                <li class="p-2.5 bg-gray-100" *ngIf="omitingAllCamps">
                  Für weitere Orgas bitte Suche verfeinern!
                </li>
                <li>
                  <app-loader *ngIf="campLoading" [fixedHeight]="false" class="h-6"></app-loader>
                </li>

              </ul>
            </div>
          </div>

          <div *ngIf="campLoading"></div>
          <!-- Persons -->
          <div (clickOutside)="personSearchActive = false" class="mt-2 flex-shrink-0" *ngIf="order && order?.camp">
            <label id="listbox-label" class="block text-sm font-medium text-gray-700">
              für Person
            </label>
            <div class="mt-1 relative">
              <input [disabled]="!order!.camp || !order.camp.id || campLoading"
                     (click)="personSearchActive = true; campSearchActive = false" [(ngModel)]="personStr"
                     (ngModelChange)="onPersonSearch(personStr)" type="text" class="
                  bg-white
                  relative
                  w-full
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  pl-3
                  pr-10
                  py-2
                  text-left
                  cursor-default
                  focus:outline-none
                  focus:ring-1
                  focus:ring-primary
                  focus:border-primary
                  sm:text-sm
                  disabled:border-dashed
                " aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label"/>
              <button type="button"
                      class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                        d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                        clip-rule="evenodd"/>
                </svg>
              </button>
              <!--
        Select popover, show/hide based on select state.

        Entering: ""
          From: ""
          To: ""
        Leaving: "transition ease-in duration-100"
          From: "opacity-100"
          To: "opacity-0"
      -->
              <ul *ngIf="personSearchActive" class="
                  absolute
                  z-10
                  mt-1
                  w-full
                  bg-white
                  shadow-lg
                  max-h-60
                  rounded-md
                  py-1
                  text-base
                  ring-1 ring-black ring-opacity-5
                  overflow-auto
                  focus:outline-none
                  sm:text-sm
                " tabindex="-1" role="listbox" aria-labelledby="listbox-label"
                  aria-activedescendant="listbox-option-3">
                <!--
          Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

          Highlighted: "text-white bg-primary", Not Highlighted: "text-gray-900"
        -->
                <li (click)="onPersonSelect(person)" *ngFor="let person of filteredCustomers" class="
                    text-gray-900
                    cursor-default
                    select-none
                    relative
                    py-2
                    px-3
                    hover:bg-gray-100
                  " id="listbox-option-0" role="option">
                  <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                  <div class="flex items-center justify-between w-full gap-2">
                    <div class="flex flex-col w-full">
                      <span class="font-bold line-clamp-1">{{ person.firstName }} {{ person.lastName }}</span>
                      <span class="font-normal text-gray-700 truncate">{{ person.eMail_1 || person.eMail_2 }}</span>
                    </div>
                    <button tooltip="in neuem Tab öffnen" (click)="onOpenPersonTab(person); $event.stopPropagation()">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-700" fill="none"
                           viewBox="0 0 24 24"
                           stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"/>
                      </svg>
                    </button>
                  </div>

                  <!--
            Checkmark, only display for selected option.

            Highlighted: "text-white", Not Highlighted: "text-primary"
          -->
                  <span class="
                      text-primary
                      absolute
                      inset-y-0
                      right-0
                      flex
                      items-center
                      pr-4
                    ">
                  </span>
                </li>

                <li (click)="personSearchActive = false; personStr = ''"
                    *ngIf="!order.camp.persons || order.camp.persons.length === 0" class="
                    text-gray-900
                    cursor-default
                    select-none
                    relative
                    py-2
                    pl-3
                    pr-9
                    hover:bg-gray-100
                  " id="listbox-option-0" role="option">
                  <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                  <span class="font-normal block truncate">
                    keine Person zugeordnet
                  </span>

                  <!--
            Checkmark, only display for selected option.

            Highlighted: "text-white", Not Highlighted: "text-primary"
          -->
                  <span class="
                      text-primary
                      absolute
                      inset-y-0
                      right-0
                      flex
                      items-center
                      pr-4
                    ">
                  </span>
                </li>

                <!-- More items... -->
              </ul>
            </div>
          </div>
        </div>

        <div>
          <div>
            <label id="listbox-label" class="block text-sm font-medium text-gray-700">
              Fälligkeit
            </label>
            <input [value]="order.requiredDate | date: 'yyyy-MM-dd'" (input)="
                order.requiredDate = parseDate($any($event.target).value)
              " type="date" class="
                mt-1
                bg-white
                relative
                w-full
                border border-gray-300
                rounded-md
                shadow-sm
                pl-3
                pr-10
                py-2
                text-left
                cursor-default
                focus:outline-none
                focus:ring-1
                focus:ring-primary
                focus:border-primary
                sm:text-sm
                disabled:border-dashed
              "/>
          </div>
          <div class="mt-2">
            <div id="listbox-label" class="
                text-sm
                font-medium
                text-gray-700
                flex
                items-center
                space-x-2
              ">
              <span>Fälligkeit Alternative</span>
              <input [(ngModel)]="additionalDateActive" (change)="onAdditionalDateToggle()" type="checkbox" class="
                  focus:ring-primary
                  h-4
                  w-4
                  text-primary
                  border-gray-300
                  rounded
                "/>
            </div>
            <input [disabled]="!additionalDateActive" [value]="order.additionalDate | date: 'yyyy-MM-dd'" (input)="
                order.additionalDate = parseDate($any($event.target).value)
              " type="date" class="
                mt-1
                bg-white
                relative
                w-full
                border border-gray-300
                rounded-md
                shadow-sm
                pl-3
                pr-10
                py-2
                text-left
                cursor-default
                focus:outline-none
                focus:ring-1
                focus:ring-primary
                focus:border-primary
                sm:text-sm
                disabled:border-dashed
              "/>
          </div>
        </div>
        <div>
          <h2 class="text-sm font-medium text-gray-700" *ngIf="order">
            Optionen
          </h2>
          <div class="relative flex items-start mt-2">
            <div class="flex items-center h-5">
              <input id="prio" aria-describedby="prio-description" name="prio" [(ngModel)]="order.prio" type="checkbox"
                     class="
                  focus:ring-primary
                  h-4
                  w-4
                  text-primary
                  border-gray-300
                  rounded
                "/>
            </div>
            <div class="ml-3 text-sm">
              <label for="prio" class="font-medium text-gray-700">Bestellung eilt</label>
            </div>
          </div>
          <div class="flex items-center mt-4 space-x-2">
            <span class="text-sm font-medium text-gray-700">Lieferung</span>
            <button (click)="onOrderTypeToggle()" [ngClass]="{ 'bg-primary': order.type.id === 1 }" type="button"
                    class="
                bg-gray-200
                relative
                inline-flex
                flex-shrink-0
                h-6
                w-11
                border-2 border-transparent
                rounded-full
                cursor-pointer
                transition-colors
                ease-in-out
                duration-200
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-primary
              " role="switch" aria-checked="false">
              <span [ngClass]="order.type.id === 1 ? 'translate-x-5' : 'translate-x-0'" aria-hidden="true" class="
                  pointer-events-none
                  inline-block
                  h-5
                  w-5
                  rounded-full
                  bg-white
                  shadow
                  transform
                  ring-0
                  transition
                  ease-in-out
                  duration-200
                "></span>
            </button>
            <span class="text-sm font-medium text-gray-700">Abholung</span>
          </div>
          <div class="flex items-center mt-4 space-x-2">
            <span class="text-sm font-medium text-gray-700">Normale Bestellung</span>
            <button (click)="order.isFlexible = !order.isFlexible" [ngClass]="{ 'bg-primary': order.isFlexible }"
                    type="button" class="
                bg-gray-200
                relative
                inline-flex
                flex-shrink-0
                h-6
                w-11
                border-2 border-transparent
                rounded-full
                cursor-pointer
                transition-colors
                ease-in-out
                duration-200
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-primary
              " role="switch" aria-checked="false">
              <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
              <span [ngClass]="order.isFlexible ? 'translate-x-5' : 'translate-x-0'" aria-hidden="true" class="
                  pointer-events-none
                  inline-block
                  h-5
                  w-5
                  rounded-full
                  bg-white
                  shadow
                  transform
                  ring-0
                  transition
                  ease-in-out
                  duration-200
                "></span>
            </button>
            <span class="text-sm font-medium text-gray-700">Flexible Bestellung</span>
          </div>
        </div>
      </div>
      <label id="listbox-label" class="block text-sm font-medium text-gray-700 mt-2">
        Bemerkungen (bitte sparsam!)
      </label>
      <div class="w-full mt-1">
        <textarea rows="3" [(ngModel)]="order.notes" class="
            h-16
            bg-white
            relative
            w-full
            border border-gray-300
            rounded-md
            shadow-sm
            pl-3
            pr-10
            py-2
            text-left
            cursor-default
            focus:outline-none
            focus:ring-1
            focus:ring-primary
            focus:border-primary
            sm:text-sm
          " name="" id="" cols="30" rows="10"></textarea>
      </div>
    </div>
    <div class="w-full flex items-center justify-center mt-2">
      <button (click)="showOrderDetails = !showOrderDetails">
        <svg xmlns="http://www.w3.org/2000/svg" [ngClass]="{ '-rotate-180': showOrderDetails }"
             class="h-6 w-6 transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
        </svg>
      </button>
    </div>
  </div>

  <div class="h-full overflow-y-auto">
    <app-compose-order-parts class="h-full overflow-y-auto" [(order)]="order" [selectedGender]="selectedGender"
                             [selectedCategory]="selectedCategory"
                             [selectedSize]="selectedSize"></app-compose-order-parts>
  </div>
</div>
