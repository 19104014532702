import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  ArticleFullSizeDto,
  BoxFullSizeDto,
  CreateBoxDto,
  DepartmentFullSizeDto,
} from '../../../../../shared/dtos';
import { OrderFullSizeDto } from '../../../../../shared/dtos';
import { PartFullSizeDto } from '../../../../../shared/dtos';
import { MenuService } from '../../services/menu.service';
import { OrderService } from '../../services/order.service';
import { BoxService } from '../../services/box.service';
import { AlertService } from '../../services/alert.service';
import { PartService } from '../../services/part.service';
import { DepartmentService } from '../../services/department.service';
import { TransformerWrapper } from '../../../../../shared/src/transformer';
import { UpdatePartDto } from '../../../../../shared/src/dtos/order/update-part.dto';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-order-parts',
  templateUrl: './order-parts.component.html',
  styleUrls: ['./order-parts.component.scss'],
})
export class OrderPartsComponent implements OnInit, OnChanges {
  @Input() order: OrderFullSizeDto = new OrderFullSizeDto();
  @Output() orderChange = new EventEmitter<OrderFullSizeDto>();
  @Input() mode: string = 'view';
  selectedPart: PartFullSizeDto = new PartFullSizeDto();
  selectedPart4NewBox: PartFullSizeDto = new PartFullSizeDto();
  newBoxModalActive: boolean = false;
  partLoading: number = 0;
  allDepartments: DepartmentFullSizeDto[] = [];
  departments: DepartmentFullSizeDto[] = [];
  filteredParts: PartFullSizeDto[] = [];
  selectedDepartment: DepartmentFullSizeDto | undefined = undefined;
  enableFilters: boolean = false;

  constructor(
    private orderSrv: OrderService,
    private menuSrv: MenuService,
    private boxSrv: BoxService,
    private partSrv: PartService,
    private alertSrv: AlertService,
    private departmentSrv: DepartmentService,
  ) {}

  onOpenPartDetails(part: PartFullSizeDto) {
    this.menuSrv.addTabAndOpen({
      text: `Best.-Teil ${part!.id}`,
      route: ['parts', part?.id.toString()!],
      exact: true,
    });
  }

  getRowsString(article: ArticleFullSizeDto) {
    if (article.hashtagRows) {
      return article.hashtagRows.map((row) => {
        return row.block.name + row.row;
      });
    }
    return '';
  }

  onPartDelete(index: number) {
    this.filteredParts.splice(index, 1);
  }

  onNewBoxAdd(part: PartFullSizeDto) {
    this.selectedPart4NewBox = part;
    this.newBoxModalActive = true;
  }

  onCheckPart(part: PartFullSizeDto) {
    const missingArticleCount = part.articleCount - part.foundCount;
    const newBox = new CreateBoxDto();
    newBox.articleCount = missingArticleCount;
    newBox.fkArticle = part.fkArticle;
    newBox.fkSubArticle = part.fkSubArticle || undefined;
    newBox.fkPart = part.id;
    this.partSrv.addBox(part.id, newBox).subscribe({
      next: async (box) => {
        this.alertSrv.alert.next({
          text: `Karton ${box.id} erstellt.`,
          linkText: 'Rückgängig',
          linkAction: this.undoCheckPart.bind(this, box, part),
        });
        this.update();
      },
      error: (err) => {
        this.alertSrv.alert.next({
          text: `Karton konnte nicht erstellt werden.`,
          linkText: '',
        });
      },
    });
  }

  filterDepartments() {
    this.departments = this.allDepartments.filter((department) =>
      this.order.parts.some(
        (part) =>  parseInt(part.article?.fkDepartment + '') === department.id,
      ),
    );
  }

  async undoCheckPart(box: BoxFullSizeDto, part: PartFullSizeDto) {
    await firstValueFrom(this.partSrv.removeBox(part.id, box.id));
    this.alertSrv.alert.next({
      text: `Karton ${box.id} gelöscht.`,
      linkText: '',
    });
    this.update();
  }

  selectDepartment(department: DepartmentFullSizeDto | undefined) {
    this.selectedDepartment =
      department?.id === this.selectedDepartment?.id ? undefined : department;
    if (this.selectedDepartment?.id) {
      this.filterParts();
    } else {
      this.filteredParts = this.order.parts;
    }
  }

  filterParts() {
    if (!this.selectedDepartment) {
      this.filteredParts = this.order.parts;
      return;
    }

    this.filteredParts = this.order.parts.filter(
      (part) => parseInt(part.article?.fkDepartment + '') === this.selectedDepartment?.id,
    );
  }

  update() {
    if (this.order.id > 0) {
      this.orderSrv.findById(this.order?.id).subscribe((order) => {
        this.order = order;
        this.filterParts();
        this.partLoading = 0;
      });
    }
  }

  ngOnInit(): void {
    this.orderSrv.updateOneOrderTrigger.subscribe(() => this.update());
    this.boxSrv.boxDeleteTrigger.subscribe(() => this.update());

    this.departmentSrv.findAll().subscribe((departments) => {
      this.allDepartments = departments.filter(
        (department) => department.showInArticleFilter,
      );
      this.departments = this.allDepartments;
      this.filterDepartments();
    });
  }

  ngOnChanges() {
    if (this.mode === 'view') {
      this.update();
    }
    this.filteredParts = this.order.parts;
    this.filterDepartments();
  }
}
