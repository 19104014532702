import { Injectable } from '@angular/core';
import { SizeFullSizeDto } from '../../../../shared/dtos';
import { HhHttpService } from './hh-http.service';

@Injectable({
  providedIn: 'root',
})
export class SizeService {
  constructor(private httpSrv: HhHttpService) {}

  findAll() {
    return this.httpSrv.get<SizeFullSizeDto[]>(`size`);
  }

  findByCategoryId(categoryId: number) {
    return this.httpSrv.get<SizeFullSizeDto[]>(`category/${categoryId}/sizes`);
  }
}
