import { Component, Input, OnInit } from '@angular/core';
import { OrderFullSizeDto } from '../../../../../shared/dtos';

@Component({
  selector: 'app-order-list-light-elem',
  templateUrl: './order-list-light-elem.component.html',
  styleUrls: ['./order-list-light-elem.component.scss']
})
export class OrderListLightElemComponent implements OnInit {

  @Input() order: OrderFullSizeDto = new OrderFullSizeDto();

  constructor() { }

  ngOnInit(): void {
  }

}
