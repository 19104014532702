<button
  (click)="onDelete()"
  class="
    button-animation
    mt-2
    uppercase
    text-sm
    font-bold
    py-2
    px-4
    rounded-lg
    shadow-md
    cursor-pointer
    bg-white
    hover:bg-gray-100
    text-red-500
    active:bg-red-500 active:text-white
  "
>
  Wirklich löschen
</button>
