import { Component, Input, OnInit } from '@angular/core';
import { GroupedOrders } from '../../../../../shared/src/dtos/GroupedOrders';

@Component({
  selector: 'app-order-list-light',
  templateUrl: './order-list-light.component.html',
  styleUrls: ['./order-list-light.component.scss'],
})
export class OrderListLightComponent implements OnInit {
  @Input() orders: GroupedOrders = {};
  @Input() campId: number = 0;

  constructor() { }

  ngOnInit(): void { }
}
