<div>
  <div class="max-w-5xl mx-auto px-4">
    <nav class="-mb-px flex justify-around" aria-label="Tabs">
      <a (click)="setStatusId(1)" class="
          cursor-pointer
          text-gray-500
          whitespace-nowrap
          py-2
          px-1
          border-b-2
          font-medium
          text-xs
        " [ngClass]="{
          'border-primary text-gray-900':
            statusId === 1
        }" aria-current="page">
        bestellt
      </a>

      <a (click)="setStatusId(2)" class="
          cursor-pointer
          text-gray-500
          whitespace-nowrap
          py-2
          px-1
          border-b-2
          font-medium
          text-xs
        " [ngClass]="{
          'border-primary text-gray-900 ':
            statusId === 2
        }" aria-current="page">
        wird gepackt
      </a>

      <a (click)="setStatusId(3)" class="
          cursor-pointer
          text-gray-500
          whitespace-nowrap
          py-2
          px-1
          border-b-2
          font-medium
          text-xs
        " [ngClass]="{
          'border-primary text-gray-900 ':
            statusId === 3
        }">
        fertig
      </a>

      <a (click)="setStatusId(4)" class="
          cursor-pointer
          text-gray-500
          whitespace-nowrap
          py-2
          px-1
          border-b-2
          font-medium
          text-xs
        " [ngClass]="{
          'border-primary text-gray-900 ':
            statusId === 4
        }">
        ausgegeben
      </a>
    </nav>
  </div>
</div>
