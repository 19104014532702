import { Component, OnInit } from '@angular/core';
import { BlockFullSizeDto } from '../../../../../shared/dtos';
import { BoxFullSizeDto } from '../../../../../shared/dtos';
import { PalletFullSizeDto } from '../../../../../shared/dtos';

@Component({
  selector: 'app-routing-blocks',
  templateUrl: './routing-blocks.component.html',
  styleUrls: ['./routing-blocks.component.scss'],
})
export class RoutingBlocksComponent implements OnInit {
  selectedBlock: BlockFullSizeDto = new BlockFullSizeDto();
  selectedPallet: PalletFullSizeDto = new PalletFullSizeDto();
  selectedBox: BoxFullSizeDto = new BoxFullSizeDto();
  constructor() {}

  onBlockSelected(block: BlockFullSizeDto) {
    this.selectedBlock = block;
    this.selectedPallet = new PalletFullSizeDto();
    this.selectedBox = new BoxFullSizeDto();
  }

  onPalletSelected(pallet: PalletFullSizeDto) {
    this.selectedPallet = pallet;
    this.selectedBox = new BoxFullSizeDto();
  }

  onBoxSelected(box: BoxFullSizeDto) {
    this.selectedBox = box;
  }

  ngOnInit(): void {}
}
