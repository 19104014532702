import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HashtagRowFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { HashtagRowService } from '../../services/hashtag-row.service';

@Component({
  selector: 'app-change-row-number',
  templateUrl: './change-row-number.component.html',
  styleUrls: ['./change-row-number.component.scss'],
})
export class ChangeRowNumberComponent implements OnInit {
  @Input() row: HashtagRowFullSizeDto = new HashtagRowFullSizeDto();
  @Output() rowChange: EventEmitter<HashtagRowFullSizeDto> = new EventEmitter();

  constructor(
    private rowSrv: HashtagRowService,
    private alertSrv: AlertService
  ) {}

  onSave() {
    this.rowSrv.update(this.row).subscribe(
      (row) => {
        this.row = row;
        this.rowChange.emit(this.row);
        this.alertSrv.alert.next({
          text: 'Gespeichert.',
          linkText: '',
        });
      },
      (err) => {
        this.alertSrv.alert.next({
          text: 'Reihe konnte nicht gespeichert werden.',
          linkText: '',
        });
      }
    );
  }

  ngOnInit(): void {}
}
