import { Component, OnInit } from '@angular/core';
import { BoxFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { BoxService } from '../../services/box.service';

@Component({
  selector: 'app-box-inventory',
  templateUrl: './box-inventory.component.html',
  styleUrls: ['./box-inventory.component.scss'],
})
export class BoxInventoryComponent implements OnInit {
  boxStr: string = '';
  boxes: BoxFullSizeDto[] = [];

  constructor(private boxSrv: BoxService, private alertSrv: AlertService) {}

  onAddBox() {
    const boxId: number = this.boxStr.includes('boxID=')
      ? parseInt(this.boxStr.split('boxID=')[1])
      : parseInt(this.boxStr);
    this.boxSrv.inventory(boxId).subscribe(
      (box) => {
        this.boxes.push(box);
        this.alertSrv.alert.next({
          text: `Karton ${box.id} hinzugefügt.`,
          linkText: '',
        });
      },
      () => {
        this.alertSrv.alert.next({
          text: `Karton nicht gefunden.`,
          linkText: '',
        });
      }
    );
  }

  ngOnInit(): void {}
}
