import { IsArray, IsNumber, IsString, ValidateNested } from "class-validator";
import { OrderFullSizeDto } from "./order.full-size.dto";
import { Expose, Type } from "class-transformer";


export class TypeFullSizeDto {

  @IsNumber()
  @Expose()
  id: number = 1;

  @IsString()
  @Expose()
  name: string = '';

  @IsArray()
  @Type(() => OrderFullSizeDto)
  @ValidateNested({ each: true })
  @Expose()
  orders: OrderFullSizeDto[] = [];
}
