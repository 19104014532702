<app-loader *ngIf="loading"></app-loader>
<div class="h-full" *ngIf="!loading">
  <div class="flex-shrink-0 px-4 mt-6 pb-4">
    <div>
      <div class="flex space-x-2">
        <h1 class="text-2xl font-bold uppercase">
          {{
          person.id
          ? person.firstName + " " + person.lastName + " bearbeiten"
          : "Neue Person anlegen"
          }}
        </h1>
        <button [disabled]="!formValid" [tooltip]="formValid ? 'Person speichern' : 'Bitte Fehler beachten'" (click)="onSave()">
          <svg *ngIf="person.id" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none"
            viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
          </svg>
        </button>
        <button tooltip="Änderungen verwerfen" [routerLink]="!!person.id 
          ? [
            '/persons',
            { outlets: { person: [person.id.toString()] } }
          ] 
          : '/persons'">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </button>
      </div>

      <div>
        <div class="flex space-x-4 mt-4 border-t border-gray-300">
          <h2 class="font-bold uppercase mt-6 w-64">Account-Infos</h2>

          <div class="flex flex-col">
            <div class="w-full">
              <div class="mt-3 flex-1 pr-4">
                <label class="block text-sm font-medium text-gray-700">
                  Anrede
                </label>
                <select type="text" class="ip mt-1 w-1/2" [(ngModel)]="person.gender">
                  <option disabled>Anrede auswählen</option>
                  <option [value]="1">Frau</option>
                  <option [value]="2">Herr</option>
                  <option [value]="3">Nicht angeben</option>
                </select>
              </div>
            </div>
            <div class="flex space-x-4">
              <div class="mt-3 flex-1 w-72">
                <label class="block text-sm font-medium text-gray-700">
                  Vorname *
                </label>
                <input (change)="validate()" type="text" class="ip mt-1 w-full" [(ngModel)]="person.firstName" />
              </div>

              <div class="mt-3 flex-1 w-72">
                <label class="block text-sm font-medium text-gray-700">
                  Nachname *
                </label>
                <input (change)="validate()" type="text" class="ip mt-1 w-full" [(ngModel)]="person.lastName" />
              </div>
            </div>
            <div>
              <div class="mt-3 flex-1">
                <label class="block text-sm font-medium text-gray-700">
                  E-Mail-Adresse
                </label>
                <input (change)="checkDuplicateEmail()" [disabled]="!!person.keycloakId" type="text" class="ip mt-1 w-72 disabled:bg-gray-50" [(ngModel)]="person.eMail_1" />
                <p *ngIf="duplicateEmail === true" class="mt-2 text-sm text-red-500" id="email-error">Die gewählte Adresse wird bereits verwendet!</p>
                <p *ngIf="duplicateEmail === false" class="mt-2 text-sm text-green-500" id="email-error">Die gewählte Adresse wird noch nicht verwendet.</p>
                <p class="mt-2 text-sm text-gray-500" id="email-description">Diese Adresse kann zum Anmelden verwendet werden. Sie kann nach dem Zuordnen nicht verändert werden.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex space-x-4 mt-4 border-t border-gray-300">
          <h2 class="font-bold uppercase mt-6 w-64">Kontaktmöglichkeiten</h2>
          <div class="flex flex-col">
            <div class="flex space-x-4 w-full">
              <div class="mt-3 flex-1">
                <label class="block text-sm font-medium text-gray-700">
                  alternative E-Mail-Adresse
                </label>
                <input type="text" class="ip mt-1 w-72" [(ngModel)]="person.eMail_2" />
              </div>
            </div>

            <div class="flex space-x-4 w-full">
              <div class="mt-3 flex-1 w-72">
                <label class="block text-sm font-medium text-gray-700">
                  Telefonnummer
                </label>
                <input type="text" class="ip mt-1 w-full" [(ngModel)]="person.phone_1" />
              </div>
              <div class="mt-3 flex-1 w-72">
                <label class="block text-sm font-medium text-gray-700">
                  alternative Telefonnummer (z.B. Mobil)
                </label>
                <input type="text" class="ip mt-1 w-full" [(ngModel)]="person.phone_2" />
              </div>
            </div>
          </div>
        </div>

        <div class="flex space-x-4 mt-4 border-t border-gray-300">
          <h2 class="font-bold uppercase mt-6 w-64">Anschrift</h2>
          <div class="flex flex-col">
            <div class="flex space-x-4 w-full">
              <div class="mt-3 flex-1 w-72">
                <label class="block text-sm font-medium text-gray-700">
                  Firma
                </label>
                <input type="text" class="ip mt-1 w-full" [(ngModel)]="person.company" />
              </div>
              <div class="mt-3 flex-1">
                <label class="block text-sm font-medium text-gray-700">
                  Straße + Hausnummer
                </label>
                <input type="text" class="ip mt-1 w-72" [(ngModel)]="person.street" />
              </div>
            </div>
            <div class="flex space-x-4 w-full">
              <div class="mt-3 flex-1">
                <label class="block text-sm font-medium text-gray-700">
                  PLZ
                </label>
                <input type="text" class="ip mt-1 w-72" [(ngModel)]="person.postcode" />
              </div>
              <div class="mt-3 flex-1">
                <label class="block text-sm font-medium text-gray-700">
                  Stadt
                </label>
                <input type="text" class="ip mt-1 w-72" [(ngModel)]="person.city" />
              </div>
            </div>
          </div>
        </div>

        <div class="flex space-x-4 mt-4 border-t border-gray-300">
          <h2 class="font-bold uppercase mt-6 w-64">Sonstiges</h2>
          <div class="flex flex-col">
            <div class="flex space-x-4 w-full">
              <div class="mt-3 flex-1 w-148">
                <label class="block text-sm font-medium text-gray-700">
                  Bemerkungen
                </label>
                <textarea rows="8" type="text" class="ip mt-1 w-full" [(ngModel)]="person.notes"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
