import { Expose } from "class-transformer";
import { PartFullSizeDto } from "./part.full-size.dto";
import { IsString } from "class-validator";

export class PartForTableDto extends PartFullSizeDto {

  @IsString()
  @Expose()
  articleName: string = '';

  @IsString()
  @Expose()
  articleGender: string = '';

  @IsString()
  @Expose()
  articleSize: string = '';

  @IsString()
  @Expose()
  rowString: string = '';
} 