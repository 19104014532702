import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PalletFullSizeDto } from '../../../../shared/dtos';
import { HhHttpService } from './hh-http.service';

@Injectable({
  providedIn: 'root',
})
export class PalletService {
  constructor(private httpSrv: HhHttpService) {}

  findById(palletId: number): Observable<PalletFullSizeDto> {
    return this.httpSrv
      .get<PalletFullSizeDto>(`pallet/${palletId}`)
      .pipe(map(this.mapPallet));
  }

  removeFromRow(palletIds: number[]) {
    return this.httpSrv.post<void>(`pallet/removeFromRow`, palletIds);
  }

  addToRow(palletIds: number[], rowId: number) {
    return this.httpSrv.post<void>(`pallet/addToRow`, {
      palletIds: palletIds,
      rowId: rowId,
    });
  }

  addToWarehouse(palletId: number, warehouseId: number) {
    return this.httpSrv.post<void>(
      `pallet/${palletId}/toWarehouse/${warehouseId}`,
      null
    );
  }

  mapPallet = (pallet: PalletFullSizeDto) => {
    pallet.created = new Date(pallet.created);
    return pallet;
  };
}
