<div [@fade]="isOpen ? 'open' : 'closed'" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="
      flex
      items-end
      justify-center
      min-h-screen
      pt-4
      px-4
      pb-20
      text-center
      sm:block sm:p-0
    ">
    <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" (click)="onClose()">
    </div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
    <div [@zoom]="isOpen ? 'open' : 'closed'"  class="
        inline-block
        align-bottom
        bg-white
        rounded-lg
        px-4
        pt-5
        pb-4
        text-left
        overflow-hidden
        shadow-xl
        transform
        transition-all
        sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6
      ">
      <div>
        <div class="
            mx-auto
            flex
            items-center
            justify-center
            h-12
            w-12
            rounded-full
            bg-green-100
          ">
          <!-- Heroicon name: outline/check -->
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
          </svg>
        </div>

        <div class="mt-3 text-center sm:mt-5">
          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
            Bestellung drucken
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              Was soll für diese Bestellung gedruckt werden?
            </p>
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="mt-6">
          <h3 class="font-bold text-md uppercase text-gray-900">
            Bestellungsübersicht
          </h3>
          <button (click)="onOrderPrint()" class="
              mt-2
              uppercase
              text-sm
              font-bold
              py-2
              px-4
              rounded-lg
              shadow-md
              cursor-pointer
              bg-white
              hover:bg-gray-100
              text-primary
              active:bg-primary active:text-white
            ">
            Bestellungsübersicht
          </button>
        </div>

        <div *ngIf="mode === 'internal'" class="mt-6">
          <h3 class="font-bold text-md uppercase text-gray-900">
            Abteilungszettel
          </h3>
          <div class="flex flex-wrap gap-2">
            <button
              (click)="onOrderPrint({ allDepartments: true })" class="
                mt-2
                uppercase
                text-sm
                font-bold
                py-2
                px-4
                rounded-lg
                shadow-md
                cursor-pointer
                bg-white
                hover:bg-gray-100
                text-primary
                active:bg-primary active:text-white
              ">
              Alle Abteilungen
            </button>
            <button *ngFor="let department of departments | keyvalue"
              (click)="onOrderPrint({ departmentId: department.value.id })" class="
                mt-2
                uppercase
                text-sm
                font-bold
                py-2
                px-4
                rounded-lg
                shadow-md
                cursor-pointer
                bg-white
                hover:bg-gray-100
                text-primary
                active:bg-primary active:text-white
              ">
              {{ department.value.name }}
            </button>
          </div>
        </div>

        <div *ngIf="mode === 'internal' && order.fkStatus > 2" class="mt-6">
          <h3 class="font-bold text-md uppercase text-gray-900">
            Palettenlabel
          </h3>
          <div class="flex flex-wrap gap-2">
            <button 
              (click)="onOrderPrint({ palletPaper: true })" class="
                mt-2
                uppercase
                text-sm
                font-bold
                py-2
                px-4
                rounded-lg
                shadow-md
                cursor-pointer
                bg-white
                hover:bg-gray-100
                text-primary
                active:bg-primary active:text-white
              ">
              Palettenlabel
            </button>
          </div>
        </div>

        <div class="mt-6">
          <h3 class="font-bold text-md uppercase text-gray-900">
            Bestellbestätigung
          </h3>
          <div class="flex flex-wrap gap-2">
            <button (click)="onOrderPrint({ confirmation: 'de' })" class="
                mt-2
                uppercase
                text-sm
                font-bold
                py-2
                px-4
                rounded-lg
                shadow-md
                cursor-pointer
                bg-white
                hover:bg-gray-100
                text-primary
                active:bg-primary active:text-white
              ">
              Bestellbestätigung (deutsch)
            </button>
            <button (click)="onOrderPrint({ confirmation: 'en' })" class="
                mt-2
                uppercase
                text-sm
                font-bold
                py-2
                px-4
                rounded-lg
                shadow-md
                cursor-pointer
                bg-white
                hover:bg-gray-100
                text-primary
                active:bg-primary active:text-white
              ">
              Bestellbestätigung (englisch)
            </button>
          </div>
        </div>
      </div>
      <div class="
          mt-5
          sm:mt-6 sm:grid sm:gap-3 sm:grid-flow-row-dense
        ">
        <button type="button" (click)="onClose()" class="
            mt-3
            w-full
            inline-flex
            justify-center
            rounded-md
            border border-gray-300
            shadow-sm
            px-4
            py-2
            bg-white
            text-base
            font-medium
            text-gray-700
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-primary
            sm:mt-0 sm:col-start-1 sm:text-sm
          ">
          Schließen
        </button>
      </div>
    </div>
  </div>
</div>
