<div *ngIf="!!authSrv.authContext && !!authSrv.myRights" class="flex flex-col h-full">
  <app-menu (search)="onSearch()"></app-menu>
  <app-search [openSearch]="onSearch" *ngIf="authSrv.authContext"></app-search>
  <div class="bg-white">
    <router-outlet></router-outlet>
  </div>
  <app-alert *ngIf="alert.text"></app-alert>
  <app-release-notes *ngIf="releaseNotesShown" (close)="releaseNotesShown = false"></app-release-notes>

  <div *ngIf="tabsOpen" class="
      absolute
      right-0
      top-menu
      max-h-screenExceptMenu
      overflow-y-auto
      flex flex-col
      shadow-md
      animate-slideInFromTop
    ">
    <div class="
        bg-white
        rounded-bl-md
        shadow-md
        flex flex-col
        z-20
        h-full
        overflow-y-auto
        relative
      ">
      <div class="self-end flex space-x-2 relative top-2 right-2">
        <button (click)="onTabMinimize(); $event.stopPropagation()">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
          </svg>
        </button>
        <button (click)="onTabClose(); $event.stopPropagation()">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="p-4 flex flex-col h-full overflow-y-auto">
        <router-outlet class="flex flex-col overflow-y-auto" name="tabs"></router-outlet>
      </div>
    </div>
  </div>

  <div class="absolute bottom-2.5 left-2.5 text-xs" tabindex="-1">
    <button class="hover:underline" [disabled]="!authSrv.authContext.person.id" (click)="releaseNotesShown = true" >Version {{appVersion}}</button>
  </div>

  <div *ngIf="swUpdatePromptOpen" class="absolute bottom-2.5 right-2.5 bg-white shadow-xl rounded-md p-4 flex items-center gap-2.5 z-50">
    <span>Es steht eine neue Version zur Verfügung.</span>
    <button class="bg-primary py-1.5 px-3 text-white rounded-md hover:bg-primaryDark" (click)="onUpdate()">Jetzt aktualisieren</button>
  </div>
</div>