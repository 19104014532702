import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreateOrderDto, MessageFullSizeDto, UpdateOrderDto, CreateMessageDto } from '../../../../shared/dtos';
import { OrderFullSizeDto } from '../../../../shared/dtos';
import { OrganisationFullSizeDto } from '../../../../shared/dtos';
import { PersonFullSizeDto } from '../../../../shared/dtos';
import { AuthService } from './auth.service';
import { HhHttpService } from './hh-http.service';
import { MessageService } from './message.service';
import { GroupedOrders } from '../../../../shared/src/dtos/GroupedOrders';
import { TransformerWrapper } from '../../../../shared/src/transformer';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  authPerson: PersonFullSizeDto | null = new PersonFullSizeDto();

  constructor(
    private httpSrv: HhHttpService,
    private authSrv: AuthService,
    private messageSrv: MessageService
  ) {
  }

  updateListTrigger: Subject<void> = new Subject<void>();
  updateOneOrderTrigger: Subject<void> = new Subject<void>();

  findAll() {
    return this.httpSrv.get<OrderFullSizeDto[]>('order');
  }

  findById(id: number) {
    return this.httpSrv.get<OrderFullSizeDto>(`order/${id}`);
  }

  findByCampId(campId: number) {
    return this.httpSrv.get<OrderFullSizeDto[]>(`camp/${campId}/orders`);
  }

  findGroupedByCampId(campId: number) {
    return this.httpSrv.get<GroupedOrders>(`camp/${campId}/orders-grouped`);
  }

  findByBoxId(boxId: number) {
    return this.httpSrv.get<OrderFullSizeDto[]>(`order/byBoxId/${boxId}`);
  }

  findByName(name: string) {
    return this.httpSrv.get<OrderFullSizeDto[]>(`order/q/${name}`);
  }

  addBox(orderId: number, boxId: number) {
    return this.httpSrv.post<void>(`order/${orderId}/addBox/${boxId}`, null);
  }

  takeOverOrder(orderId: number) {
    return this.httpSrv
      .post<OrderFullSizeDto>(`order/${orderId}/takeOver`, null)
      .pipe(map((elem) => TransformerWrapper.plainToInstanceExclude(OrderFullSizeDto, elem)));
  }

  setStatus(order: OrderFullSizeDto, newStatusId: number): Observable<MessageFullSizeDto> {
    const newMessage = new CreateMessageDto();
    newMessage.fkOrder = order.id;
    newMessage.fkStatus = newStatusId;
    return this.messageSrv.create(newMessage);
  }

  delete(orderId: number) {
    return this.httpSrv.delete<void>(`order/${orderId}`);
  }

  update(orderId: number, dto: UpdateOrderDto) {
    return this.httpSrv
      .patch<OrderFullSizeDto>(`order/${orderId}`, dto)
      .pipe(map((elem) => TransformerWrapper.plainToInstanceExclude(OrderFullSizeDto, elem)));
  }

  create(order: CreateOrderDto) {
    return this.httpSrv
      .post<OrderFullSizeDto>(`order`, TransformerWrapper.plainToInstanceExclude(CreateOrderDto, order, {
        enableImplicitConversion: true,
      }))
      .pipe(map((elem) => TransformerWrapper.plainToInstanceExclude(OrderFullSizeDto, elem)));
  }
}
