<div
  class="fixed z-10 inset-0 overflow-y-auto"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div
    class="
      flex
      items-end
      justify-center
      min-h-screen
      pt-4
      px-4
      pb-20
      text-center
      sm:block sm:p-0
    "
  >
    <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      aria-hidden="true"
    ></div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true"
      >&#8203;</span
    >

    <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
    <div
      class="
        inline-block
        align-bottom
        bg-white
        rounded-lg
        px-4
        pt-5
        pb-4
        text-left
        overflow-hidden
        shadow-xl
        transform
        transition-all
        sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6
      "
    >
      <div>
        <div
          class="
            mx-auto
            flex
            items-center
            justify-center
            h-12
            w-12
            rounded-full
            bg-red-100
          "
        >
          <!-- Heroicon name: outline/check -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </div>

        <div class="mt-3 text-center sm:mt-5">
          <h3
            class="text-lg leading-6 font-medium text-gray-900"
            id="modal-title"
          >
            Möchtest du Bestellung {{ order.id }} wirklich löschen?
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              Bitte bestätige dies, in dem du
              <span class="font-bold">{{ order.id }}</span> eingibst.
            </p>
          </div>
        </div>
      </div>
      <div class="mt-3 flex-1 w-full">
        <label class="block text-sm font-medium text-gray-700">
          Bestellungsnummer
        </label>

        <input
          (keyup.enter)="onDelete()"
          type="text"
          class="ip mt-1 w-full"
          [(ngModel)]="orderId"
        />

        <p class="mt-2 text-sm text-gray-500" id="email-description">
          Dieser Schritt kann nicht rückgängig gemacht werden! Alle Kartons in
          dieser Bestellung werden zurück ins Lager gebucht.
        </p>
      </div>
      <div
        class="
          mt-5
          sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense
        "
      >
        <button
          type="button"
          (click)="onDelete()"
          class="
            w-full
            inline-flex
            justify-center
            rounded-md
            border border-transparent
            shadow-sm
            px-4
            py-2
            bg-red-600
            text-base
            font-medium
            text-white
            hover:bg-red-600
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-red-600
            sm:col-start-2 sm:text-sm
          "
        >
          Löschen
        </button>
        <button
          type="button"
          (click)="onClose()"
          class="
            mt-3
            w-full
            inline-flex
            justify-center
            rounded-md
            border border-gray-300
            shadow-sm
            px-4
            py-2
            bg-white
            text-base
            font-medium
            text-gray-700
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-primary
            sm:mt-0 sm:col-start-1 sm:text-sm
          "
        >
          Schließen
        </button>
      </div>
    </div>
  </div>
</div>
