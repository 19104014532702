<div *ngIf="row">
  <div class="flex flex-col space-y-2">
    <div class="flex justify-between">
      <div class="flex flex-col">
        <div class="flex space-x-2">
          <h1 class="text-xl font-bold text-gray-900 uppercase">
            Reihe {{ getRowString(row) }}
          </h1>
          <button class="text-gray-600" tooltip="in neuem Tab öffnen" (click)="onOpenHashtagRowTab()">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg>
          </button>
        </div>
        <div class="text-sm text-gray-800" [innerHtml]="row.article?.nameDe || '-'"></div>
        <div class="text-xs text-gray-600">
          {{ row.article?.gender?.nameDe || '-' }},
          {{ row.article?.size?.sizeName || '-' }}
        </div>
      </div>
      <p class="text-sm text-gray-500">
        {{ row.pallets.length }}/{{ row.length }} Paletten
      </p>
    </div>
    <div class="flex flex-wrap gap-2">
      <a [routerLink]="['/hashtag-row-inventory', row.id]" class="
          uppercase
          text-sm
          font-bold
          py-2
          px-4
          rounded-lg
          shadow-md
          cursor-pointer
          bg-white
          hover:bg-gray-100
          text-primary
          active:bg-primary active:text-white
          button-animation
        ">
        Inventur
      </a>
      <a (click)="onRowPrint()" class="
          uppercase
          text-sm
          font-bold
          py-2
          px-4
          rounded-lg
          shadow-md
          cursor-pointer
          bg-white
          hover:bg-gray-100
          text-primary
          active:bg-primary active:text-white
          button-animation
        ">
        Reihenzettel drucken
      </a>
      <a (click)="changeRowNumber = !changeRowNumber" class="
          uppercase
          text-sm
          font-bold
          py-2
          px-4
          rounded-lg
          shadow-md
          cursor-pointer
          bg-white
          hover:bg-gray-100
          text-primary
          active:bg-primary active:text-white
          button-animation
        ">
        Reihennummer ändern
      </a>
    </div>

    <app-change-row-number *ngIf="changeRowNumber" [(row)]="row"></app-change-row-number>
    <div class="font-bold uppercase mt-4">Paletten</div>
    <div class="flex flex-row gap-2 mt-4">
      <div class="
          px-4
          py-2
          bg-gray-100
          hover:bg-gray-200
          cursor-pointer
          shadow-md
          rounded-md
          flex flex-col
          button-animation
        " *ngFor="let pallet of row.pallets; let i = index" (click)="onPalletSelect(pallet)" [ngClass]="{
          'border border-dashed border-red-400 bg-red-50 hover:bg-red-100':
            i + 1 > row.length
        }">
        <span>{{ pallet.id }}</span>
        <span class="text-xs text-gray-500">{{ pallet.boxes.length }} Kartons</span>
      </div>

      <!-- empty slots -->
      <ng-container *ngIf="row.length > row.pallets.length">
        <div *ngFor="let extra of [extraArray]" class="
            relative
            rounded-lg
            border border-gray-300 border-dashed
            bg-gray-50
            px-6
            py-2
            shadow-sm
            flex
            items-center
            space-x-3
          ">
          <div class="flex-1 min-w-0">
            <a class="focus:outline-none">
              <span class="absolute inset-0" aria-hidden="true"></span>
              <p class="text-sm font-medium text-gray-900">frei</p>
              <p class="text-sm text-gray-500 truncate"></p>
            </a>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
