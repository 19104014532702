<div class="flex items-center divide-x space-x-4">
  <nav aria-label="Progress" class="h-screenExceptMenu p-4 flex flex-col">
    <ol role="list" class="space-y-4">
      <li class="md:flex-1">
        <!-- Completed Step -->
        <span [ngClass]="{ 'border-primary': step >= 1 }"
          class="group pl-4 py-2 flex flex-col border-l-4 border-gray-200">
          <span [ngClass]="{ 'text-primary': step >= 1 }"
            class="text-xs text-gray-500 font-semibold tracking-wide uppercase">Schritt 1</span>
          <span class="text-sm font-medium">Persönliche Details</span>
        </span>
      </li>

      <li class="md:flex-1">
        <!-- Current Step -->
        <span [ngClass]="{ 'border-primary': step >= 2 }" class="pl-4 py-2 flex flex-col border-l-4 border-gray-200"
          aria-current="step">
          <span [ngClass]="{ 'text-primary': step >= 2 }"
            class="text-xs text-gray-500 font-semibold tracking-wide uppercase">Schritt 2</span>
          <span class="text-sm font-medium">Gruppenzugehörigkeit</span>
        </span>
      </li>

      <li class="md:flex-1">
        <!-- Upcoming Step -->
        <span [ngClass]="{ 'border-primary': step >= 3 }"
          class="group pl-4 py-2 flex flex-col border-l-4 border-gray-200">
          <span [ngClass]="{ 'text-primary': step >= 3 }"
            class="text-xs text-gray-500 font-semibold tracking-wide uppercase">Schritt 3</span>
          <span class="text-sm font-medium">Organisationen</span>
        </span>
      </li>

      <li class="md:flex-1">
        <!-- Upcoming Step -->
        <span [ngClass]="{ 'border-primary': step >= 4 }"
          class="group pl-4 py-2 flex flex-col border-l-4 border-gray-200">
          <span [ngClass]="{ 'text-primary': step >= 4 }"
            class="text-xs text-gray-500 font-semibold tracking-wide uppercase">Schritt 4</span>
          <span class="text-sm font-medium">Zusammenfassung</span>
        </span>
      </li>
    </ol>

    <div class="flex space-x-4 mt-8 justify-center">
      <button (click)="prev()" [disabled]="step <= 1" [ngClass]="{ 'hover:bg-gray-300': step > 1 }"
        class="button-animation bg-gray-200 text-gray-700 px-4 py-2 rounded-lg font-medium">
        Zurück
      </button>
      <button (click)="next()" [disabled]="step >= maxStep || !stepsValid[step - 1]"
         class="
          button-animation
          bg-primary
          px-4
          py-2
          rounded-lg
          text-white
          font-medium
          disabled:bg-gray-200
          disabled:text-gray-700
        ">
        Weiter
      </button>
    </div>
    <span *ngIf="!stepsValid[step - 1] && step < maxStep" class="text-sm text-gray-800 font-bold self-center mt-2.5">Bitte alle Felder<br> mit * ausfüllen</span>
  </nav>
  <div class="h-screenExceptMenu overflow-y-auto w-full">
    <app-person-edit (isValid)="stepsValid[0] = $event" [(person)]="person" *ngIf="step === 1"></app-person-edit>
    <app-person-groups [updateDirectly]="false" [(person)]="person" *ngIf="step === 2"></app-person-groups>
    <app-person-camps [updateDirectly]="false" [(person)]="person" *ngIf="step === 3"></app-person-camps>
    <app-person-new-summary [person]="person" *ngIf="step === 4"></app-person-new-summary>
  </div>
</div>
