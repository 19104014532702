import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateArticleDto, DepartmentFullSizeDto, GenderLagerFullSizeDto, UpdateArticleDto } from '../../../../../shared/dtos';
import { DepartmentService } from '../../services/department.service';
import { ArticleFullSizeDto } from '../../../../../shared/dtos';
import { ArticleService } from '../../services/article.service';
import { CategoryFullSizeDto } from '../../../../../shared/dtos';
import { GenderFullSizeDto } from '../../../../../shared/dtos';
import { SizeFullSizeDto } from '../../../../../shared/dtos';
import { CategoryService } from '../../services/category.service';
import { GenderService } from '../../services/gender.service';
import { SizeService } from '../../services/size.service';
import { SubArticleFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { ArticleGroupService } from '../../services/article-group.service';
import { ExportArticleService } from '../../services/export-article.service';
import { ArticleGroupFullSizeDto } from '../../../../../shared/dtos';
import { ExportArticleFullSizeDto } from '../../../../../shared/dtos';
import { TransformerWrapper } from '../../../../../shared/src/transformer';

@Component({
  selector: 'app-article-edit',
  templateUrl: './article-edit.component.html',
  styleUrls: ['./article-edit.component.scss'],
})
export class ArticleEditComponent implements OnInit {
  article: ArticleFullSizeDto = new ArticleFullSizeDto();
  loading: boolean = false;
  departments: DepartmentFullSizeDto[] = [];
  categories: CategoryFullSizeDto[] = [];
  genders: GenderLagerFullSizeDto[] = [];
  sizes: SizeFullSizeDto[] = [];
  articleGroups: ArticleGroupFullSizeDto[] = [];
  exportArticles: ExportArticleFullSizeDto[] = [];
  selectedDepartmentId: number = -1;
  selectedGenderId: number = -1;
  selectedCategoryId: number = -1;
  selectedSizeId: number = -1;
  selectedArticleGroupId: number = -1;
  selectedExportArticleId: number = -1;
  newSubArticleName = '';
  filteredSizes: SizeFullSizeDto[] = [];

  constructor(
    private articleSrv: ArticleService,
    private route: ActivatedRoute,
    private router: Router,
    private departmentSrv: DepartmentService,
    private categorySrv: CategoryService,
    private genderSrv: GenderService,
    private sizeSrv: SizeService,
    private articleGroupSrv: ArticleGroupService,
    private exportArticleSrv: ExportArticleService,
    private alertSrv: AlertService
  ) {}

  onSubArticleDelete(index: number) {
    this.article.subArticles.splice(index, 1);
  }

  onSubArticleAdd(name: string) {
    if (name) {
      const subArticle = new SubArticleFullSizeDto();
      subArticle.fkArticle = this.article.id;
      subArticle.article = this.article;
      subArticle.name = name;
      this.article.subArticles.push(subArticle);
    }
  }

  onSave() {
    this.article.id ? this.updateArticle() : this.createArticle();
  }

  createArticle() {
    const createDto: CreateArticleDto = new CreateArticleDto();
    createDto.nameDe = this.article.nameDe;
    createDto.nameEn = this.article.nameEn || undefined;
    createDto.nameKur = this.article.nameKur || undefined;
    createDto.description = this.article.description || undefined;
    createDto.componentCount = this.article.componentCount;
    createDto.minRequired = this.article.minRequired || undefined;
    createDto.showInList = this.article.showInList;
    createDto.addable = this.article.addable;
    createDto.fkCategory = this.article.fkCategory || undefined;
    createDto.fkGender = this.article.fkGender || undefined;
    createDto.fkSize = this.article.fkSize || undefined;
    createDto.fkArticleGroup = this.article.fkArticleGroup || undefined;
    createDto.fkExportArticle = this.article.fkExportArticle || undefined;
    createDto.fkDepartment = this.article.fkDepartment || undefined;
    
    this.articleSrv.create(createDto).subscribe(
      (article) => {
        this.article = article;
        this.alertSrv.alert.next({
          text: 'Der Artikel wurde gespeichert.',
          linkText: '',
        });
        this.articleSrv.updateTrigger.next();
        this.router.navigate([
          '/articles',
          { outlets: { article: [article.id.toString()] } }
        ]);
      },
      (err) => {
        this.alertSrv.alert.next({
          text: 'Fehler beim Speichern des Artikels.',
          linkText: '',
        });
      }
    );
  }

  updateArticle() {
    const updateDto: UpdateArticleDto = TransformerWrapper.plainToInstanceExclude(UpdateArticleDto, this.article);
    this.articleSrv.update(this.article.id, updateDto).subscribe(
      (article) => {
        this.article = article;
        this.alertSrv.alert.next({
          text: 'Der Artikel wurde gespeichert.',
          linkText: '',
        });
        this.articleSrv.updateTrigger.next();
        this.router.navigate([
          '/articles',
          { outlets: { article: [article.id.toString()] } }
        ]);
      },
      (err) => {
        this.alertSrv.alert.next({
          text: 'Fehler beim Speichern des Artikels.',
          linkText: '',
        });
      }
    );
  }

  onDepartmentSelect() {
    this.article.department =
      this.departments.find((department) => {
        return (
          department.id.toString() === this.selectedDepartmentId.toString()
        );
      }) || null;
    this.article.fkDepartment = this.article.department?.id || null;
  }

  onCategorySelect() {
    this.article.category =
      this.categories.find((category) => {
        return category.id.toString() === this.selectedCategoryId.toString();
      }) || null;
    this.article.fkCategory = this.article.category?.id || null;
    this.filterSizes();
  }

  filterSizes() {
    this.filteredSizes = this.sizes.filter((size) => {
      return this.selectedCategoryId.toString() === size.fkCategory?.toString();
    });
  }

  onGenderSelect() {
    this.article.gender =
      this.genders.find((gender) => {
        return gender.id.toString() === this.selectedGenderId.toString();
      }) || null;
    this.article.fkGender = this.article.gender?.id || null;
  }

  onSizeSelect() {
    this.article.size =
      this.sizes.find((size) => {
        return size.id.toString() === this.selectedSizeId.toString();
      }) || null;
    this.article.fkSize = this.article.size?.id || null;
  }

  onArticleGroupSelect() {
    this.article.articleGroup =
      this.articleGroups.find(
        (articleGroup) =>
          articleGroup.id.toString() === this.selectedArticleGroupId.toString()
      ) || null;
    this.article.fkArticleGroup = this.article.articleGroup?.id || null;

  }

  onExportArticleSelect() {
    this.article.exportArticle =
      this.exportArticles.find(
        (exportArticle) =>
          exportArticle.id.toString() ===
          this.selectedExportArticleId.toString()
      ) || null;
    this.article.fkExportArticle = this.article.exportArticle?.id || null;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params.articleId) {
        this.loading = true;
        this.articleSrv.findById(params.articleId).subscribe((article) => {
          // select-madness :(
          this.selectedDepartmentId = article.department?.id || 0;
          this.selectedCategoryId = article.category?.id || 0;
          this.selectedGenderId = article.gender?.id || 0;
          this.selectedSizeId = article.size?.id || 0;
          this.selectedArticleGroupId = article.articleGroup?.id || 0;
          this.selectedExportArticleId = article.exportArticle?.id || 0;

          this.article = article;
          this.loading = false;
        });
      }
    });

    this.departmentSrv.findAll().subscribe((departments) => {
      this.departments = departments.filter((department) => department.id > 0);
    });

    this.genderSrv.findAll().subscribe((genders) => {
      this.genders = genders;
    });

    this.articleGroupSrv.findAll().subscribe((articleGroups) => {
      this.articleGroups = articleGroups;
    });

    this.exportArticleSrv.findAll().subscribe((exportArticles) => {
      this.exportArticles = exportArticles;
    });

    this.sizeSrv.findAll().subscribe((sizes) => {
      this.sizes = sizes;

      this.categorySrv.findAll().subscribe((categories) => {
        this.categories = categories;
        this.filterSizes();
      });
    });
  }
}
