import { IsNumber, IsObject, ValidateNested } from "class-validator";
import { DepartmentFullSizeDto } from "./department.full-size.dto";
import { PersonFullSizeDto } from "./person.full-size.dto";
import { Expose, Type } from "class-transformer";


export class HelperFullSizeDto {

  @IsNumber()
  @Expose()
  id: number = 0;

  @IsNumber()
  @Expose()
  fkPerson: number = 0;

  @IsNumber()
  @Expose()
  fkDepartment: number = 0;

  @IsObject()
  @Type(() => PersonFullSizeDto)
  @ValidateNested()
  @Expose()
  person: PersonFullSizeDto = new PersonFullSizeDto();

  @IsObject()
  @Type(() => DepartmentFullSizeDto)
  @ValidateNested()
  @Expose()
  department: DepartmentFullSizeDto = new DepartmentFullSizeDto();
}
