<div class="flex h-full overflow-y-auto">
  <div
    *ngIf="order.fkStatus <= 2"
    class="
      flex-1 flex flex-col
      h-full
      overflow-y-auto
      w-1/2
      border-r border-gray-300
    "
  >
    <div class="pt-2 pb-4 px-4 border-b border-gray-300">
      <h2 class="text-xl font-bold">Artikel packen</h2>
      <app-article-search
        (articlesChanged)="onSearchUpdate($event)"
        (loading)="loading = $event"
      ></app-article-search>
    </div>
    <div class="h-full overflow-y-auto">
      <app-article-list
        [mode]="'edit'"
        (partAdd)="onPartAdd($event)"
        [articles]="filteredArticles"
        [loading]="loading"
      ></app-article-list>
    </div>
  </div>
  <div
    class="overflow-y-auto h-full"
    [ngClass]="{ 'w-1/2': order.fkStatus <= 2, 'w-full': order.fkStatus >= 3 }"
  >
    <h2 class="text-xl font-bold px-4 py-2">Bestellungsübersicht</h2>
    <app-order-parts
      [mode]="order.fkStatus <= 2 ? 'edit' : 'view'"
      [order]="order"
    ></app-order-parts>
  </div>
</div>
