import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BlockFullSizeDto, WarehouseFullSizeDto } from '../../../../../shared/dtos';
import { WarehouseService } from '../../services/warehouse.service';

@Component({
  selector: 'app-warehouse-list',
  templateUrl: './warehouse-list.component.html',
  styleUrls: ['./warehouse-list.component.scss'],
})
export class WarehouseListComponent implements OnInit {
  warehouses: WarehouseFullSizeDto[] = [];
  @Output() blockSelected: EventEmitter<BlockFullSizeDto> = new EventEmitter();
  loading: boolean = false;

  constructor(private warehouseSrv: WarehouseService) {}

  onBlockSelected(block: BlockFullSizeDto) {
    this.blockSelected.emit(block);
  }

  ngOnInit(): void {
    this.loading = true;
    this.warehouseSrv.findAll().subscribe((warehouses) => {
      this.warehouses = warehouses.filter(
        (warehouse) => warehouse.blocks.length > 0
      );
      this.loading = false;
    });
  }
}
