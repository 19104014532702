<app-loader *ngIf="loading"></app-loader>
<div class="h-full" *ngIf="!loading">
  <main
    *ngIf="group"
    class="flex-1 relative z-0 focus:outline-none xl:order-last h-full"
  >
    <article class="flex h-full flex-col overflow-y-hidden">
      <!-- Profile header -->
      <div class="flex-shrink-0">
        <div class="px-4 sm:px-6 lg:px-8">
          <div class="mt-6 min-w-0 flex-1">
            <div class="mt-4 flex justify-between items-center gap-8">
              <div class="flex space-x-2 items-center">
                <h1 class="text-2xl font-bold text-gray-900 truncate uppercase">
                  {{ group.name }}
                </h1>
                <button (click)="onOpenGroupTab()">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- tabs -->
      <div class="mt-6 sm:mt-2 2xl:mt-5 flex-shrink-0">
        <div class="border-b border-gray-200">
          <div class="px-4 sm:px-6 lg:px-8">
            <nav class="-mb-px flex space-x-8" aria-label="Tabs">
              <!-- Current: "border-blue-500 text-gray-900", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
              <a
                (click)="view = 'persons'"
                class="
                  cursor-pointer
                  text-gray-500
                  whitespace-nowrap
                  py-4
                  px-1
                  border-b-2
                  font-medium
                  text-sm
                "
                [ngClass]="{
                  'border-primary text-gray-900': view === 'persons'
                }"
                aria-current="page"
              >
                Mitglieder
              </a>

              <a
                (click)="view = 'rights'"
                class="
                  border-transparent
                  cursor-pointer
                  text-gray-500
                  hover:text-gray-700
                  whitespace-nowrap
                  py-4
                  px-1
                  border-b-2
                  font-medium
                  text-sm
                "
                [ngClass]="{
                  'border-primary text-gray-900': view === 'rights'
                }"
              >
                Rechte
              </a>
            </nav>
          </div>
        </div>
      </div>

      <div *ngIf="view === 'rights'" class="flex overflow-y-auto h-full">
        <div class="h-full flex flex-col overflow-y-auto">
          <app-right-list [rights]="rights"></app-right-list>
        </div>
      </div>
      <div *ngIf="view === 'persons'" class="flex overflow-y-auto h-full">
        <div class="h-full flex flex-col overflow-y-auto">
          <app-person-list
            (personSelect)="onPersonSelect($event)"
            [persons]="persons"
          ></app-person-list>
        </div>
        <div
          *ngIf="selectedPerson && selectedPerson.id"
          class="overflow-y-auto h-full"
        >
          <app-person [person]="selectedPerson"></app-person>
        </div>
      </div>
    </article>
  </main>
</div>
