import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ArticleFullSizeDto } from '../../../../../shared/dtos';
import { SubArticleFullSizeDto } from '../../../../../shared/dtos';
import { PartFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { ArticleService } from '../../services/article.service';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.scss'],
})
export class ArticleListComponent implements OnInit {
  @Input() articles: ArticleFullSizeDto[] = [];
  @Input() selectedArticleId: number = -1;
  @Output() articlesChange: EventEmitter<ArticleFullSizeDto[]> = new EventEmitter();
  @Output() articleSelect: EventEmitter<ArticleFullSizeDto> = new EventEmitter();
  @Output() partAdd: EventEmitter<PartFullSizeDto> = new EventEmitter();
  @Output() subArticleSelect: EventEmitter<SubArticleFullSizeDto> = new EventEmitter();
  @Input() mode: string = 'select-all';
  @Input() loading: boolean = false;
  amounts: Array<number | undefined> = [];
  selectedArticle: ArticleFullSizeDto | null = null;
  selectedSubArticle: SubArticleFullSizeDto | null = null;

  constructor(
    private articleSrv: ArticleService,
    private menuSrv: MenuService,
    private alertSrv: AlertService,
  ) { }

  onArticleSelect(article: ArticleFullSizeDto, amount?: number) {
    this.selectedArticle = article;
    this.articleSelect.emit(article);
  }

  onOpenArticleTab(article: ArticleFullSizeDto) {
    this.menuSrv.addTabAndOpen({
      text: `${article.nameDe}`,
      route: ['articles', article?.id.toString()!],
      exact: true,
    });
  }

  onSubArticleSelect(subArticle: SubArticleFullSizeDto) {
    if (this.mode === 'edit') {
      this.selectedSubArticle = subArticle;
    }

    const subArticle2 = Object.assign(new SubArticleFullSizeDto(), subArticle);

    const article = Object.assign(
      new SubArticleFullSizeDto(),
      this.articles.find((article) => article.id === subArticle.fkArticle)
    );
    if (article) {
      article.subArticles = [];
    }

    subArticle2.article = article || new ArticleFullSizeDto();
    this.subArticleSelect.emit(subArticle2);
  }

  onArticleAdd(
    article: ArticleFullSizeDto,
    amount: number,
    subArticle: SubArticleFullSizeDto | null
  ): void {

    if (!(
      amount &&
      ((article.subArticles.length > 0 &&
        this.selectedSubArticle?.fkArticle === article.id) ||
        article.subArticles.length === 0)
    )) {
      this.alertSrv.alert.next({
        text: 'Bitte Unterartikel auswählen und Menge angeben!',
        linkText: '',
      });
      return;
    }

    const part = new PartFullSizeDto();
    part.article = article;
    part.fkArticle = article.id;

    part.subArticle = subArticle!;
    part.fkSubArticle = subArticle && subArticle.id ? subArticle.id : null;

    part.articleCount = amount;

    this.selectedSubArticle = new SubArticleFullSizeDto();
    this.partAdd.emit(part);
  }

  getRowsString(article: ArticleFullSizeDto) {
    return article.hashtagRows
      ? article.hashtagRows.map((row) => {
        return row.block.name + row.row;
      })
      : '';
  }

  ngOnInit(): void { }
}
