<app-loader *ngIf="loading"></app-loader>
<div class="h-full" *ngIf="!loading">
  <div class="flex-shrink-0 px-4 mt-6 pb-4 border-b border-gray-300">
    <div>
      <div class="flex space-x-2">
        <h1 class="text-2xl font-bold uppercase">
          {{
          article.id
          ? article.nameDe + " bearbeiten"
          : "Neuen Artikel anlegen"
          }}
        </h1>
        <button class="text-gray-600" tooltip="Artikel speichern" (click)="onSave()">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
          </svg>
        </button>
        <button class="text-gray-600" tooltip="Änderungen verwerfen" [routerLink]="!!article.id ? [
            '/articles',
            { outlets: { article: [article.id.toString()] } }
          ] : ['/articles']">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </button>
      </div>
      <div>
        <div class="flex divide-x divide-gray-300 mt-4">
          <div class="flex mr-4">
            <div>
              <div class="mt-3">
                <label class="block text-sm font-medium text-gray-700">
                  Name deutsch
                </label>
                <input type="text" class="ip mt-1 w-96" [(ngModel)]="article.nameDe" />
              </div>
              <div class="mt-3">
                <label class="block text-sm font-medium text-gray-700">
                  Name englisch
                </label>
                <input type="text" class="ip mt-1 w-96" [(ngModel)]="article.nameEn" />
              </div>
              <div class="mt-3">
                <label class="block text-sm font-medium text-gray-700">
                  Name kurdisch
                </label>
                <input dir="rtl" type="text" class="ip mt-1 text-right w-96" [(ngModel)]="article.nameKur" />
              </div>
            </div>
            <div class="flex flex-col mt-3 mx-4">
              <h4 class="font-bold text-darkGray uppercase">Wichtig</h4>
              <ul class="text-sm text-gray-500 w-40 block">
                <li class="mt-2 flex items-center space-x-3">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-green-400" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span>Immer den Plural verwenden.</span>
                </li>
                <li class="mt-2 flex items-center space-x-3">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-green-400" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span>Mehrere Teile mit " / " trennen.</span>
                </li>
                <li class="mt-2 flex items-center space-x-3">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-green-400" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span>Runde Klammern für Erläuterungen</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="pl-8 flex-grow">
            <div>
              <label class="block text-sm font-medium text-gray-700">
                Bemerkungen
              </label>
              <textarea type="text" rows="6" class="ip mt-1 w-full" [(ngModel)]="article.description"></textarea>
            </div>
            <div class="grid grid-cols-2 gap-4">
              <!-- addable -->
              <div class="flex items-start mt-2">
                <div class="flex items-center h-5">
                  <input id="addable" [(ngModel)]="article.addable" type="checkbox" class="
                      focus:ring-primary
                      h-4
                      w-4
                      text-primary
                      border-gray-300
                      rounded
                    " />
                </div>
                <div class="ml-3 text-sm">
                  <label for="addable" class="font-medium text-gray-700">Registrierbar</label>
                </div>
              </div>

              <!-- show in list -->
              <div class="flex items-start mt-2">
                <div class="flex items-center h-5">
                  <input id="showInList" [(ngModel)]="article.showInList" type="checkbox" class="
                      focus:ring-primary
                      h-4
                      w-4
                      text-primary
                      border-gray-300
                      rounded
                    " />
                </div>
                <div class="ml-3 text-sm">
                  <label for="showInList" class="font-medium text-gray-700">Eingeblendet</label>
                </div>
              </div>

              <div class="col-span-2">
                <label class="block text-sm font-medium text-gray-700">
                  Anzahl der Bestandteile
                </label>
                <input type="number" class="ip mt-1 w-96" [(ngModel)]="article.componentCount" />
              </div>
            </div>
          </div>
        </div>
        <div class="mt-8">
          <div class="grid grid-cols-4 gap-4">
            <div class="flex flex-col">
              <label for="department" class="mt-3 text-sm font-medium text-gray-700">Abteilung</label>
              <select [(ngModel)]="selectedDepartmentId" id="department" class="ip mt-1"
                (ngModelChange)="onDepartmentSelect()">
                <!--<option value="null" disabled>Abteilung auswählen</option>-->
                <option *ngFor="let department of departments" [value]="department.id">
                  {{ department.name }}
                </option>
              </select>
            </div>
            <div class="flex flex-col">
              <label for="gender" class="mt-3 text-sm font-medium text-gray-700">Geschlecht</label>
              <select [(ngModel)]="selectedGenderId" id="gender" class="ip mt-1" (ngModelChange)="onGenderSelect()">
                <option [value]="{ id: 0 }" disabled>
                  Geschlecht auswählen
                </option>
                <option *ngFor="let gender of genders" [value]="gender.id">
                  {{ gender.nameDe }}
                </option>
              </select>
            </div>
            <div class="flex flex-col">
              <label for="category" class="mt-3 text-sm font-medium text-gray-700">Kategorie</label>
              <select [(ngModel)]="selectedCategoryId" id="category" (ngModelChange)="onCategorySelect()"
                class="ip mt-1" [disabled]="article.gender?.id === 0">
                <option [value]="{ id: 0 }" disabled>
                  Kategorie auswählen
                </option>
                <option *ngFor="let category of categories" [value]="category.id">
                  {{ category.nameDe }}
                </option>
              </select>
            </div>
            <div class="flex flex-col">
              <label for="size" class="mt-3 text-sm font-medium text-gray-700">Größe</label>
              <select [(ngModel)]="selectedSizeId" id="size" class="ip mt-1" [disabled]="article.category?.id === 0"
                (ngModelChange)="onSizeSelect()">
                <option [value]="{ id: 0 }" disabled>Größe auswählen</option>
                <option *ngFor="let size of filteredSizes" [value]="size.id">
                  {{ size.sizeName }}
                </option>
              </select>
            </div>

            <div class="flex flex-col">
              <label for="size" class="mt-3 text-sm font-medium text-gray-700">Artikelgruppe</label>
              <select [(ngModel)]="selectedArticleGroupId" id="articleGroup" class="ip mt-1"
                (ngModelChange)="onArticleGroupSelect()">
                <option [value]="{ id: 0 }" disabled>
                  Artikelgruppe auswählen
                </option>
                <option *ngFor="let articleGroup of articleGroups" [value]="articleGroup.id">
                  {{ articleGroup.name }}
                </option>
              </select>
            </div>

            <div class="flex flex-col">
              <label for="size" class="mt-3 text-sm font-medium text-gray-700">Export-Artikelgruppe</label>
              <select [(ngModel)]="selectedExportArticleId" id="exportArticle" class="ip mt-1"
                (ngModelChange)="onExportArticleSelect()">
                <option [value]="{ id: 0 }" disabled>
                  Export-Artikelgruppe auswählen
                </option>
                <option *ngFor="let exportArticle of exportArticles" [value]="exportArticle.id">
                  {{ exportArticle.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- subarticles -->
  <div class="px-4 mt-6 pb-4">
    <h3 class="font-bold text-xl uppercase">Unterartikel</h3>
    <div *ngFor="let subArticle of article.subArticles; let i = index">
      <div class="mt-3 flex space-x-2">
        <input type="text" class="ip mt-1 w-96" [(ngModel)]="subArticle.name" />
        <button (click)="onSubArticleDelete(i)" class="
            flex
            justify-center
            items-center
            px-3.5
            border border-gray-300
            shadow-sm
            text-sm
            font-medium
            rounded-md
            text-gray-700
            bg-white
            hover:bg-gray-300
            mt-1
          ">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 12H6" />
          </svg>
        </button>
      </div>
    </div>
    <div class="mt-5 flex space-x-2">
      <input type="text" class="ip mt-1 w-96" [(ngModel)]="newSubArticleName"
        placeholder="Unterartikel hinzufügen..." />
      <button (click)="onSubArticleAdd(newSubArticleName)" class="
          flex
          justify-center
          items-center
          px-3.5
          border border-gray-300
          shadow-sm
          text-sm
          font-medium
          rounded-md
          text-gray-700
          bg-green-200
          hover:bg-green-300
          mt-1
        ">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
        </svg>
      </button>
    </div>
  </div>
</div>
