import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HashtagRowFullSizeDto } from '../../../../../shared/dtos';
import { PalletFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { HashtagRowService } from '../../services/hashtag-row.service';
import { PalletService } from '../../services/pallet.service';

@Component({
  selector: 'app-routing-hashtag-row-inventory',
  templateUrl: './routing-hashtag-row-inventory.component.html',
  styleUrls: ['./routing-hashtag-row-inventory.component.scss'],
})
export class RoutingHashtagRowInventoryComponent implements OnInit {
  loading: boolean = false;
  hashtagRow: HashtagRowFullSizeDto = new HashtagRowFullSizeDto();
  hashtagRowId: number = 0;
  checkedPallets: boolean[] = [];
  additionalPalletIds: number[] = [];
  newPallet: PalletFullSizeDto = new PalletFullSizeDto();
  newBoxId: number | undefined;
  constructor(
    private router: ActivatedRoute,
    private hashtagRowSrv: HashtagRowService,
    private alertSrv: AlertService,
    private palletSrv: PalletService
  ) {}

  onSave() {
    const removedPallets: number[] = [];
    this.checkedPallets.forEach((value, index) => {
      if (value === false) {
        removedPallets.push(index);
      }
    });

    this.palletSrv.removeFromRow(removedPallets).subscribe(() => {
      this.palletSrv
        .addToRow(this.additionalPalletIds, this.hashtagRow.id)
        .subscribe(() => {
          this.alertSrv.alert.next({
            text: 'Inventur ausgeführt',
            linkText: '',
          });
          this.update();
        });
    });
  }

  onAddPallet() {
    this.hashtagRow.pallets.push(Object.assign(new PalletFullSizeDto(), this.newPallet));
    this.checkedPallets[this.newPallet.id] = true;
    this.additionalPalletIds.push(this.newPallet.id);
  }

  update() {
    this.loading = true;
    this.additionalPalletIds = [];
    this.checkedPallets = [];
    this.hashtagRowSrv.findById(this.hashtagRowId).subscribe(
      (hashtagRow) => {
        this.loading = false;
        this.hashtagRow = hashtagRow;
        hashtagRow.pallets.forEach(
          (pallet) => (this.checkedPallets[pallet.id] = true)
        );
      },
      (err) => {
        this.loading = false;
        this.alertSrv.alert.next({
          text: `Reihe konnte nicht gefunden werden.`,
          linkText: '',
        });
      }
    );
  }

  ngOnInit(): void {
    this.router.params.subscribe((params) => {
      if (params.hashtagRowId) {
        this.hashtagRowId = params.hashtagRowId;
        this.update();
      }
    });
  }
}
