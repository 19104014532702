import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CreatePersonDto, PersonFullSizeDto, UpdatePersonDto } from '../../../../shared/dtos';
import { HhHttpService } from './hh-http.service';
import { Subject } from 'rxjs';
import { TransformerWrapper } from '../../../../shared/src/transformer';

@Injectable({
  providedIn: 'root',
})
export class PersonService {
  constructor(private httpSrv: HhHttpService) {}

  updateTrigger: Subject<void> = new Subject<void>();

  findAll() {
    return this.httpSrv.get<PersonFullSizeDto[]>(`person`);
  }

  findById(id: number) {
    return this.httpSrv.get<PersonFullSizeDto>(`person/${id}`);
  }

  findByEmail(email: string) {
    return this.httpSrv.get<PersonFullSizeDto>(`person/by-email/${email}`);
  }

  findByName(q: string) {
    return this.httpSrv.get<PersonFullSizeDto[]>(`person/q/${q}`);
  }

  resetPw(id: number) {
    return this.httpSrv.post<void>(`person/resetPw/${id}`, {});
  }

  updatePw(dto: any) {
    // TODO: implement keycloak
  }

  update(personId: number, person: UpdatePersonDto)  {
    const updateDto = TransformerWrapper.plainToInstanceExclude(UpdatePersonDto, person);
    return this.httpSrv
      .put<PersonFullSizeDto>(`person/${personId}`, updateDto)
      .pipe(map((elem) => TransformerWrapper.plainToInstanceExclude(PersonFullSizeDto, elem)));
  }

  addGroup(personId: number, groupId: number) {
    return this.httpSrv.put(`person/${personId}/groups/${groupId}/add`, {});
  }

  removeGroup(personId: number, groupId: number) {
    return this.httpSrv.put(`person/${personId}/groups/${groupId}/remove`, {});
  }

  addCamp(personId: number, campId: number) {
    return this.httpSrv.put(`person/${personId}/camps/${campId}/add`, {});
  }

  removeCamp(personId: number, campId: number) {
    return this.httpSrv.put(`person/${personId}/camps/${campId}/remove`, {});
  }

  setWarehouse(personId: number, warehouseId: number) {
    return this.httpSrv
      .put<PersonFullSizeDto>(`person/${personId}/setWarehouse/${warehouseId}`, null)
      .pipe(map((elem) => Object.assign(new PersonFullSizeDto(), elem)));
  }

  create(dto: CreatePersonDto) {
    return this.httpSrv
      .post<PersonFullSizeDto>(`person`, dto)
      .pipe(map((elem) => TransformerWrapper.plainToInstance(PersonFullSizeDto, elem)));
  }

  delete(person: PersonFullSizeDto) {
    return this.httpSrv.delete<void>(`person/${person.id}`);
  }
}
