import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { MessageFullSizeDto } from '../../../../../shared/dtos';
import { OrderFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { AuthService } from '../../services/auth.service';
import { MessageService } from '../../services/message.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss'],
})
export class OrderStatusComponent implements OnInit, OnChanges {
  @Input() order: OrderFullSizeDto = new OrderFullSizeDto();
  @Output() orderChange: EventEmitter<OrderFullSizeDto> =
    new EventEmitter<OrderFullSizeDto>();
  @Input() mode: string = 'edit';
  statusId: number = 0;
  resetStatusModalId: number = -1;
  collectorModalActive = false;
  packerModalActive = false;
  senderModalActive = false;

  constructor(
    private orderSrv: OrderService,
    private alertSrv: AlertService,
    private authSrv: AuthService,
    private messageSrv: MessageService,
  ) {}

  getLastStatusMessage(statusId: number): MessageFullSizeDto | undefined {
    const messages = this.order?.messages || [];
    return messages
      ?.filter((message) => message.status.id === statusId)
      .splice(-1)[0];
  }

  setOrderStatus(newStatusId: number) {
    if (newStatusId < this.statusId) {
      this.resetStatusModalId = newStatusId;
    }
    if (newStatusId === this.statusId + 1)
      switch (newStatusId) {
        case 2:
          this.collectorModalActive = true;
          break;

        case 3:
          this.packerModalActive = true;
          break;

        case 4:
          this.senderModalActive = true;
          break;

        default:
          break;
      }
  }

  resetStatus(newStatusId: number) {
    this.order.status.id = newStatusId;
    this.order.fkStatus = newStatusId;

    this.orderSrv.setStatus(this.order, newStatusId).subscribe((message) => {
      this.order.messages.push(message);
      this.orderSrv.update(this.order.id, { fkStatus: newStatusId }).subscribe(
        (order) => {
          this.alertSrv.alert.next({
            text: 'Der Status wurde zurückgesetzt.',
            linkText: '',
          });
          this.order = order;
          this.resetStatusModalId = -1;
          this.orderSrv.updateListTrigger.next();
          this.update();
        },
        (err) => {
          this.alertSrv.alert.next({
            text: 'Der Status konnte nicht zurückgesetzt werden.',
            linkText: '',
          });
          this.resetStatusModalId = -1;
        },
      );
    });
  }

  update() {
    this.statusId = this.order?.status?.id ?? 0;
    this.orderChange.emit(this.order);
  }

  ngOnInit(): void {}

  ngOnChanges() {
    this.update();
  }
}
