import {IsArray, IsDateString, IsNumber, IsString, ValidateNested} from "class-validator";
import {Exclude, Expose, Type} from "class-transformer";
import {CampFullSizeDto} from "../../../dtos";

/**
 * This Dto is the main category of a camp and belongs to the entity CampCategory.
 *
 * @author Julius Behrendt
 */
export class CampCategoryFullSizeDto {

    @IsNumber()
    @Expose()
    id: number = 0;

    @IsDateString()
    @Exclude()
    createdAt: Date;

    @IsDateString()
    @Exclude()
    updatedAt: Date;

    @IsString()
    @Expose()
    name: string = '';

    @IsArray()
    @Type(() => CampFullSizeDto)
    @ValidateNested({each: true})
    @Expose()
    camps: CampFullSizeDto[] = [];
}
