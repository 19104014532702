import { IsArray, IsDateString, IsNumber, IsObject, IsString, ValidateNested } from 'class-validator';
import { HashtagRowFullSizeDto } from './hashtag-row.full-size.dto';
import {WarehouseFullSizeDto} from './warehouseLager.full-size.dto'
import { Exclude, Expose, Type } from 'class-transformer';


export class BlockFullSizeDto {

  @IsNumber()
  @Expose()
  id: number = 0;

  @IsDateString()
  @Exclude()
  createdAt: Date;

  @IsDateString()
  @Exclude()
  updatedAt: Date;

  @IsString()
  @Expose()
  name: string = '';

  @IsObject()
  @Type(() => WarehouseFullSizeDto)
  @Expose()
  warehouse: WarehouseFullSizeDto = new WarehouseFullSizeDto();

  @Expose()
  @IsNumber()
  fkWarehouse: number = 0;

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => HashtagRowFullSizeDto)
  @Expose()
  hashtagRows: HashtagRowFullSizeDto[] = [];
}
