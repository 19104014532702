<app-loader *ngIf="loading"></app-loader>
<div class="flex w-full h-full" *ngIf="!loading">
  <google-map
    [center]="locationElbe264"
    [zoom]="12"
    height="100%"
    width="100%"
    class="w-full h-full"
  >
    <map-info-window>{{ order.camp.name }}</map-info-window>
    <map-directions-renderer
      *ngIf="directionsResults$ | async as directionsResults"
      [directions]="directionsResults"
    ></map-directions-renderer>
  </google-map>
</div>
