import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BoxFullSizeDto, CreateBoxDto, PartFullSizeDto } from '../../../../shared/dtos';
import { HhHttpService } from './hh-http.service';
import { UpdatePartDto } from '../../../../shared/src/dtos/order/update-part.dto';

@Injectable({
  providedIn: 'root',
})
export class PartService {
  constructor(private httpSrv: HhHttpService) {}

  findById(id: number) {
    return this.httpSrv.get<PartFullSizeDto>(`part/${id}`);
  }

  addBox(partId: number, createBoxDto: CreateBoxDto) {
    return this.httpSrv
      .patch<BoxFullSizeDto>(`part/${partId}/addBox`, createBoxDto)
      .pipe(map((elem) => Object.assign(new BoxFullSizeDto(), elem)));
  }

  removeBox(partId: number,  boxId: number) {
    return this.httpSrv
      .patch<BoxFullSizeDto>(`part/${partId}/removeBox/${boxId}`, undefined);
  }
}
