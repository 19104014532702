import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArticleFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { ArticleService } from '../../services/article.service';

@Component({
  selector: 'app-routing-article',
  templateUrl: './routing-article.component.html',
  styleUrls: ['./routing-article.component.scss'],
})
export class RoutingArticleComponent implements OnInit {
  loading: boolean = false;
  article: ArticleFullSizeDto = new ArticleFullSizeDto();

  constructor(
    private router: ActivatedRoute,
    private articleSrv: ArticleService,
    private alertSrv: AlertService
  ) {}

  ngOnInit(): void {
    this.router.params.subscribe((params) => {
      if (params.articleId) {
        this.loading = true;
        this.articleSrv.findById(params.articleId).subscribe(
          (article) => {
            this.loading = false;
            this.article = article;
          },
          (err) => {
            this.loading = false;
            this.alertSrv.alert.next({
              text: `Artikel konnte nicht gefunden werden.`,
              linkText: '',
            });
          }
        );
      }
    });
  }
}
