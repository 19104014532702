import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BoxFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { BoxService } from '../../services/box.service';

@Component({
  selector: 'app-box-change-amount',
  templateUrl: './box-change-amount.component.html',
  styleUrls: ['./box-change-amount.component.scss'],
})
export class BoxChangeAmountComponent implements OnInit {
  @Input() box: BoxFullSizeDto = new BoxFullSizeDto();
  @Output() boxChange: EventEmitter<BoxFullSizeDto> = new EventEmitter();

  constructor(private boxSrv: BoxService, private alertSrv: AlertService) {}

  onSave() {
    this.boxSrv.update(this.box).subscribe(
      (box) => {
        this.box = box;
      },
      (err) => {
        this.alertSrv.alert.next({
          text: 'Karton konnte nicht gespeichert werden.',
          linkText: '',
        });
      }
    );
  }

  ngOnInit(): void {}
}
