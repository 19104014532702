import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { CampService } from 'src/app/services/camp.service';
import { CampFullSizeDto } from '../../../../../shared/dtos';

@Component({
  selector: 'app-camp-search',
  templateUrl: './camp-search.component.html',
  styleUrls: ['./camp-search.component.scss'],
})
export class CampSearchComponent implements OnInit {
  @Output() campsChanged: EventEmitter<CampFullSizeDto[]> = new EventEmitter();
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  q: string = '';
  inputChange: Subject<string> = new Subject();
  showFilters = false;
  @Input() showArchived: boolean = false;
  constructor(private campSrv: CampService) {}

  onSearch(q: string) {
    this.inputChange.next(q);
  }

  toggleFilter() {
    this.showFilters = !this.showFilters;
  }

  onSearchReset() {
    this.loading.emit(true);
    this.campSrv.findAll().subscribe((camps) => {
      this.campsChanged.emit(camps);
    });
    this.q = '';
  }

  ngOnInit(): void {
    this.campSrv.updateTrigger.subscribe(() => {
      this.inputChange.next(this.q);
    });

    this.inputChange
      .pipe(
        debounceTime(200),
        switchMap((q) => {
          this.loading.emit(true);
          return this.campSrv.findByName(q, {
            showArchived: this.showArchived,
          });
        }),
      )
      .subscribe(
        (camps) => {
          this.campsChanged.emit(camps);
          this.loading.emit(false);
        },
        (err) => {
          this.loading.emit(false);
        },
      );
  }
}
