import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from '../../../services/alert.service';
import { AuthService } from '../../../services/auth.service';
import { OrderService } from '../../../services/order.service';
import {
  OrderFullSizeDto,
  PersonFullSizeDto,
} from '../../../../../../shared/dtos';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-packer',
  templateUrl: './packer.component.html',
  styleUrls: ['./packer.component.scss'],
})
export class PackerComponent implements OnInit {
  @Input() order: OrderFullSizeDto = new OrderFullSizeDto();
  @Input() onlyEditCounts: boolean = false;
  @Output() orderChange: EventEmitter<OrderFullSizeDto> =
    new EventEmitter<OrderFullSizeDto>();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() save: EventEmitter<void> = new EventEmitter<void>();

  statusId: number = 3;

  constructor(
    private orderSrv: OrderService,
    private alertSrv: AlertService,
    private authSrv: AuthService,
  ) {}

  onClose() {
    this.close.emit();
  }

  async setStatus() {
    this.order.fkStatus = this.statusId;
    this.order.status.id = this.statusId;

    if (!this.onlyEditCounts) {
      const message = await firstValueFrom(
        this.orderSrv.setStatus(this.order, this.statusId),
      );
      this.order.messages.push(message);
    }

    this.order.fkPacker = this.authSrv.authContext?.person.id || 0;
    this.order.packer = new PersonFullSizeDto();
    this.order.packer.id = this.authSrv.authContext?.person.id || 0;
    this.orderSrv
      .update(this.order.id, {
        fkStatus: this.statusId,
        bigBoxCount: this.order.bigBoxCount || undefined,
        smallBoxCount: this.order.smallBoxCount || undefined,
        palletCount: this.order.palletCount || undefined,
        individualPartCount: this.order.individualPartCount || undefined,
      })
      .subscribe({
        next: (order) => {
          this.order = order;
          this.alertSrv.alert.next({
            text: 'Die Bestellung wurde gespeichert',
            linkText: '',
          });
          this.orderSrv.updateListTrigger.next();
          this.orderChange.emit(this.order);
          this.onClose();
        },
        error: (err) => {
          this.alertSrv.alert.next({
            text: 'Die Bestellung konnte nicht gespeichert werden',
            linkText: '',
          });
          this.onClose();
        },
      });
  }

  ngOnInit(): void {}
}
