import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GroupFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { GroupService } from '../../services/group.service';

@Component({
  selector: 'app-routing-group',
  templateUrl: './routing-group.component.html',
  styleUrls: ['./routing-group.component.scss'],
})
export class RoutingGroupComponent implements OnInit {
  loading: boolean = false;
  group: GroupFullSizeDto = new GroupFullSizeDto();

  constructor(
    private router: ActivatedRoute,
    private groupSrv: GroupService,
    private alertSrv: AlertService
  ) {}

  ngOnInit(): void {
    this.router.params.subscribe((params) => {
      if (params.groupId) {
        this.loading = true;
        this.groupSrv.findByIdWithPersons(params.groupId).subscribe(
          (group) => {
            this.loading = false;
            this.group = group;
          },
          (err) => {
            this.loading = false;
            this.alertSrv.alert.next({
              text: `Gruppe konnte nicht gefunden werden.`,
              linkText: '',
            });
          }
        );
      }
    });
  }
}
