<app-loader *ngIf="loading"></app-loader>
<div
  *ngIf="!loading"
  class="max-w-3xl mx-auto flex flex-col h-screenExceptMenu"
>
  <h1 class="text-xl uppercase font-bold mt-4 text-gray-900">
    Paletteninventur für {{ pallet.id }}
  </h1>
  <div>
    <h2 class="text-lg uppercase font-bold text-gray-900 mt-4">
      bestehende Kartons
    </h2>
    <p class="font-sm text-gray-600">Bitte vorhandene Kartons abhaken:</p>
    <ul>
      <li class="w-full flex" *ngFor="let box of pallet.boxes">
        <div class="flex-1">{{ box.id }}</div>
        <div class="flex-1 flex space-x-1 items-center">
          <input
            class="
              focus:ring-primary
              h-4
              w-4
              text-primary
              border-gray-300
              rounded
            "
            type="checkbox"
            [name]="'check_box' + box.id"
            [id]="'check_box' + box.id"
            [(ngModel)]="checkedBoxes[box.id]"
          />
          <label [for]="'check_box' + box.id">ist vorhanden</label>
        </div>
      </li>
    </ul>
  </div>
  <div>
    <h2 class="text-lg uppercase font-bold text-gray-900 mt-4">
      Kartons hinzufügen
    </h2>
    <div class="mt-3">
      <label class="block text-sm font-medium text-gray-700">
        Kartonnummer
      </label>
      <div class="flex space-x-2 items-center">
        <input
          (keydown.enter)="onAddBox()"
          type="number"
          class="ip mt-1 w-96"
          [(ngModel)]="newBox.id"
        />
        <button
          (click)="onAddBox()"
          class="
            mt-2
            uppercase
            text-sm
            font-bold
            py-2
            px-4
            rounded-lg
            shadow-md
            cursor-pointer
            bg-primary
            hover:bg-primaryDark
            text-white
            active:bg-white active:text-primary
          "
        >
          Karton hinzufügen
        </button>
      </div>
    </div>
  </div>
  <button
    (click)="onSave()"
    class="
      mt-4
      uppercase
      text-sm
      font-bold
      py-2
      px-4
      rounded-lg
      shadow-md
      cursor-pointer
      bg-primary
      hover:bg-primaryDark
      text-white
      active:bg-white active:text-primary
    "
  >
    Speichern
  </button>
</div>
