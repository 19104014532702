import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CampService } from 'src/app/services/camp.service';
import { CampFullSizeDto } from '../../../../../shared/dtos';
import { MenuService } from '../../services/menu.service';
import { PersonService } from '../../services/person.service';
import { firstValueFrom } from 'rxjs';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-camp',
  templateUrl: './camp.component.html',
  styleUrls: ['./camp.component.scss'],
})
export class CampComponent implements OnChanges, OnInit {
  @Input() camp: CampFullSizeDto = new CampFullSizeDto();
  @Output() campChange: EventEmitter<CampFullSizeDto> = new EventEmitter<CampFullSizeDto>();
  loading: boolean = false;
  view: string = 'persons';

  constructor(
    private campSrv: CampService,
    private personSrv: PersonService,
    private router: ActivatedRoute,
    private menuSrv: MenuService,
    private alertSrv: AlertService,
  ) {}

  onOpenCampTab() {
    this.menuSrv.addTabAndOpen({
      text: `${this.camp.name}`,
      route: ['camps', this.camp?.id.toString()!],
      exact: true,
    });
  }

  async onArchiveCamp() {
    this.camp = await firstValueFrom(this.campSrv.update(this.camp.id, { isArchived: true }));
    this.alertSrv.alert.next({
      text: `${this.camp.name} wurde archiviert.`,
      linkText: '',
    });
    this.campChange.emit(this.camp);
    this.campSrv.updateTrigger.next();
  }

  async onUnarchiveCamp() {
    this.camp = await firstValueFrom(this.campSrv.update(this.camp.id, { isArchived: false }));
      this.alertSrv.alert.next({
        text: `${this.camp.name} wurde wiederhergestellt.`,
        linkText: '',
      });
    this.campChange.emit(this.camp);
    this.campSrv.updateTrigger.next();
  }

  ngOnChanges(): void {
    this.loading = true;
    this.campSrv.findById(this.camp.id).subscribe((camp) => {
      this.camp = camp;
      this.loading = false;
    });
  }

  ngOnInit() {
    this.personSrv.updateTrigger.subscribe(async () => {
      this.camp = await firstValueFrom(this.campSrv.findById(this.camp.id));
    });

    this.router.params.subscribe((params) => {
      if (params.campId) {
        this.loading = true;
        this.campSrv.findById(params.campId).subscribe((camp) => {
          this.camp = camp;
          this.loading = false;
        });
      }
    });
  }
}
