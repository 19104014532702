<app-loader *ngIf="loading"></app-loader>
<div class="h-full" *ngIf="!loading">
  <main *ngIf="person" class="
      flex-1
      relative
      z-0
      focus:outline-none
      xl:order-last
      h-full
      animate-fadeIn
    ">
    <article class="flex h-full flex-col overflow-y-hidden">
      <!-- Profile header -->
      <div class="flex-shrink-0">
        <div class="px-4 sm:px-6 lg:px-8 flex flex-col">
          <div class="mt-6 min-w-0 flex-1 flex flex-col">
            <div class="mt-4 flex justify-between items-center gap-8">
              <div class="flex space-x-2 items-center w-full">
                <h1 [tooltip]="person.firstName + ' ' + person.lastName" class="text-2xl font-bold text-gray-900 uppercase line-clamp-1">
                  {{ person.firstName }} {{ person.lastName }}
                </h1>
                <button *ngIf="this.authSrv.hasRight('manage users')" class="button-animation" tooltip="Person bearbeiten" [routerLink]="[
                    '/persons',
                    { outlets: { person: ['edit', person.id] } }
                  ]">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                  </svg>
                </button>
                <button class="button-animation" tooltip="Passwort zurücksetzen" *ngIf="person.eMail_1 && person.keycloakId && this.authSrv.hasRight('manage users')" (click)="onPwReset()">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
                  </svg>
                </button>
                <button *ngIf="this.authSrv.hasRight('manage users')" class="button-animation" tooltip="Person löschen" (click)="onPersonDelete()">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                </button>
                <button class="button-animation" tooltip="in neuem Tab öffnen" (click)="onOpenPersonTab()">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Tabs -->
      <div class="mt-6 sm:mt-2 2xl:mt-5 flex-shrink-0">
        <div class="border-b border-gray-200">
          <div class="px-4 sm:px-6 lg:px-8">
            <nav class="-mb-px flex space-x-8" aria-label="Tabs">
              <!-- Current: "border-blue-500 text-gray-900", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
              <a (click)="view = 'details'" class="
                  cursor-pointer
                  text-gray-500
                  whitespace-nowrap
                  py-4
                  px-1
                  border-b-2
                  font-medium
                  text-sm
                " [ngClass]="{
                  'border-primary text-gray-900': view === 'details'
                }" aria-current="page">
                Details
              </a>

              <a (click)="view = 'groups'" class="
                  cursor-pointer
                  text-gray-500
                  hover:text-gray-700
                  whitespace-nowrap
                  py-4
                  px-1
                  border-b-2
                  font-medium
                  text-sm
                " [ngClass]="{
                  'border-primary text-gray-900': view === 'groups'
                }">
                Gruppen
              </a>
              <a (click)="view = 'camps'" class="
                  cursor-pointer
                  text-gray-500
                  hover:text-gray-700
                  whitespace-nowrap
                  py-4
                  px-1
                  border-b-2
                  font-medium
                  text-sm
                " [ngClass]="{
                  'border-primary text-gray-900': view === 'camps'
                }">
                Organisationen
              </a>
            </nav>
          </div>
        </div>
      </div>
      <div class="overflow-y-auto">
        <app-person-details *ngIf="view === 'details'" [(person)]="person"></app-person-details>
        <app-person-groups *ngIf="view === 'groups'" [(person)]="person"></app-person-groups>
        <app-person-camps *ngIf="view === 'camps'" [(person)]="person"></app-person-camps>
      </div>
    </article>
  </main>
</div>

<!-- Modal -->

<div *ngIf="pwResetModalActive" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
  aria-modal="true">
  <div class="
      flex
      items-end
      justify-center
      min-h-screen
      pt-4
      px-4
      pb-20
      text-center
      sm:block sm:p-0
    ">
    <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
    <div class="
        inline-block
        align-bottom
        bg-white
        rounded-lg
        px-4
        pt-5
        pb-4
        text-left
        overflow-hidden
        shadow-xl
        transform
        transition-all
        sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6
      ">
      <div>
        <div class="
            mx-auto
            flex
            items-center
            justify-center
            h-12
            w-12
            rounded-full
            bg-green-100
          ">
          <!-- Heroicon name: outline/check -->
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
          </svg>
        </div>
        <div class="mt-3 text-center sm:mt-5">
          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
            Passwort zurücksetzen
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              Möchtest du {{ person.firstName }} {{ person.lastName }} eine
              E-Mail mit einem Passwort-Reset-Link an
              <span class="font-medium">{{ person.eMail_1 }}</span> schicken?
            </p>
          </div>
        </div>
      </div>
      <div class="
          mt-5
          sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense
        ">
        <button type="button" (click)="onSendPwMail()" class="
            w-full
            inline-flex
            justify-center
            rounded-md
            border border-transparent
            shadow-sm
            px-4
            py-2
            bg-primary
            text-base
            font-medium
            text-white
            hover:bg-primary
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-primary
            sm:col-start-2 sm:text-sm
          ">
          Mail verschicken
        </button>
        <button type="button" (click)="pwResetModalActive = false" class="
            mt-3
            w-full
            inline-flex
            justify-center
            rounded-md
            border border-gray-300
            shadow-sm
            px-4
            py-2
            bg-white
            text-base
            font-medium
            text-gray-700
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-primary
            sm:mt-0 sm:col-start-1 sm:text-sm
          ">
          Abbrechen
        </button>
      </div>
    </div>
  </div>
</div>

<app-delete-person [person]="person" (close)="onDeleteModalClose($event)" *ngIf="personDeleteModalShown">
</app-delete-person>
