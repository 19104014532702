import { IsArray, IsBoolean, IsDateString, IsNumber, IsObject, IsOptional, IsString, ValidateNested } from "class-validator";
import { DepartmentFullSizeDto } from "../people/department.full-size.dto";
import { ArticleGroupFullSizeDto } from "./article-group.full-size.dto";
import { BoxFullSizeDto } from "./box.full-size.dto";
import { CategoryFullSizeDto } from "./category.full-size.dto";
import { ExportArticleFullSizeDto } from "./export-article.full-size.dto";
import { GenderLagerFullSizeDto } from "./genderLager.full-size.dto";
import { HashtagRowFullSizeDto } from "./hashtag-row.full-size.dto";
import { PalletFullSizeDto } from "./pallet.full-size.dto";
import { SizeFullSizeDto } from "./size.full-size.dto";
import { SubArticleFullSizeDto } from "./sub-article.full-size.dto";
import { Exclude, Expose, Type } from "class-transformer";

export class ArticleFullSizeDto {

  @IsNumber()
  @Expose()
  id: number;

  @IsDateString()
  @Exclude()
  createdAt: Date;

  @IsDateString()
  @Exclude()
  updatedAt: Date;

  @IsNumber()
  @IsOptional()
  @Exclude()
  createdBy: number | null;

  @IsString()
  @Exclude()
  shortName: string | null = null;

  @IsString()
  @Expose()
  nameDe: string = '';

  @IsString()
  @Expose()
  nameEn: string | null = null;

  @IsString()
  @Expose()
  nameKur: string | null = null;;

  @IsString()
  @Expose()
  description: string | null = null;;

  @IsNumber()
  @Exclude()
  avgWeight: number | null = null;

  @IsNumber()
  @Exclude()
  avgWeightCalculated: number | null = null;

  @IsNumber()
  @Expose()
  componentCount: number = 1;

  @IsNumber()
  @Exclude()
  minRequired: number | null = 0;

  @IsNumber()
  @Exclude()
  leftInB7: number = 0;

  @IsNumber()
  @Exclude()
  avgInA3: number = 0;

  @IsNumber()
  @Exclude()
  maxToDeliver: number | null = 0;

  @IsBoolean()
  @Expose()
  showInList: boolean = true;

  @IsBoolean()
  @Expose()
  addable: boolean = true;

  @IsObject()
  @Type(() => CategoryFullSizeDto)
  @Expose()
  category: CategoryFullSizeDto | null = new CategoryFullSizeDto();

  @IsNumber()
  @Expose()
  fkCategory: number | null;

  @IsObject()
  @Type(() => GenderLagerFullSizeDto)
  @Expose()
  gender: GenderLagerFullSizeDto | null = new GenderLagerFullSizeDto();

  @IsNumber()
  @Expose()
  fkGender: number | null;

  @IsObject()
  @Type(() => SizeFullSizeDto)
  @Expose()
  size: SizeFullSizeDto | null = new SizeFullSizeDto();

  @IsNumber()
  @Expose()
  fkSize: number | null;

  @IsObject()
  @Type(() => ArticleGroupFullSizeDto)
  @Expose()
  articleGroup: ArticleGroupFullSizeDto | null;

  @IsNumber()
  @Expose()
  fkArticleGroup: number | null;

  @IsObject()
  @Type(() => ExportArticleFullSizeDto)
  @Expose()
  exportArticle: ExportArticleFullSizeDto | null

  @IsNumber()
  @Expose()
  fkExportArticle: number | null;

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => ExportArticleFullSizeDto)
  @Expose()
  boxes: BoxFullSizeDto[] = [];

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => HashtagRowFullSizeDto)
  @Expose()
  hashtagRows: HashtagRowFullSizeDto[];

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => PalletFullSizeDto)
  @Expose()
  pallets: PalletFullSizeDto[];

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => SubArticleFullSizeDto)
  @Expose()
  subArticles: SubArticleFullSizeDto[] = [];

  @IsObject()
  @Type(() => DepartmentFullSizeDto)
  @Expose()
  department: DepartmentFullSizeDto | null;

  @IsNumber()
  @Expose()
  fkDepartment: number | null = null;
}
