import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from '../../../services/alert.service';
import { BoxService } from '../../../services/box.service';
import { PartService } from '../../../services/part.service';
import {
  BoxFullSizeDto,
  CreateBoxDto,
  OrderFullSizeDto,
  PartFullSizeDto,
} from '../../../../../../shared/dtos';
import { firstValueFrom, lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-new-box4-order',
  templateUrl: './new-box4-order.component.html',
  styleUrls: ['./new-box4-order.component.scss'],
})
export class NewBox4OrderComponent implements OnInit {
  @Input() order: OrderFullSizeDto = new OrderFullSizeDto();
  @Output() orderChange: EventEmitter<OrderFullSizeDto> =
    new EventEmitter<OrderFullSizeDto>();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() save: EventEmitter<void> = new EventEmitter<void>();
  @Input() part: PartFullSizeDto = new PartFullSizeDto();
  newBox: CreateBoxDto = new CreateBoxDto();

  constructor(
    private boxSrv: BoxService,
    private alertSrv: AlertService,
    private partSrv: PartService
  ) {}

  onClose() {
    this.close.emit();
  }

  getMissingArticleCount() {
    return this.part.articleCount - this.part.foundCount;
  }

  getMissingArticleText() {
    const count = this.getMissingArticleCount();
    return `Noch ${count} Artikel ${count === 1 ? 'fehlt' : 'fehlen'}.`;
  }
  
  async undoCheckPart(box: BoxFullSizeDto, part: PartFullSizeDto) {
    await firstValueFrom(this.partSrv.removeBox(part.id, box.id));
    this.alertSrv.alert.next({
      text: `Karton ${box.id} gelöscht.`,
      linkText: '',
    });
    this.boxSrv.boxDeleteTrigger.next();
  }

  addBox() {
    if (this.newBox.articleCount || 0 > 0) {
      this.partSrv.addBox(this.part.id, this.newBox).subscribe({
        next: async (box) => {
          this.alertSrv.alert.next({
            text: `Karton ${box.id} erstellt.`,
            linkText: 'Rückgängig',
            linkAction: this.undoCheckPart.bind(this, box, this.part),
          });
          this.newBox = new CreateBoxDto();
          this.onClose();
        },
        error: (err) => {
          this.alertSrv.alert.next({
            text: `Karton konnte nicht erstellt werden.`,
            linkText: '',
          });
        },
      });
    } else {
      this.alertSrv.alert.next({
        text: `Bitte gib eine Anzahl ein.`,
        linkText: '',
      });
    }
  }

  ngOnInit(): void {
    this.newBox.fkArticle = this.part.fkArticle;
    this.newBox.fkSubArticle = this.part.fkSubArticle || undefined;
    this.newBox.fkPart = this.part.id;
  }
}
