import { IsNumber, IsObject, IsString, ValidateNested } from "class-validator";
import { PersonFullSizeDto } from "./person.full-size.dto";
import { SettingFullSizeDto } from "./setting.full-size.dto";
import { Expose, Type } from "class-transformer";


export class PersonSettingFullSizeDto {

  @IsNumber()
  @Expose()
  id: number = 0;

  @IsNumber()
  @Expose()
  fkPerson: number = 0;
  
  @IsNumber()
  @Expose()
  fkSetting: number = 0;

  @IsString()
  @Expose()
  settingVal: string = '';

  @IsObject()
  @Type(() => PersonFullSizeDto)
  @ValidateNested()
  @Expose()
  person: PersonFullSizeDto = new PersonFullSizeDto();

  @IsObject()
  @Type(() => SettingFullSizeDto)
  @ValidateNested()
  @Expose()
  setting: SettingFullSizeDto = new SettingFullSizeDto();
}
