import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CampFullSizeDto } from '../../../../../shared/dtos';

@Component({
  selector: 'app-order-camp-details',
  templateUrl: './order-camp-details.component.html',
  styleUrls: ['./order-camp-details.component.scss'],
})
export class OrderCampDetailsComponent implements OnInit {
  @Input() camp: CampFullSizeDto = new CampFullSizeDto();
  @Output() campChange: EventEmitter<CampFullSizeDto> = new EventEmitter<CampFullSizeDto>();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  onClose() {
    this.close.emit();
  }

  constructor() {}

  ngOnInit(): void {}
}
