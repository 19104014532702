import { IsArray, IsDateString, IsNumber, IsObject, IsOptional, IsString, ValidateNested } from "class-validator";
import { OrderFullSizeDto } from "./order.full-size.dto";
import { Exclude, Expose, Type } from "class-transformer";


export class OrganisationFullSizeDto {

  @IsNumber()
  @Expose()
  id: number = 0;

  @IsDateString()
  @Exclude()
  createdAt: Date;

  @IsDateString()
  @Exclude()
  updatedAt: Date;

  @IsString()
  @Expose()
  name: string = '';

  @IsString()
  @Expose()
  webpage: string = '';

  @IsArray()
  @Type(() => OrderFullSizeDto)
  @ValidateNested({ each: true })
  @Expose()
  orders: OrderFullSizeDto[] = [];
}
