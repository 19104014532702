<div class="mt-1 flex flex-col">
  <label class="block text-sm font-medium text-gray-700" for="newAmount"
    >Anzahl der zu entfernenden Artikel angeben:</label
  >
  <input
    name="newAmount"
    class="ip"
    type="number"
    [(ngModel)]="newBox.articleCount"
  />
  <button
    (click)="onSave()"
    class="
      button-animation
      mt-2
      uppercase
      text-sm
      font-bold
      py-2
      px-4
      rounded-lg
      shadow-md
      cursor-pointer
      bg-white
      hover:bg-gray-100
      text-primary
      active:bg-primary active:text-white
    "
  >
    Speichern
  </button>
</div>
