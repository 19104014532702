import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PersonFullSizeDto } from '../../../../../shared/dtos';
import { CampFullSizeDto } from '../../../../../shared/dtos';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-camp-persons',
  templateUrl: './camp-persons.component.html',
  styleUrls: ['./camp-persons.component.scss'],
})
export class CampPersonsComponent implements OnInit {
  @Input() camp: CampFullSizeDto = new CampFullSizeDto();
  @Output() campChange: EventEmitter<CampFullSizeDto> = new EventEmitter<CampFullSizeDto>();
  selectedPerson: PersonFullSizeDto = new PersonFullSizeDto();
  personDetailsShown: boolean = false;

  constructor(
    private router: Router,
    public authSrv: AuthService,
  ) {}

  onPersonCreate() {
    const url = `/persons/new?addCampId=${this.camp.id.toString()}`;
    this.router.navigateByUrl(url);
  }

  onPersonSelect(person: PersonFullSizeDto) {
    this.selectedPerson = person;
    this.personDetailsShown = true;
  }

  ngOnInit(): void {}
}
