import { IsArray, IsNumber, IsString, ValidateNested } from "class-validator";
import { PersonFullSizeDto } from "./person.full-size.dto";
import { Expose, Type } from "class-transformer";


export class GenderFullSizeDto {

  @IsNumber()
  @Expose()
  id: number = 0;

  @IsString()
  @Expose()
  name: string;

  @IsString()
  @Expose()
  customerGender: string = '';

  @IsArray()
  @Type(() => PersonFullSizeDto)
  @ValidateNested({ each: true })
  @Expose()
  people: PersonFullSizeDto[] = [];
}
