import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { SearchResult } from '../../../../shared/dtos';
import { MenuService } from '../services/menu.service';
import { SearchService } from '../services/search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @Input() searchVisible: boolean = false;
  @Output() searchVisibleChange: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('searchBox', { static: false }) orderSearch!: ElementRef;
  q: string = '';
  results: SearchResult[] = [];
  loading: boolean = false;
  inputChange: Subject<string> = new Subject();
  focusedResultIndex = 0;

  @Input() openSearch() {
    this.searchVisible = true;
  }

  constructor(
    private searchSrv: SearchService,
    private router: Router,
    private menuSrv: MenuService
  ) { }

  onSearch() {
    this.inputChange.next(this.q);
  }

  onNavigate(item: SearchResult) {
    if (item.outlet === 'tabs') {
      this.menuSrv.addTabAndOpen({
        text: `${item.type} ${item?.id}`,
        route: [item.link, item?.id.toString()!],
        exact: true,
      });
    } else {
      this.router.navigate([
        item.link,
        { outlets: { [item.outlet]: [item.id.toString()] } },
      ]);
    }
    this.searchVisible = false;
  }

  onSearchToggle($event: KeyboardEvent) {
    const metaKey = navigator.userAgent.includes('Mac')
      ? $event.metaKey
      : $event.ctrlKey;

    if (metaKey && $event.key === 'k') {
      $event.preventDefault();
      this.searchSrv.searchVisible.next(!this.searchVisible);
      this.q = '';
      this.results = [];
      if (this.searchVisible) {
        setTimeout(() => {
          this.orderSearch.nativeElement.focus();
        });
      }
    }
  }

  onSearchClose() {
    this.searchSrv.searchVisible.next(false);
  }

  onOpenFocussed() {
    if (this.results.length > 0) {
      this.onNavigate(this.results[this.focusedResultIndex]);
    }
  }

  ngOnInit(): void {
    this.searchSrv.searchVisible.subscribe((searchVisible) => {
      if (searchVisible) {
        this.focusedResultIndex = 0;
      }
      this.searchVisible = searchVisible;
      this.q = '';
      this.results = [];
      if (this.searchVisible) {
        setTimeout(() => {
          this.orderSearch.nativeElement.focus();
        });
      }
    });

    this.inputChange
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((q) => {
          this.loading = true;
          return this.searchSrv.search(q);
        })
      )
      .subscribe((results) => {
        this.results = results;
        this.loading = false;
      });
  }
}
