import { Component, OnInit } from '@angular/core';
import { OrderFullSizeDto } from '../../../../../shared/dtos';
import { PersonFullSizeDto } from '../../../../../shared/dtos';
import { AuthService } from '../../services/auth.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss'],
})
export class MyOrdersComponent implements OnInit {
  orders: OrderFullSizeDto[] = [];

  constructor(private authSrv: AuthService, private orderSrv: OrderService) {}

  ngOnInit(): void {
    this.orderSrv.findAll().subscribe((orders) => {
      this.orders = orders;
    });
  }
}
