import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BoxFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { BoxService } from '../../services/box.service';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-box-delete',
  templateUrl: './box-delete.component.html',
  styleUrls: ['./box-delete.component.scss'],
})
export class BoxDeleteComponent implements OnInit {
  @Input() box: BoxFullSizeDto = new BoxFullSizeDto();
  @Output() boxChange: EventEmitter<BoxFullSizeDto> = new EventEmitter();

  constructor(
    private boxSrv: BoxService,
    private alertSrv: AlertService,
    private menuSrv: MenuService,
  ) {}

  onDelete() {
    this.boxSrv.delete(this.box.id).subscribe(() => {
      this.alertSrv.alert.next({ text: 'Karton gelöscht!', linkText: '' });
      this.boxChange.emit(new BoxFullSizeDto());
      this.boxSrv.reloadBoxList.next();

      // Close tab when deleting via tab
      if (this.menuSrv.activeRouteCurrent[0] === 'boxes' && this.menuSrv.activeRouteCurrent[1] === this.box.id.toString()) {
        this.menuSrv.closeCurrentTab();
      }
    });
  }

  ngOnInit(): void {}
}
