import { Component, OnInit } from '@angular/core';
import { WarehouseFullSizeDto } from '../../../../../shared/dtos';
import { PersonFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { AuthService } from '../../services/auth.service';
import { PersonService } from '../../services/person.service';
import { WarehouseService } from '../../services/warehouse.service';

@Component({
  selector: 'app-routing-personal-settings',
  templateUrl: './routing-personal-settings.component.html',
  styleUrls: ['./routing-personal-settings.component.scss'],
})
export class RoutingPersonalSettingsComponent implements OnInit {
  warehouses: WarehouseFullSizeDto[] = [];
  selectedWarehouseId: number = 0;

  constructor(
    private warehouseSrv: WarehouseService,
    private authSrv: AuthService,
    private personSrv: PersonService,
    private alertSrv: AlertService
  ) {}

  onWarehouseSelect() {
    this.warehouses.find(
      (warehouse) => warehouse.id === this.selectedWarehouseId
    ) || new WarehouseFullSizeDto();
  }

  onSave() {
    if (!this.authSrv.authContext?.person.id) {
      return;
    }
    this.personSrv
      .setWarehouse(this.authSrv.authContext?.person.id, this.selectedWarehouseId)
      .subscribe(() => {

        if (!this.authSrv.authContext?.person){
          return;
        }

        this.authSrv.authContext.person.fkCurrentWarehouse = this.selectedWarehouseId;
        this.alertSrv.alert.next({
          text: 'Lagerort gespeichert.',
          linkText: '',
        });
      });
  }

  ngOnInit(): void {

    this.warehouseSrv.findAll().subscribe((warehouses) => {
      this.warehouses = warehouses;
      this.selectedWarehouseId = this.authSrv.authContext?.person.fkCurrentWarehouse || 0;
    });
  }
}
