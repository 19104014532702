import { Expose } from "class-transformer";
import { IsNumber, IsObject, IsOptional, IsString } from "class-validator";

export class UpdatePartDto {

  @IsNumber()
  @Expose()
  id?: number;

  @IsNumber()
  @Expose()
  fkArticle: number;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkSubArticle: number | null;

  @IsNumber()
  @Expose()
  articleCount: number = 0;

  @IsNumber()
  @IsOptional()
  @Expose()
  foundCount: number;

  @Expose()
  @IsOptional()
  @IsString()
  notes: string | null = null;
}