import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-person-empty',
  templateUrl: './person-empty.component.html',
  styleUrls: ['./person-empty.component.scss']
})
export class PersonEmptyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
