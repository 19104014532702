<div class="h-screenExceptMenu" *ngIf="loading">
  <app-loader></app-loader>
</div>
<div *ngIf="!loading" class="sm:h-screenExceptMenu flex flex-col max-w-7xl mx-auto">
  <button [routerLink]="['/']" class="
      mx-2
      sm:mx-0
      self-start
      flex
      space-x-2
      items-center
      mt-4
      bg-primary
      text-primary100
      p-2
      rounded-md
      shadow-md
    ">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
    </svg>
    <span> Zurück zur Übersicht</span>
  </button>
  <div class="flex space-x-2 items-center mt-4">
    <span class="text-3xl uppercase font-bold">Bestellung {{ order.id }}</span>
    <button (click)="onOrderPrint()">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-gray-600" fill="none" viewBox="0 0 24 24"
        stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
          d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
      </svg>
    </button>
  </div>

  <div class="flex flex-col mt-4">
    <span class="text-lg font-bold">Status</span>
    <app-order-status mode="view" [order]="order"></app-order-status>
  </div>
  <div class="flex flex-col sm:flex-row gap-4 mt-4">
    <div>
      <h3 class="text-sm text-primary">Organisation</h3>

      <p class="font-semibold max-w-2xl text-gray-700" [ngClass]="{
        'line-through': order.camp.isArchived
      }" [innerHTML]="order.camp.name"></p>
      <p class="max-w-2xl font-medium text-sm text-gray-500">
        {{ order.camp.street }} {{ order.camp.streetNumber }}
      </p>
      <p class="max-w-2xl font-medium text-sm text-gray-500">
        {{ order.camp.postCode }} {{ order.camp.city }}
      </p>
    </div>
    <div>
      <h3 class="text-sm text-primary">Ansprechpartner:in</h3>

      <p *ngIf="order.customer?.person" class="font-semibold max-w-2xl text-gray-700">
        {{ order.customer.person.firstName }}
        {{ order.customer.person.lastName }}
      </p>
      <p *ngIf="order.customer?.person" class="max-w-2xl font-medium text-sm text-gray-500">
        {{ order.customer.person.eMail_1 || order.customer.person.eMail_2 }}<br />
        {{ order.customer.person.phone_1 || order.customer.person.phone_2 }}
      </p>
      <p *ngIf="!order.customer.person" class="max-w-2xl font-medium text-sm text-gray-500">
        -
      </p>
    </div>
  </div>
  <div class="flex flex-col mt-4" *ngIf="order.notes">
    <span class="text-lg font-bold">Bemerkungen</span>
    <span class="text-gray-600 text-sm">{{ order.notes }}</span>
  </div>
  <div class="mt-4 h-full overflow-y-auto flex flex-col">
    <span class="text-lg font-bold">Bestellungsteile</span>
    <div class="h-full overflow-y-auto">
      <app-order-parts [order]="order" mode="2view"></app-order-parts>
    </div>
  </div>
</div>

<app-print-order [order]="order" (close)="printModalActive = false" mode="external" *ngIf="printModalActive">
</app-print-order>
