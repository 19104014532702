import { IsNull } from "typeorm";
import { ArticleFullSizeDto } from "./article.full-size.dto";
import { CategoryFullSizeDto } from "./category.full-size.dto";
import { IsAlpha, IsArray, IsBoolean, IsDateString, IsNumber, IsObject, IsOptional, IsString, ValidateNested } from "class-validator";
import { Exclude, Expose, Type } from "class-transformer";

export class SizeFullSizeDto {

  @IsNumber()
  @Expose()
  id: number = 0;

  @IsDateString()
  @Exclude()
  createdAt: Date;

  @IsDateString()
  @Exclude()
  updatedAt: Date;

  @IsString()
  @Expose()
  sizeName: string = '';

  @IsString()
  @IsOptional()
  @Expose()
  nameDe: string | null = '';

  @IsString()
  @Expose()
  nameEn: string = '';

  @IsNumber()
  @IsOptional()
  @Expose()
  fkCategory: number | null = null;

  @IsBoolean()
  @Expose()
  showInList: boolean = false;

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => ArticleFullSizeDto)
  @Expose()
  articles: ArticleFullSizeDto[] = [];

  @IsObject()
  @ValidateNested()
  @Type(() => CategoryFullSizeDto)
  @Expose()
  category: CategoryFullSizeDto | null = new CategoryFullSizeDto();
}
