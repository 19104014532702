import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ArticleFullSizeDto } from '../../../../../shared/dtos';
import { SubArticleFullSizeDto } from '../../../../../shared/dtos';

@Component({
  selector: 'app-article-details',
  templateUrl: './article-details.component.html',
  styleUrls: ['./article-details.component.scss'],
})
export class ArticleDetailsComponent implements OnInit, OnChanges {
  @Input() article: ArticleFullSizeDto = new ArticleFullSizeDto();
  @Output() articleChange: EventEmitter<ArticleFullSizeDto> = new EventEmitter();

  @Input() subArticle: SubArticleFullSizeDto | null = new SubArticleFullSizeDto();
  constructor() {}
  articleData: { key: string; val: string | null }[] = [];

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.articleData = [
      { key: 'Artikelnummer', val: this.article?.id.toString() },
      { key: 'Bezeichnung englisch', val: this.article?.nameEn },
      { key: 'Bezeichnung kurdisch', val: this.article?.nameKur },
      { key: 'Export-Kategorie', val: this.article?.exportArticle?.name || '-' },
      { key: 'Artikelgruppe', val: this.article?.articleGroup?.name || '-' },
    ].filter((obj) => obj.val && obj.val.length > 0);

    if (this.subArticle && this.subArticle.id) {
      this.article.subArticles = [this.subArticle];
    }
  }
}
