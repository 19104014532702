<app-loader *ngIf="loading"></app-loader>
<ul *ngIf="articles && articles.length > 0 && !loading" class="flex flex-col relative z-0 animate-fadeIn">
  <li *ngFor="let article of articles; let i = index" (click)="
      (mode === 'select-all' ||
        (mode === 'select-sub' && article.subArticles.length === 0)) &&
        onArticleSelect(article)
    " [ngClass]="{
      'bg-gray-100': article.id === selectedArticleId,
      'hover:bg-gray-100 cursor-pointer':
        mode === 'select-all' ||
        (mode === 'select-sub' &&
          (!article.subArticles || article.subArticles.length === 0)),
      'border-b border-gray-200': mode === 'edit'
    }">
    <div class="px-4 py-4 sm:px-6">
      <div class="flex items-center justify-between">
        <div [tooltip]="article.nameDe" class="text-sm font-medium text-primary truncate w-9/12">
          {{ article.nameDe }}
        </div>
        <div class="w-1/12 h-5 flex justify-end">
          <button tooltip="In neuem Tab öffnen" (click)="onOpenArticleTab(article); $event.stopPropagation()">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg>
          </button>
        </div>
        <div class="flex space-x-1 w-2/12 justify-end">
          <div *ngIf="!article.showInList">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-primary" viewBox="0 0 20 20"
              fill="currentColor">
              <path fill-rule="evenodd"
                d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                clip-rule="evenodd" />
              <path
                d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
            </svg>
          </div>
          <div *ngIf="!article.addable">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-primary" viewBox="0 0 20 20"
              fill="currentColor">
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
          </div>

          <div *ngIf="getRowsString(article) && getRowsString(article).length > 0" class="ml-2 flex-shrink-0 flex">
            <p class="
                  px-2
                  inline-flex
                  text-xs
                  leading-5
                  font-semibold
                  rounded-sm
                  bg-green-50
                  text-green-700
                ">
              {{ getRowsString(article) }}
            </p>
          </div>
        </div>
      </div>
      <div class="mt-2 sm:flex sm:justify-between">
        <div class="sm:flex">
          <p class="flex items-center text-sm text-gray-500">
            {{ article.gender?.nameDe }}
          </p>
          <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
            {{ article.size?.sizeName }}
          </p>
        </div>
        <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
          {{ article.category?.nameDe || '-' }}
        </div>
      </div>
      <div class="mt-2" *ngIf="article.subArticles && article.subArticles.length > 0">
        <ul class="flex gap-2 flex-wrap">
          <li class="cursor-pointer button-animation" *ngFor="let subArticle of article.subArticles">
            <a (click)="
                (mode === 'edit' || mode === 'select-sub') &&
                  onSubArticleSelect(subArticle)
              " [ngClass]="{
                'bg-gray-600': subArticle.id === selectedSubArticle?.id,
                'text-white': subArticle.id === selectedSubArticle?.id
              }" class="
                inline-flex
                items-center
                px-2.5
                py-1
                rounded-sm
                text-xs
                font-medium
                bg-gray-100
              ">
              {{ subArticle.name }}
            </a>
          </li>
        </ul>
      </div>
      <div class="mt-2 flex items-center space-x-2" *ngIf="mode === 'edit'">
        <input [(ngModel)]="amounts[i]" type="number" min="1"
          (keydown.enter)="onArticleAdd(article, amounts[i] || 0, selectedSubArticle); amounts[i] = undefined"
          class="py-1 flex-grow rounded-md text-sm focus:border-primary" placeholder="Menge angeben" />
        <button
          [tooltip]="amounts[i] && article.subArticles.length > 0 && selectedSubArticle?.fkArticle !== article.id ? 'Bitte Unterartikel auswählen' : 'Artikel hinzufügen'"
          class="bg-primary disabled:opacity-50 text-white p-1 rounded-md" [disabled]="
            !(
              amounts[i] &&
              ((article.subArticles.length > 0 &&
                selectedSubArticle?.fkArticle === article.id) ||
                article.subArticles.length === 0)
            )
          " (click)="
            onArticleAdd(article, amounts[i] || 0, selectedSubArticle);
            amounts[i] = undefined;
          ">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
          </svg>
        </button>
      </div>
    </div>
  </li>
</ul>
<div *ngIf="!loading && (!articles || articles.length === 0)" class="flex justify-center items-center mt-16 p-5">
  Keine Artikel gefunden
</div>