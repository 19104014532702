import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { WarehouseFullSizeDto } from '../../../../shared/dtos';
import { HhHttpService } from './hh-http.service';

@Injectable({
  providedIn: 'root',
})
export class WarehouseService {
  constructor(private httpSrv: HhHttpService) {}

  findAll() {
    return this.httpSrv.get<WarehouseFullSizeDto[]>(`warehouse`);
  }

  update(warehouse: WarehouseFullSizeDto) {
    return this.httpSrv
      .put<WarehouseFullSizeDto>(`warehouse`, warehouse)
      .pipe(map((elem) => Object.assign(new WarehouseFullSizeDto(), elem)));
  }
}
