import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CampFullSizeDto} from '../../../../../shared/dtos';
import {MenuService} from '../../services/menu.service';

/**
 * The component is responsible for listing camps.
 *
 * @author Julius Behrendt
 * @author Torben Scharf
 */
@Component({
  selector: 'app-camp-list',
  templateUrl: './camp-list.component.html',
  styleUrls: ['./camp-list.component.scss'],
})
export class CampListComponent implements OnInit {
  @Input() camps: CampFullSizeDto[] = [];
  @Input() selectedCampId: number;
  @Input() mode: string = 'select';
  @Output() campSelect: EventEmitter<CampFullSizeDto> = new EventEmitter();
  loading: boolean = false;

  constructor(private menuSrv: MenuService) {
  }

  /**
   * Opens a camp tab.
   *
   * @param camp the selected camp
   */
  onOpenCampTab(camp: CampFullSizeDto): void {
    this.menuSrv.addTabAndOpen({
      text: `${camp.name}`,
      route: ['camps', camp?.id.toString()!],
      exact: true,
    });
  }

  /**
   * Is executed when a camp is selected.
   *
   * @param camp the selected camp
   */
  onCampSelect(camp: CampFullSizeDto): void {
    this.campSelect.emit(camp);
  }

  ngOnInit(): void {
  }
}
