import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { BlockFullSizeDto } from '../../../../../shared/dtos';
import { WarehouseFullSizeDto } from '../../../../../shared/dtos';
import { BlockService } from '../../services/block.service';

@Component({
  selector: 'app-block-grid',
  templateUrl: './block-grid.component.html',
  styleUrls: ['./block-grid.component.scss'],
})
export class BlockGridComponent implements OnChanges {
  blocks: BlockFullSizeDto[] = [];
  @Input() warehouse: WarehouseFullSizeDto = new WarehouseFullSizeDto();
  @Output() blockSelected: EventEmitter<BlockFullSizeDto> = new EventEmitter();
  loading: boolean = false;

  constructor(private blockSrv: BlockService) {}

  onBlockSelected(block: BlockFullSizeDto) {
    this.blockSelected.emit(block);
  }

  ngOnChanges(): void {
    this.loading = true;
    this.blockSrv.findByWarehouseId(this.warehouse.id).subscribe((blocks) => {
      this.blocks = blocks;
      this.loading = false;
    });
  }
}
