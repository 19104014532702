import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BoxFullSizeDto } from '../../../../../shared/dtos';
import { HashtagRowFullSizeDto } from '../../../../../shared/dtos';
import { PalletFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { HashtagRowService } from '../../services/hashtag-row.service';

@Component({
  selector: 'app-routing-hashtag-row',
  templateUrl: './routing-hashtag-row.component.html',
  styleUrls: ['./routing-hashtag-row.component.scss'],
})
export class RoutingHashtagRowComponent implements OnInit {
  loading: boolean = false;
  hashtagRow: HashtagRowFullSizeDto = new HashtagRowFullSizeDto();
  pallet: PalletFullSizeDto = new PalletFullSizeDto();
  box: BoxFullSizeDto | null = new BoxFullSizeDto();

  constructor(
    private router: ActivatedRoute,
    private hashtagRowSrv: HashtagRowService,
    private alertSrv: AlertService
  ) {}

  ngOnInit(): void {
    this.router.params.subscribe((params) => {
      if (params.hashtagRowId) {
        this.loading = true;
        this.hashtagRowSrv.findById(params.hashtagRowId).subscribe(
          (hashtagRow) => {
            this.loading = false;
            this.hashtagRow = hashtagRow;
          },
          (err) => {
            this.loading = false;
            this.alertSrv.alert.next({
              text: `Reihe konnte nicht gefunden werden.`,
              linkText: '',
            });
          }
        );
      }
    });
  }
}
