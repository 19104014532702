<div class="max-w-3xl mx-auto h-screenExceptMenu flex flex-col">
  <h1 class="text-xl uppercase font-bold mt-4 text-gray-900">
    Kartoninventur durchführen
  </h1>
  <div class="mt-3">
    <label class="block text-sm font-medium text-gray-700">
      Hier mit dem Scanner reinschreiben
    </label>
    <div class="flex space-x-2 items-center">
      <input
        (keydown.enter)="onAddBox()"
        type="text"
        class="ip mt-1 w-96"
        [(ngModel)]="boxStr"
      />
      <button
        (click)="onAddBox()"
        class="
          mt-2
          uppercase
          text-sm
          font-bold
          py-2
          px-4
          rounded-lg
          shadow-md
          cursor-pointer
          bg-primary
          hover:bg-primaryDark
          text-white
          active:bg-white active:text-primary
        "
      >
        Karton hinzufügen
      </button>
    </div>
  </div>
  <ul class="flex flex-col w-full mt-4">
    <li class="flex">
      <div class="flex-1 font-bold">Kartonnummer</div>
      <div class="flex-1 font-bold">Artikel</div>
      <div class="flex-1 font-bold">Geschlecht</div>
      <div class="flex-1 font-bold">Größe</div>
      <div class="flex-1 font-bold">Anzahl</div>
    </li>
    <li ngClass="text-gray-600" *ngFor="let box of boxes" class="flex">
      <div class="flex-1">{{ box.id }}</div>
      <div class="flex-1">{{ box.article.nameDe }}</div>
      <div class="flex-1">{{ box.article.gender?.nameDe }}</div>
      <div class="flex-1">{{ box.article.size?.sizeName }}</div>
      <div class="flex-1">{{ box.articleCount }}</div>
    </li>
  </ul>
</div>
