<div class="flex space-x-4 h-screenExceptMenu overflow-y-auto">
  <div
    class="
      flex flex-col
      pt-6
      px-6
      flex-shrink-0
      h-full
      overflow-y-auto
      border-r border-gray-300
    "
  >
    <h1 class="text-xl font-bold text-gray-900 uppercase">Lagerblöcke</h1>
    <app-warehouse-list
      class="h-full overflow-y-auto"
      (blockSelected)="onBlockSelected($event)"
    ></app-warehouse-list>
  </div>
  <div
    class="
      max-w-full
      w-72
      flex-shrink-0
      h-full
      overflow-y-auto
      border-r border-gray-300
    "
  >
    <app-hashtag-row-list
      [block]="selectedBlock"
      (palletSelected)="onPalletSelected($event)"
    ></app-hashtag-row-list>
  </div>
  <div
    class="
      pt-6
      md:h-full md:w-full
      overflow-y-auto
      px-4
      flex flex-col
      w-72
      flex-shrink-0
      md:flex-shrink
    "
  >
    <app-pallet-details
      (boxSelected)="onBoxSelected($event)"
      [pallet]="selectedPallet"
    ></app-pallet-details>

    <div class="mt-4">
      <app-box-details
        class="overflow-y-auto h-full"
        [box]="selectedBox"
      ></app-box-details>
    </div>
  </div>
</div>
