<div class="flex overflow-y-hidden h-full">
  <div *ngIf="!this.authSrv.hasRight('manage users')" class="flex-1 border-r border-gray-300 overflow-y-auto">
    <h2 class="text-lg font-bold p-4">Gruppen</h2>
    <app-group-list
      mode="select"
      [(groups)]="personGroups"
    ></app-group-list>
  </div>
  <div  *ngIf="this.authSrv.hasRight('manage users')" class="flex-1 border-r border-gray-300 overflow-y-auto">
    <h2 class="text-lg font-bold p-4">Gruppe entfernen</h2>
    <app-group-list
      mode="remove"
      (groupSelected)="onGroupRemove($event)"
      [(groups)]="personGroups"
    ></app-group-list>
  </div>
  <div *ngIf="this.authSrv.hasRight('manage users')" class="flex-1 overflow-y-auto">
    <h2 class="text-lg font-bold p-4">Gruppe hinzufügen</h2>
    <app-group-list
      mode="add"
      (groupSelected)="onGroupAdd($event)"
      [(groups)]="groups"
    ></app-group-list>
  </div>
</div>
