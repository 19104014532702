import { IsArray, IsBoolean, IsNumber, IsString, ValidateNested } from "class-validator";
import { MessageFullSizeDto } from "./message.full-size.dto";
import { OrderFullSizeDto } from "./order.full-size.dto";
import { Expose, Type } from "class-transformer";


export class StatusFullSizeDto {
  
  @IsNumber()
  @Expose()
  id: number = 0;

  @IsString()
  @Expose()
  name: string = '';

  @IsString()
  @Expose()
  nameOfficial: string = '';

  @IsString()
  @Expose()
  description: string = '';

  @IsBoolean()
  @Expose()
  orderOnly: boolean = false;

  @IsNumber()
  @Expose()
  percentage: number = 0;

  @IsArray()
  @Type(() => MessageFullSizeDto)
  @ValidateNested({ each: true })
  @Expose()
  messages: MessageFullSizeDto[] = [];

  @IsArray()
  @Type(() => OrderFullSizeDto)
  @ValidateNested({ each: true })
  @Expose()
  orders: OrderFullSizeDto[] = [];

  checked: boolean;
}
