import { Injectable } from '@angular/core';
import { PersonGroupFullSizeDto } from '../../../../shared/dtos';
import { HhHttpService } from './hh-http.service';

@Injectable({
  providedIn: 'root',
})
export class PersonGroupService {
  constructor(private httpSrv: HhHttpService) {}

  delete(personGroup: PersonGroupFullSizeDto) {
    return this.httpSrv.delete(`person-group/${personGroup.id}`);
  }
}
