<div class="space-y-6">
  <div *ngIf="orders && orders['Bestellt']">
    <h2 class="font-bold uppercase text-lg">Bestellt</h2>
    <div class="flex gap-4 max-w-full overflow-x-auto mt-2">
      <ng-container *ngFor="let order of orders['Bestellt']">
        <app-order-list-light-elem [order]="order"></app-order-list-light-elem>
      </ng-container>
    </div>
  </div>

  <div *ngIf="orders && orders['In Bearbeitung']">
    <h2 class="font-bold uppercase text-lg">In Bearbeitung</h2>
    <div class="flex gap-4 max-w-full overflow-x-auto mt-2">
      <ng-container *ngFor="let order of orders['In Bearbeitung']">
        <app-order-list-light-elem [order]="order"></app-order-list-light-elem>
      </ng-container>
    </div>
  </div>

  <div *ngIf="orders && orders['Fertig im Lager']">
    <h2 class="font-bold uppercase text-lg">Fertig im Lager</h2>
    <div class="flex gap-4 max-w-full overflow-x-auto mt-2">
      <ng-container *ngFor="let order of orders['Fertig im Lager']">
        <app-order-list-light-elem [order]="order"></app-order-list-light-elem>
      </ng-container>
    </div>
  </div>

  <div *ngIf="orders && orders['Zugestellt/Abgeholt']">
    <h2 class="font-bold uppercase text-lg">Zugestellt/Abgeholt</h2>
    <div class="flex gap-4 max-w-full overflow-x-auto mt-2">
      <ng-container *ngFor="let order of orders['Zugestellt/Abgeholt']">
        <app-order-list-light-elem [order]="order"></app-order-list-light-elem>
      </ng-container>
    </div>
  </div>
</div>
