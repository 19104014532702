import {Component, OnInit} from '@angular/core';
import {routes} from '../../menu/routes';
import {PersonFullSizeDto} from '../../../../../shared/dtos';
import {ArticleService} from '../../services/article.service';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-routing-dashboard',
  templateUrl: './routing-dashboard.component.html',
  styleUrls: ['./routing-dashboard.component.scss'],
})
export class RoutingDashboardComponent implements OnInit {
  sentItemsCount: string = '';
  routes = routes.filter((route) => !route.bold);

  constructor(
    private articleSrv: ArticleService,
    public authSrv: AuthService
  ) {
  }


  ngOnInit(): void {
    this.articleSrv.getSentItems().subscribe((sentItemsCount) => {
      this.sentItemsCount = sentItemsCount.sumPretty;
    });
  }

  get loggedIn(): boolean {
    return this.authSrv.loggedIn;
  }

  get userName(): string {
    return this.authSrv.userName;
  }
}
