<app-loader *ngIf="loading"></app-loader>
<div class="flex flex-col w-full h-screenExceptMenu" *ngIf="!loading">
  <google-map
    [center]="locationElbe264"
    [zoom]="12"
    height="100%"
    width="100%"
    class="w-full h-full"
  >
    <map-marker
      [options]="{
        label: {
          text: order.id.toString(),
          color: 'white',
          fontSize: '7pt',
          fontWeight: '700'
        }
      }"
      *ngFor="let order of orders"
      [position]="order.location"
      (mapClick)="onOrderSelect(order)"
    ></map-marker>
    <!--<map-info-window>{{ order.camp.name }}</map-info-window>-->
  </google-map>
  <div
    class="
      lg:absolute lg:left-10 lg:bottom-10
      bg-white
      shadow-lg
      rounded-lg
      max-w-full
      lg:max-w-sm
      overflow-hidden
    "
  >
    <div class="p-4">
      <h2 class="font-bold uppercase text-lg">Lieferungen</h2>
      <span class="text-gray-500">{{ orders.length }} gefunden</span>
    </div>

    <div class="px-4 grid grid-cols-2 gap-y-1">
      <div class="relative flex items-start" *ngFor="let aStatus of status">
        <div class="flex items-center h-5">
          <input [id]="'showStatus_' + aStatus.id" [name]="'showStatus_' + aStatus.id" [(ngModel)]="aStatus.checked" 
          (change)="filterOrders()" type="checkbox" 
             class="
              focus:ring-primary
              h-4
              w-4
              text-primary
              border-gray-300
              rounded
            " />
        </div>
        <div class="ml-3 text-sm">
          <label [for]="'showStatus_' + aStatus.id" class="font-medium text-gray-700">{{aStatus.name}}</label>
        </div>
      </div>
    </div>
    
    <div class="h-120 mt-2.5 overflow-y-auto">
      <app-order-list
        (orderSelect)="onOrderSelect($event)"
        [orders]="orders"
      ></app-order-list>
    </div>
  </div>
  <div
    class="
      absolute
      right-0
      bottom-0
      h-screenExceptMenu
      bg-white
      shadow-lg
      rounded-lg
      z-10
    "
    *ngIf="selectedOrder && selectedOrder.id"
  >
    <div
      (click)="onDetailsClose()"
      class="absolute top-1 right-1 cursor-pointer z-20"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </div>
    <app-order [order]="selectedOrder"></app-order>
  </div>
</div>
