import { IsBoolean, IsEmail, IsNumber, IsOptional, IsString } from "class-validator";
import { Expose } from "class-transformer";

export class CreateCampDto {

  @IsString()
  @Expose()
  name: string;

  @IsString()
  @IsOptional()
  @Expose()
  deliveryNote: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  street: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  streetNumber: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  postCode: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  city: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  subLocality: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  country: string | null = null;

  @IsEmail()
  @IsOptional()
  @Expose()
  email: string | null;

  @IsBoolean()
  @Expose()
  isZea: boolean;

  @IsBoolean()
  @Expose()
  isWinter: boolean;

  @IsBoolean()
  @Expose()
  isExport: boolean;
  
  @IsString()
  @IsOptional()
  @Expose()
  notes: string | null;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkCampCategory: number | null = null;
}
