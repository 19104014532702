<div
  class="absolute w-3/5 z-10 right-0 top-0 h-full bg-white shadow-lg rounded-lg"
>
  <div (click)="onClose()" class="z-30 absolute top-1 right-1 cursor-pointer">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  </div>
  <app-person [(person)]="person"></app-person>
</div>
