import { IsArray, IsNumber, IsString, ValidateNested } from "class-validator";
import { PersonSettingFullSizeDto } from "./person-setting.full-size.dto";
import { Expose, Type } from "class-transformer";


export class SettingFullSizeDto {

  @IsNumber()
  @Expose()
  id: number = 0;

  @IsString()
  @Expose()
  name: string = '';

  @IsArray()
  @Type(() => PersonSettingFullSizeDto)
  @ValidateNested({ each: true })
  @Expose()
  personSettings: PersonSettingFullSizeDto[] = [];
}
