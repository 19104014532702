import {Injectable} from '@angular/core';
import {HhHttpService} from "./hh-http.service";
import {CampCategoryFullSizeDto} from "../../../../shared/src/dtos/order/camp-category.full-size.dto";
import {Observable} from "rxjs";

/**
 * The service is responsible for getting camp categories.
 *
 * @author Julius Behrendt
 */
@Injectable({
  providedIn: 'root'
})
export class CampCategoryService {
  constructor(private httpSrv: HhHttpService) {
  }

  /**
   * Gets camp categories.
   */
  findAll(): Observable<CampCategoryFullSizeDto[]> {
    return this.httpSrv.get<CampCategoryFullSizeDto[]>('camp-categories');
  }
}
