import { IsArray, IsBoolean, IsNumber, IsObject, IsOptional, IsString, ValidateNested } from "class-validator";
import { Exclude, Expose, Type } from "class-transformer";

export class UpdateArticleDto {

  @IsString()
  @Expose()
  @IsOptional()
  nameDe?: string;

  @IsNumber()
  @Expose()
  @IsOptional()
  fkDepartment?: number;

  @IsNumber()
  @Expose()
  @IsOptional()
  fkCategory?: number;

  @IsNumber()
  @Expose()
  @IsOptional()
  fkGender?: number;

  @IsNumber()
  @Expose()
  @IsOptional()
  fkSize?: number;

  @IsNumber()
  @Expose()
  @IsOptional()
  fkArticleGroup?: number;

  @IsNumber()
  @Expose()
  @IsOptional()
  fkExportArticle?: number;

  @IsString()
  @Expose()
  @IsOptional()
  nameEn?: string;

  @IsString()
  @Expose()
  @IsOptional()
  nameKur?: string;

  @IsString()
  @Expose()
  @IsOptional()
  description?: string;

  @IsNumber()
  @Expose()
  @IsOptional()
  componentCount?: number;

  @IsNumber()
  @Exclude()
  @IsOptional()
  minRequired?: number;

  @IsNumber()
  @Exclude()
  @IsOptional()
  leftInB7?: number;

  @IsNumber()
  @Exclude()
  @IsOptional()
  avgInA3?: number;

  @IsNumber()
  @Exclude()
  @IsOptional()
  maxToDeliver?: number;

  @IsBoolean()
  @Expose()
  @IsOptional()
  showInList?: boolean;

  @IsBoolean()
  @Expose()
  @IsOptional()
  addable?: boolean;

}
