<app-camp-person-details *ngIf="personDetailsShown" [(person)]="selectedPerson"
  (close)="personDetailsShown = false"></app-camp-person-details>
<div class="mt-6 px-4 sm:px-6 lg:px-8 grid grid-cols-2 gap-4">
  <button (click)="onPersonSelect(person)" class="
      border-2 border-gray-300
      rounded-md
      p-4
      flex flex-col
      items-start
      cursor-pointer
      hover:border-gray-700 hover:border-2
      button-animation
      w-full
      text-left
    " *ngFor="let person of camp.persons">
    <span class="font-bold text-sm text-gray-700 line-clamp-1">{{ person.firstName }} {{ person.lastName }}</span>
    <span *ngIf="person.company" class="font-medium text-sm text-gray-700 line-clamp-1">{{ person.company }}
    </span>
    <span class="text-gray-500 text-sm mt-1 w-full line-clamp-1">{{
      person.phone_1 || person.phone_2
      }}</span>
    <span class="text-gray-500 text-sm w-full line-clamp-1">{{
      person.eMail_1 || person.eMail_2
      }}</span>
  </button>
  <button *ngIf="authSrv.hasRight('manage users')" class="button-animation
    relative
    block
    max-w-fullExceptFold
    border-2 border-gray-300 border-dashed
    rounded-lg
    p-4
    mx-4
    lg:mx-0
    text-center
    hover:border-gray-700
    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary" (click)="onPersonCreate()">
    <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24"
      stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
    </svg>

    <span class="mt-2 block text-sm font-medium text-gray-900">
      Erstelle eine:n Ansprechpartner:in
    </span>
  </button>
</div>