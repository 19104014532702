import { Injectable } from '@angular/core';
import { SizeFullSizeDto, StatusFullSizeDto } from '../../../../shared/dtos';
import { HhHttpService } from './hh-http.service';

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  constructor(private httpSrv: HhHttpService) {}

  findAll() {
    return this.httpSrv.get<StatusFullSizeDto[]>(`status`);
  }
}
