import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MapDirectionsService,
  MapInfoWindow,
  MapMarker,
} from '@angular/google-maps';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';
import { HhHttpService } from 'src/app/services/hh-http.service';
import { OrderFullSizeDto } from '../../../../../shared/dtos';

@Component({
  selector: 'app-order-map',
  templateUrl: './order-map.component.html',
  styleUrls: ['./order-map.component.scss'],
})
export class OrderMapComponent implements OnInit {
  loading = false;
  @Input() order: OrderFullSizeDto = new OrderFullSizeDto();
  @Output() orderChange = new EventEmitter<OrderFullSizeDto>();

  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow | undefined;
  directionsResults$:
    | Observable<google.maps.DirectionsResult | undefined>
    | undefined;

  locationElbe264: google.maps.LatLngLiteral = {
    lat: 53.544738911201044,
    lng: 9.932603983533888,
  };

  locationCamp: google.maps.LatLngLiteral = {
    lat: 53.544738911201044,
    lng: 9.932603983533888,
  };

  constructor(
    private http: HhHttpService,
    private mapDirectionsService: MapDirectionsService,
    private alertSrv: AlertService
  ) {}

  openInfoWindow(marker: MapMarker) {
    if (this.infoWindow) {
      this.infoWindow.open(marker);
    }
  }

  ngOnInit(): void {
    this.loading = true;
    this.http.get<any>(`geocode/${this.order.id}`).subscribe(
      (data) => {
        this.locationCamp = data.results[0].geometry.location;

        const request: google.maps.DirectionsRequest = {
          destination: this.locationCamp,
          origin: this.locationElbe264,
          travelMode: google.maps.TravelMode.DRIVING,
        };

        this.directionsResults$ = this.mapDirectionsService
          .route(request)
          .pipe(map((response) => response.result));

        this.loading = false;
      },
      (err) => {
        this.alertSrv.alert.next({
          text: 'Der Ort konnte bei Google Maps nicht gefunden werden...',
          linkText: '',
        });
        this.loading = false;
      }
    );
  }
}
