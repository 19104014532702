import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  CampFullSizeDto,
  CustomerFullSizeDto,
  UpdatePersonDto,
} from '../../../../../shared/dtos';
import { PersonFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { CampService } from '../../services/camp.service';
import { CustomerService } from '../../services/customer.service';
import { PersonService } from '../../services/person.service';
import { ActivatedRoute, Route } from '@angular/router';
import { TransformerWrapper } from '../../../../../shared/src/transformer';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-person-camps',
  templateUrl: './person-camps.component.html',
  styleUrls: ['./person-camps.component.scss'],
})
export class PersonCampsComponent implements OnInit, OnChanges {
  @Input() updateDirectly: boolean = true;
  @Input() person: PersonFullSizeDto = new PersonFullSizeDto();
  @Output() personChange: EventEmitter<PersonFullSizeDto> =
    new EventEmitter<PersonFullSizeDto>();
  camps: CampFullSizeDto[] = [];
  personCamps: CampFullSizeDto[] = [];
  loading = false;

  constructor(
    private alertSrv: AlertService,
    private personSrv: PersonService,
    private campSrv: CampService,
    private customerSrv: CustomerService,
    public authSrv: AuthService,
    private route: ActivatedRoute,
  ) {}

  onCampAdd(camp: CampFullSizeDto) {
    if (
      this.person.customers.findIndex(
        (customer) => customer.fkCamp === camp.id,
      ) === -1
    ) {
      const customer = new CustomerFullSizeDto();
      customer.fkCamp = camp.id;
      customer.camp = camp;
      customer.fkPerson = this.person.id;
      customer.person.id = this.person.id;
      this.person.customers.push(customer);
      this.updateCustomers();
      if (this.updateDirectly) {
        this.personSrv.addCamp(this.person.id, camp.id).subscribe();
      }
    }
  }

  onCampRemove(camp: CampFullSizeDto) {
    const index = this.person.customers.findIndex(
      (customer) => customer.fkCamp === camp.id,
    );
    this.person.customers.splice(index, 1);
    this.updateCustomers();
    if (index >= 0 && this.updateDirectly) {
      this.personSrv.removeCamp(this.person.id, camp.id).subscribe();
    }
  }

  addCampFromRoute() {

    if (!this.route.snapshot.queryParams['addCampId']) {
      return;
    }

    const camp = this.camps.find(
      (camp) => camp.id === parseInt(this.route.snapshot.queryParams['addCampId']),
    );

    if (!camp) {
      return;
    }
    this.onCampAdd(camp);
  }

  update() {
    if (this.person.id) {
      this.personSrv.findById(this.person.id).subscribe((person) => {
        this.person = person;
        this.updateCustomers();
      });
    } else {
      this.personCamps = this.person.customers.map((customer) => customer.camp);
    }
  }

  saveChanges() {
    this.personSrv.update(this.person.id, TransformerWrapper.plainToInstanceExclude(UpdatePersonDto, this.person)).subscribe(
      (person) => {
        this.person = person;
        this.alertSrv.alert.next({
          text: 'Änderung gespeichert.',
          linkText: '',
        });
      },
      (err) => {
        this.alertSrv.alert.next({
          text: 'Änderung konnte nicht gespeichert werden.',
          linkText: '',
        });
      },
    );
  }

  updateCustomers() {
    if (this.person.customers) {
      this.personCamps = this.person.customers.map((customer) => customer.camp);
    }
  }

  ngOnInit(): void {
    this.loading = true;
    this.campSrv.findAll().subscribe((camps) => {
      this.camps = camps;
      this.addCampFromRoute();
      this.loading = false;
    });
    this.update();
  }

  ngOnChanges() {
    this.update();
  }
}
