<div class=" w-full md:h-screen bg-primary space-y-12 md:space-y-0 flex flex-col md:flex-row items-center justify-center md:space-x-12 text-white
  ">
  <img class="h-40 md:h-72" src="/assets/img/logohh_white.png" alt="Hanseatic Help Logo" />
  <div class="flex flex-col items-center md:items-start gap-5">
    <span class="text-3xl font-bold text-center">404 - Seite nicht gefunden</span>
    <span class="text-base font-normal">Huch, diese Seite gibt es gar nicht!</span>
    <a [routerLink]="['/']" class="button-animation mt-4 rounded-md bg-white px-2 py-3 text-primary font-bold uppercase">
      Zur Startseite
    </a>
  </div>
</div>