<div class="max-w-md mx-auto">
  <div class="flex-shrink-0 px-4 mt-6 pb-4">
    <h2 class="text-2xl font-bold uppercase">
      {{ person.firstName }} {{ person.lastName }} hinzufügen
    </h2>
    <!-- <h2 class="font-bold text-xl uppercase">Details</h2> -->
    <app-person-details [(person)]="person"></app-person-details>
    <div class="flex mt-8">
      <div class="flex-1">
        <h2 class="font-bold text-xl uppercase">Gruppen</h2>
        <ul>
          <app-group-list [groups]="personGroups"></app-group-list>
        </ul>
      </div>
      <div class="flex-1">
        <h2 class="font-bold text-xl uppercase">Organisationen</h2>
        <app-camp-list [camps]="personCamps"></app-camp-list>
      </div>
    </div>
    <div
      class="w-full flex flex-col justify-center items-center space-y-4 mt-16"
    >
      <p *ngIf="person.eMail_1" class="text-gray-600 text-sm">
        Nach dem Erstellen der Person wird an die angegebene Mail-Adresse eine
        Mail mit einem Link zum Setzen dess Passworts geschickt.
      </p>

      <button
        (click)="onPersonCreate()"
        class="bg-primary px-4 py-2 text-white font-medium rounded-lg"
      >
        Person anlegen
      </button>
    </div>
  </div>
</div>
