import { IsArray, IsBoolean, IsDateString, IsNumber, IsOptional, IsString, ValidateNested } from "class-validator";
import { HelperFullSizeDto } from "./helper.full-size.dto";
import { Exclude, Expose, Type } from "class-transformer";
import { ArticleFullSizeDto } from "../lager/article.full-size.dto";


export class DepartmentFullSizeDto {

  @IsNumber()
  @Expose()
  id: number = 0;

  @IsDateString()
  @Exclude()
  createdAt: Date;

  @IsDateString()
  @Exclude()
  updatedAt: Date;

  @IsString()
  @Expose()
  name: string = '';

  @IsString()
  @IsOptional()
  @Expose()
  catName: string | null = null;

  @IsString()
  @Expose()
  description: string = '';

  @IsNumber()
  @IsOptional()
  @Expose()
  fkOrganisation: number | null = null;

  @IsArray()
  @Type(() => HelperFullSizeDto)
  @ValidateNested({ each: true })
  @Expose()
  helpers: HelperFullSizeDto[] = [];

  @IsArray()
  @Type(() => ArticleFullSizeDto)
  @ValidateNested({ each: true })
  @Expose()
  articles: ArticleFullSizeDto[] = [];

  @IsBoolean()
  @Expose()
  showInArticleFilter: boolean = false;
}
