import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArticleEditComponent } from './article/article-edit/article-edit.component';
import { ArticleEmptyComponent } from './article/article-empty/article-empty.component';
import { ArticleComponent } from './article/article/article.component';
import { AuthGuard } from './auth.guard';
import { BoxInventoryComponent } from './box/box-inventory/box-inventory.component';
import { CampEditComponent } from './camp/camp-edit/camp-edit.component';
import { CampEmptyComponent } from './camp/camp-empty/camp-empty.component';
import { CampComponent } from './camp/camp/camp.component';
import { GroupComponent } from './group/group/group.component';
import { ComposeOrderComponent } from './order/compose-order/compose-order.component';
import { OrderEmptyComponent } from './order/order-empty/order-empty.component';
import { OrderLightComponent } from './order/order-light/order-light.component';
import { OrderPackComponent } from './order/order-pack/order-pack.component';
import { OrderComponent } from './order/order/order.component';
import { OrdersMapComponent } from './order/orders-map/orders-map.component';
import { PersonEditComponent } from './person/person-edit/person-edit.component';
import { PersonEmptyComponent } from './person/person-empty/person-empty.component';
import { PersonNewComponent } from './person/person-new/person-new.component';
import { PersonComponent } from './person/person/person.component';
import { MyOrdersComponent } from './routing/my-orders/my-orders.component';
import { RegisterComponent } from './routing/register/register.component';
import { RoutingArticleComponent } from './routing/routing-article/routing-article.component';
import { RoutingBlocksComponent } from './routing/routing-blocks/routing-blocks.component';
import { RoutingBoxComponent } from './routing/routing-box/routing-box.component';
import { RoutingCampComponent } from './routing/routing-camp/routing-camp.component';
import { RoutingDashboardComponent } from './routing/routing-dashboard/routing-dashboard.component';
import { RoutingGroupComponent } from './routing/routing-group/routing-group.component';
import { RoutingHashtagRowInventoryComponent } from './routing/routing-hashtag-row-inventory/routing-hashtag-row-inventory.component';
import { RoutingHashtagRowComponent } from './routing/routing-hashtag-row/routing-hashtag-row.component';
import { RoutingIndexComponent } from './routing/routing-index/routing-index.component';
import { RoutingOrderComponent } from './routing/routing-order/routing-order.component';
import { RoutingPalletInventoryComponent } from './routing/routing-pallet-inventory/routing-pallet-inventory.component';
import { RoutingPalletComponent } from './routing/routing-pallet/routing-pallet.component';
import { RoutingPartComponent } from './routing/routing-part/routing-part.component';
import { RoutingPersonComponent } from './routing/routing-person/routing-person.component';
import { RoutingPersonalSettingsComponent } from './routing/routing-personal-settings/routing-personal-settings.component';
import { ShowArticlesComponent } from './routing/show-articles/show-articles.component';
import { ShowCampsComponent } from './routing/show-camps/show-camps.component';
import { ShowGroupsComponent } from './routing/show-groups/show-groups.component';
import { ShowOrdersComponent } from './routing/show-orders/show-orders.component';
import { ShowPersonsComponent } from './routing/show-persons/show-persons.component';
import { RoutingNotFoundComponent } from './routing/routing-not-found/routing-not-found.component';

export const routes: Routes = [
  {
    path: 'articles',
    component: ShowArticlesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ArticleEmptyComponent,
        outlet: 'article',
      },
      {
        path: 'edit',
        component: ArticleEditComponent,
        outlet: 'article',
      },
      {
        path: 'edit/:articleId',
        component: ArticleEditComponent,
        outlet: 'article',
      },
      {
        path: ':articleId',
        component: ArticleComponent,
        outlet: 'article',
      },
    ],
  },
  { path: 'register', component: RegisterComponent, canActivate: [AuthGuard] },
  {
    path: 'boxInventory',
    component: BoxInventoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'blocks',
    component: RoutingBlocksComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'myOrders',
    component: MyOrdersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'groups',
    component: ShowGroupsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: ':groupId',
        component: GroupComponent,
        outlet: 'group',
      },
    ],
  },
  {
    path: 'boxes/:boxId',
    component: RoutingBoxComponent,
    canActivate: [AuthGuard],
    outlet: 'tabs',
  },
  {
    path: 'pallet-inventory/:palletId',
    canActivate: [AuthGuard],
    component: RoutingPalletInventoryComponent,
  },
  {
    path: 'hashtag-row-inventory/:hashtagRowId',
    canActivate: [AuthGuard],
    component: RoutingHashtagRowInventoryComponent,
  },
  {
    path: 'pallets/:palletId',
    component: RoutingPalletComponent,
    canActivate: [AuthGuard],
    outlet: 'tabs',
  },
  {
    path: 'orders/:orderId',
    component: RoutingOrderComponent,
    canActivate: [AuthGuard],
    outlet: 'tabs',
  },
  {
    path: 'parts/:partId',
    component: RoutingPartComponent,
    canActivate: [AuthGuard],
    outlet: 'tabs',
  },
  {
    path: 'camps/:campId',
    component: RoutingCampComponent,
    canActivate: [AuthGuard],
    outlet: 'tabs',
  },
  {
    path: 'persons/:personId',
    component: RoutingPersonComponent,
    canActivate: [AuthGuard],
    outlet: 'tabs',
  },
  {
    path: 'groups/:groupId',
    component: RoutingGroupComponent,
    canActivate: [AuthGuard],
    outlet: 'tabs',
  },
  {
    path: 'hashtagRows/:hashtagRowId',
    component: RoutingHashtagRowComponent,
    canActivate: [AuthGuard],
    outlet: 'tabs',
  },
  {
    path: 'articles/:articleId',
    component: RoutingArticleComponent,
    canActivate: [AuthGuard],
    outlet: 'tabs',
  },
  {
    path: 'orders',
    component: ShowOrdersComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: OrderEmptyComponent,
        outlet: 'order',
      },
      {
        path: 'compose',
        component: ComposeOrderComponent,
        outlet: 'order',
      },
      {
        path: 'pack/:orderId',
        component: OrderPackComponent,
        outlet: 'order',
      },
      {
        path: 'compose/:orderId',
        component: ComposeOrderComponent,
        outlet: 'order',
      },
      {
        path: ':orderId',
        component: OrderComponent,
        outlet: 'order',
      },
    ],
  },
  {
    path: 'ordersExternal/compose',
    component: ComposeOrderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ordersExternal/compose/:campId',
    component: ComposeOrderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ordersExternal/:orderId',
    component: OrderLightComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'orderMap',
    component: OrdersMapComponent,
  },
  {
    path: 'camps',
    component: ShowCampsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: CampEmptyComponent,
        outlet: 'camp',
      },
      {
        path: 'edit',
        component: CampEditComponent,
        outlet: 'camp',
      },
      {
        path: 'edit/:campId',
        component: CampEditComponent,
        outlet: 'camp',
      },
      {
        path: ':campId',
        component: CampComponent,
        outlet: 'camp',
      },
    ],
  },
  {
    path: 'persons',
    component: ShowPersonsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: PersonEmptyComponent,
        outlet: 'person',
      },
      {
        path: 'new',
        component: PersonEditComponent,
        outlet: 'person',
      },
      {
        path: 'edit',
        component: PersonEditComponent,
        outlet: 'person',
      },
      {
        path: 'edit/:personId',
        component: PersonEditComponent,
        outlet: 'person',
      },
      {
        path: ':personId',
        component: PersonComponent,
        outlet: 'person',
      },
    ],
  },
  {
    path: 'persons/new',
    component: PersonNewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'personalSettings',
    component: RoutingPersonalSettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: RoutingDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '404',
    component: RoutingNotFoundComponent,
  },
  {
    path: '**',
    redirectTo: '/404',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
