import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import { CampFullSizeDto } from '../../../../../shared/dtos';
import { OrderFullSizeDto } from '../../../../../shared/dtos';
import { PersonFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit, OnChanges {
  @Input() order: OrderFullSizeDto = new OrderFullSizeDto();
  @Output() orderChange = new EventEmitter<OrderFullSizeDto>();
  selectedCamp: CampFullSizeDto = new CampFullSizeDto();
  campDetailsShown: boolean = false;
  @Output() modeChange: EventEmitter<{ mode: string; order: OrderFullSizeDto }> =
    new EventEmitter<{ mode: string; order: OrderFullSizeDto }>();
  paramsOrderId: string = '';
  printUrl: string = '';
  packerModalActive: boolean = false;

  selectedPerson: PersonFullSizeDto = new PersonFullSizeDto();
  personDetailsShown: boolean = false;
  printModalActive: boolean = false;
  deleteModalActive: boolean = false;
  loading = false;
  showOrderDetails = true;
  statusShown = false;
  showFullNotes = false;

  view = 'parts';

  onSetMode(mode: string) {
    this.modeChange.emit({ mode: mode, order: this.order || new OrderFullSizeDto() });
  }

  constructor(
    private orderSrv: OrderService,
    private router: ActivatedRoute,
    private router2: Router,
    private menuSrv: MenuService,
    private http: HttpClient,
    private alertSrv: AlertService
  ) { }

  getNoteParagaraphs() {
    return this.order.notes.split(/\r\n|\r|\n/).length
  }

  onOrderPrint() {
    this.printModalActive = true;
  }

  onOrderDelete() {
    this.deleteModalActive = true;
  }

  onOpenOrderTab() {
    this.menuSrv.addTabAndOpen({
      text: `Bestellung ${this.order!.id}`,
      route: ['orders', this.order?.id.toString()!],
      exact: true,
    });
  }

  onOpenCampDetails(camp: CampFullSizeDto) {
    this.menuSrv.addTabAndOpen({
      text: `${camp.name}`,
      route: ['camps', camp.id.toString()!],
      exact: true,
    });
  }

  onOpenPersonDetails(person: PersonFullSizeDto | undefined | null) {
    if (person) {
      this.menuSrv.addTabAndOpen({
        text: `${person.firstName} ${person.lastName}`,
        route: ['persons', person.id.toString()!],
        exact: true,
      });
    }
  }

  update() {
    if (this.order) {
      this.loading = true;
      this.orderSrv.findById(this.order.id).subscribe((order) => {
        this.order = order;
        this.loading = false;
      });
    }
  }

  ngOnInit(): void {
    this.router.params.subscribe((params) => {
      if (params.orderId) {
        this.paramsOrderId = params.orderId;
        this.loading = true;
        this.orderSrv.findById(params.orderId).subscribe(
          (order) => {
            this.loading = false;
            this.order = order;
          },
          () => {
            this.loading = false;
            this.alertSrv.alert.next({
              text: 'Bestellung gibt es nicht!',
              linkText: '',
            });
            this.router2.navigate(['/orders']);
          }
        );
      }
    });
  }

  ngOnChanges() {
    this.update();
  }
}
