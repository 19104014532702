import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import es6printJS from 'print-js';
import { HashtagRowService } from 'src/app/services/hashtag-row.service';
import { HashtagRowFullSizeDto, PalletFullSizeDto } from '../../../../../shared/dtos';
import { environment } from '../../../environments/environment';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-hashtag-row-details',
  templateUrl: './hashtag-row-details.component.html',
  styleUrls: ['./hashtag-row-details.component.scss'],
})
export class HashtagRowDetailsComponent implements OnInit, OnChanges {
  @Input() rowId: number = -1;
  row: HashtagRowFullSizeDto | null = null;
  extraArray: number[] = [];
  @Output() palletSelected: EventEmitter<PalletFullSizeDto> = new EventEmitter();
  changeRowNumber: boolean = false;

  constructor(
    private rowSrv: HashtagRowService,
    private route: ActivatedRoute,
    private menuSrv: MenuService
  ) {}

  onPalletSelect(pallet: PalletFullSizeDto) {
    this.palletSelected.emit(pallet);
  }

  onRowPrint() {
    const printUrl = `${environment.apiRoot}hashtag-row/${this.row?.id}.pdf`;
    es6printJS(printUrl);
  }

  onOpenHashtagRowTab() {
    this.menuSrv.addTabAndOpen({
      text: `Reihe ${this.row?.block.name}${this.row?.row}`,
      route: ['hashtagRows', this.row?.id.toString()!],
      exact: true,
    });
  }

  update() {
    if (this.rowId > 0) {
      this.rowSrv.findById(this.rowId).subscribe((row) => {
        this.row = row;
        this.extraArray =
          this.row.pallets.length < this.row.length
            ? [...Array(this.row.length - this.row.pallets.length).keys()]
            : [];
      });
    } else {
      this.row = null;
    }
  }

  getRowString(row: HashtagRowFullSizeDto | null) {
    return row!.block.name + row!.row || '';
  }

  ngOnInit(): void {
    this.rowId = parseInt(this.route.snapshot.paramMap.get('id') ?? '-1');
    this.update();
  }

  ngOnChanges(): void {
    this.update();
  }
}
