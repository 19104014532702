<main *ngIf="article" class="flex-1 relative z-0 focus:outline-none xl:order-last animate-fadeIn">
  <article class="flex h-full flex-col overflow-y-hidden">
    <!-- Profile header -->
    <div>
      <div class="px-4 sm:px-6 lg:px-8">
        <div class="mt-6 w-full min-w-0 flex-1">
          <div class="flex justify-between gap-8">
            <div class="flex space-x-2 items-center">
              <h1 class="text-2xl font-bold text-gray-900 truncate uppercase">
                {{ article.nameDe }}
              </h1>
              <button tooltip="Artikel bearbeiten" [routerLink]="[
                  '/articles',
                  { outlets: { article: ['edit', article.id] } }
                ]">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                </svg>
              </button>
              <button tooltip="in neuem Tab öffnen" class="text-gray-600" (click)="onOpenArticleTab()">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
              </button>
            </div>

            <div *ngIf="
                getRowsString(article) && getRowsString(article).length > 0
              ">
              <p class="
                  px-4
                  py-2
                  inline-flex
                  leading-5
                  font-semibold
                  rounded-lg
                  bg-green-50
                  text-green-700
                ">
                {{ getRowsString(article) }}
              </p>
            </div>
          </div>

          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            {{ article.category?.nameDe || '-' }},
            {{ article.gender?.nameDe }},
            {{ article.size?.sizeName }}
          </p>
        </div>
      </div>
    </div>

    <!-- Tabs -->
    <div class="mt-6 sm:mt-2 2xl:mt-5">
      <div class="border-b border-gray-200">
        <div class="px-4 sm:px-6 lg:px-8">
          <nav class="-mb-px flex space-x-8" aria-label="Tabs">
            <!-- Current: "border-blue-500 text-gray-900", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
            <a (click)="view = 'details'" class="
                cursor-pointer
                text-gray-500
                whitespace-nowrap
                py-4
                px-1
                border-b-2
                font-medium
                text-sm
              " [ngClass]="{
                'border-primary text-gray-900': view === 'details'
              }" aria-current="page">
              Artikeldetails
            </a>

            <a *ngIf="
                getRowsString(article) && getRowsString(article).length > 0
              " (click)="view = 'warehouse'" class="
                cursor-pointer
                text-gray-500
                hover:text-gray-700
                whitespace-nowrap
                py-4
                px-1
                border-b-2
                font-medium
                text-sm
              " [ngClass]="{
                'border-primary text-gray-900': view === 'warehouse'
              }">
              Lager
            </a>

            <!-- <a (click)="view = 'statistics'" class="
                border-transparent
                cursor-pointer
                text-gray-500
                hover:text-gray-700
                whitespace-nowrap
                py-4
                px-1
                border-b-2
                font-medium
                text-sm
              " [ngClass]="{
                'border-primary text-gray-900': view === 'statistics'
              }">
              Statistik
            </a> -->
          </nav>
        </div>
      </div>
    </div>
    <app-article-details *ngIf="view === 'details'" [(article)]="article" [subArticle]="subArticle">
    </app-article-details>
    <app-article-warehouse *ngIf="view === 'warehouse'" [(article)]="article"></app-article-warehouse>
    <app-article-statistics *ngIf="view === 'statistics'" [(article)]="article"></app-article-statistics>
  </article>
</main>
