import { Injectable } from '@angular/core';
import { GenderFullSizeDto, GenderLagerFullSizeDto } from '../../../../shared/dtos';
import { HhHttpService } from './hh-http.service';

@Injectable({
  providedIn: 'root',
})
export class GenderService {
  constructor(private httpSrv: HhHttpService) {}

  findAll() {
    return this.httpSrv.get<GenderLagerFullSizeDto[]>(`gender`);
  }
}
