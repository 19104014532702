<div class="
      bg-primary100
      shadow-sm
      rounded-xl
      p-4
      flex flex-col
      cursor-pointer
      border-2 border-transparent border-dashed
      hover:border-primary
      w-52
    " [routerLink]="['/ordersExternal', order.id]">
  <div class="flex justify-between items-start gap-2">
    <div class="flex flex-col">
      <span class="text-primary flex flex-col">
        <span class="text-xs">Bestellnr.</span>
        <span class="text-2xl font-bold">{{ order.id }}</span></span>
      <span class="text-primary500">{{ order.type.name }}</span>
    </div>
    <div class="bg-primary font-semibold uppercase shadow-sm text-primary100 py-1 px-2 rounded-xl text-xs">
      {{
      order.requiredDate | date: "dd.MM.yyyy"
      }}
    </div>
  </div>
  <div class="flex flex-col mt-2 w-full">
    <span class="text-xs text-primary300">BestellerIn:</span>
    <span class="text-sm text-primary text-ellipsis font-medium whitespace-nowrap overflow-hidden">{{
      order.customer.person.firstName ||
      '-' }}
      {{
      order.customer.person.lastName
      }}</span>
  </div>

  <!-- <div class="flex flex-col mt-2">
    <span class="text-xs text-primary300">Typ:</span>
    <span class="text-sm text-primary">{{ order.type.name }}</span>
  </div> -->
</div>
