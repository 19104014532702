<div *ngIf="pallet && pallet.id">
  Wähle den neuen Lagerort:
  <div class="flex gap-2 flex-wrap w-96">
    <button
      (click)="onAddToWarehouse(warehouse)"
      class="
        uppercase
        text-sm
        font-bold
        py-2
        px-4
        rounded-lg
        shadow-md
        cursor-pointer
        bg-white
        hover:bg-gray-100
        text-primary
        active:bg-primary active:text-white
      "
      *ngFor="let warehouse of warehouses"
    >
      {{ warehouse.name }}
    </button>
  </div>
</div>
