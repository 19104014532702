import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PartFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { PartService } from '../../services/part.service';

@Component({
  selector: 'app-routing-part',
  templateUrl: './routing-part.component.html',
  styleUrls: ['./routing-part.component.scss'],
})
export class RoutingPartComponent implements OnInit {
  loading: boolean = false;
  part: PartFullSizeDto = new PartFullSizeDto();

  constructor(
    private router: ActivatedRoute,
    private partService: PartService,
    private alertSrv: AlertService
  ) {}

  ngOnInit(): void {
    this.router.params.subscribe((params) => {
      if (params.partId) {
        this.loading = true;
        this.partService.findById(params.partId).subscribe(
          (part) => {
            this.loading = false;
            this.part = part;
          },
          (err) => {
            this.loading = false;
            this.alertSrv.alert.next({
              text: `Bestellungsteil ${params.partId} konnte nicht gefunden werden.`,
              linkText: '',
            });
          }
        );
      }
    });
  }
}
