import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BoxFullSizeDto } from '../../../../../shared/dtos';
import { OrderFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { BoxService } from '../../services/box.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-box-take-from-pallet',
  templateUrl: './box-take-from-pallet.component.html',
  styleUrls: ['./box-take-from-pallet.component.scss'],
})
export class BoxTakeFromPalletComponent implements OnInit {
  @Input() box: BoxFullSizeDto = new BoxFullSizeDto();
  @Output() boxChange: EventEmitter<BoxFullSizeDto> = new EventEmitter();
  orders: OrderFullSizeDto[] = [];

  constructor(
    private boxSrv: BoxService,
    private alertSrv: AlertService,
    private orderSrv: OrderService
  ) {}

  onOrderSelect(order: OrderFullSizeDto) {
    this.boxSrv.putOnOrder(this.box, order).subscribe(
      (box) => {
        this.box = box;
        this.boxChange.emit(this.box);
        this.alertSrv.alert.next({
          text: `Karton wurde auf Bestellung ${order.id} gepackt!`,
          linkText: '',
        });
      },
      (err) => {
        this.alertSrv.alert.next({
          text: `Das hat leider nicht funktioniert!`,
          linkText: '',
        });
      }
    );
  }

  ngOnInit(): void {
    this.orderSrv.findByBoxId(this.box.id).subscribe((orders) => {
      this.orders = orders;
    });
  }
}
