<div *ngIf="!block || !block.id" class="
    h-full
    w-full
    flex
    justify-center
    items-center
    text-center text-gray-600
  ">
  Bitte wähle einen Block auf der linken Seite.
</div>
<div *ngIf="block && block.id" class="overflow-y-auto h-full flex flex-col animate-fadeIn mr-4">
  <h2 class="font-bold uppercase text-xl mt-6 mb-4 text-gray-800">
    Block {{ block.name }}
  </h2>
  <div *ngIf="block.hashtagRows.length > 0" class="overflow-y-auto h-full space-y-4">
    <div class="flex flex-col" *ngFor="let row of block.hashtagRows">
      <div class="flex justify-between items-center">
        <div class="flex space-x-2">
          <div class="text-primary text-lg">#{{ block.name }}{{ row.row }}</div>
          <button tooltip="in neuem Tab öffnen" (click)="onOpenHashtagRowTab(row)">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg>
          </button>
        </div>

        <div class="text-gray-600 text-xs">
          {{ row?.pallets?.length }}/{{ row.length }} Paletten
        </div>
      </div>
      <div class="text-sm text-gray-500 whitespace-nowrap truncate" [innerHtml]="row?.article?.nameDe"></div>
      <div class="flex space-x-2 mt-1 overflow-x-auto pb-1">
        <button class="
            px-4
            py-2
            bg-gray-100
            hover:bg-gray-200
            cursor-pointer
            shadow-md
            rounded-md
            flex flex-col
            button-animation
          " *ngFor="let pallet of row.pallets; let i = index" (click)="onPalletSelect(pallet)" [ngClass]="{
            'border border-dashed border-red-400 bg-red-50 hover:bg-red-100':
              i + 1 > row.length
          }">
          <span>{{ pallet.id }}</span>
          <span class="text-xs text-gray-500">{{ pallet.boxes.length }} Kartons</span>
        </button>

        <div *ngFor="let _ of getExtraSlots(row); let i = index" class="
            px-4
            py-2
            bg-gray-50
            shadow-md
            rounded-md
            border border-dashed border-gray-500
          ">
          leer
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!block.hashtagRows || block.hashtagRows.length === 0"
    class="h-full w-full flex items-center justify-center">
    Block leer
  </div>
</div>
