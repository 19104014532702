import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PersonFullSizeDto } from '../../../../../shared/dtos';
import { PersonService } from '../../services/person.service';

@Component({
  selector: 'app-camp-person-details',
  templateUrl: './camp-person-details.component.html',
  styleUrls: ['./camp-person-details.component.scss'],
})
export class CampPersonDetailsComponent implements OnInit {
  @Input() person: PersonFullSizeDto = new PersonFullSizeDto();
  @Output() personChange: EventEmitter<PersonFullSizeDto> = new EventEmitter<PersonFullSizeDto>();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  onClose() {
    this.close.emit();
  }

  constructor(
    private personSrv: PersonService,
  ) {}

  ngOnInit(): void {
    this.personSrv.updateTrigger.subscribe(() => {
      this.close.next();
    })
  }
}
