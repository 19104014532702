import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CampFullSizeDto, CreateCampDto, UpdateCampDto } from '../../../../shared/dtos';
import { HhHttpService } from './hh-http.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CampService {
  constructor(private httpSrv: HhHttpService) {}
  
  updateTrigger: Subject<void> = new Subject<void>();

  findAll() {
    return this.httpSrv.get<CampFullSizeDto[]>('camp');
  }

  findById(id: number) {
    return this.httpSrv.get<CampFullSizeDto>(`camp/${id}`);
  }

  findByName(name: string, options: {
    showArchived: boolean;  
  }) {
    return this.httpSrv.get<CampFullSizeDto[]>(`camp/q/${name}?showArchived=${options.showArchived ? 1 : 0}`);
  }

  update(campId: number, dto: UpdateCampDto) {
    return this.httpSrv
      .patch<CampFullSizeDto>(`camp/${campId}`, dto)
      .pipe(map((elem) => Object.assign(new CampFullSizeDto(), elem)));
  }

  create(createDto: CreateCampDto) {
    return this.httpSrv
      .post<CampFullSizeDto>(`camp`, createDto)
      .pipe(map((elem) => Object.assign(new CampFullSizeDto(), elem)));
  }
}
