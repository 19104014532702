import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CategoryFullSizeDto, GenderLagerFullSizeDto } from '../../../../../shared/dtos';
import { GenderFullSizeDto } from '../../../../../shared/dtos';
import { SizeFullSizeDto } from '../../../../../shared/dtos';
import { CategoryService } from '../../services/category.service';
import { GenderService } from '../../services/gender.service';
import { SizeService } from '../../services/size.service';

@Component({
  selector: 'app-article-filter',
  templateUrl: './article-filter.component.html',
  styleUrls: ['./article-filter.component.scss'],
})
export class ArticleFilterComponent implements OnInit {
  genders: GenderLagerFullSizeDto[] = [];
  categories: CategoryFullSizeDto[] = [];
  sizes: SizeFullSizeDto[] = [];

  selectedGender: GenderLagerFullSizeDto = new GenderLagerFullSizeDto();
  selectedCategory: CategoryFullSizeDto = new CategoryFullSizeDto();
  selectedSize: SizeFullSizeDto = new SizeFullSizeDto();

  @Output() genderSelected: EventEmitter<GenderLagerFullSizeDto> = new EventEmitter();
  @Output() categorySelected: EventEmitter<CategoryFullSizeDto> = new EventEmitter();
  @Output() sizeSelected: EventEmitter<SizeFullSizeDto> = new EventEmitter();

  constructor(
    private genderSrv: GenderService,
    private categorySrv: CategoryService,
    private sizeSrv: SizeService
  ) {}

  onGenderSelect(gender: GenderLagerFullSizeDto) {
    this.selectedGender =
      gender.id !== this.selectedGender.id ? gender : new GenderLagerFullSizeDto();
    this.genderSelected.emit(this.selectedGender);
  }

  onCategorySelect(category: CategoryFullSizeDto) {
    this.selectedSize = new SizeFullSizeDto();
    this.selectedCategory =
      category.id !== this.selectedCategory.id ? category : new CategoryFullSizeDto();

    this.categorySelected.emit(this.selectedCategory);
    this.sizeSelected.emit(this.selectedSize);
    this.sizeSrv
      .findByCategoryId(this.selectedCategory.id)
      .subscribe((sizes) => {
        this.sizes = sizes;
      });
  }

  onSizeSelect(size: SizeFullSizeDto) {
    this.selectedSize = size.id !== this.selectedSize.id ? size : new SizeFullSizeDto();
    this.sizeSelected.emit(this.selectedSize);
  }

  ngOnInit(): void {
    this.genderSrv.findAll().subscribe((genders) => {
      this.genders = genders;
    });

    this.categorySrv.findAll().subscribe((categories) => {
      this.categories = categories;
    });
  }
}
