import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CampFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { CampService } from '../../services/camp.service';

@Component({
  selector: 'app-routing-camp',
  templateUrl: './routing-camp.component.html',
  styleUrls: ['./routing-camp.component.scss'],
})
export class RoutingCampComponent implements OnInit {
  loading: boolean = false;
  camp: CampFullSizeDto = new CampFullSizeDto();

  constructor(
    private router: ActivatedRoute,
    private campSrv: CampService,
    private alertSrv: AlertService
  ) {}

  ngOnInit(): void {
    this.router.params.subscribe((params) => {
      if (params.campId) {
        this.loading = true;
        this.campSrv.findById(params.campId).subscribe(
          (camp) => {
            this.loading = false;
            this.camp = camp;
          },
          (err) => {
            this.loading = false;
            this.alertSrv.alert.next({
              text: `Unterkunft konnte nicht gefunden werden.`,
              linkText: '',
            });
          }
        );
      }
    });
  }
}
