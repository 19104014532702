import { Component, Input, OnInit } from '@angular/core';
import { PalletFullSizeDto, WarehouseFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { PalletService } from '../../services/pallet.service';
import { WarehouseService } from '../../services/warehouse.service';

@Component({
  selector: 'app-pallet-choose-warehouse',
  templateUrl: './pallet-choose-warehouse.component.html',
  styleUrls: ['./pallet-choose-warehouse.component.scss'],
})
export class PalletChooseWarehouseComponent implements OnInit {
  @Input() pallet: PalletFullSizeDto = new PalletFullSizeDto();
  warehouses: WarehouseFullSizeDto[] = [];

  constructor(
    private warehouseSrv: WarehouseService,
    private palletSrv: PalletService,
    private alertSrv: AlertService
  ) {}

  onAddToWarehouse(warehouse: WarehouseFullSizeDto) {
    this.palletSrv.addToWarehouse(this.pallet.id, warehouse.id).subscribe(
      () => {
        this.palletSrv.findById(this.pallet.id).subscribe((pallet) => {
          this.pallet = pallet;
          this.alertSrv.alert.next({
            text: `Die Palette wurde ins Lager ${warehouse.name} verschoben.`,
            linkText: '',
          });
        });
      },
      () => {
        this.palletSrv.findById(this.pallet.id).subscribe((pallet) => {
          this.pallet = pallet;
          this.alertSrv.alert.next({
            text: 'Fehler beim Zuordnen',
            linkText: '',
          });
        });
      }
    );
  }

  ngOnInit(): void {
    this.warehouseSrv.findAll().subscribe((warehouses) => {
      this.warehouses = warehouses;
    });
  }
}
