import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { GroupFullSizeDto, PersonFullSizeDto, RightFullSizeDto, TokenAuthContext } from '../../../../shared/dtos';
import { KeycloakProfile } from 'keycloak-js';
import { environment } from '../../environments/environment';
import { HhHttpService } from './hh-http.service';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public authContext: TokenAuthContext | null = null;
  public refreshToken: string | null = null;
  public myRights: RightFullSizeDto[] | undefined = undefined;

  constructor(
    private keycloakSrv: KeycloakService,
    private httpSrv: HhHttpService,
  ) {}

  async login(): Promise<void> {
    await this.keycloakSrv.login();
    /* const token = await this.keycloakSrv.getToken();
    this.authContext = TokenAuthContext.fromKeycloakToken(token); */
  }

  get loggedIn(): boolean {
    return this.keycloakSrv.isLoggedIn();
  }

  logout(): void {
    this.keycloakSrv.logout().then();
  }

  get userName(): string {
    return this.keycloakSrv.getUsername();
  }

  async autoLogin(): Promise<void> {
    if (!this.loggedIn) {
      await this.login();
      return;
    }
    
    await this.updateKeycloakToken();
    setInterval(this.tokenCheck.bind(this), 60000);
    await this.updateMyRights();
  }

  tokenCheck = async (): Promise<void> => {
    const isRefreshed = await this.keycloakSrv.updateToken(70);
    if (isRefreshed) {
      this.updateKeycloakToken();
    }
  }

  async updateKeycloakToken (): Promise<void> {
    const token = await this.keycloakSrv.getToken();
    this.setToken(token);
  }

  async setToken(token: string): Promise<void> {
    this.refreshToken = token;
    this.authContext = TokenAuthContext.fromKeycloakToken(this.refreshToken);
  }

  hasRight(rightText: string): boolean {
    return !!this.myRights?.find((right) => right.name === rightText);
  }

  redirectToAccountManagement(): void {
    const url = this.keycloakSrv.getKeycloakInstance().createAccountUrl();
    window.location.href = url;
  }

  async updateMyRights(): Promise<void> {
    this.myRights = await firstValueFrom(this.httpSrv.get<RightFullSizeDto[]>(`right/mine`));
  }

}
