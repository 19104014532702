<div class="w-full h-fit flex items-center justify-center mt-5" *ngIf="!orders">Keine Bestellungen vorhanden</div>
<div *ngIf="orders" class="flex flex-row h-full overflow-y-auto">
  <div class="flex flex-col overflow-y-auto h-auto border-r border-gray-200">
    <app-order-list
      [showStatus]="true"
      (orderSelect)="selectedOrder = $event"
      [orders]="orders"
    ></app-order-list>
  </div>
  <div class="flex-grow flex flex-col overflow-y-auto h-auto">
    <app-order
    class="flex flex-col overflow-y-auto h-full"
      *ngIf="selectedOrder && selectedOrder.id"
      [order]="selectedOrder"
    ></app-order>
  </div>
</div>
