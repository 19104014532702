import { IsDateString, IsNumber, IsObject, IsOptional, IsString, ValidateNested } from "class-validator";
import { PersonFullSizeDto } from "../people/person.full-size.dto";
import { OrderFullSizeDto } from "./order.full-size.dto";
import { StatusFullSizeDto } from "./status.full-size.dto";
import { Exclude, Expose, Type } from "class-transformer";


export class MessageFullSizeDto {

  @IsNumber()
  @Expose()
  id: number = 0;

  @IsDateString()
  @Exclude()
  createdAt: Date;

  @IsDateString()
  @Exclude()
  updatedAt: Date;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkOrder: number | null = null;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkStatus: number | null = null;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkPerson: number | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  content: string | null = null;

  @IsDateString()
  @Expose()
  created: Date = new Date();

  @IsObject()
  @Type(() => StatusFullSizeDto)
  @ValidateNested()
  @Expose()
  status: StatusFullSizeDto = new StatusFullSizeDto();

  @IsObject()
  @Type(() => OrderFullSizeDto)
  @ValidateNested()
  @Expose()
  order: OrderFullSizeDto = new OrderFullSizeDto();

  @IsObject()
  @Type(() => PersonFullSizeDto)
  @ValidateNested()
  @Expose()
  person: PersonFullSizeDto;
}
