import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CampService } from 'src/app/services/camp.service';
import { CampFullSizeDto } from '../../../../../shared/dtos';

@Component({
  selector: 'app-show-camps',
  templateUrl: './show-camps.component.html',
  styleUrls: ['./show-camps.component.scss'],
})
export class ShowCampsComponent implements OnInit {
  selectedCamp: CampFullSizeDto = new CampFullSizeDto();
  camps: CampFullSizeDto[] = [];
  filteredCamps: CampFullSizeDto[] = [];
  q: string = '';
  enableFilers: boolean = false;
  loading: boolean = false;
  menuShown: boolean = true;
  selectedCampId: number = -1;

  constructor(
    private campSrv: CampService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  onCampSelect(camp: CampFullSizeDto) {
    this.selectedCampId = camp.id;
    this.router.navigate([
      '/camps',
      { outlets: { camp: [camp.id.toString()] } },
    ]);
  }

  filter() {
    this.filteredCamps = this.camps;
  }

  updateCamps() {
    this.loading = true;
    this.campSrv.findAll().subscribe((camps) => {
      this.camps = camps;
      this.filter();
      this.loading = false;
    });
  }

  ngOnInit(): void {
    this.route.children[0]?.params.subscribe((params) => {
      if (!params['campId']) {
        return;
      }
      this.selectedCampId = +params['campId'];
    });

    this.updateCamps();
  }
}
