<div *ngFor="let customer of authPerson?.customers" class="mt-6 md:shadow-md md:rounded-b-xl">
  <div
    class="flex flex-col md:flex-row justify-between md:items-center gap-2 md:rounded-t-xl md:bg-primary400 py-1.5 px-3 md:px-6"
    [ngClass]="{'rounded-b-xl': customer.collapsed}">
    <span class="
        block
        max-w-2xl
        md:text-white
        text-primary
        font-bold
        uppercase
        text-2xl
        md:text-base
        sticky
        top-0
        w-full
      ">{{ customer.camp.name }}</span>
    <div class="flex justify-start items-center gap-4">
      <button [routerLink]="['/ordersExternal/compose', customer.camp.id]" class="
        bg-white
        rounded-md
        py-1.5 px-3
        md:uppercase
        flex
        justify-center
        items-center
        shadow-md
        cursor-pointer
        space-x-2
        text-primary
        hover:bg-primary100
      ">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
        </svg>
        <span class="font-bold text-xs md:text-sm whitespace-nowrap"><span class="hidden lg:inline">neue</span>
          Bestellung
          erstellen</span>
      </button>
      <button (click)="customer.collapsed = !customer.collapsed" class="text-white hidden md:inline duration-200"
        [ngClass]="{'-rotate-180': !customer.collapsed}">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
        </svg>
      </button>
    </div>
  </div>
  <div *ngIf="!customer.collapsed" class="p-3 rounded-b-xl overflow-hidden">
    <app-loader class="w-full" *ngIf="loading"></app-loader>
    <app-order-list-light *ngIf="!loading" [orders]="customer.camp.groupedOrders" [campId]="customer.camp.id">
    </app-order-list-light>
    <div class="flex flex-col items-center p-3 justify-center gap-2"
      *ngIf="!loading && customer?.camp?.groupedOrders && !customer.camp?.groupedOrders?.Bestellt && !customer.camp.groupedOrders['Fertig im Lager'] && !customer.camp.groupedOrders['In Bearbeitung'] && !customer.camp.groupedOrders['Zugestellt/Abgeholt']">
      Noch sind keine Bestellungen vorhanden.
      <button [routerLink]="['/ordersExternal/compose', customer.camp.id]"
        class="bg-primary text-white uppercase font-bold py-1.5 px-3 rounded-md shadow-md">Erstelle die
        erste</button>
    </div>
  </div>
  <div class="bg-gray-200 h-px md:h-0 w-full"></div>
</div>
