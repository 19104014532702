import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import es6printJS from 'print-js';
import { DepartmentFullSizeDto, OrderFullSizeDto } from '../../../../../../shared/dtos';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-print-order',
  templateUrl: './print-order.component.html',
  styleUrls: ['./print-order.component.scss'],
  animations: [
    trigger('zoom', [
      state('open', style({
        opacity: 1,
        transform: 'scale(1)',
      })),
      state('closed', style({
        transform: 'scale(0.8)',
        opacity: 0,
      })),
      transition('open => closed', [
        animate('.1s ease')
      ]),
      transition('closed => open', [
        animate('.1s ease')
      ]),
    ]),
    trigger('fade', [
      state('open', style({
        opacity: 1,
      })),
      state('closed', style({
        opacity: 0,
      })),
      transition('open => closed', [
        animate('.1s ease')
      ]),
      transition('closed => open', [
        animate('.1s ease')
      ]),
    ]),
  ]
})
export class PrintOrderComponent implements OnInit {
  @Input() order: OrderFullSizeDto = new OrderFullSizeDto();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Input() mode: string = 'internal';
  departments: Map<string, DepartmentFullSizeDto> = new Map();
  isOpen: boolean = false;

  constructor(private http: HttpClient) { }

  setStatus() { }

  onOrderPrint(options?: { departmentId?: number; allDepartments?: boolean; confirmation?: string, palletPaper?: boolean }) {
    let departmentStr = '';
    if (options?.allDepartments) {
      departmentStr = '?allDepartments=true';
    } else {
    departmentStr = options?.departmentId
      ? `?departmentId=${options?.departmentId}`
      : '';
    }

    const confirmationStr = options?.confirmation
      ? `?confirmation=${options?.confirmation}`
      : '';

    const palletPaperStr = options?.palletPaper ? '?palletPaper=true' : '';


    const printUrl = `${environment.apiRoot}order/${this.order?.id}.pdf${departmentStr}${confirmationStr}${palletPaperStr}`;
    this.http
      .get(printUrl, {
        responseType: 'blob',
      })
      .subscribe((blob) => {
        es6printJS({
          printable: URL.createObjectURL(blob),
          type: 'pdf',
          documentTitle: `Bestellung ${this.order?.id}`,
        });
      });
  }

  onClose() {
    this.isOpen = false;
    setTimeout(() => {
      this.close.emit();
    }, 100) // sync with animation
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.isOpen = true;
    }, 100) // sync with animation

    this.order.parts.forEach((part) => {
      if (!!part.article.department && !this.departments.has(part.article.department.name)) {
        this.departments.set(
          part.article.department.name,
          part.article.department
        );
      }
    });
  }
}
