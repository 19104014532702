import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-order-status-tabs',
  templateUrl: './order-status-tabs.component.html',
  styleUrl: './order-status-tabs.component.scss'
})
export class OrderStatusTabsComponent {
  @Input() statusId = 1;
  @Output() statusIdChange = new EventEmitter<number>();

  setStatusId(statusId: number) {
    this.statusId = statusId;
    this.statusIdChange.emit(statusId);
  }
}
