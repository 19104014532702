<div class="overflow-y-auto g flex flex-col">
  <div *ngIf="mode === 'view'" class="flex px-4 py-2 gap-2.5 overflow-x-auto">
    <button (click)="selectDepartment(undefined)" class="whitespace-nowrap text-xs button-animation select-none uppercase font-bold py-2 px-4 rounded-md cursor-pointer"
    [ngClass]="{
      'bg-gray-50 text-gray-600': !!selectedDepartment?.id,
      'bg-gray-200 text-gray-800': !selectedDepartment?.id
    }">
      Alle Abteilungen
    </button>
    <button (click)="selectDepartment(department)" *ngFor="let department of departments" class="whitespace-nowrap text-xs button-animation select-none uppercase font-bold py-2 px-4 rounded-md cursor-pointer"
    [ngClass]="{
      'bg-gray-50 text-gray-600': selectedDepartment?.id !== department.id,
      'bg-gray-200 text-gray-800': selectedDepartment?.id === department.id
    }">
      {{department.name}}
    </button>
  </div>
  <div>
    <div class="
        text-sm
        py-2
        px-4
        flex
        items-center
        space-x-4
        border-b-2 border-gray-200
        font-semibold
      ">
      <div class="truncate" [ngClass]="mode === 'view' ? 'w-5/12' : 'w-5/12'">Artikel</div>
      <div *ngIf="mode === 'view'" class="w-1/12 truncate" tooltip="Hinweis">Hinweis</div>
      <div class="truncate" [ngClass]="mode === 'view' ? 'w-1/12' : 'w-2/12'">Geschlecht</div>
      <div class="truncate" [ngClass]="mode === 'view' ? 'w-1/12' : 'w-2/12'">Größe</div>
      <div class="truncate" [ngClass]="mode === 'view' ? 'w-1/12' : 'w-2/12'">Lager</div>
      <div class="w-1/12 truncate text-right" *ngIf="mode === 'view'">
        bestellt
      </div>
      <div class="w-1/12 truncate text-right" *ngIf="mode === 'view'">
        rausgesucht
      </div>
      <div class="w-1/12 truncate" *ngIf="mode === 'view'">Aktion</div>
    </div>
  </div>
  <div [ngClass]="{ 'hover:bg-gray-100 cursor-pointer': mode === 'view' }" class="text-sm py-2 px-4 border-b border-gray-200"
  (click)="mode === 'view' && onOpenPartDetails(part)"
    *ngFor="let part of filteredParts; let odd = odd; let i = index">
    <div 
      class="flex items-center space-x-4">
      <div class="truncate" [ngClass]="mode === 'view' ? 'w-5/12' : 'w-5/12'">
        <span class="flex space-x-1 items-center" *ngIf="
            !part.subArticle || !part.subArticle!.id || part.subArticle!.id <= 0
          ">
          <span class="w-4" *ngIf="part.article?.addable"></span>
          <svg tooltip="Aus Abteilung packen" *ngIf="!part.article?.addable" xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4 text-primary flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
            <path
              d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <span [tooltip]="part.article.nameDe || '-'" class="truncate">{{ part.article.nameDe }}</span>
        </span>
        <span class="flex space-x-1 items-center" *ngIf="part.subArticle?.id">
          <svg tooltip="Unterartikel" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 flex-shrink-0 text-primary" fill="none"
            viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>
          <span [tooltip]="part.subArticle?.name || '-'" class="truncate">{{ part.subArticle?.name }}</span>
        </span>
      </div>
      <div *ngIf="mode === 'view'" [tooltip]="part.notes || '-'" class="w-1/12 truncate">{{ part.notes }}</div>
      <div [tooltip]="part.article.gender?.nameDe || '-'" class="truncate" [ngClass]="mode === 'view' ? 'w-1/12' : 'w-2/12'">{{ part.article.gender?.nameDe }}</div>
      <div [tooltip]="part.article.size?.sizeName || '-'" class="truncate" [ngClass]="mode === 'view' ? 'w-1/12' : 'w-2/12'">{{ part.article.size?.sizeName }}</div>
      <div  class="truncate -my-1 flex justify-end" [ngClass]="mode === 'view' ? 'w-1/12' : 'w-2/12'">
        <div *ngIf="part.article?.addable">
          <span *ngIf="
            getRowsString(part.article) &&
            getRowsString(part.article).length > 0
          " class="
            px-2
            py-1
            inline-flex
            leading-5
            font-semibold
            text-sm
            rounded-sm
            bg-green-100
            text-green-800
          ">
            {{ getRowsString(part.article) }}
          </span>
        </div>
        <div [tooltip]="part.article.department?.name + '-Abteilung'" class="text-xs truncate" *ngIf="!part.article?.addable && !!part.article.department?.name">
          {{ part.article.department?.name }}-Abteilung
        </div>
      </div>
      <div class="w-1/12 truncate text-right" *ngIf="mode === 'view'">
        {{ part.articleCount }}
      </div>
      <div class="w-1/12 truncate text-right" *ngIf="mode === 'view'">
        <span *ngIf="partLoading === part.id"><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 animate-spin"
            fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
          </svg>
        </span>
        <span *ngIf="partLoading !== part.id" [ngClass]="{
            'text-green-700': part.articleCount - part.foundCount <= 0,
            'text-yellow-600':
              part.articleCount - part.foundCount > 0 && part.foundCount !== 0,
            'text-red-700': part.foundCount === 0
          }">{{ part.foundCount }}</span>
      </div>
      <div *ngIf="mode === 'view'" class="w-1/12 truncate flex gap-2.5">
        <button tooltip="Karton erstellen" (click)="$event.stopPropagation(); onNewBoxAdd(part)">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </button>
        <button [disabled]="part.articleCount <= part.foundCount" tooltip="Anzahl auffüllen" (click)="$event.stopPropagation(); onCheckPart(part)" class="disabled:cursor-not-allowed">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
          </svg>
        </button>
      </div>
    </div>
    <div *ngIf="mode === 'edit'" class="flex space-x-2 mt-2">
      <input min="1" class="py-1 flex-grow w-1/4 rounded-md text-sm focus:border-primary" type="number"
        [(ngModel)]="part.articleCount" />
      <input min="1" class="py-1 flex-grow w-3/4 rounded-md text-sm focus:border-primary" type="text"
        [(ngModel)]="part.notes" placeholder="Hinweise" />
      <button tooltip="Bestellungsteil löschen" (click)="onPartDelete(i)"
        class="bg-red-400 hover:bg-red-500 text-white p-1 rounded-md">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
        </svg>
      </button>
    </div>
  </div>
</div>

<app-new-box4-order [(order)]="order" [part]="selectedPart4NewBox" (close)="
    update(); newBoxModalActive = false; partLoading = selectedPart4NewBox.id
  " *ngIf="newBoxModalActive"></app-new-box4-order>
