import { IsNumber, IsObject, ValidateNested } from "class-validator";
import { CampFullSizeDto } from "./camp.full-size.dto";
import { Expose, Type } from "class-transformer";

export class CampWarehouseFullSizeDto {

  @IsNumber()
  @Expose()
  id: number;

  @IsNumber()
  @Expose()
  fkCamp: number;

  @IsNumber()
  @Expose()
  fkWarehouse: number;

  @IsObject()
  @Type(() => CampFullSizeDto)
  @ValidateNested()
  @Expose()
  camp: CampFullSizeDto = new CampFullSizeDto();
}
