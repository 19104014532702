import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BoxFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { BoxService } from '../../services/box.service';

@Component({
  selector: 'app-routing-box',
  templateUrl: './routing-box.component.html',
  styleUrls: ['./routing-box.component.scss'],
})
export class RoutingBoxComponent implements OnInit {
  loading: boolean = false;
  box: BoxFullSizeDto = new BoxFullSizeDto();

  constructor(
    private router: ActivatedRoute,
    private boxSrv: BoxService,
    private alertSrv: AlertService
  ) {}

  ngOnInit(): void {
    this.router.params.subscribe((params) => {
      if (params.boxId) {
        this.loading = true;
        this.boxSrv.findById(params.boxId).subscribe(
          (box) => {
            this.loading = false;
            this.box = box;
          },
          (err) => {
            this.loading = false;
            this.alertSrv.alert.next({
              text: `Karton ${params.boxId} konnte nicht gefunden werden.`,
              linkText: '',
            });
          }
        );
      }
    });
  }
}
