import { Component, OnInit } from '@angular/core';
import { PersonFullSizeDto } from '../../../../../shared/dtos';

@Component({
  selector: 'app-person-new',
  templateUrl: './person-new.component.html',
  styleUrls: ['./person-new.component.scss'],
})
export class PersonNewComponent implements OnInit {
  step: number = 1;
  maxStep: number = 4;
  person: PersonFullSizeDto = new PersonFullSizeDto();
  stepsValid = [false, true, true];

  constructor() { }

  prev() {
    this.step = this.step > 1 ? this.step - 1 : 1;
  }

  next() {
    this.step = this.step >= this.maxStep ? this.maxStep : this.step + 1;
  }

  ngOnInit(): void { }
}
