import { IsArray, IsDateString, IsNumber, IsObject, IsOptional, IsString, ValidateNested } from "class-validator";
import { ArticleFullSizeDto } from "../lager/article.full-size.dto";
import { BoxFullSizeDto } from "../lager/box.full-size.dto";
import { SubArticleFullSizeDto } from "../lager/sub-article.full-size.dto";
import { OrderFullSizeDto } from "./order.full-size.dto";
import { Exclude, Expose, Type } from "class-transformer";

export class PartFullSizeDto {

  @IsNumber()
  @Expose()
  id: number = 0;

  @IsDateString()
  @Exclude()
  createdAt: Date;

  @IsDateString()
  @Exclude()
  updatedAt: Date;

  @IsNumber()
  @Expose()
  fkArticle: number = 0;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkSubArticle: number | null = null;

  @IsNumber()
  @Expose()
  articleCount: number = 0;

  @IsNumber()
  @Expose()
  foundCount: number = 0;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkOrder: number | null = null;

  @IsObject()
  @Type(() => OrderFullSizeDto)
  @ValidateNested()
  @Expose()
  order: OrderFullSizeDto = new OrderFullSizeDto();
  
  @Expose()
  @IsString()
  notes: string | null = null;

  @IsObject()
  @Type(() => ArticleFullSizeDto)
  @ValidateNested()
  @Expose()
  article: ArticleFullSizeDto = new ArticleFullSizeDto();

  @IsObject()
  @Type(() => SubArticleFullSizeDto)
  @ValidateNested()
  @Expose()
  subArticle: SubArticleFullSizeDto | null = new SubArticleFullSizeDto();

  @IsArray()
  @Type(() => BoxFullSizeDto)
  @ValidateNested({each: true})
  @Expose()
  boxes: BoxFullSizeDto[] = [];
}
