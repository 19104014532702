import { Component } from '@angular/core';

@Component({
  selector: 'app-routing-not-found',
  standalone: false,
  templateUrl: './routing-not-found.component.html',
  styleUrl: './routing-not-found.component.scss'
})
export class RoutingNotFoundComponent {

}
