<div [@fade]="isOpen ? 'open' : 'closed'" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="
      flex
      items-end
      justify-center
      min-h-screen
      h-screen
      pt-4
      px-4
      pb-20
      text-center
      sm:p-0
      sm:items-center
    ">
    <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" (click)="onClose()">
    </div>


    <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
    <div [@zoom]="isOpen ? 'open' : 'closed'"  class="
        flex
        flex-col
        bg-white
        rounded-lg
        px-4
        pt-5
        pb-4
        text-left
        overflow-hidden
        shadow-xl
        transform
        transition-all
        sm:max-w-lg sm:w-full sm:p-6
        max-h-148 overflow-y-auto
      ">
      <div>
        <div class="
            mx-auto
            flex
            items-center
            justify-center
            h-12
            w-12
            rounded-full
            bg-green-100
          ">
          <!-- Heroicon name: outline/check -->
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
          </svg>          
        </div>

        <div class="mt-3 text-center sm:mt-5">
          <h3 class="text-xl leading-6 font-bold text-gray-900" id="modal-title">
            Neuigkeiten zum Logistiksystem
          </h3>
        </div>
      </div>
      <div class="flex flex-col gap-2.5 mobileLandscape:max-w-xl max-w-[80vh] h-full max-h-80vh overflow-y-auto mt-6">
        <div class="h-full overflow-y-auto">
          <ul>
            <li
              *ngFor="let version; let index = index  of releaseNotes"
              class="pb-4"
            >
              <div class="relative">
                <span
                  *ngIf="index !== releaseNotes.length - 1"
                  class="absolute top-5 left-6 -ml-px h-full w-0.5 bg-gray"
                  aria-hidden="true"
                ></span>
                <div class="flex items-start space-x-3">
                  <div class="relative px-1">
                    <div class="flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 font-bold ring-8 ring-white text-xs">
                      {{ version.version }}
                    </div>
                  </div>
                  <div class="min-w-0 flex-1">
                    <div class="flex">
                      <p class="mt-3 text-xs text-gray-600">
                        {{ version.date.format('DD.MM.YYYY') }}
                      </p>
                    </div>
                    <div
                      *ngIf="version.features.length > 0"
                      class="mt-2 text-sm text-gray-700"
                    >
                      <p class="text-base font-bold">
                        Neue Funktionen
                      </p>
                      <ul class="list-disc pl-5 text-sm text-gray-600">
                        <li
                          *ngFor="let feature; let i = index of version.features"
                          :key="i"
                          class="mt-1"
                        >
                          {{ feature }}
                        </li>
                      </ul>
                    </div>
                    <div
                      *ngIf="version.changes.length > 0"
                      class="mt-2 text-sm text-gray-700"
                    >
                      <p class="text-base font-bold">
                        Änderungen
                      </p>
                      <ul class="list-disc pl-5 text-sm text-gray-600">
                        <li
                          *ngFor="let change; let i = index of version.changes"
                        >
                          {{ change }}
                        </li>
                      </ul>
                    </div>
                    <div
                      *ngIf="version.bugfixes.length > 0"
                      class="mt-2 text-sm text-gray-700"
                    >
                      <p class="text-base font-bold">
                        Fehlerbehebungen
                      </p>
                      <ul class="list-disc pl-5 text-sm text-gray-600">
                        <li
                          *ngFor="let bugfix; let i = index of version.bugfixes"
                          :key="i"
                        >
                          {{ bugfix }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="
          mt-5
          sm:mt-6 sm:grid sm:gap-3 sm:grid-flow-row-dense
        ">
        <button type="button" (click)="onClose()" class="
            mt-3
            w-full
            inline-flex
            justify-center
            rounded-md
            border border-gray-300
            shadow-sm
            px-4
            py-2
            bg-white
            text-base
            font-medium
            text-gray-700
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-primary
            sm:mt-0 sm:col-start-1 sm:text-sm
            button-animation
          ">
          Schließen
        </button>
      </div>
    </div>
  </div>
</div>
