<app-loader *ngIf="loading"></app-loader>
<div *ngIf="!loading" class="h-screenExceptMenu flex flex-col overflow-y-hidden">
  <div class="flex space-x-4 justify-between w-full">
    <div class="">
      <div class="px-4 mt-6 pb-4 flex space-x-2">
        <div>
          <div class="flex space-x-2">
            <h1 class="text-2xl font-bold uppercase">
              Bestellung {{ order.id }} packen
            </h1>
            <button [routerLink]="[
                '/orders',
                { outlets: { order: [order.id.toString()] } }
              ]">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="px-4 flex items-center space-x-4">
        <div class="mt-3">
          <label class="block text-sm font-medium text-gray-700">
            Hier mit dem Scanner reinschreiben
          </label>
          <div class="flex space-x-2 items-center">
            <input (keydown.enter)="onBoxAdd()" type="text" class="ip mt-1 w-96" [(ngModel)]="boxIdInput" />
            <button (click)="onBoxAdd()" class="
                mt-2
                uppercase
                text-sm
                font-bold
                py-2
                px-4
                rounded-lg
                shadow-md
                cursor-pointer
                bg-primary
                hover:bg-primaryDark
                text-white
                active:bg-white active:text-primary
              ">
              Karton hinzufügen
            </button>
          </div>
        </div>
      </div>
    </div>
    <ul class="flex w-full max-w-xl flex-col mt-4 h-40 overflow-y-auto">
      <li class="flex gap-1 pr-6 w-full">
        <div class="w-2/12 font-bold truncate">Karton-Nr.</div>
        <div class="w-5/12 font-bold truncate">Artikel</div>
        <div class="w-2/12 font-bold truncate">Geschlecht</div>
        <div class="w-2/12 font-bold truncate">Größe</div>
        <div class="w-1/12 font-bold truncate">Anzahl</div>
      </li>
      <div class="h-full overflow-y-auto flex flex-col w-full pr-6">
        <li ngClass="text-gray-600 gap-1  w-full" *ngFor="let box of lastBoxes" class="flex">
          <div class="w-2/12 truncate">{{ box.id }}</div>
          <div class="w-5/12 truncate">{{ box.article.nameDe }}</div>
          <div class="w-2/12 truncate">{{ box.article.gender?.nameDe }}</div>
          <div class="w-2/12 truncate">{{ box.article.size?.sizeName }}</div>
          <div class="w-1/12 truncate">{{ box.articleCount }}</div>
        </li>
      </div>
    </ul>
  </div>
  <div class="mt-4 h-full overflow-y-auto">
    <app-order-parts [(order)]="order"></app-order-parts>
  </div>
</div>
