import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { HhHttpService } from './hh-http.service';
import { SearchResult } from '../../../../shared/dtos';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private httpSrv: HhHttpService) {}

  searchVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  search(q: string) {
    return this.httpSrv
      .get<SearchResult[]>(`search/${q}`)
      .pipe(distinctUntilChanged());
  }
}
