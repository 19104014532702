import {Component} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    FormsModule,
    NgIf
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  constructor(private authSrv: AuthService) {
  }

  /* ngOnInit(): void {
    this.authSrv.autoLogin();
  } */

  get loggedIn(): boolean {
    return this.authSrv.loggedIn;
  }

  login(): void {
    this.authSrv.login();
  }

  logout(): void {
    this.authSrv.logout();
  }
}
