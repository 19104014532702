<app-loader *ngIf="loading"></app-loader>
<div class="animate-fadeIn" *ngIf="!loading">
  <ul *ngIf="orders && orders.length > 0" class="flex flex-col space-y-2 relative z-0 divide-y divide-gray-200">
    <li class="cursor-pointer">
      <a (click)="onOrderSelect(order)" *ngFor="let order of orders" class="block hover:bg-gray-200 group" [ngClass]="{
        'bg-gray-100': selectedOrderId === order.id
      }">
        <div class="px-4 py-4 sm:px-6">
          <div class="flex items-center justify-between space-x-2">
            <div class="flex space-x-2 items-center">
              <p class="
                  text-lg
                  font-bold
                  text-primary
                  truncate
                ">
                {{ order.id }}
              </p>
            </div>

            <div class="flex flex-col items-start w-full">
              <div class="flex gap-1 line-clamp-1">
                <!-- <div class="text-xs text-gray-500 line-clamp-1">
                  <span *ngIf="order.fkStatus === 1">erfasst von:</span>
                  <span *ngIf="order.fkStatus === 2">gesammelt von:</span>
                  <span *ngIf="order.fkStatus === 3">gepackt von:</span>
                </div> -->
                <div class="text-xs text-gray-700 line-clamp-1">
                  <span [tooltip]="order?.creator?.firstName + ''" *ngIf="order.fkStatus === 1">{{
                    order?.creator?.firstName
                    }}</span>
                  <span [tooltip]="order?.collector?.firstName + ''" *ngIf="order.fkStatus === 2">{{
                    order?.collector?.firstName
                    }}</span>
                  <span [tooltip]="order?.packer?.firstName + ''" *ngIf="order.fkStatus === 3">{{
                    order?.packer?.firstName
                    }}</span>
                </div>
              </div>
              <div class="flex text-xs text-gray-500 font-bold" *ngIf="
                  order?.palletCount ||
                  order?.bigBoxCount ||
                  order?.smallBoxCount ||
                  order?.individualPartCount
                ">
                <span>{{ order?.palletCount || 0 }}P/</span>
                <span>{{ order?.bigBoxCount || 0 }}G/</span>
                <span>{{ order?.smallBoxCount || 0 }}R/</span>
                <span>{{ order?.individualPartCount || 0 }}E</span>
              </div>
            </div>
            <div class="ml-2 flex-shrink-0 gap-2 flex">
              <p class="
                  px-2
                  inline-flex
                  text-xs
                  leading-5
                  font-semibold
                  rounded-sm
                  bg-gray-50
                  text-gray-800
                ">
                {{ order?.requiredDate | date: "dd.MM.yyyy" }}
              </p>
              <button tooltip="In neuem Tab öffnen" (click)="onOpenOrderTab(order); $event.stopPropagation()">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
              </button>
            </div>
          </div>
          <div class="mt-2 sm:flex sm:justify-between sm:space-x-4">
            <div class="sm:flex">
              <p class="flex flex-col text-sm">
                <span [tooltip]="showStatus ? order?.status?.name || '-' : order?.camp?.name || '-'" class="text-gray-800 line-clamp-2">{{
                  showStatus ? order?.status?.name : order?.camp?.name
                  }}</span>
                <span [tooltip]="order?.customer?.person?.firstName + ' ' + order?.customer?.person?.lastName" class="text-gray-500 line-clamp-1">{{ order?.customer?.person?.firstName }}
                  {{ order?.customer?.person?.lastName }}</span>
              </p>
            </div>
            <div class="mt-2 flex flex-col items-end text-sm text-gray-500 sm:mt-0">
              <span>{{ order?.type?.name }}</span>
              <span tooltip="Keine Eilbestellung" class="font-extrabold text-green-500" *ngIf="!order?.prio">·</span>
              <div class="relative right-1">
                <span class="
                    absolute
                    animate-ping
                    font-extrabold
                    text-red-500
                  " *ngIf="order?.prio">!</span>
                <span tooltip="Eilbestellung" class="absolute font-extrabold text-red-500" *ngIf="order?.prio">!</span>
              </div>
            </div>
          </div>
          <div *ngIf="order?.collectionStatusPercent" class="w-full flex items-center mt-2.5">
            
            <div [ngStyle]="{
                'width.%': order?.collectionStatusPercent?.fullyCollected
              }" class="h-0.5 bg-green-500 "></div>
            <div [ngStyle]="{
                'width.%': order?.collectionStatusPercent?.partiallyCollected
              }" class="h-0.5 bg-green-200 "></div>
            <div [ngStyle]="{
              'width.%': order?.collectionStatusPercent?.notCollected
            }" class="h-px bg-green-50 "></div>
          </div>
        </div>
      </a>
    </li>
  </ul>
  <div *ngIf="!orders || orders?.length === 0" class="flex justify-center items-center mt-16 mx-4">
    Keine Bestellungen gefunden
  </div>
</div>
