import { Expose } from "class-transformer";
import { IsArray, IsNumber, IsOptional, IsString } from "class-validator";

export class CreatePersonDto {

  @IsNumber()
  @IsOptional()
  fkGender?: number;

  @IsString()
  @IsOptional()
  @Expose()
  title?: string;

  @IsString()
  @Expose()
  firstName: string;

  @IsString()
  @Expose()
  lastName: string;

  @IsString()
  @IsOptional()
  @Expose()
  company?: string;

  @IsString()
  @IsOptional()
  @Expose()
  phone_1?: string;

  @IsString()
  @IsOptional()
  @Expose()
  phone_2?: string;

  @IsString()
  @IsOptional()
  @Expose()
  eMail_1: string;

  @IsString()
  @IsOptional()
  @Expose()
  eMail_2?: string;

  @IsString()
  @IsOptional()
  @Expose()
  street?: string;

  @IsString()
  @IsOptional()
  @Expose()
  city?: string;

  @IsString()
  @IsOptional()
  @Expose()
  postcode?: string;

  @IsString()
  @IsOptional()
  @Expose()
  notes?: string;

  @IsArray()
  @IsOptional()
  campIds: number[];

  @IsArray()
  @IsOptional()
  groupIds: number[];
}