import { Injectable } from '@angular/core';
import { BlockFullSizeDto } from '../../../../shared/dtos';
import { HhHttpService } from './hh-http.service';

@Injectable({
  providedIn: 'root',
})
export class BlockService {
  constructor(private httpSrv: HhHttpService) {}

  findByWarehouseId(warehouseId: number) {
    return this.httpSrv.get<BlockFullSizeDto[]>(`warehouse/${warehouseId}/blocks`);
  }

  findById(id: number) {
    return this.httpSrv.get<BlockFullSizeDto>(`block/${id}`);
  }
}
