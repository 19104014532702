import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from '../../../services/alert.service';
import { PersonService } from '../../../services/person.service';
import { PersonFullSizeDto } from '../../../../../../shared/dtos';

@Component({
  selector: 'app-delete-person',
  templateUrl: './delete-person.component.html',
  styleUrls: ['./delete-person.component.scss'],
})
export class DeletePersonComponent implements OnInit {
  @Input() person: PersonFullSizeDto = new PersonFullSizeDto();
  personFirstName: string = '';
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private personSrv: PersonService,
    private alertSrv: AlertService
  ) {}

  onPersonDelete() {
    if (this.personFirstName === this.person.firstName) {
      this.personSrv.delete(this.person).subscribe(
        () => {
          this.alertSrv.alert.next({
            text: 'Account gelöscht!',
            linkText: '',
          });
          this.onClose(true);
          this.personSrv.updateTrigger.next();
        },
        () => {
          this.alertSrv.alert.next({
            text: `${this.person.firstName} ${this.person.lastName} kann nicht gelöscht werden. Wahrscheinlich ist der Account noch einer Bestellung zugeordnet.`,
            linkText: '',
          });
        }
      );
    } else {
      this.alertSrv.alert.next({
        text: 'Bitte gib den Vorname in das Textfeld ein.',
        linkText: '',
      });
    }
  }

  onClose(deleted: boolean) {
    this.close.emit(deleted);
  }

  ngOnInit(): void {}
}
