export class Route {
  logo?: boolean = false;
  text: string = '';
  route: string[] = [];
  bold?: boolean;
  subText?: string = '';
  rightText?: string = '';
  exact: boolean = false;
  showOnTopMenu?: boolean = true;
}

export const routes: Route[] = [
  {
    text: 'Hanseatic Help e.V.',
    route: ['/'],
    bold: true,
    subText: 'Zur Startseite zurück',
    rightText: '',
    exact: true,
    showOnTopMenu: true,
    logo: true,
  },
  {
    text: 'Registrieren',
    route: ['/', 'register'],
    subText: 'Registriere Kartons ins System',
    rightText: 'register',
    exact: true,
    showOnTopMenu: true,
  },
  {
    text: 'Lager',
    route: ['/', 'blocks'],
    subText: 'Verschaffe dir einen Überblick im Lager',
    rightText: 'manage all articles',
    exact: true,
    showOnTopMenu: true,
  },
  /*{
    text: 'Meine Bestellungen',
    route: ['/', 'myOrders'],
    subText: 'Schau dir deine bisherigen Bestellungen an',
    rightText: 'maintain orders for own',
    exact: true,
    showOnTopMenu: true,
  },*/
  {
    text: 'Kartoninventur',
    route: ['/', 'boxInventory'],
    subText: 'Räume im Lager auf',
    rightText: 'warehouse admin',
    exact: true,
    showOnTopMenu: false,
  },
  {
    text: 'Artikel',
    route: ['/', 'articles'],
    subText: 'Verwalte die Artikel im Lager',
    rightText: 'manage all articles',
    exact: false,
    showOnTopMenu: false,
  },
  {
    text: 'Bestellungen',
    route: ['/', 'orders'],
    subText: 'Verschaff dir einen Bestellungsüberblick',
    rightText: 'view order details',
    exact: false,
    showOnTopMenu: true,
  },
  {
    text: 'Karte',
    route: ['/', 'orderMap'],
    subText: 'Verwalte die Lieferungen auf der Karte',
    rightText: 'view order details',
    exact: false,
    showOnTopMenu: false,
  },
  {
    text: 'Organisationen',
    route: ['/', 'camps'],
    subText: 'Verwalte die Orgas, die bei uns bestellen',
    rightText: 'view customers',
    exact: false,
    showOnTopMenu: true,
  },
  {
    text: 'Personen',
    route: ['/', 'persons'],
    subText: 'Bearbeite alle Helfer:innen und Bestelller:innen',
    rightText: 'view customers',
    exact: false,
    showOnTopMenu: false,
  },
  {
    text: 'Gruppen',
    route: ['/', 'groups'],
    subText: 'Lasse dir die Rechteverwaltung anzeigen',
    rightText: 'manage users',
    exact: false,
    showOnTopMenu: false,
  },
];
