import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CampFullSizeDto, CreatePersonDto } from '../../../../../shared/dtos';
import { GroupFullSizeDto } from '../../../../../shared/dtos';
import { PersonFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { PersonService } from '../../services/person.service';
import { TransformerWrapper } from '../../../../../shared/src/transformer';

@Component({
  selector: 'app-person-new-summary',
  templateUrl: './person-new-summary.component.html',
  styleUrls: ['./person-new-summary.component.scss'],
})
export class PersonNewSummaryComponent implements OnInit {
  @Input() person: PersonFullSizeDto = new PersonFullSizeDto();
  personGroups: GroupFullSizeDto[] = [];
  personCamps: CampFullSizeDto[] = [];

  constructor(
    private personSrv: PersonService,
    private alertSrv: AlertService,
    private router: Router
  ) {}

  onPersonCreate() {
    const dto = TransformerWrapper.plainToInstanceExclude(CreatePersonDto, this.person);

    dto.campIds = this.person.customers.map((customer) => customer.fkCamp);
    dto.groupIds = this.person.personGroups.map((personGroup) => personGroup.fkGroup);

    this.personSrv.create(dto).subscribe(
      (person) => {
        this.person = person;
        this.alertSrv.alert.next({
          text: `${this.person.firstName} ${this.person.lastName} wurde erfolgreich erstellt.`,
          linkText: '',
        });
        this.router.navigateByUrl(`/persons/(person:${person.id})`);
      },
      (err) => {
        this.alertSrv.alert.next({
          text: err?.error?.message || `Probleme beim Erstellen der Person.`,
          linkText: '',
        });
      }
    );
  }

  ngOnInit(): void {
    this.personGroups = this.person.personGroups.map(
      (personGroup) => personGroup.group
    );

    this.personCamps = this.person.customers.map((customer) => customer.camp);
  }
}
