import { IsDateString, IsNumber, IsObject, ValidateNested } from "class-validator";
import { GroupFullSizeDto } from "./group.full-size.dto";
import { PersonFullSizeDto } from "./person.full-size.dto";
import { Exclude, Expose, Type } from "class-transformer";


export class PersonGroupFullSizeDto {

  @IsNumber()
  @Expose()
  id: number = 0;

  @IsDateString()
  @Exclude()
  createdAt: Date;

  @IsDateString()
  @Exclude()
  updatedAt: Date;

  @IsNumber()
  @Expose()
  fkPerson: number = 0;

  @IsNumber()
  @Expose()
  fkGroup: number = 0;

  @IsObject()
  @Type(() => PersonFullSizeDto)
  @ValidateNested()
  @Expose()
  person: PersonFullSizeDto = new PersonFullSizeDto();

  @IsObject()
  @Type(() => GroupFullSizeDto)
  @ValidateNested()
  @Expose()
  group: GroupFullSizeDto = new GroupFullSizeDto();
}
