<div class="flex items-center h-full">
  <app-loader *ngIf="loading"></app-loader>
  <div *ngIf="!loading" class="
      grid grid-cols-1
      md:grid-cols-2
      lg:grid-cols-3
      xl:grid-cols-4
      gap-4
      w-full
      animate-fadeIn
    ">
    <div (click)="onBlockSelected(block)" class="
        bg-gray-100
        flex flex-col
        items-start
        p-4
        shadow-md
        rounded-md
        text-gray-800 text-center
        cursor-pointer
        hover:bg-gray-200
        button-animation
      " *ngFor="let block of blocks">
      <span> {{ block.name }}</span>
      <span class="text-xs text-gray-500">{{ block.hashtagRows.length }} Reihen</span>
    </div>
  </div>
</div>
