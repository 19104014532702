import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BoxFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { BoxService } from '../../services/box.service';

@Component({
  selector: 'app-box-to-other-pallet',
  templateUrl: './box-to-other-pallet.component.html',
  styleUrls: ['./box-to-other-pallet.component.scss'],
})
export class BoxToOtherPalletComponent implements OnInit {
  @Input() box: BoxFullSizeDto = new BoxFullSizeDto();
  @Output() boxChange: EventEmitter<BoxFullSizeDto> = new EventEmitter();
  newPalletId: number = 0;

  constructor(private boxSrv: BoxService, private alertSrv: AlertService) {}

  onSave() {
    if (this.newPalletId) {
      this.boxSrv.putOnPallet(this.box.id, this.newPalletId).subscribe(
        (box) => {
          this.box = box;
          this.boxChange.emit(this.box);
        },
        (err) => {
          this.alertSrv.alert.next({
            text: 'Karton konnte nicht gespeichert werden.',
            linkText: '',
          });
        }
      );
    }
  }

  ngOnInit(): void {}
}
