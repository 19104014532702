<div *ngIf="pallet && pallet.id" class="max-w-3xl">
  <div class="flex flex-col space-y-2">
    <div class="flex justify-between">
      <div class="flex flex-col">
        <div class="flex items-center space-x-2">
          <h1 class="text-xl font-bold text-gray-900 uppercase">
            Palette {{ pallet.id }}
          </h1>
          <button tooltip="in neuem Tab öffnen" (click)="onOpenPalletTab()">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg>
          </button>
        </div>
        <div class="text-sm text-gray-800" [innerHtml]="pallet.article.nameDe"></div>
        <div class="text-xs text-gray-600">
          {{ pallet.article.gender?.nameDe || '-' }},
          {{ pallet.article.size?.sizeName || '-' }}
        </div>
      </div>
      <p class="text-sm text-gray-500">
        {{ articleCount }} Artikel in {{ pallet.boxes.length }} Kartons
      </p>
    </div>
    <div class="flex flex-wrap gap-2">
      <a (click)="chooseWarehouseEnabled = !chooseWarehouseEnabled" class="
          uppercase
          text-sm
          font-bold
          py-2
          px-4
          rounded-lg
          shadow-md
          cursor-pointer
          button-animation
        " [ngClass]="{
          'bg-primary text-white hover:bg-primaryDark ': chooseWarehouseEnabled,
          'text-primary bg-white hover:bg-gray-100': !chooseWarehouseEnabled
        }">
        manuelles Umbuchen
      </a>
      <a (click)="onPalletPrint()" class="
          uppercase
          text-sm
          font-bold
          py-2
          px-4
          rounded-lg
          shadow-md
          cursor-pointer
          bg-white
          hover:bg-gray-100
          text-primary
          active:bg-primary active:text-white
          button-animation
        ">
        Label drucken
      </a>
      <a [routerLink]="['/pallet-inventory', pallet.id]" class="
          uppercase
          text-sm
          font-bold
          py-2
          px-4
          rounded-lg
          shadow-md
          cursor-pointer
          bg-white
          hover:bg-gray-100
          text-primary
          active:bg-primary active:text-white
          button-animation
        ">
        Inventur
      </a>
    </div>
  </div>
  <div class="font-bold uppercase mt-4">Kartons</div>
  <div class="flex gap-2 flex-wrap mt-1">
    <div (click)="onBoxSelect(box)" *ngFor="let box of pallet.boxes" class="
        px-4
        py-2
        bg-gray-100
        hover:bg-gray-200
        cursor-pointer
        shadow-md
        rounded-md
        flex flex-col
        button-animation
      ">
      <span class="text-sm font-medium text-gray-900">
        {{ box.id }}
      </span>
      <span class="text-xs text-gray-500 truncate">
        {{ box.articleCount }} Artikel
      </span>
    </div>
  </div>
  <div *ngIf="chooseWarehouseEnabled" class="mt-4">
    <app-pallet-choose-warehouse [pallet]="pallet"></app-pallet-choose-warehouse>
  </div>
</div>
