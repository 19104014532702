import { Component, OnInit } from '@angular/core';
import { PersonFullSizeDto } from '../../../../../shared/dtos';
import { AuthService } from '../../services/auth.service';
import { CampService } from '../../services/camp.service';
import { OrderService } from '../../services/order.service';
import { PersonService } from '../../services/person.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-routing-dashboard-external',
  templateUrl: './routing-dashboard-external.component.html',
  styleUrls: ['./routing-dashboard-external.component.scss'],
})
export class RoutingDashboardExternalComponent implements OnInit {
  loading: boolean = false;
  authPerson: PersonFullSizeDto | null = null;

  constructor(
    private authSrv: AuthService,
    private personSrv: PersonService,
    private campSrv: CampService,
    private orderSrv: OrderService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;

    if (!this.authSrv.authContext?.person.id) {
      return;
    }

    this.authPerson = await firstValueFrom(this.personSrv.findById(this.authSrv.authContext?.person.id));
    
    this.authPerson?.customers.map((customer) => {
      this.campSrv.findById(customer.fkCamp).subscribe((camp) => {
        customer.camp = camp;
        this.orderSrv.findGroupedByCampId(camp.id).subscribe((orders) => {
          this.loading = false;
          camp.groupedOrders = orders;
        });
      });
    });
  }
}
