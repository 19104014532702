<app-loader *ngIf="loading"></app-loader>
<div *ngIf="!loading" class="flex flex-col">
  <div
    (click)="onOpenBoxTab(box)"
    class="hover:bg-gray-100 cursor-pointer animate-fadeIn"
    *ngFor="let box of boxes"
  >
    <div class="px-4 py-4 sm:px-6">
      <div class="flex items-center justify-between space-x-2">
        <p class="text-lg font-medium text-primary truncate">
          {{ box.id }}
        </p>
        <div class="flex flex-col items-center">
          <div class="flex space-x-1"></div>
        </div>
        <div class="ml-2 flex-shrink-0 flex">
          <p
            class="
              px-2
              inline-flex
              text-xs
              leading-5
              font-semibold
              rounded-sm
              bg-gray-50
              text-gray-800
            "
          >
            {{ box.createdAt | date: "HH:mm, dd.MM" }}
          </p>
        </div>
      </div>
      <div class="mt-2 sm:flex sm:justify-between sm:space-x-4">
        <div class="sm:flex truncate">
          <p class="flex flex-col text-sm truncate">
            <span class="text-gray-800 truncate"
              ><span class="font-bold">{{ box.articleCount }}x</span>
              <span [tooltip]="!box.subArticle || !box.subArticle.id
              ? box.article.nameDe
              : box.subArticle.name">
                {{
                  !box.subArticle || !box.subArticle.id
                    ? box.article.nameDe
                    : box.subArticle.name
                }}
                </span>
              </span
            >
            <span class="text-gray-500"
              >{{ box.article.gender?.nameDe }},
              {{ box.article.size?.sizeName }}</span
            >
          </p>
        </div>
        <div
          *ngIf="box.newGoods"
          class="
            self-start
            text-xs
            px-2
            leading-5
            font-semibold
            rounded-sm
            inline-flex
            bg-red-50
            text-red-500
          "
        >
          Neuware
        </div>
      </div>
    </div>
  </div>
</div>
