<div class="overflow-y-auto h-full flex flex-col max-w-2xl" *ngIf="box && box.id">
  <div class="flex flex-col space-y-2">
    <div class="flex flex-col">
      <div class="flex items-center space-x-2">
        <h1 class="font-bold uppercase text-lg">Karton {{ box.id }}</h1>
        <button tooltip="Karton in neuem Tab öffnen" (click)="onOpenBoxTab()">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
          </svg>
        </button>
      </div>
      <div class="flex space-x-4">
        <div class="flex flex-col">
          <h3 class="text-sm text-primary">Details</h3>
          <p class="text-sm text-gray-800">
            <span class="font-bold">{{ box.articleCount }}</span> Artikel
          </p>
          <div class="text-sm text-gray-800 cursor-pointer" (click)="onOpenArticleTab()" [innerHtml]="
              box.subArticle && box.subArticle.id
                ? box.subArticle.name
                : box.article.nameDe
            "></div>
          <div tooltip="Artikel in neuem Tab öffnen" (click)="onOpenArticleTab()"
            class="text-xs text-gray-600 cursor-pointer">
            {{ box.article.gender?.nameDe }},
            {{ box.article.size?.sizeName }}
          </div>
        </div>
        <div *ngIf="box.fkPallet" class="flex flex-col">
          <h3 class="text-sm text-primary">Lager</h3>
          <p class="text-sm text-gray-700">
            Palette:
            <span tooltip="Palette in neuem Tab öffnen" class="font-bold cursor-pointer" (click)="onOpenPalletTab()">{{
              box.pallet?.id }}</span>
          </p>
          <p *ngIf="box.pallet?.hashtagRow" class="text-sm text-gray-700">
            Reihe:
            <span tooltip="Reihe in neuem Tab öffnen" class="font-bold cursor-pointer"
              (click)="onOpenHashtagRowTab()">{{
              box.pallet?.hashtagRow?.block?.name
              }}{{ box.pallet?.hashtagRow?.row || '-' }}</span>
          </p>
        </div>
        <div *ngIf="box.fkPart" (click)="onOpenOrderTab()" class="flex flex-col cursor-pointer">
          <h3 class="text-sm text-primary">Bestellung</h3>
          <p class="text-sm text-gray-700">
            Bestellung
            <span class="font-bold">{{ order.id }}</span>
          </p>
          <p class="text-sm text-gray-500">
            {{ order.camp.name }}
          </p>
          <p *ngIf="order.customer?.person" class="text-sm text-gray-500">
            {{ order.customer.person.firstName }} {{ order.customer.person.lastName }}
          </p>
          <p *ngIf="!order.customer.person" class="text-sm text-gray-500">
            -
          </p>
        </div>
        <div class="font-medium text-primary mt-2" (click)="onOpenPalletTab()"></div>
      </div>
      <div class="flex flex-wrap gap-2 mt-2.5">
        <a (click)="action = action === 'changeAmount' ? '' : 'changeAmount'" [ngClass]="{
            'bg-primary text-white hover:bg-primaryDark ':
              action === 'changeAmount',
            'text-primary bg-white hover:bg-gray-100': action !== 'changeAmount'
          }" class="
            uppercase
            text-sm
            font-bold
            py-2
            px-4
            rounded-lg
            shadow-md
            cursor-pointer
            button-animation
          ">
          Anzahl ändern
        </a>
        <a (click)="action = action === 'changeWeight' ? '' : 'changeWeight'" [ngClass]="{
            'bg-primary text-white hover:bg-primaryDark ':
              action === 'changeWeight',
            'text-primary bg-white hover:bg-gray-100': action !== 'changeWeight'
          }" class="
            uppercase
            text-sm
            font-bold
            py-2
            px-4
            rounded-lg
            shadow-md
            cursor-pointer
            button-animation
          ">
          Gewicht ändern
        </a>
        <a (click)="action = action === 'extractArticle' ? '' : 'extractArticle'" [ngClass]="{
            'bg-primary text-white hover:bg-primaryDark ':
              action === 'extractArticle',
            'text-primary bg-white hover:bg-gray-100':
              action !== 'extractArticle'
          }" class="
            uppercase
            text-sm
            font-bold
            py-2
            px-4
            rounded-lg
            shadow-md
            cursor-pointer
            button-animation
          ">
          Artikel extrahieren
        </a>
        <a (click)="action = action === 'toOtherPallet' ? '' : 'toOtherPallet'" [ngClass]="{
            'bg-primary text-white hover:bg-primaryDark ':
              action === 'toOtherPallet',
            'text-primary bg-white hover:bg-gray-100':
              action !== 'toOtherPallet'
          }" class="
            uppercase
            text-sm
            font-bold
            py-2
            px-4
            rounded-lg
            shadow-md
            cursor-pointer
            button-animation
          ">
          Auf andere Palette
        </a>
        <a (click)="action = action === 'toNewPallet' ? '' : 'toNewPallet'" [ngClass]="{
            'bg-primary text-white hover:bg-primaryDark ':
              action === 'toNewPallet',
            'text-primary bg-white hover:bg-gray-100': action !== 'toNewPallet'
          }" class="
            uppercase
            text-sm
            font-bold
            py-2
            px-4
            rounded-lg
            shadow-md
            cursor-pointer
            button-animation
          ">
          Auf neue Palette
        </a>
        <a (click)="onBoxPrint()" class="
            uppercase
            text-sm
            font-bold
            py-2
            px-4
            rounded-lg
            shadow-md
            cursor-pointer
            bg-white
            hover:bg-gray-100
            text-primary
            active:bg-primary active:text-white
            button-animation
          ">
          Ettikett erneut drucken
        </a>
        <a (click)="action = action === 'takeFromPallet' ? '' : 'takeFromPallet'" [ngClass]="{
            'bg-primary text-white hover:bg-primaryDark ':
              action === 'takeFromPallet',
            'text-primary bg-white hover:bg-gray-100':
              action !== 'takeFromPallet'
          }" class="
            uppercase
            text-sm
            font-bold
            py-2
            px-4
            rounded-lg
            shadow-md
            cursor-pointer
            bg-white
            hover:bg-gray-100
            text-primary
            active:bg-primary active:text-white
            button-animation
          ">
          Ausgeben
        </a>
        <a (click)="action = action === 'delete' ? '' : 'delete'" class="
            uppercase
            text-sm
            font-bold
            py-2
            px-4
            rounded-lg
            shadow-md
            cursor-pointer
            bg-white
            hover:bg-gray-100
            text-red-500
            active:bg-red-500 active:text-white
            button-animation
          ">
          Löschen
        </a>
      </div>
    </div>

    <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 mt-4">
      <div class="sm:col-span-1" *ngFor="let data of boxData">
        <dt class="text-sm font-medium text-gray-500">{{ data.key }}</dt>
        <dd class="mt-1 text-sm text-gray-900">{{ data.val }}</dd>
      </div>
    </dl>

    <div *ngIf="action === 'extractArticle'" class="mt-4">
      <app-box-extract-article (boxChange)="update()" [(box)]="box"></app-box-extract-article>
    </div>

    <div *ngIf="action === 'toNewPallet'" class="mt-4">
      <app-box-to-new-pallet (boxChange)="update()" [(box)]="box"></app-box-to-new-pallet>
    </div>

    <div *ngIf="action === 'toOtherPallet'" class="mt-4">
      <app-box-to-other-pallet (boxChange)="update()" [(box)]="box"></app-box-to-other-pallet>
    </div>

    <div *ngIf="action === 'changeAmount'" class="mt-4">
      <app-box-change-amount [(box)]="box"></app-box-change-amount>
    </div>

    <div *ngIf="action === 'changeWeight'" class="mt-4">
      <app-box-change-weight [(box)]="box"></app-box-change-weight>
    </div>

    <div *ngIf="action === 'delete'" class="mt-4">
      <app-box-delete [(box)]="box"></app-box-delete>
    </div>

    <div *ngIf="action === 'takeFromPallet'" class="pt-4 overflow-y-auto h-full">
      <app-box-take-from-pallet class="overflow-y-auto h-full" [(box)]="box" (boxChange)="update()">
      </app-box-take-from-pallet>
    </div>
  </div>
</div>
