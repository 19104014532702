<div class="mt-6 px-4 sm:px-6 lg:px-8">
  <div class="flex h-96 flex-col items-center justify-center">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-24 w-24 text-gray-500 animate-pulse"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
      />
    </svg>
    <p class="text-lg text-gray-700 mt-2">Im Bau</p>
  </div>
</div>
