import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { MenuService } from '../../services/menu.service';
import { PersonService } from '../../services/person.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss'],
})
export class PersonComponent implements OnInit {
  @Input() person: PersonFullSizeDto = new PersonFullSizeDto();
  @Output() personChange: EventEmitter<PersonFullSizeDto> = new EventEmitter<PersonFullSizeDto>();
  loading: boolean = false;
  view: string = 'details';
  pwResetModalActive: boolean = false;
  personDeleteModalShown: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private personSrv: PersonService,
    public authSrv: AuthService,
    private alertSrv: AlertService,
    private menuSrv: MenuService
  ) {}

  onOpenPersonTab() {
    this.menuSrv.addTabAndOpen({
      text: `${this.person.firstName} ${this.person.lastName}`,
      route: ['persons', this.person?.id.toString()!],
      exact: true,
    });
  }

  onPwReset() {
    this.pwResetModalActive = true;
  }

  onPersonDelete() {
    this.personDeleteModalShown = true;
  }

  onSendPwMail() {
    this.personSrv.resetPw(this.person.id).subscribe(
      () => {
        this.alertSrv.alert.next({
          text: `Es wurde eine Mail verschickt!`,
          linkText: '',
        });
        this.pwResetModalActive = false;
      },
      (err) => {
        this.alertSrv.alert.next({
          text: `Die Mail konnte nicht verschickt werden.`,
          linkText: '',
        });
        this.pwResetModalActive = false;
      }
    );
  }

  onDeleteModalClose(deleted: boolean) {
    this.personDeleteModalShown = false;
    if (deleted) {
      this.personSrv.updateTrigger.next();
      this.router.navigate(['/persons', { outlets: { person: null } }]);
    }
  }

  update() {
    this.route.params.subscribe((params) => {
      if (params.personId) {
        this.loading = true;
        this.personSrv.findById(params.personId).subscribe((person) => {
          this.person = person;
          this.loading = false;
        });
      }
    });
  }

  ngOnInit(): void {
    this.update();
  }
}
