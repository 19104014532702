import { Injectable } from '@angular/core';
import { ExportArticleFullSizeDto } from '../../../../shared/dtos';
import { HhHttpService } from './hh-http.service';

@Injectable({
  providedIn: 'root',
})
export class ExportArticleService {
  constructor(private httpSrv: HhHttpService) {}

  findAll() {
    return this.httpSrv.get<ExportArticleFullSizeDto[]>(`export-article`);
  }
}
