import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BoxFullSizeDto, CreateBoxDto, OrderFullSizeDto } from '../../../../shared/dtos';
import { TransformerWrapper } from '../../../../shared/src/transformer';
import { HhHttpService } from './hh-http.service';
import { OrderService } from './order.service';

@Injectable({
  providedIn: 'root',
})
export class BoxService {
  constructor(
    private httpSrv: HhHttpService,
    private orderSrv: OrderService
  ) {}

  reloadBoxList: Subject<void> = new Subject<void>();
  boxDeleteTrigger: Subject<void> = new Subject<void>();

  findLast() {
    return this.httpSrv.get<BoxFullSizeDto[]>(`box/last`);
  }

  findById(boxId: number): Observable<BoxFullSizeDto> {
    return this.httpSrv.get<BoxFullSizeDto>(`box/${boxId}`).pipe(map(this.mapBox));
  }

  create(box: CreateBoxDto): Observable<BoxFullSizeDto> {
    return this.httpSrv
      .post<BoxFullSizeDto>(`box`, TransformerWrapper.plainToInstanceExclude(CreateBoxDto, box))
      .pipe(map((elem) => TransformerWrapper.plainToInstanceExclude(BoxFullSizeDto, elem)));
  }

  putOnOrder(box: BoxFullSizeDto, order: OrderFullSizeDto) {
    return this.httpSrv
      .post<BoxFullSizeDto>(`order/${order.id}/addBox/${box.id}`, null)
      .pipe(map((elem) => Object.assign(new BoxFullSizeDto(), elem)));
  }

  addToPallet(boxIds: number[], palletId: number) {
    return this.httpSrv.post<BoxFullSizeDto>(`box/toPallet/${palletId}`, {
      boxIds: boxIds,
    });
  }

  removeFromPallet(boxIds: number[]) {
    return this.httpSrv.post<BoxFullSizeDto>(`box/fromPallet`, {
      boxIds: boxIds,
    });
  }

  putOnPallet(boxId: number, palletId: number) {
    return this.httpSrv
      .post<BoxFullSizeDto>(`box/${boxId}/toPallet/${palletId}`, null)
      .pipe(map((elem) => Object.assign(new BoxFullSizeDto(), elem)));
  }

  putOnNewPallet(boxId: number) {
    return this.httpSrv
      .post<BoxFullSizeDto>(`box/${boxId}/newPallet`, null)
      .pipe(map((elem) => Object.assign(new BoxFullSizeDto(), elem)));
  }

  delete(boxId: number) {
    return this.httpSrv.delete<BoxFullSizeDto>(`box/${boxId}`)
      .pipe(tap(() => {
        this.orderSrv.updateOneOrderTrigger.next();
        this.boxDeleteTrigger.next();
      }));
  }

  inventory(boxId: number) {
    return this.httpSrv
      .put<BoxFullSizeDto>(`box/${boxId}/inventory`, null)
      .pipe(map((elem) => Object.assign(new BoxFullSizeDto(), elem)));
  }

  update(box: BoxFullSizeDto) {
    return this.httpSrv
      .put<BoxFullSizeDto>(`box`, box)
      .pipe(map((elem) => Object.assign(new BoxFullSizeDto(), elem)));
  }

  mapBox = (box: BoxFullSizeDto) => {
    box.createdAt = new Date(box.createdAt);
    return box;
  };
}
