<div class="mt-6 px-4 sm:px-6 lg:px-8">
  <app-hashtag-row-details
    *ngFor="let row of article.hashtagRows"
    [rowId]="row.id"
    (palletSelected)="onPalletSelect($event)"
  ></app-hashtag-row-details>

  <!-- pallet -->
  <div class="mt-8"></div>
  <app-pallet-details
    [(pallet)]="selectedPallet"
    (boxSelected)="selectedBox = $event"
  ></app-pallet-details>

  <!-- box -->
  <div class="mt-8"></div>
  <app-box-details [(box)]="selectedBox"></app-box-details>
</div>
