import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { ArticleFullSizeDto } from '../../../../../shared/dtos';
import { SubArticleFullSizeDto } from '../../../../../shared/dtos';
import { ArticleService } from '../../services/article.service';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
})
export class ArticleComponent implements OnInit {
  @Input() article: ArticleFullSizeDto | null = null;
  @Output() articleChange = new EventEmitter<ArticleFullSizeDto>();

  @Input() subArticle: SubArticleFullSizeDto | null = null;
  articleData: any[] = [];
  view = 'details';

  constructor(
    private router: ActivatedRoute,
    private articleSrv: ArticleService,
    private alertSrv: AlertService,
    private menuSrv: MenuService
  ) {}

  getRowsString(article: ArticleFullSizeDto) {
    if (article.hashtagRows) {
      return article.hashtagRows.map((row) => {
        return row.block.name + row.row;
      });
    }
    return '';
  }

  onOpenArticleTab() {
    this.menuSrv.addTabAndOpen({
      text: `${this.article?.nameDe}`,
      route: ['articles', this.article?.id.toString()!],
      exact: true,
    });
  }

  ngOnInit(): void {
    this.router.params.subscribe((params) => {
      if (params.articleId) {
        this.articleSrv.findById(params.articleId).subscribe(
          (article) => (this.article = article),
          (err) => {
            this.alertSrv.alert.next({
              text: 'Fehler beim Laden!',
              linkText: '',
            });
          }
        );
      }
    });
    this.view = 'details';
  }
}
