import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BoxFullSizeDto } from '../../../../../shared/dtos';
import { PalletFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { BoxService } from '../../services/box.service';
import { PalletService } from '../../services/pallet.service';

@Component({
  selector: 'app-routing-pallet-inventory',
  templateUrl: './routing-pallet-inventory.component.html',
  styleUrls: ['./routing-pallet-inventory.component.scss'],
})
export class RoutingPalletInventoryComponent implements OnInit {
  loading: boolean = false;
  pallet: PalletFullSizeDto = new PalletFullSizeDto();
  palletId: number = 0;
  checkedBoxes: boolean[] = [];
  additionalBoxIds: number[] = [];
  newBox: BoxFullSizeDto = new BoxFullSizeDto();

  constructor(
    private router: ActivatedRoute,
    private palletSrv: PalletService,
    private alertSrv: AlertService,
    private boxSrv: BoxService
  ) {}

  onSave() {
    const removedBoxes: number[] = [];
    this.checkedBoxes.forEach((value, index) => {
      if (value === false) {
        removedBoxes.push(index);
      }
    });

    this.boxSrv.removeFromPallet(removedBoxes).subscribe(() => {
      this.boxSrv
        .addToPallet(this.additionalBoxIds, this.pallet.id)
        .subscribe(() => {
          this.alertSrv.alert.next({
            text: 'Inventur ausgeführt',
            linkText: '',
          });
          this.update();
        });
    });
  }

  onAddBox() {
    this.pallet.boxes.push(Object.assign(new BoxFullSizeDto(), this.newBox));
    this.checkedBoxes[this.newBox.id] = true;
    this.additionalBoxIds.push(this.newBox.id);
  }

  update() {
    this.loading = true;
    this.additionalBoxIds = [];
    this.checkedBoxes = [];
    this.palletSrv.findById(this.palletId).subscribe(
      (pallet) => {
        this.loading = false;
        this.pallet = pallet;
        pallet.boxes.forEach((box) => (this.checkedBoxes[box.id] = true));
      },
      (err) => {
        this.loading = false;
        this.alertSrv.alert.next({
          text: `Palette konnte nicht gefunden werden.`,
          linkText: '',
        });
      }
    );
  }

  ngOnInit(): void {
    this.router.params.subscribe((params) => {
      if (params.palletId) {
        this.palletId = params.palletId;
        this.update();
      }
    });
  }
}
