import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupFullSizeDto } from '../../../../../shared/dtos';
import { GroupService } from '../../services/group.service';

@Component({
  selector: 'app-show-groups',
  templateUrl: './show-groups.component.html',
  styleUrls: ['./show-groups.component.scss'],
})
export class ShowGroupsComponent implements OnInit {
  groups: GroupFullSizeDto[] = [];
  selectedGroup: GroupFullSizeDto = new GroupFullSizeDto();
  loading = false;
  menuShown: boolean = true;
  selectedGroupId: number = -1;

  constructor(
    private groupSrv: GroupService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  onGroupSelect(group: GroupFullSizeDto) {
    this.selectedGroupId = group.id;
    this.router.navigate([
      '/groups',
      { outlets: { group: [group.id.toString()] } },
    ]);
  }

  ngOnInit(): void {
    this.route.children[0]?.params.subscribe((params) => {
      if (!params['groupId']) {
        return;
      }
      this.selectedGroupId = +params['groupId'];
    });

    this.loading = true;
    this.groupSrv.findAll().subscribe((groups) => {
      this.groups = groups;
      this.loading = false;
    });
  }
}
