<app-order-camp-details *ngIf="campDetailsShown" [(camp)]="selectedCamp" (close)="campDetailsShown = false">
</app-order-camp-details>
<app-order-person-details *ngIf="personDetailsShown" [(person)]="selectedPerson" (close)="personDetailsShown = false">
</app-order-person-details>
<app-loader *ngIf="loading"></app-loader>
<div class="lg:h-full lg:overflow-y-hidden" *ngIf="!loading">
  <div *ngIf="!order && paramsOrderId !== '0'" class="
      h-full
      w-full
      flex
      justify-center
      items-center
      text-lg text-gray-600
      px-2
    ">
    Bestellung {{ paramsOrderId }} wurde nicht gefunden
  </div>
  <main *ngIf="order" class="
      flex-1
      h-full
      relative
      z-0
      focus:outline-none
      xl:order-last
      animate-fadeIn
    ">
    <article class="flex h-full flex-col overflow-y-hidden">
      <!-- Profile header -->
      <div class="flex-shrink-0">
        <div class="w-full flex justify-center cursor-pointer lg:hidden" (click)="statusShown = !statusShown">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" [ngClass]="{ 'transform rotate-180': statusShown }"
               fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
          </svg>
        </div>
        <app-order-status class="lg:block" [ngClass]="{ hidden: !statusShown }" [(order)]="order"></app-order-status>
        <div class="px-4 sm:px-6 lg:px-8">
          <div class="min-w-0 flex-1">
            <div class="flex space-x-4 justify-between"></div>
            <div class="mt-4 flex justify-between items-center gap-8">
              <div class="flex w-full justify-between items-center">
                <div class="flex flex-col lg:flex-row gap-2 lg:items-center">
                  <h1 class="text-2xl font-bold text-gray-900 truncate uppercase">
                    Bestellung {{ order.id }}
                  </h1>
                  <div class="flex space-x-2 items-center">
                    <button class="button-animation" tooltip="Bestellung bearbeiten" [routerLink]="[
                        '/orders',
                        { outlets: { order: ['compose', order.id] } }
                      ]">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none"
                           viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
                      </svg>
                    </button>
                    <button class="button-animation" tooltip="Bestellung packen" [routerLink]="[
                        '/orders',
                        { outlets: { order: ['pack', order.id] } }
                      ]">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600 p-0.5"
                           stroke="currentColor" fill="currentColor" stroke-linejoin="round"
                           stroke-width="4" viewBox="0 0 512 512">
                        <path
                          d="M218.6 2.4c-7.6 4.6-7.5 4-7.6 49.3V92h-24.3c-21.7 0-24.6.2-27.7 1.9-6.4 3.3-9.5 11.9-6.9 18.8.5 1.5 15.9 17.8 34.2 36l33.2 33.3h-73.3c-70.9 0-73.4.1-77.1 2-2.2 1.1-4.8 3.4-5.9 5.2-1.1 1.8-15.7 30.6-32.6 63.9C9.6 294.8 0 314.7 0 316.9c0 5.1 3.5 10.8 8 13.1 3.6 1.8 5.8 2 28.5 2H61v84.1c0 83.8 0 84.1 2.1 87.9 1.2 2.1 3.8 4.7 5.9 5.9l3.8 2.1h183.1c177.9 0 183.3-.1 186.9-1.9 2.2-1.1 4.6-3.5 6-5.9l2.2-4V332h24.5c22.7 0 24.9-.2 28.5-2 4.5-2.3 8-8.1 8-13.2 0-2.3-9.6-22.2-32.3-66.8-30.9-60.9-32.4-63.6-36.2-65.7l-4-2.3h-147l33.2-33.3c18.3-18.2 33.7-34.5 34.2-36 2.6-6.9-.5-15.5-6.9-18.8-3.1-1.7-6-1.9-27.7-1.9H301V52c0-38.2-.1-40.3-2-44-1.3-2.6-3.4-4.7-6-6-3.7-1.9-5.8-2-37.2-2-33.2 0-33.3 0-37.2 2.4zM271 70c0 38.2.1 40.3 2 44 3.4 6.6 7.4 8 23.2 8h13.3l-26.8 26.7-26.7 26.8-26.7-26.8-26.8-26.7h13.3c15.8 0 19.8-1.4 23.2-8 1.9-3.7 2-5.8 2-44V30h30v40zm178.4 186.5c12.4 24.4 22.6 44.7 22.6 45 0 .3-97.2.5-216 .5s-216-.2-216-.5 10.2-20.6 22.6-45L85.2 212h341.6l22.6 44.5zM151 356c0 27.5.5 29.7 7.8 33.8l4 2.2h93.1c89.5 0 93.3-.1 96.9-1.9 2.2-1.1 4.6-3.5 6-5.9 2.1-3.8 2.2-5 2.2-28.1V332h60v150H91V332h60v24zm180-9v15H181v-30h150v15z"/>
                      </svg>
                    </button>
                    <button class="button-animation" tooltip="Bestellung drucken" (click)="onOrderPrint()">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none"
                           viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"/>
                      </svg>
                    </button>
                    <button class="button-animation" tooltip="Bestellung löschen" (click)="onOrderDelete()">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none"
                           viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                      </svg>
                    </button>
                    <button class="button-animation" tooltip="Bestellung in neuem Tab öffnen"
                            (click)="onOpenOrderTab()">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none"
                           viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"/>
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="self-start lg:self-center mt-0.5 lg:mt-0">
                  <p class="
                      px-2
                      py-1
                      lg:px-4 lg:py-2
                      inline-flex
                      leading-5
                      font-semibold
                      rounded-md
                      bg-gray-50
                      text-gray-800
                      lg:text-base
                      text-ls
                    ">
                    {{ order.requiredDate | date: "dd.MM.yyyy" }}
                  </p>
                </div>
              </div>
            </div>
            <div *ngIf="showOrderDetails" class="
                flex flex-col
                lg:flex-row
                gap-4
                md:gap-8
                lg:gap-12
                max-w-4xl
                mt-2
                items-start
              ">
              <div class="cursor-pointer lg:flex-shrink-0 lg:max-w-80" (click)="onOpenCampDetails(order.camp)">
                <h3 class="text-sm text-primary">Organisation</h3>

                <div class="flex items-center gap-1">
                  <p [tooltip]="order.camp.name" class="font-semibold max-w-2xl text-gray-700 line-clamp-2" [innerHTML]="order.camp.name"></p>
                  <div *ngIf="order.camp.isArchived" class="text-gray-500">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4">
                    <path d="M3 2a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3Z" />
                    <path fill-rule="evenodd" d="M3 6h10v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6Zm3 2.75A.75.75 0 0 1 6.75 8h2.5a.75.75 0 0 1 0 1.5h-2.5A.75.75 0 0 1 6 8.75Z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
                <p [tooltip]="order.camp.street + ' ' + order.camp.streetNumber" class="max-w-2xl font-medium text-sm text-gray-500 line-clamp-2">
                  {{ order.camp.street }} {{ order.camp.streetNumber }}
                </p>
                <p class="max-w-2xl font-medium text-sm text-gray-500">
                  {{ order.camp.postCode }} {{ order.camp.city }}
                </p>
                <p class="max-w-2xl font-medium text-sm text-gray-500">
                  {{ order.camp.email }}
                </p>
                <div *ngIf="order.camp.deliveryNote" class="flex gap-1 items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4 text-gray-400">
                    <path d="M2.908 2.067A.978.978 0 0 0 2 3.05V8h6V3.05a.978.978 0 0 0-.908-.983 32.481 32.481 0 0 0-4.184 0ZM12.919 4.722A.98.98 0 0 0 11.968 4H10a1 1 0 0 0-1 1v6.268A2 2 0 0 1 12 13h1a.977.977 0 0 0 .985-1 31.99 31.99 0 0 0-1.066-7.278Z" />
                    <path d="M11 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM2 12V9h6v3a1 1 0 0 1-1 1 2 2 0 1 0-4 0 1 1 0 0 1-1-1Z" />
                    <path d="M6 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
                  </svg>
                  <p [tooltip]="order.camp.deliveryNote" class="max-w-2xl text-sm text-gray-700 line-clamp-1 italic">
                    {{ order.camp.deliveryNote }}
                  </p>
                </div>
              </div>
              <button [disabled]="!order.customer.person" class="cursor-pointer lg:flex-shrink-0 lg:max-w-80 disabled:cursor-not-allowed text-left"
                      (click)="order.customer.person && onOpenPersonDetails(order.customer.person)">
                <h3 class="text-sm text-primary">Ansprechpartner:in</h3>

                <p *ngIf="!order.customer.person" class="text-gray-400 font-semibold line-through">gelöscht<br/>&nbsp;
                </p>
                <p *ngIf="order.customer.person" class="font-semibold max-w-2xl text-gray-700 line-clamp-1">
                  {{ order.customer.person.firstName || '-' }}
                  {{ order.customer.person.lastName }}
                </p>
                <p *ngIf="order.customer.person && (order.customer.person.eMail_1 || order.customer.person.eMail_2)" class="max-w-2xl font-medium text-sm text-gray-500 line-clamp-1">
                  {{
                    order.customer.person.eMail_1 || order.customer.person.eMail_2
                  }}
                  
                </p>
                <p *ngIf="order.customer.person && (order.customer.person.phone_1 || order.customer.person.phone_2)" class="max-w-2xl font-medium text-sm text-gray-500 line-clamp-1">
                  {{ order.customer.person.phone_1 || order.customer.person.phone_2 }}
                </p>
              </button>
              <div class="flex flex-col">
                <h3 class="text-sm text-primary">Details</h3>
                <span class="
                    font-medium
                    text-sm text-gray-500
                    flex
                    justify-between
                    space-x-2
                  ">
                  <span class="whitespace-nowrap"> flexible Bestellung:</span>
                  <span class="font-semibold">{{
                      order.isFlexible ? "ja" : "nein"
                    }}</span></span>
                <span class="
                    font-medium
                    text-sm text-gray-500
                    flex
                    justify-between
                    space-x-2
                  ">
                  <span> Typ:</span>
                  <span class="font-semibold">{{ order.type.name }}</span></span>
                <span *ngIf="order.additionalDate" class="
                    font-medium
                    text-sm text-gray-500
                    flex
                    justify-between
                    space-x-2
                  ">
                  <span>Weiterer Termin:</span>
                  <span class="font-semibold">{{
                      order.additionalDate | date: "dd.MM.yyyy"
                    }}</span></span>
              </div>
              <div class="flex flex-col" *ngIf="
                  order.palletCount ||
                  order.bigBoxCount ||
                  order.smallBoxCount ||
                  order.individualPartCount
                ">
                <div class="flex items-center gap-2">
                  <h3 class="text-sm text-primary">Logistik</h3>
                  <button class="button-animation" tooltip="Bestellung löschen" (click)="packerModalActive = true">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none"
                         viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
                    </svg>
                  </button>
                </div>
                <div class="text-xs text-gray-700 flex flex-col">
                  <span class="whitespace-nowrap" *ngIf="order.palletCount">{{
                      order.palletCount
                    }}<span class="hidden sm:inline">
                      Palette<span *ngIf="order.palletCount > 1">n</span>
                    </span>
                    <span class="sm:hidden">P</span></span>
                  <span class="whitespace-nowrap" *ngIf="order.bigBoxCount">{{
                      order.bigBoxCount
                    }}<span class="hidden sm:inline"> Gitterwagen</span>
                    <span class="sm:hidden">G</span></span>
                  <span class="whitespace-nowrap" *ngIf="order.smallBoxCount">{{
                      order.smallBoxCount
                    }}<span class="hidden sm:inline"> Rollwagen</span><span class="sm:hidden">R</span></span>
                  <span class="whitespace-nowrap" *ngIf="order.individualPartCount">{{
                      order.individualPartCount
                    }}<span class="hidden sm:inline">
                      Einzelteil<span *ngIf="order.individualPartCount > 1">e</span></span><span
                      class="sm:hidden">E</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="mt-2" *ngIf="showOrderDetails && order?.notes">
              <h3 class="text-sm text-primary">Bemerkungen</h3>
              <div class="flex flex-col items-start gap-1">
                <span *ngIf="!showFullNotes" class="font-medium text-sm text-gray-500 whitespace-pre-line line-clamp-2">
                  {{ order.notes }}
                </span>
                <span *ngIf="showFullNotes" class="font-medium text-sm text-gray-500 whitespace-pre-line ">
                  {{ order.notes }}
                </span>
                <button *ngIf="getNoteParagaraphs() > 2" class="text-xs text-gray-600 flex items-center gap-1.5"
                        (click)="showFullNotes = !showFullNotes">
                  <svg class="duration-100 transition-all w-4 h-4" [ngClass]="showFullNotes ? 'rotate-180' : 'rotate-0'"
                       xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                          d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                          clip-rule="evenodd"/>
                  </svg>
                  <span *ngIf="showFullNotes">weniger anzeigen</span>
                  <span *ngIf="!showFullNotes">mehr anzeigen</span>
                </button>
              </div>
            </div>
          </div>
          <div class="w-full flex items-center justify-center mt-2">
            <button (click)="showOrderDetails = !showOrderDetails">
              <svg xmlns="http://www.w3.org/2000/svg" [ngClass]="{ '-rotate-180': showOrderDetails }"
                   class="h-6 w-6 transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
              </svg>
            </button>
          </div>
        </div>
      </div>

      <!-- Tabs -->
      <div class="mt-6 sm:mt-2 2xl:mt-5 flex-shrink-0">
        <div class="border-b border-gray-200">
          <div class="px-4 sm:px-6 lg:px-8">
            <nav class="-mb-px flex space-x-8" aria-label="Tabs">
              <!-- Current: "border-blue-500 text-gray-900", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
              <a (click)="view = 'parts'" class="
                  cursor-pointer
                  text-gray-500
                  whitespace-nowrap
                  py-4
                  px-1
                  border-b-2
                  font-medium
                  text-sm
                " [ngClass]="{
                  'border-primary text-gray-900': view === 'parts'
                }" aria-current="page">
                Artikel
              </a>

              <a (click)="view = 'messages'" class="
                  cursor-pointer
                  text-gray-500
                  hover:text-gray-700
                  whitespace-nowrap
                  py-4
                  px-1
                  border-b-2
                  font-medium
                  text-sm
                " [ngClass]="{
                  'border-primary text-gray-900': view === 'messages'
                }">
                Verlauf
              </a>
              <a (click)="view = 'map'" *ngIf="order.type.id === 0" class="
                  cursor-pointer
                  text-gray-500
                  hover:text-gray-700
                  whitespace-nowrap
                  py-4
                  px-1
                  border-b-2
                  font-medium
                  text-sm
                " [ngClass]="{
                  'border-primary text-gray-900': view === 'map'
                }">
                Karte
              </a>
            </nav>
          </div>
        </div>
      </div>
      <div class="overflow-y-auto h-full">
        <app-order-parts *ngIf="view === 'parts'" [(order)]="order"></app-order-parts>
        <app-order-messages *ngIf="view === 'messages'" [(order)]="order"
                            (personSelected)="onOpenPersonDetails($event)"></app-order-messages>
        <app-order-map *ngIf="view === 'map'" [(order)]="order"></app-order-map>
      </div>
    </article>
  </main>
</div>

<!-- modals -->
<app-print-order [order]="order" (close)="printModalActive = false" *ngIf="printModalActive"></app-print-order>

<app-delete-order [order]="order" (close)="deleteModalActive = false" *ngIf="deleteModalActive">
</app-delete-order>

<app-packer [onlyEditCounts]="true" [(order)]="order" (close)="packerModalActive = false; update()"
            *ngIf="packerModalActive"></app-packer>
