import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from '../../../services/alert.service';
import { AuthService } from '../../../services/auth.service';
import { MessageService } from '../../../services/message.service';
import { OrderService } from '../../../services/order.service';
import {
  OrderFullSizeDto,
  PersonFullSizeDto,
} from '../../../../../../shared/dtos';

@Component({
  selector: 'app-collector',
  templateUrl: './collector.component.html',
  styleUrls: ['./collector.component.scss'],
})
export class CollectorComponent implements OnInit {
  @Input() order: OrderFullSizeDto = new OrderFullSizeDto();
  @Output() orderChange: EventEmitter<OrderFullSizeDto> =
    new EventEmitter<OrderFullSizeDto>();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() save: EventEmitter<void> = new EventEmitter<void>();

  statusId: number = 2;

  constructor(
    private orderSrv: OrderService,
    private alertSrv: AlertService,
    private authSrv: AuthService,
    private messageSrv: MessageService,
  ) {}

  onClose() {
    this.close.emit();
  }

  setStatus() {
    this.order.fkStatus = this.statusId;
    this.order.status.id = this.statusId;

    this.orderSrv.setStatus(this.order, this.statusId).subscribe((message) => {
      this.order.messages.push(message);
      this.order.fkCollector = this.authSrv.authContext?.person.id || 0;
      this.order.collector = new PersonFullSizeDto();
      this.order.collector.id = this.authSrv.authContext?.person.id || 0;
      this.orderSrv
        .update(this.order.id, {
          fkStatus: this.statusId,
        })
        .subscribe({
          next: (order) => {
            this.order = order;
            this.alertSrv.alert.next({
              text: 'Die Bestellung wurde gespeichert',
              linkText: '',
            });
            this.orderChange.emit(this.order);
            this.orderSrv.updateListTrigger.next();
            this.onClose();
          },
          error: (err) => {
            this.alertSrv.alert.next({
              text: 'Die Bestellung konnte nicht gespeichert werden',
              linkText: '',
            });
            this.onClose();
          },
        });
    });
  }

  ngOnInit(): void {}
}
