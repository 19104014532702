import { Injectable } from '@angular/core';
import { CategoryFullSizeDto } from '../../../../shared/dtos';
import { HhHttpService } from './hh-http.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private httpSrv: HhHttpService) {}

  findAll() {
    return this.httpSrv.get<CategoryFullSizeDto[]>(`category`);
  }
}
