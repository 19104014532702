<app-loader *ngIf="loading"></app-loader>
<div class="h-full" *ngIf="!loading">
  <div class="flex-shrink-0 px-4 mt-6 pb-4">
    <div>
      <div class="flex space-x-2 items-center">
        <h1 class="text-2xl font-bold uppercase">
          {{
            camp.id ? camp.name + " bearbeiten" : "Neue Organisation anlegen"
          }}
        </h1>
        <button tooltip="Organisation speichern" (click)="onSave()">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"/>
          </svg>
        </button>
        <button tooltip="Änderungen verwerfen"
                [routerLink]="!!camp.id ? ['/camps', { outlets: { camp: [camp.id.toString()] } }] : ['/camps']">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
          </svg>
        </button>
      </div>
      <div>
        <div class="flex mt-4">
          <div class="flex space-x-4 w-full">
            <div>
              <div class="mt-3 relative" (clickOutside)="suggestions = []">
                <label class="block text-sm font-medium text-gray-700">
                  * Organisation
                </label>
                <input type="text" class="ip mt-1 w-96" (input)="onInputChange($event)" placeholder="Suche..."
                       [(ngModel)]="camp.name">
                <ul *ngIf="suggestions.length"
                    class="absolute z-10 mt-1 w-full  bg-white  shadow-lg  max-h-60 rounded-md  pt-1  text-base  ring-1 ring-black ring-opacity-5  overflow-auto  focus:outline-none sm:text-sm"
                    tabindex="-1" role="listbox" aria-labelledby="listbox-label">

                  <li *ngFor="let suggestion of suggestions" (click)="selectSuggestion(suggestion)" class="
                    text-gray-900
                    cursor-default
                    select-none
                    relative
                    py-2
                    pl-3
                    pr-9
                    hover:bg-gray-100
                  " id="listbox-option-0" role="option">
                    <div class="flex items-center justify-between w-full truncate">
                      <div class="flex flex-col truncate">
                        <span class="font-bold truncate">{{ suggestion.description }}</span>
                        <!--<span
                          class="font-normal text-gray-700 truncate">{{ camp.street }} {{ camp.streetNumber }}</span>
                        <span class="font-normal text-gray-700 truncate">{{ camp.postCode }} {{ camp.city }}</span>
                      --></div>
                    </div>

                    <span class="text-primary absolute inset-y-0 right-0 flex items-center pr-4">
                  </span>
                  </li>
                  <li class="p-2.5 bg-gray-100" *ngIf="suggestions.length === 0 && !suggestionsLoading">
                    Kein Ergebnis gefunden!
                  </li>
                  <li>
                    <app-loader *ngIf="suggestionsLoading" [fixedHeight]="false" class="h-6"></app-loader>
                  </li>

                </ul>

              </div>

              <div class="mt-3">
                <label class="block text-sm font-medium text-gray-700">
                  E-Mail
                </label>
                <input type="text" class="ip mt-1 w-96" [(ngModel)]="camp.email"/>
              </div>

              <div class="mt-3">
                <label class="block text-sm font-medium text-gray-700">
                  Kategorie
                </label>
                <select [(ngModel)]="selectedCampCategoryId" id="category" (ngModelChange)="onCategorySelect()"
                        class="ip mt-1 w-96">
                  <option [value]="-1" disabled>
                    Kategorie auswählen
                  </option>
                  <option *ngFor="let campCategory of campCategories" [value]="campCategory.id">
                    {{ campCategory.name }}
                  </option>
                </select>
              </div>

              <div class="mt-3">
                <label class="block text-sm font-medium text-gray-700">
                  Lieferhinweis
                </label>
                <input type="text" class="ip mt-1 w-96" [(ngModel)]="camp.deliveryNote"/>
              </div>

              <div class="mt-3">
                <label class="block text-sm font-medium text-gray-700">
                  Straße
                </label>
                <input type="text" class="ip mt-1 w-96" [(ngModel)]="camp.street"/>
              </div>

              <div class="mt-3">
                <label class="block text-sm font-medium text-gray-700">
                  Hausnummer
                </label>
                <input type="text" class="ip mt-1 w-96" [(ngModel)]="camp.streetNumber"/>
              </div>

              <div class="mt-3">
                <label class="block text-sm font-medium text-gray-700">
                  Postleitzahl
                </label>
                <input type="text" class="ip mt-1 w-96" [(ngModel)]="camp.postCode"/>
              </div>

              <div class="mt-3">
                <label class="block text-sm font-medium text-gray-700">
                  Bezirk
                </label>
                <input type="text" class="ip mt-1 w-96" [(ngModel)]="camp.subLocality"/>
              </div>

              <div class="mt-3">
                <label class="block text-sm font-medium text-gray-700">
                  Stadt
                </label>
                <input type="text" class="ip mt-1 w-96" [(ngModel)]="camp.city"/>
              </div>

              <div class="mt-3">
                <label class="block text-sm font-medium text-gray-700">
                  Land
                </label>
                <input type="text" class="ip mt-1 w-96" [(ngModel)]="camp.country"/>
              </div>

              <div class="flex-1 flex space-x-1 items-center mt-3">
                <input id="skipAddressValidation"
                       class="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" type="checkbox"
                       [(ngModel)]="skipAddressValidation"/>
                <label for="skipAddressValidation">Adressvalidierung überspringen</label>
              </div>

            </div>

            <div class="mt-3 w-full">
              <label class="block text-sm font-medium text-gray-700">
                Bemerkungen
              </label>
              <textarea rows="8" type="text" class="ip mt-1 w-full" [(ngModel)]="camp.notes"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
