<div class="h-full overflow-y-auto flex flex-col">
  <p class="text-sm text-gray-700">
    Bitte gib die Bestellung an, zu der dieser Karton gehört:
  </p>
  <div class="w-96 overflow-y-auto h-full">
    <app-order-list
      (orderSelect)="onOrderSelect($event)"
      [orders]="orders"
    ></app-order-list>
  </div>
</div>
