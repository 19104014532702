import { IsArray, IsDateString, IsNumber, IsString, ValidateNested } from "class-validator";
import { ArticleFullSizeDto } from "./article.full-size.dto";
import { Exclude, Expose, Type } from "class-transformer";
export class ArticleGroupFullSizeDto {

  @IsNumber()
  @Expose()
  id: number = 0;

  @IsDateString()
  @Exclude()
  createdAt: Date;

  @IsDateString()
  @Exclude()
  updatedAt: Date;

  @IsString()
  @Expose()
  name: string = '';

  @IsString()
  @Expose()
  picto: string | null = null;

  @IsArray()
  @Type(() => ArticleFullSizeDto)
  @ValidateNested({ each: true })
  @Expose()
  articles: ArticleFullSizeDto[] = [];
}
