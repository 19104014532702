<div class="h-screenExceptMenu flex overflow-hidden bg-white">
  <div class="flex flex-col min-w-0 flex-1 overflow-hidden">
    <div class="relative z-0 flex overflow-hidden">
      <div class="
          h-screenExceptMenu
          flex
          justify-center
          items-center
          cursor-pointer
          lg:hidden
        " (click)="menuShown = !menuShown">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" [ngClass]="{ 'transform rotate-180': menuShown }"
          fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
        </svg>
      </div>
      <aside class="
          lg:flex
          flex-col flex-shrink-0
          max-w-fullExceptFold
          overflow-y-auto
          h-screenExceptMenu
          w-96
          border-r border-gray-200
          print:hidden
          animate-slideInFromLeft
          lg:animate-none
        " [ngClass]="{ hidden: !menuShown, flex: menuShown }">
        <div class="px-6 pb-4 pt-6 border-b border-gray-200">
          <div class="flex justify-between items-center">
            <div>
              <h2 class="text-xl font-bold text-gray-900 uppercase">
                Organisationen
              </h2>
              <p class="mt-1 text-sm text-gray-600">
                {{ filteredCamps.length }} gefunden
              </p>
            </div>

            <button tooltip="Neue Orga anlegen" [routerLink]="['/camps', { outlets: { camp: ['edit'] } }]" class="
                inline-flex
                justify-center
                px-3.5
                py-2
                border border-gray-300
                shadow-sm
                text-sm
                font-medium
                rounded-md
                text-gray-700
                bg-white
                hover:bg-gray-300
                button-animation
              ">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
              </svg>
            </button>
          </div>

          <app-camp-search (campsChanged)="camps = $event; filter()" (loading)="loading = $event"></app-camp-search>
        </div>
        <!-- Directory list -->
        <nav class="flex-1 min-h-0 overflow-y-auto" aria-label="Directory">
          <div class="relative">
            <!-- List -->
            <app-loader *ngIf="loading"></app-loader>
            <app-camp-list *ngIf="!loading" [camps]="filteredCamps" [selectedCampId]="selectedCampId"
              (campSelect)="onCampSelect($event); menuShown = false"></app-camp-list>
          </div>
        </nav>
      </aside>
      <div class="flex-grow flex flex-col overflow-y-auto h-screenExceptMenu">
        <router-outlet name="camp"></router-outlet>
      </div>
    </div>
  </div>
</div>
