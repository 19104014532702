import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HashtagRowFullSizeDto } from '../../../../shared/dtos';
import { HhHttpService } from './hh-http.service';

@Injectable({
  providedIn: 'root',
})
export class HashtagRowService {
  constructor(private httpSrv: HhHttpService) {}

  findById(id: number) {
    return this.httpSrv.get<HashtagRowFullSizeDto>(`hashtag-row/${id}`);
  }

  update(row: HashtagRowFullSizeDto): Observable<HashtagRowFullSizeDto> {
    return this.httpSrv
      .put<HashtagRowFullSizeDto>(`hashtag-row`, row)
      .pipe(map((elem) => Object.assign(new HashtagRowFullSizeDto(), elem)));
  }
}
