import { IsArray, IsBoolean, IsDateString, IsEmail, IsNumber, IsObject, IsOptional, IsString, IsUUID, ValidateNested } from "class-validator";
import { WarehouseFullSizeDto } from "../lager/warehouseLager.full-size.dto";
import { CustomerFullSizeDto } from "./customer.full-size.dto";
import { GenderFullSizeDto } from "./gender.full-size.dto";
import { HelperFullSizeDto } from "./helper.full-size.dto";
import { PersonGroupFullSizeDto } from "./person-group.full-size.dto";
import { PersonSettingFullSizeDto } from "./person-setting.full-size.dto";
import { Exclude, Expose, Type } from "class-transformer";
import { OrderFullSizeDto } from "../order/order.full-size.dto";


export class PersonFullSizeDto {

  @IsNumber()
  @Expose()
  id: number;

  @IsDateString()
  @Exclude()
  createdAt: Date;

  @IsDateString()
  @Exclude()
  updatedAt: Date;

  @IsNumber()
  @IsOptional()
  @Exclude()
  createdBy: number | null;

  @IsUUID()
  @IsOptional()
  @Expose()
  keycloakId: string | null = null;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkGender: number | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  title: string | null = null;

  @IsString()
  @Expose()
  firstName: string = '';

  @IsString()
  @Expose()
  lastName: string = '';

  @IsString()
  @IsOptional()
  @Exclude()
  pw: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  sessionId: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  resetStr: string | null = null;

  @IsDateString()
  @IsOptional()
  @Expose()
  resetTime: Date | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  company: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  phone_1: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  phone_2: string | null = null;

  @IsString()
  @IsEmail()
  @IsOptional()
  @Expose()
  eMail_1: string | null = null;

  @IsString()
  @IsEmail()
  @IsOptional()
  @Expose()
  eMail_2: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  street: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  city: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  postcode: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  notes: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  lastSite: string | null = null;

  @IsDateString()
  @IsOptional()
  @Exclude()
  lastActionTime: Date | null = null;

  @IsNumber()
  @Expose()
  fkCurrentWarehouse: number = 8;

  @IsBoolean()
  @Exclude()
  disabled: boolean = false;

  @IsDateString()
  @Exclude()
  created: Date = new Date();

  @IsArray()
  @Type(() => CustomerFullSizeDto)
  @ValidateNested({ each: true })
  @Expose()
  customers: CustomerFullSizeDto[] = [];

  @IsArray()
  @Type(() => HelperFullSizeDto)
  @ValidateNested({ each: true })
  @Expose()
  helpers: HelperFullSizeDto[] = [];

  @IsObject()
  @Type(() => GenderFullSizeDto)
  @ValidateNested()
  @Expose()
  gender: GenderFullSizeDto = new GenderFullSizeDto();

  @IsObject()
  @Type(() => WarehouseFullSizeDto)
  @ValidateNested()
  @Expose()
  currentWarehouse: WarehouseFullSizeDto = new WarehouseFullSizeDto();

  @IsArray()
  @Type(() => PersonGroupFullSizeDto)
  @ValidateNested({ each: true })
  @Expose()
  personGroups: PersonGroupFullSizeDto[] = [];

  @IsArray()
  @Type(() => PersonSettingFullSizeDto)
  @ValidateNested({ each: true })
  @Expose()
  personSettings: PersonSettingFullSizeDto[] = [];

  @IsArray()
  @Type(() => OrderFullSizeDto)
  @ValidateNested({ each: true })
  @Expose()
  ordersAsCreator: OrderFullSizeDto[] = [];

  @IsArray()
  @Type(() => OrderFullSizeDto)
  @ValidateNested({ each: true })
  @Expose()
  ordersAsCollector: OrderFullSizeDto[] = [];

  @IsArray()
  @Type(() => OrderFullSizeDto)
  @ValidateNested({ each: true })
  @Expose()
  ordersAsPacker: OrderFullSizeDto[] = [];

  @Exclude()
  _token?: string | null;

  @Exclude()
  _tokenExpirationDate?: Date;

  @IsString()
  @IsOptional()
  @Exclude()
  get token(): string | null | undefined {
    if (!this._tokenExpirationDate || new Date() > this._tokenExpirationDate) {
      return null;
    }
    return this._token;
  }

  set token(token: string | null | undefined) {
    if (this._token) {
      return;
    }
    this._token = token;
  }

  set tokenExpirationDate(date: Date) {
    if (this._tokenExpirationDate) {
      return;
    }
    this._tokenExpirationDate = date;
  }
}
