import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { OrderService } from 'src/app/services/order.service';
import { CategoryFullSizeDto, CreateOrderDto, CreatePartDto, GenderLagerFullSizeDto } from '../../../../../shared/dtos';
import { UpdateOrderDto } from '../../../../../shared/dtos';
import { SizeFullSizeDto } from '../../../../../shared/dtos';
import { CampFullSizeDto } from '../../../../../shared/dtos';
import { OrderFullSizeDto } from '../../../../../shared/dtos';
import { OrganisationFullSizeDto } from '../../../../../shared/dtos';
import { StatusFullSizeDto } from '../../../../../shared/dtos';
import { CustomerFullSizeDto } from '../../../../../shared/dtos';
import { PersonFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { AuthService } from '../../services/auth.service';
import { CampService } from '../../services/camp.service';
import { MenuService } from '../../services/menu.service';
import { TransformerWrapper } from '../../../../../shared/src/transformer';
import { UpdatePartDto } from '../../../../../shared/src/dtos/order/update-part.dto';



const MAX_CAMP_RESULTS = 50;

@Component({
  selector: 'app-compose-order',
  templateUrl: './compose-order.component.html',
  styleUrls: ['./compose-order.component.scss'],
})
export class ComposeOrderComponent implements OnInit {
  loading: boolean = false;
  campStr: string = '';
  personStr: string = '';
  allCamps: CampFullSizeDto[] = [];
  camps: CampFullSizeDto[] = [];
  showOrderDetails: boolean = true;
  selectedPerson: PersonFullSizeDto = new PersonFullSizeDto();
  filteredCustomers: PersonFullSizeDto[] = [];
  campSearchActive: boolean = false;
  personSearchActive: boolean = false;
  campLoading: boolean = false;
  omitingAllCamps: boolean = false;
  @Input() order: OrderFullSizeDto = new OrderFullSizeDto();
  @Output() orderChange: EventEmitter<OrderFullSizeDto> = new EventEmitter<OrderFullSizeDto>();
  campSearchChange: Subject<string> = new Subject();
  personSearchChange: Subject<string> = new Subject();
  additionalDateActive: boolean = false;
  view: string = 'details';
  mode: string = 'internal';

  selectedGender: GenderLagerFullSizeDto = new GenderLagerFullSizeDto();
  selectedCategory: CategoryFullSizeDto = new CategoryFullSizeDto();
  selectedSize: SizeFullSizeDto = new SizeFullSizeDto();

  constructor(
    private campSrv: CampService,
    private router: ActivatedRoute,
    private router2: Router,
    private orderSrv: OrderService,
    private alertSrv: AlertService,
    private authSrv: AuthService,
    private menuSrv: MenuService,
  ) { }

  onSave() {
    this.order.id ? this.updateOrder() : this.createOrder();
  }

  onSetView(view: string) {
    this.view = view;
    this.selectedCategory = new CategoryFullSizeDto();
    this.selectedGender = new GenderLagerFullSizeDto();
    this.selectedSize = new SizeFullSizeDto();
  }

  onNavigateBack() {
    if (this.mode !== 'internal') {
      this.router2.navigate(['/']);
      return;
    }

    if (!this.order.id) {
      this.router2.navigate(['/orders']);
      return;
    }

    this.router2.navigate([
      '/orders',
      { outlets: { order: [this.order.id.toString()] } },
    ]);

  }

  updateOrder() {
    const updateOrderDto: UpdateOrderDto = new UpdateOrderDto();

    updateOrderDto.fkCamp = this.order.fkCamp;
    updateOrderDto.fkCustomer = this.order.fkCustomer;
    updateOrderDto.fkType = this.order.type.id;
    updateOrderDto.isFlexible = this.order.isFlexible;
    updateOrderDto.notes = this.order.notes;
    updateOrderDto.prio = this.order.prio;
    updateOrderDto.requiredDate = this.order.requiredDate as Date;
    updateOrderDto.additionalDate = this.order.additionalDate as Date;
    updateOrderDto.parts = this.order.parts.map((part) => {
      const partDto = new UpdatePartDto();
      partDto.id = part.id !== 0 ? part.id : undefined;
      partDto.fkArticle = part.article.id;
      partDto.fkSubArticle = part.subArticle?.id || null;
      partDto.articleCount = part.articleCount;
      partDto.notes = part.notes;

      return partDto;
    });

    /* const updateOrder = this.order;
    updateOrder.organisation = new OrganisationFullSizeDto();
    updateOrder.organisation.id = 1;
    updateOrder.parts.forEach((part) => ((part.article as any) = undefined));
    updateOrder.parts.forEach((part) => ((part.subArticle as any) = undefined));
    (updateOrder.camp.orders as any) = undefined;
    updateOrder.requiredDate = new Date(this.order.requiredDate);
    updateOrder.additionalDate = this.order.additionalDate
      ? new Date(this.order.additionalDate)
      : null; */
    this.orderSrv.update(this.order.id, updateOrderDto).subscribe(
      (order) => {
        this.order = order;

        this.alertSrv.alert.next({
          text: 'Die Bestellung wurde gespeichert.',
          linkText: '',
        });
        this.orderSrv.updateListTrigger.next();
        this.onNavigateBack();
      },
      (err) => {
        this.alertSrv.alert.next({
          text: 'Die Bestellung konnte nicht gespeichert werden.',
          linkText: '',
        });
      }
    );
  }

  createOrder() {
    const createOrderDto: CreateOrderDto = new CreateOrderDto();
    createOrderDto.fkCamp = this.order.fkCamp;
    createOrderDto.fkCustomer = this.order.fkCustomer;
    createOrderDto.fkType = this.order.type.id;
    createOrderDto.isFlexible = this.order.isFlexible;
    createOrderDto.notes = this.order.notes;
    createOrderDto.prio = this.order.prio;

    createOrderDto.parts = this.order.parts.map((part) => {
      const partDto = new CreatePartDto();
      partDto.fkArticle = part.article.id;
      partDto.fkSubArticle = part.subArticle?.id || null;
      partDto.articleCount = part.articleCount;
      partDto.notes = part.notes;

      return partDto;
    });
    createOrderDto.requiredDate = this.order.requiredDate;

    if (this.order.additionalDate) {
      createOrderDto.additionalDate = this.order.additionalDate;
    }

    this.orderSrv.create(createOrderDto).subscribe(
      (order) => {
        this.order = order;
        this.alertSrv.alert.next({
          text: 'Die Bestellung wurde gespeichert.',
          linkText: '',
        });
        this.orderSrv.updateListTrigger.next();
        this.onNavigateBack();
      },
      (err) => {
        this.alertSrv.alert.next({
          text: 'Die Bestellung konnte nicht gespeichert werden.',
          linkText: '',
        });
      }
    );
  }

  onAdditionalDateToggle() {
    this.order.additionalDate = this.additionalDateActive ? new Date() : null;
  }

  onOrderTypeToggle() {
    if (!this.order.type.id) {
      this.order.type = {
        id: 1,
        name: 'Abholung',
        orders: [],
      };
    } else {
      this.order.type = {
        id: 0,
        name: 'Lieferung',
        orders: [],
      };
    }
  }

  onCampSearchInit() {
    this.campSearchActive = true;
    this.personSearchActive = false;
  }

  onPersonSearch(q: string) {
    this.filteredCustomers = this.order.camp.persons.filter((person) =>
      q.length > 0
        ? person.firstName
          .toLocaleLowerCase()
          .includes(q.toLocaleLowerCase()) ||
        person.lastName.toLocaleLowerCase().includes(q.toLocaleLowerCase())
        : person
    );
  }

  onCampSelect(camp: CampFullSizeDto) {
    this.campStr = camp.name;
    this.campSearchActive = false;
    this.campLoading = true;
    this.order.camp = camp;
    this.order.fkCamp = camp.id;
    this.selectedPerson = new PersonFullSizeDto();
    this.personStr = 'Lädt Mitarbeiter:innen...';

    this.campSrv.findById(this.order.camp.id).subscribe((camp) => {
      this.order.camp = camp;
      this.order.fkCamp = camp.id;
      this.campLoading = false;
      if (this.mode === 'internal') {
        this.personStr = '';
        this.onPersonSearch('');
      }
    });
  }

  onPersonSelect(person: PersonFullSizeDto | undefined) {
    if (person && person.customers?.length > 0) {
      this.selectedPerson = person;
      this.personSearchActive = false;
      this.personStr = `${this.selectedPerson?.firstName} ${this.selectedPerson?.lastName}`;
      this.order.customer = person.customers.find(cu => cu.fkCamp === this.order.fkCamp)!;
      this.order.fkCustomer = this.order.customer.id;
    }
  }

  onCampSearch(q: string) {
    this.campSearchActive = true;
    if (q.length > 0) {
      this.campSearchChange.next(q);
    } else {
      this.camps = this.allCamps.slice(0, MAX_CAMP_RESULTS);
      this.omitingAllCamps = true;
    }
  }

  onReset() {
    this.personStr = '';
    this.campStr = '';
    this.order = new OrderFullSizeDto();
    this.selectedPerson = new PersonFullSizeDto();
    this.campSearchActive = false;
    this.personSearchActive = false;
    this.camps = [];
    this.filteredCustomers = [];
  }

  parseDate(date: string) {
    return new Date(date);
  }

  onOpenCampTab(camp: CampFullSizeDto) {
    this.menuSrv.addTabAndOpen({
      text: `${camp.name}`,
      route: ['camps', camp?.id.toString()!],
      exact: true,
    });
  }

  onOpenPersonTab(person: PersonFullSizeDto) {
    this.menuSrv.addTabAndOpen({
      text: `${person.firstName} ${person.lastName}`,
      route: ['persons', person?.id.toString()!],
      exact: true,
    });
  }

  ngOnInit(): void {
    this.router.params.subscribe((params) => {
      if (params.orderId) {
        this.loading = true;
        this.orderSrv.findById(params.orderId).subscribe((order) => {
          this.order = order;
          this.additionalDateActive = !!this.order.additionalDate;
          this.order.requiredDate = new Date(this.order.requiredDate);

          if (this.order.id) {
            this.personStr = this.order.customer?.person ? `${this.order.customer.person.firstName} ${this.order.customer.person.lastName}` : '-';
            this.campSrv.findById(this.order.camp.id).subscribe((camp) => {
              this.order.camp = camp;
              this.order.fkCamp = camp.id;
              this.campStr = this.order.camp.name;
              this.filteredCustomers = this.order.camp.persons;
              this.onPersonSelect(this.order.customer.person);
              this.loading = false;
            });
          }
        });
      }
      if (params.campId) {
        this.mode = 'external';

        this.campSrv.findById(params.campId).subscribe((camp) => {
          this.onCampSelect(camp);
            this.onPersonSelect(this.authSrv.authContext?.person || new PersonFullSizeDto());
        });
      } else {
        this.campLoading = true;
        this.campSrv.findAll().subscribe((camps) => {
          this.campLoading = false;
          this.allCamps = camps;
          this.camps = camps.slice(0, MAX_CAMP_RESULTS);
          this.omitingAllCamps = true;
        });
      }
    });

    this.campSearchChange
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((q) => {
          return this.campSrv.findByName(q, { showArchived: false });
        })
      )
      .subscribe((camps) => {
        this.camps = camps.slice(0, MAX_CAMP_RESULTS);
        this.omitingAllCamps = camps.length > MAX_CAMP_RESULTS;
      });
  }
}
