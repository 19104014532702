import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  CreateMessageDto,
  MessageFullSizeDto,
} from '../../../../../shared/dtos';
import { OrderFullSizeDto } from '../../../../../shared/dtos';
import { PersonFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { AuthService } from '../../services/auth.service';
import { MessageService } from '../../services/message.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-order-messages',
  templateUrl: './order-messages.component.html',
  styleUrls: ['./order-messages.component.scss'],
})
export class OrderMessagesComponent implements OnInit, OnChanges {
  @Input() order: OrderFullSizeDto = new OrderFullSizeDto();
  @Output() orderChange = new EventEmitter<OrderFullSizeDto>();
  @Output() personSelected: EventEmitter<PersonFullSizeDto> =
    new EventEmitter<PersonFullSizeDto>();
  loading: boolean = false;

  newContent: string;
  editId: number = -1;

  constructor(
    private messageSrv: MessageService,
    public authSrv: AuthService,
    private orderSrv: OrderService,
    private alertSrv: AlertService,
  ) {}

  onPersonSelect(person: PersonFullSizeDto) {
    this.personSelected.emit(person);
  }

  onSend() {
    if (this.newContent && this.newContent.length > 0) {
      const dto = new CreateMessageDto();
      dto.content = this.newContent;
      dto.fkOrder = this.order.id;
      dto.fkStatus = this.order.fkStatus;

      this.messageSrv.create(dto).subscribe((messages) => {
        this.update();
        this.newContent = '';
      });
    }
  }

  onTakeOver() {
    this.orderSrv.takeOverOrder(this.order.id).subscribe({
      next: (order) => {
        this.order = order;
        this.orderChange.emit(order);
        this.update();
        this.alertSrv.alert.next({
          text: 'Bestellung wurde übernommen.',
          linkText: '',
        });
      },
      error: () => {
        this.alertSrv.alert.next({
          text: 'Das hat leider nicht geklappt.',
          linkText: '',
        });
      },
    });
  }

  onUpdate(message: MessageFullSizeDto) {
    this.messageSrv.update(message).subscribe(() => {
      this.update();
      this.editId = -1;
    });
  }

  onDelete(message: MessageFullSizeDto) {
    this.messageSrv.delete(message).subscribe(() => {
      this.update();
    });
  }

  update() {
    this.loading = true;
    this.orderSrv.findById(this.order.id).subscribe((order) => {
      this.order = order;
      this.loading = false;
    });
  }

  ngOnChanges() {
    this.update();
  }

  ngOnInit(): void {
    /*setInterval(() => {
      this.update();
    }, 10000);*/
  }
}
