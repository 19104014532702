import {
  ClassConstructor,
  ClassTransformOptions,
  plainToInstance,
} from "class-transformer";

export class TransformerWrapper {
  static plainToInstance<T, V>(
    cls: ClassConstructor<T>,
    plain: V,
    options?: ClassTransformOptions
  ): T {
    return plainToInstance(cls, plain, options);
  }

  static plainToInstanceExclude<T, V>(
    cls: ClassConstructor<T>,
    plain: V,
    options?: ClassTransformOptions
  ): T {
    return plainToInstance(cls, plain, { 
      excludeExtraneousValues: true, 
      exposeDefaultValues: false,
      enableImplicitConversion: true,
      strategy: "excludeAll",
      ...options 
    });
  }
}
