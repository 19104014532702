import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonFullSizeDto } from '../../../../../shared/dtos';
import { AuthService } from '../../services/auth.service';
import { PersonService } from '../../services/person.service';
import { firstValueFrom, Subscription } from 'rxjs';

@Component({
  selector: 'app-show-persons',
  templateUrl: './show-persons.component.html',
  styleUrls: ['./show-persons.component.scss'],
})
export class ShowPersonsComponent implements OnInit {
  persons: PersonFullSizeDto[] = [];
  selectedPerson: PersonFullSizeDto = new PersonFullSizeDto();
  loading = false;
  menuShown: boolean = true;
  selectedPersonId: number = -1;

  constructor(
    private personSrv: PersonService,
    private router: Router,
    private route: ActivatedRoute,
    public authSrv: AuthService
  ) {}

  canSeeCustomers() {
    return this.authSrv.hasRight('view customers');
  }

  navigateToNewPerson() {
    this.router.navigateByUrl('/persons/new');
  }

  onPersonSelect(person: PersonFullSizeDto) {
    this.selectedPersonId = person.id;
    this.router.navigate([
      '/persons',
      { outlets: { person: [person.id.toString()] } },
    ]);
  }

  async updatePersons() {
    this.loading = true;
    this.persons = await firstValueFrom(this.personSrv.findAll());
    this.loading = false;
  }

  async ngOnInit() {
    this.route.children[0]?.params.subscribe((params) => {
      if (!params['personId']) {
        return;
      }
      this.selectedPersonId = +params['personId'];
    });

    this.updatePersons();
  }
}
