import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PersonFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { PersonService } from '../../services/person.service';

@Component({
  selector: 'app-routing-person',
  templateUrl: './routing-person.component.html',
  styleUrls: ['./routing-person.component.scss'],
})
export class RoutingPersonComponent implements OnInit {
  loading: boolean = false;
  person: PersonFullSizeDto = new PersonFullSizeDto();

  constructor(
    private router: ActivatedRoute,
    private personSrv: PersonService,
    private alertSrv: AlertService
  ) {}

  ngOnInit(): void {
    this.router.params.subscribe((params) => {
      if (params.personId) {
        this.loading = true;
        this.personSrv.findById(params.personId).subscribe(
          (person) => {
            this.loading = false;
            this.person = person;
          },
          (err) => {
            this.loading = false;
            this.alertSrv.alert.next({
              text: `Person konnte nicht gefunden werden.`,
              linkText: '',
            });
          }
        );
      }
    });
  }
}
