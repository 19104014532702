<div class="overflow-y-hidden">
  <ul class="overflow-y-auto">
    <li
      *ngFor="let right of rights"
      class="flex px-3 py-2 items-center justify-between"
      [ngClass]="{ 'hover:bg-gray-200 cursor-pointer': mode === 'select' }"
    >
      <div class="flex flex-col">
        <span class="text-sm text-primary font-medium">{{ right.name }}</span>
        <span class="text-sm text-gray-500">{{ right.description }}</span>
      </div>
    </li>
  </ul>
  <div
    *ngIf="!rights || rights.length === 0"
    class="mt-16 flex justify-center items-center"
  >
    kein Recht
  </div>
</div>
