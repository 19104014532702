<div class="flex flex-col overflow-y-hidden h-full p-4 pr-8" *ngIf="!loading">
  <div class="flex-shrink-0">
    <h2 class="text-2xl font-bold uppercase">Bestellungsteil {{ part.id }}</h2>
    <span class="text-gray-600 text-sm">{{ part.foundCount }} / {{ part.articleCount }} Artikeln
      rausgesucht</span><br />
    <span *ngIf="part.notes" class="text-gray-600 text-sm">
      Hinweis: {{ part.notes || '-' }}
    </span>
    <div class="mt-6 sm:mt-2 2xl:mt-5">
      <div class="border-b border-gray-200">
        <div class="">
          <nav class="-mb-px flex space-x-8" aria-label="Tabs">
            <!-- Current: "border-blue-500 text-gray-900", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
            <a (click)="view = 'article'" class="
                cursor-pointer
                text-gray-500
                whitespace-nowrap
                py-4
                px-1
                border-b-2
                font-medium
                text-sm
              " [ngClass]="{
                'border-primary text-gray-900': view === 'article'
              }" aria-current="page">
              Artikel des Bestellungsteils
            </a>

            <a *ngIf="part.boxes && part.boxes.length > 0" (click)="view = 'boxes'" class="
                border-transparent
                cursor-pointer
                text-gray-500
                hover:text-gray-700
                whitespace-nowrap
                py-4
                px-1
                border-b-2
                font-medium
                text-sm
              " [ngClass]="{
                'border-primary text-gray-900': view === 'boxes'
              }">
              Kartons
            </a>
          </nav>
        </div>
      </div>
    </div>
    <div *ngIf="view === 'article' && part.article && part.article.id" class="-ml-8">
      <app-article [subArticle]="part.subArticle" [(article)]="part.article"></app-article>
    </div>
  </div>
  <div *ngIf="part.boxes && part.boxes.length > 0 && view === 'boxes'">
    <div class="overflow-y-auto gap-2 grid grid-cols-2 lg:grid-cols-3 mt-2">
      <div (click)="onBoxSelect(box)" *ngFor="let box of part.boxes" class="
          relative
          rounded-lg
          border border-gray-300
          bg-gray-50
          px-6
          py-2
          shadow-sm
          flex
          items-center
          space-x-3
          hover:border-gray-400
          focus-within:ring-2
          focus-within:ring-offset-2
          focus-within:ring-pink-500
        ">
        <div class="flex-1 min-w-0">
          <a class="focus:outline-none">
            <span class="absolute inset-0" aria-hidden="true"></span>
            <p class="text-sm font-medium text-gray-900">
              {{ box.id }}
            </p>
            <p class="text-sm text-gray-500 truncate">
              {{ box.articleCount }} Artikel
            </p>
          </a>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <app-box-details *ngIf="selectedBox && selectedBox.id" [box]="selectedBox"></app-box-details>
    </div>
  </div>
</div>
