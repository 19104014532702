import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BoxFullSizeDto } from '../../../../../shared/dtos';
import { BoxService } from '../../services/box.service';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-box-list',
  templateUrl: './box-list.component.html',
  styleUrls: ['./box-list.component.scss'],
})
export class BoxListComponent implements OnInit {
  @Input() boxes: BoxFullSizeDto[] = [];
  loading = false;

  constructor(private boxSrv: BoxService, private menuSrv: MenuService) {}

  @Input() update() {
    this.loading = true;
    this.boxSrv.findLast().subscribe(
      (boxes) => {
        this.boxes = boxes;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  onOpenBoxTab(box: BoxFullSizeDto) {
    this.menuSrv.addTabAndOpen({
      text: `Karton ${box.id}`,
      route: ['boxes', box.id.toString()],
      exact: true,
    });
  }

  ngOnInit(): void {
    this.update();
    this.boxSrv.reloadBoxList.subscribe(() => {
      this.update();
    });
  }
}
