import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AlertComponent } from './alert/alert.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ArticleDetailsComponent } from './article/article-details/article-details.component';
import { ArticleEditComponent } from './article/article-edit/article-edit.component';
import { ArticleEmptyComponent } from './article/article-empty/article-empty.component';
import { ArticleFilterComponent } from './article/article-filter/article-filter.component';
import { ArticleListComponent } from './article/article-list/article-list.component';
import { ArticleStatisticsComponent } from './article/article-orders/article-statistics.component';
import { ArticleSearchComponent } from './article/article-search/article-search.component';
import { ArticleWarehouseComponent } from './article/article-warehouse/article-warehouse.component';
import { ArticleComponent } from './article/article/article.component';
import { BlockGridComponent } from './block/block-grid/block-grid.component';
import { BoxChangeAmountComponent } from './box/box-change-amount/box-change-amount.component';
import { BoxChangeWeightComponent } from './box/box-change-weight/box-change-weight.component';
import { BoxDeleteComponent } from './box/box-delete/box-delete.component';
import { BoxDetailsComponent } from './box/box-details/box-details.component';
import { BoxExtractArticleComponent } from './box/box-extract-article/box-extract-article.component';
import { BoxInventoryComponent } from './box/box-inventory/box-inventory.component';
import { BoxListComponent } from './box/box-list/box-list.component';
import { BoxRegisterFormComponent } from './box/box-register-form/box-register-form.component';
import { BoxRegisterComponent } from './box/box-register/box-register.component';
import { BoxTakeFromPalletComponent } from './box/box-take-from-pallet/box-take-from-pallet.component';
import { BoxToNewPalletComponent } from './box/box-to-new-pallet/box-to-new-pallet.component';
import { BoxToOtherPalletComponent } from './box/box-to-other-pallet/box-to-other-pallet.component';
import { CampEditComponent } from './camp/camp-edit/camp-edit.component';
import { CampEmptyComponent } from './camp/camp-empty/camp-empty.component';
import { CampListComponent } from './camp/camp-list/camp-list.component';
import { CampOrdersLightComponent } from './camp/camp-orders-light/camp-orders-light.component';
import { CampOrdersComponent } from './camp/camp-orders/camp-orders.component';
import { CampPersonDetailsComponent } from './camp/camp-person-details/camp-person-details.component';
import { CampPersonsComponent } from './camp/camp-persons/camp-persons.component';
import { CampSearchComponent } from './camp/camp-search/camp-search.component';
import { CampComponent } from './camp/camp/camp.component';
import { GroupListComponent } from './group/group-list/group-list.component';
import { GroupComponent } from './group/group/group.component';
import { ChangeRowNumberComponent } from './hashtag-row/change-row-number/change-row-number.component';
import { HashtagRowDetailsComponent } from './hashtag-row/hashtag-row-details/hashtag-row-details.component';
import { HashtagRowListComponent } from './hashtag-row/hashtag-row-list/hashtag-row-list.component';
import { LoaderComponent } from './loader/loader.component';
import { MenuComponent } from './menu/menu.component';
import { ComposeOrderPartsComponent } from './order/compose-order-parts/compose-order-parts.component';
import { ComposeOrderComponent } from './order/compose-order/compose-order.component';
import { CollectorComponent } from './order/modals/collector/collector.component';
import { DeleteOrderComponent } from './order/modals/delete-order/delete-order.component';
import { NewBox4OrderComponent } from './order/modals/new-box4-order/new-box4-order.component';
import { PackerComponent } from './order/modals/packer/packer.component';
import { PrintOrderComponent } from './order/modals/print-order/print-order.component';
import { SenderComponent } from './order/modals/sender/sender.component';
import { OrderCampDetailsComponent } from './order/order-camp-details/order-camp-details.component';
import { OrderEmptyComponent } from './order/order-empty/order-empty.component';
import { OrderLightComponent } from './order/order-light/order-light.component';
import { OrderListLightComponent } from './order/order-list-light/order-list-light.component';
import { OrderListComponent } from './order/order-list/order-list.component';
import { OrderMapComponent } from './order/order-map/order-map.component';
import { OrderMessagesComponent } from './order/order-messages/order-messages.component';
import { OrderPackComponent } from './order/order-pack/order-pack.component';
import { OrderPartDetailsComponent } from './order/order-part-details/order-part-details.component';
import { OrderPartsComponent } from './order/order-parts/order-parts.component';
import { OrderPersonDetailsComponent } from './order/order-person-details/order-person-details.component';
import { OrderStatusComponent } from './order/order-status/order-status.component';
import { OrderComponent } from './order/order/order.component';
import { OrdersMapComponent } from './order/orders-map/orders-map.component';
import { PalletChooseWarehouseComponent } from './pallet/pallet-choose-warehouse/pallet-choose-warehouse.component';
import { PalletDetailsComponent } from './pallet/pallet-details/pallet-details.component';
import { DeletePersonComponent } from './person/modals/delete-person/delete-person.component';
import { PersonCampsComponent } from './person/person-camps/person-camps.component';
import { PersonDetailsComponent } from './person/person-details/person-details.component';
import { PersonEditComponent } from './person/person-edit/person-edit.component';
import { PersonEmptyComponent } from './person/person-empty/person-empty.component';
import { PersonGroupsComponent } from './person/person-groups/person-groups.component';
import { PersonListComponent } from './person/person-list/person-list.component';
import { PersonNewSummaryComponent } from './person/person-new-summary/person-new-summary.component';
import { PersonNewComponent } from './person/person-new/person-new.component';
import { PersonSearchComponent } from './person/person-search/person-search.component';
import { PersonComponent } from './person/person/person.component';
import { RightListComponent } from './right/right-list/right-list.component';
import { MyOrdersComponent } from './routing/my-orders/my-orders.component';
import { RegisterComponent } from './routing/register/register.component';
import { RoutingArticleComponent } from './routing/routing-article/routing-article.component';
import { RoutingBlocksComponent } from './routing/routing-blocks/routing-blocks.component';
import { RoutingBoxComponent } from './routing/routing-box/routing-box.component';
import { RoutingCampComponent } from './routing/routing-camp/routing-camp.component';
import { RoutingDashboardExternalComponent } from './routing/routing-dashboard-external/routing-dashboard-external.component';
import { RoutingDashboardComponent } from './routing/routing-dashboard/routing-dashboard.component';
import { RoutingGroupComponent } from './routing/routing-group/routing-group.component';
import { RoutingHashtagRowInventoryComponent } from './routing/routing-hashtag-row-inventory/routing-hashtag-row-inventory.component';
import { RoutingHashtagRowComponent } from './routing/routing-hashtag-row/routing-hashtag-row.component';
import { RoutingIndexComponent } from './routing/routing-index/routing-index.component';
import { RoutingOrderComponent } from './routing/routing-order/routing-order.component';
import { RoutingPalletInventoryComponent } from './routing/routing-pallet-inventory/routing-pallet-inventory.component';
import { RoutingPalletComponent } from './routing/routing-pallet/routing-pallet.component';
import { RoutingPartComponent } from './routing/routing-part/routing-part.component';
import { RoutingPersonComponent } from './routing/routing-person/routing-person.component';
import { RoutingPersonalSettingsComponent } from './routing/routing-personal-settings/routing-personal-settings.component';
import { ShowArticlesComponent } from './routing/show-articles/show-articles.component';
import { ShowCampsComponent } from './routing/show-camps/show-camps.component';
import { ShowGroupsComponent } from './routing/show-groups/show-groups.component';
import { ShowOrdersComponent } from './routing/show-orders/show-orders.component';
import { ShowPersonsComponent } from './routing/show-persons/show-persons.component';
import { SearchComponent } from './search/search.component';
import { ArticleService } from './services/article.service';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { AuthService } from './services/auth.service';
import { HhHttpService } from './services/hh-http.service';
import { WarehouseListComponent } from './warehouse/warehouse-list/warehouse-list.component';
import { TooltipDirective } from './tooltip.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { OrderListLightElemComponent } from './order/order-list-light-elem/order-list-light-elem.component';
import { TabsComponent } from './menu/tabs/tabs.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReleaseNotesComponent } from './menu/release-notes/release-notes.component';
import { RoutingNotFoundComponent } from './routing/routing-not-found/routing-not-found.component';
import { OrderStatusTabsComponent } from './order/order-status-tabs/order-status-tabs.component';
import {
  ApmErrorHandler,
  ApmModule,
  ApmService,
} from '@elastic/apm-rum-angular';
import { LoginComponent } from './login/login.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

const initializeKeycloak = (keycloakSrv: KeycloakService) => {
  return () =>
    keycloakSrv.init({
      config: {
        url: environment.keycloakRoot,
        realm: 'hhelp',
        clientId: 'hhelp-frontend',
      },
      initOptions: {
        checkLoginIframe: false,
      },
      loadUserProfileAtStartUp: false,
    });
};

@NgModule({ declarations: [
        AppComponent,
        RoutingIndexComponent,
        ShowArticlesComponent,
        ArticleComponent,
        ArticleListComponent,
        ArticleSearchComponent,
        ArticleDetailsComponent,
        ArticleWarehouseComponent,
        ArticleStatisticsComponent,
        HashtagRowDetailsComponent,
        PalletDetailsComponent,
        BoxDetailsComponent,
        ShowOrdersComponent,
        OrderListComponent,
        OrderComponent,
        OrderPartsComponent,
        OrderStatusComponent,
        OrderMessagesComponent,
        OrderPartDetailsComponent,
        ShowCampsComponent,
        CampComponent,
        CampListComponent,
        CampPersonsComponent,
        CampOrdersComponent,
        CampSearchComponent,
        CampPersonDetailsComponent,
        PersonComponent,
        PersonDetailsComponent,
        PersonGroupsComponent,
        OrderCampDetailsComponent,
        OrderPersonDetailsComponent,
        ComposeOrderComponent,
        ComposeOrderPartsComponent,
        RoutingDashboardComponent,
        PersonListComponent,
        ShowPersonsComponent,
        GroupListComponent,
        PersonCampsComponent,
        PersonSearchComponent,
        LoaderComponent,
        MenuComponent,
        ArticleEditComponent,
        CampEditComponent,
        PersonEditComponent,
        ArticleEmptyComponent,
        PersonEmptyComponent,
        CampEmptyComponent,
        OrderEmptyComponent,
        AlertComponent,
        OrderMapComponent,
        OrdersMapComponent,
        PersonNewComponent,
        PersonNewSummaryComponent,
        CollectorComponent,
        PackerComponent,
        SenderComponent,
        BoxRegisterComponent,
        RegisterComponent,
        ArticleFilterComponent,
        BoxRegisterFormComponent,
        BoxListComponent,
        RoutingBlocksComponent,
        BlockGridComponent,
        WarehouseListComponent,
        HashtagRowListComponent,
        OrderPackComponent,
        RoutingBoxComponent,
        RoutingArticleComponent,
        RoutingPalletComponent,
        RoutingHashtagRowComponent,
        RoutingHashtagRowInventoryComponent,
        RoutingPalletInventoryComponent,
        PalletChooseWarehouseComponent,
        BoxExtractArticleComponent,
        BoxChangeAmountComponent,
        BoxChangeWeightComponent,
        BoxToNewPalletComponent,
        BoxToOtherPalletComponent,
        BoxTakeFromPalletComponent,
        RoutingOrderComponent,
        RoutingCampComponent,
        RoutingPersonComponent,
        RoutingPartComponent,
        NewBox4OrderComponent,
        DeletePersonComponent,
        ChangeRowNumberComponent,
        ShowGroupsComponent,
        GroupComponent,
        RoutingGroupComponent,
        RightListComponent,
        BoxDeleteComponent,
        PrintOrderComponent,
        DeleteOrderComponent,
        BoxInventoryComponent,
        SearchComponent,
        RoutingPersonalSettingsComponent,
        MyOrdersComponent,
        OrderListLightComponent,
        CampOrdersLightComponent,
        RoutingDashboardExternalComponent,
        OrderLightComponent,
        TooltipDirective,
        ClickOutsideDirective,
        OrderListLightElemComponent,
        TabsComponent,
        ReleaseNotesComponent,
        RoutingNotFoundComponent,
        OrderStatusTabsComponent,
    ],
    bootstrap: [AppComponent], imports: [ApmModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        GoogleMapsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        LoginComponent,
        KeycloakAngularModule], providers: [
        HhHttpService,
        ArticleService,
        AuthService,
        {
            provide: ErrorHandler,
            useClass: ApmErrorHandler,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService],
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
  constructor(service: ApmService) {
    // Agent API is exposed through this apm instance
    const apm = service.init({
      serviceName: 'logistic_frontend_' + environment.envName,
      serverUrl: 'https://apm.hanseatic-help.org',
    });
  }
}
