import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { ArticleFullSizeDto } from '../../../../../shared/dtos';
import { ArticleService } from 'src/app/services/article.service';

@Component({
  selector: 'app-article-search',
  templateUrl: './article-search.component.html',
  styleUrls: ['./article-search.component.scss'],
})
export class ArticleSearchComponent implements OnInit {
  @Output() articlesChanged: EventEmitter<ArticleFullSizeDto[]> =
    new EventEmitter();
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  q: string = '';
  showFilters = false;
  @Input() showOnlyAddable = false;
  @Input() showOnlyNotAddable = false;
  @Input() showDisabled = false;
  inputChange: Subject<string> = new Subject();

  constructor(private articleSrv: ArticleService) {}

  toggleFilter() {
    this.showFilters = !this.showFilters;
  }

  onSearch(q: string) {
    this.inputChange.next(q);
  }

  onSearchReset() {
    this.articleSrv.findAll().subscribe((articles) => {
      this.articlesChanged.emit(articles);
    });

    this.q = '';
  }

  ngOnInit(): void {
    this.articleSrv.updateTrigger.subscribe(() => {
      this.inputChange.next(this.q);
    });

    this.inputChange
      .pipe(
        debounceTime(200),
        switchMap((q) => {
          this.loading.emit(true);
          return this.articleSrv.findByName(q, {
            showDisabled: this.showDisabled,
            showOnlyAddable: this.showOnlyAddable,
            showOnlyNotAddable: this.showOnlyNotAddable,
          });
        }),
      )
      .subscribe({
        next: (articles) => {
          this.articlesChanged.emit(articles);
          this.loading.emit(false);
        },
        error: (err) => {
          this.loading.emit(false);
        },
      });
  }
}
