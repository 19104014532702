import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderFullSizeDto, RightFullSizeDto } from '../../../../../shared/dtos';
import { CampFullSizeDto } from '../../../../../shared/dtos';
import { CampService } from '../../services/camp.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-camp-orders',
  templateUrl: './camp-orders.component.html',
  styleUrls: ['./camp-orders.component.scss'],
})
export class CampOrdersComponent implements OnInit {
  @Input() campId: number;
  selectedOrder: OrderFullSizeDto = new OrderFullSizeDto();

  orders: OrderFullSizeDto[] = [];

  constructor(
    private orderSrv: OrderService,
  ) {}

  ngOnInit(): void {
    this.orderSrv.findByCampId(this.campId).subscribe((orders) => {
      this.orders = orders;
    });
  }
}
