import { Expose } from "class-transformer";
import { IsEmail, IsNumber, IsOptional, IsString, MaxLength, MinLength } from "class-validator";

export class UpdatePersonDto {

  @IsNumber()
  @IsOptional()
  @Expose()
  fkGender: number | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  title: string | null = null;

  @IsString()
  @Expose()
  @IsOptional()
  firstName: string = '';

  @IsString()
  @Expose()
  @IsOptional()
  lastName: string = '';

  @IsString()
  @IsOptional()
  @Expose()
  company: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  phone_1: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  phone_2: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  eMail_1: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  eMail_2: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  street: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  city: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  /* @MinLength(4)
  @MaxLength(5) */
  postcode: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  notes: string | null = null;

}