import { Expose } from "class-transformer";
import { IsNumber, IsOptional, IsString } from "class-validator";

export class CreateMessageDto {

  @IsNumber()
  @Expose()
  fkOrder: number;

  @IsNumber()
  @Expose()
  fkStatus: number;

  @IsString()
  @IsOptional()
  @Expose()
  content?: string;
}