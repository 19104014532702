import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PersonFullSizeDto } from '../../../../../shared/dtos';

@Component({
  selector: 'app-order-person-details',
  templateUrl: './order-person-details.component.html',
  styleUrls: ['./order-person-details.component.scss'],
})
export class OrderPersonDetailsComponent implements OnInit {
  @Input() person: PersonFullSizeDto = new PersonFullSizeDto();
  @Output() personChange: EventEmitter<PersonFullSizeDto> = new EventEmitter<PersonFullSizeDto>();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  onClose() {
    this.close.emit();
  }

  constructor() {}

  ngOnInit(): void {}
}
