import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {PersonFullSizeDto} from '../../../../shared/dtos';
import {AuthService} from '../services/auth.service';
import {MenuService} from '../services/menu.service';
import {SearchService} from '../services/search.service';
import {Route, routes} from './routes';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  routes: Route[] = routes;
  additionalRoutes: Route[] = routes.filter((route) => !route.showOnTopMenu);
  visible: boolean = true;
  activeRoute: string[] = [];
  accountModalShown: boolean = false;
  searchVisible: boolean = false;
  @Output() search: EventEmitter<void> = new EventEmitter<void>();
  mobileMenuShown: boolean = false;
  additionalMenuShown: boolean = false;
  searchPlaceholder: string = '';
  envName = environment.envName;

  outsideRoutes: string[] = ['login', 'resetPw', 'newPw', '404'];

  constructor(
    public authSrv: AuthService,
    private router: Router,
    private menuSrv: MenuService,
    private searchSrv: SearchService
  ) {
  }

  onLogout() {
    this.authSrv.logout();
  }

  onSearch() {
    this.searchSrv.searchVisible.next(!this.searchVisible);
  }

  onMobileMenuToggle() {
    this.mobileMenuShown = !this.mobileMenuShown;
  }

  onAdditionalMenuToggle() {
    this.additionalMenuShown = !this.additionalMenuShown;
  }

  filterRoutes(routes: Route[]): Route[] {
    return routes.filter((route) => {
      return (
        !route.showOnTopMenu &&
        ((route.rightText && this.authSrv.hasRight(route.rightText)) ||
          !route.rightText)
      );
    });
  }

  ngOnInit(): void {

    this.searchPlaceholder = navigator.userAgent.includes('Mac') ? '⌘ K' : 'Strg+K';


    this.routes = routes.filter(
      (route) =>
        (route.rightText && this.authSrv.hasRight(route.rightText)) ||
        !route.rightText
    );

    this.additionalRoutes = routes.filter((route) => !route.showOnTopMenu);

    this.searchSrv.searchVisible.subscribe(
      (searchVisible) => (this.searchVisible = searchVisible)
    );

    this.router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe((event) => {
        this.visible = !this.outsideRoutes.includes(event.urlAfterRedirects.split('/')[1]);
      });

    this.menuSrv.activeRoute.subscribe(
      (activeRoute) => (this.activeRoute = activeRoute)
    );
  }
}
