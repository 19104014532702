<label class="block text-sm font-medium text-gray-700" for="newAmount"
  >Palettennummer angeben:</label
>
<input name="newAmount" class="ip" type="number" [(ngModel)]="newPalletId" />
<button
  (click)="onSave()"
  class="
    mt-2
    uppercase
    text-sm
    font-bold
    py-2
    px-4
    rounded-lg
    shadow-md
    cursor-pointer
    bg-white
    hover:bg-gray-100
    text-primary
    active:bg-primary active:text-white
  "
>
  Auf Palette {{ newPalletId || "" }}
</button>
