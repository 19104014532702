import { IsArray, IsNumber, IsObject, IsOptional, IsString, ValidateNested } from "class-validator";
import { ArticleFullSizeDto } from "./article.full-size.dto";
import { BoxFullSizeDto } from "./box.full-size.dto";
import { Expose, Type } from "class-transformer";


export class SubArticleFullSizeDto {

  @IsNumber()
  @Expose()
  id: number;

  @IsString()
  @Expose()
  name: string = '';

  @IsNumber()
  @Expose()
  fkArticle: number = 0;

  @IsNumber()
  @IsOptional()
  @Expose()
  minRequired: number | null = null;

  @IsArray()
  @Type(() => BoxFullSizeDto)
  @ValidateNested({each: true})
  @Expose()
  boxes: BoxFullSizeDto[] = [];

  @IsObject()
  @Type(() => ArticleFullSizeDto)
  @ValidateNested()
  @Expose()
  article: ArticleFullSizeDto = new ArticleFullSizeDto();
}
