<div class="h-screenExceptMenu flex overflow-hidden">
  <div *ngIf="orderSearchBoxOpen" class="z-20 absolute inset-0 opacity-50 bg-gray-800"></div>
  <div *ngIf="orderSearchBoxOpen" class="absolute z-30 inset-0 flex justify-center items-center">
    <div class="w-96 max-w-fullExceptFold shadow-lg">
      <input #orderSearch class="border border-gray-300 rounded-md w-full focus:ring-primary" type="number"
        name="orderSearch" [(ngModel)]="searchId" placeholder="Bestellungsnummer eingeben"
        (keydown.enter)="onOrderSearch()" />
    </div>
  </div>
  <div class="flex-col min-w-0 flex-1 overflow-hidden flex">
    <div class="relative z-0 flex overflow-hidden h-full">
      <div class="
          h-screenExceptMenu
          flex
          justify-center
          items-center
          cursor-pointer
          lg:hidden
        " (click)="menuShown = !menuShown">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" [ngClass]="{ 'transform rotate-180': menuShown }"
          fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
        </svg>
      </div>
      <aside class="
          lg:flex
          flex-col flex-shrink-0
          w-96
          max-w-fullExceptFold
          resize-x
          overflow-auto
          print:hidden
          border-r border-gray-200
          animate-slideInFromLeft
          lg:animate-none
        " [ngClass]="{ hidden: !menuShown, flex: menuShown }">
        <div class="px-6 pt-6">
          <div class="flex justify-between items-center">
            <div class="flex flex-col">
              <h2 class="
                  text-md
                  sm:text-xl
                  font-bold
                  text-gray-900
                  uppercase
                ">
                Bestellungen
              </h2>
              <p class="mt-1 text-sm text-gray-600">
                {{ filteredOrders.length }} gefunden
              </p>
            </div>
            <div class="flex space-x-1">
              <button [tooltip]="'Filter ' + (enableFilers ? 'ausblenden' : 'einblenden')"
                (click)="enableFilers = !enableFilers; update()" [ngClass]="{ 'bg-gray-200': enableFilers }" class="
                  inline-flex
                  justify-center
                  items-center
                  px-2
                  py-2
                  border border-gray-300
                  shadow-sm
                  text-sm
                  font-medium
                  rounded-md
                  text-gray-700
                  bg-white
                  hover:bg-gray-300
                  button-animation
                ">
                <!-- Heroicon name: solid/filter -->
                <svg class="h-5 w-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                  fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                    clip-rule="evenodd" />
                </svg>
              </button>
              <button [tooltip]="'Orgasuche ' + (showSearch ? 'ausblenden' : 'einblenden')"
                (click)="showSearch = !showSearch; update()" [ngClass]="{ 'bg-gray-200': showSearch }" class="
                  inline-flex
                  justify-center
                  items-center
                  px-2
                  py-2
                  border border-gray-300
                  shadow-sm
                  text-sm
                  font-medium
                  rounded-md
                  text-gray-700
                  bg-white
                  hover:bg-gray-300
                  button-animation
                ">
                <!-- Heroicon name: solid/search -->
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </button>
              <button tooltip="Neue Bestellung erstellen"
                [routerLink]="['/orders', { outlets: { order: ['compose'] } }]" class="
                  inline-flex
                  justify-center
                  items-center
                  px-2
                  py-2
                  border border-gray-300
                  shadow-sm
                  text-sm
                  font-medium
                  rounded-md
                  text-gray-700
                  bg-white
                  hover:bg-gray-300
                  button-animation
                ">
                <!-- Heroicon name: solid/add -->
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                </svg>
              </button>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-2 mt-4" *ngIf="enableFilers">
            <div class="relative flex items-start">
              <div class="flex items-center h-5">
                <input id="collection" name="collection" type="checkbox" [(ngModel)]="showCollections"
                  (ngModelChange)="update()" class="
                    focus:ring-primary
                    h-4
                    w-4
                    text-primary
                    border-gray-300
                    rounded
                  " />
              </div>
              <div class="ml-3 text-sm">
                <label for="collection" class="font-medium text-gray-700">Abholungen</label>
              </div>
            </div>
            <div class="relative flex items-start">
              <div class="flex items-center h-5">
                <input id="showDeliveries" name="showDeliveries" type="checkbox" [(ngModel)]="showDeliveries"
                  (ngModelChange)="update()" class="
                    focus:ring-primary
                    h-4
                    w-4
                    text-primary
                    border-gray-300
                    rounded
                  " />
              </div>
              <div class="ml-3 text-sm">
                <label for="showDeliveries" class="font-medium text-gray-700">Lieferungen</label>
              </div>
            </div>
            <div class="relative flex items-start">
              <div class="flex items-center h-5">
                <input id="showPrioOnly" name="showPrioOnly" type="checkbox" [(ngModel)]="showPrioOnly"
                  (ngModelChange)="update()" class="
                    focus:ring-primary
                    h-4
                    w-4
                    text-primary
                    border-gray-300
                    rounded
                  " />
              </div>
              <div class="ml-3 text-sm">
                <label for="showPrioOnly" class="font-medium text-gray-700">nur Eilbestellungen
                  anzeigen</label>
              </div>
            </div>
          </div>
          <div *ngIf="showSearch">
            <div class="mt-2 relative rounded-md shadow-sm">
              <div class="
                  absolute
                  inset-y-0
                  left-0
                  pl-3
                  flex
                  items-center
                  pointer-events-none
                ">
                <!-- Heroicon name: solid/mail -->
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </div>
              <input type="text" name="q" id="q" [(ngModel)]="q" (ngModelChange)="update()" class="
                  focus:ring-primar focus:border-primary
                  block
                  w-full
                  pl-10
                  sm:text-sm
                  border-gray-300
                  rounded-md
                " placeholder="nach Orgas filtern" />
            </div>
          </div>
        </div>

        <div class="
            mt-6
            sm:mt-4
            2xl:mt-5
            border-b border-gray-200
          ">
          <app-order-status-tabs [(statusId)]="statusId" (statusIdChange)="setStatusId(statusId)"></app-order-status-tabs>
        </div>

        <!-- Directory list -->
        <nav class="flex-1 min-h-0 overflow-y-auto" aria-label="Directory">
          <div class="relative">
            <!-- List -->
            <app-loader *ngIf="loading"></app-loader>
            <app-order-list *ngIf="!loading" [orders]="filteredOrders" [selectedOrderId]="selectedOrder.id"
              (orderSelect)="onOrderSelect($event); menuShown = false"></app-order-list>
          </div>
        </nav>
      </aside>
      <div class="flex flex-col h-full flex-grow overflow-y-auto">
        <router-outlet name="order"></router-outlet>
      </div>
    </div>
  </div>
</div>
