import {Component, OnInit} from '@angular/core';
import {AlertService} from './services/alert.service';
import {AuthService} from './services/auth.service';
import {MenuService} from './services/menu.service';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {filter} from 'rxjs';
import pkg from '../../package.json';
import {VERSION} from './localStorageConsts';
import {Router} from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { TokenAuthContext } from '../../../shared/dtos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    public authSrv: AuthService,
    private alertSrv: AlertService,
    private menuSrv: MenuService,
    private router: Router,
    private keycloakSrv: KeycloakService,
    swUpdate: SwUpdate,
  ) {
    swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(evt => {
        console.log(evt);
        this.swUpdatePromptOpen = true;
      });
  }

  tabsOpen: boolean = false;
  swUpdatePromptOpen: boolean = false;
  searchVisible: boolean = false;
  appVersion: string = pkg.version;
  releaseNotesShown: boolean = false;
  alertTimeout: ReturnType<typeof setTimeout> | null = null;

  alert: { text: string; linkText: string } = {text: '', linkText: ''};

  onTabMinimize() {
    this.menuSrv.tabsOpen.next(false);
  }

  onTabClose() {
    this.menuSrv.closeCurrentTab();
    this.onTabMinimize();
  }

  onSearch() {
    this.searchVisible = true;
  }

  onUpdate() {
    window.location.reload();
  }

  showReleaseNotes() {
    const localStorageVersion = localStorage.getItem(VERSION);

    if (((this.appVersion !== localStorageVersion && this.appVersion !== 'TEST'))) {
      this.releaseNotesShown = true;
      localStorage.setItem(VERSION, this.appVersion);
    }
  }

  async ngOnInit() {

    if (window.localStorage.getItem('access_token')) {
      this.authSrv.setToken(window.localStorage.getItem('access_token') + '');
      await this.authSrv.updateMyRights();
    } else {
      await this.authSrv.autoLogin();
    }

    if (localStorage.getItem("TABS")) {
      this.menuSrv.setTabs(JSON.parse(localStorage.getItem("TABS") + ''));
    }

    this.menuSrv.tabsOpen.subscribe((tabsOpen) => (this.tabsOpen = tabsOpen));

    this.alertSrv.alert.asObservable().subscribe((alert) => {
      this.alert = alert;
      this.alertTimeout && clearTimeout(this.alertTimeout);
      this.alertTimeout = setTimeout(() => {
        this.alertSrv.alert.next({text: '', linkText: ''});
      }, 6000);
    });
  }
}
