<div class="flex overflow-y-hidden h-full">
  <div
  *ngIf="!this.authSrv.hasRight('manage users')"
    class="flex-1 border-r border-gray-300 flex flex-col h-full overflow-y-auto"
  >
    <h2 class="text-lg font-bold p-4">Organisationen</h2>
    <app-camp-list
      mode="select"
      [camps]="personCamps"
    ></app-camp-list>
  </div>
  <div
  *ngIf="this.authSrv.hasRight('manage users')"
    class="flex-1 border-r border-gray-300 flex flex-col h-full overflow-y-auto"
  >
    <h2 class="text-lg font-bold p-4">Organisation entfernen</h2>
    <app-camp-list
      mode="remove"
      (campSelect)="onCampRemove($event)"
      [camps]="personCamps"
    ></app-camp-list>
  </div>
  <div *ngIf="this.authSrv.hasRight('manage users')" class="flex-1 flex flex-col h-full overflow-y-hidden">
    <h2 class="text-lg font-bold p-4">Organisation hinzufügen</h2>
    <div class="mx-2">
      <app-camp-search (campsChanged)="camps = $event"></app-camp-search>
    </div>
    <div *ngIf="!loading" class="overflow-y-auto h-full">
      <app-camp-list
        mode="add"
        (campSelect)="onCampAdd($event)"
        [camps]="camps"
      ></app-camp-list>
    </div>
    <app-loader *ngIf="loading"></app-loader>
  </div>
</div>
