import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ArticleFullSizeDto, UpdateArticleDto, CreateArticleDto } from '../../../../shared/dtos';
import { HhHttpService } from './hh-http.service';
import { Subject } from 'rxjs';
import { TransformerWrapper } from '../../../../shared/src/transformer';

@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  constructor(private httpSrv: HhHttpService) {}

  updateTrigger: Subject<void> = new Subject<void>();

  findAll() {
    return this.httpSrv.get<ArticleFullSizeDto[]>('articles');
  }

  findById(id: number) {
    return this.httpSrv.get<ArticleFullSizeDto>(`articles/${id}`);
  }

  findByName(
    name: string,
    options: {
      showOnlyAddable: boolean;
      showOnlyNotAddable: boolean;
      showDisabled: boolean;
    },
  ) {
    return this.httpSrv.get<ArticleFullSizeDto[]>(
      `articles/q/${name}?showOnlyAddable=${
        options.showOnlyAddable ? 1 : 0
      }&showOnlyNotAddable=${options.showOnlyNotAddable ? 1 : 0}&showDisabled=${
        options.showDisabled ? 1 : 0
      }`,
    );
  }

  getSentItems() {
    return this.httpSrv.get<{ sum: number; sumPretty: string }>(
      `articles/sent`,
    );
  }

  update(articleId: number, article: UpdateArticleDto) {
    return this.httpSrv
      .patch<ArticleFullSizeDto>(
        `articles/${articleId}`,
        TransformerWrapper.plainToInstanceExclude(UpdateArticleDto, article),
      )
      .pipe(
        map((elem) =>
          TransformerWrapper.plainToInstanceExclude(ArticleFullSizeDto, elem),
        ),
      );
  }

  create(article: CreateArticleDto) {
    return this.httpSrv
      .post<ArticleFullSizeDto>(`articles`, TransformerWrapper.plainToInstanceExclude(CreateArticleDto, article))
      .pipe(map((elem) => Object.assign(new ArticleFullSizeDto(), elem)));
  }
}
