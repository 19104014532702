import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BoxFullSizeDto, CreateBoxDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { BoxService } from '../../services/box.service';
import { TransformerWrapper } from '../../../../../shared/src/transformer';

@Component({
  selector: 'app-box-extract-article',
  templateUrl: './box-extract-article.component.html',
  styleUrls: ['./box-extract-article.component.scss'],
})
export class BoxExtractArticleComponent implements OnInit {
  @Input() box: BoxFullSizeDto = new BoxFullSizeDto();
  @Output() boxChange: EventEmitter<BoxFullSizeDto> = new EventEmitter();
  newBox: CreateBoxDto = new CreateBoxDto();

  constructor(private boxSrv: BoxService, private alertSrv: AlertService) {}

  onSave() {
    const newArticleCount =
      (this.box.articleCount || 0) - (this.newBox.articleCount || 0);
    if (newArticleCount <= 0) {
      this.alertSrv.alert.next({
        text: 'Im alten Karton muss mindestens ein Artikel bleiben!',
        linkText: '',
      });
    } else {
      this.box.articleCount = newArticleCount;
      this.boxSrv.update(this.box).subscribe(
        (box) => {
          this.box = box;
          this.boxChange.emit(this.box);
          const newBoxArticleCount = this.newBox.articleCount;
          this.newBox = TransformerWrapper.plainToInstanceExclude(CreateBoxDto, this.box);
          this.newBox.articleCount = newBoxArticleCount;

          this.boxSrv.create(this.newBox).subscribe((newBox) => {
            this.alertSrv.alert.next({
              text: `Karton ${newBox.id} wurde erstellt.`,
              linkText: '',
            });
          });
        },
        (err) => {
          this.alertSrv.alert.next({
            text: 'Karton konnte nicht gespeichert werden.',
            linkText: '',
          });
        }
      );
    }
  }

  ngOnInit(): void {}
}
