import {
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderFullSizeDto } from '../../../../../shared/dtos';
import { ComposeOrderComponent } from '../../order/compose-order/compose-order.component';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-show-orders',
  templateUrl: './show-orders.component.html',
  styleUrls: ['./show-orders.component.scss'],
})
export class ShowOrdersComponent implements OnInit, OnChanges {
  loading = false;
  orders: OrderFullSizeDto[] = [];
  filteredOrders: OrderFullSizeDto[] = [];
  selectedOrder: OrderFullSizeDto = new OrderFullSizeDto();
  enableFilers = false;
  showSearch = false;
  showCollections = true;
  showDeliveries = true;
  showPrioOnly = false;
  statusId = 1;
  q: string = '';
  mode: string = '';
  menuShown: boolean = true;

  @ViewChild('orderSearch', { static: false }) orderSearch!: ElementRef;
  @ViewChild('orderSearch') set assetInput(elRef: ElementRef) {
    this.orderSearch = elRef;
  }

  @ViewChild(ComposeOrderComponent, { static: true })
  composeOrderComponent: ComposeOrderComponent | undefined;
  orderSearchBoxOpen = false;
  searchId: string = '';

  constructor(
    private orderSrv: OrderService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  onOrderAdd() {
    this.mode = 'orderAdd';
  }

  onOrderSearchBoxToggle() {
    this.searchId = '';
    this.orderSearchBoxOpen = !this.orderSearchBoxOpen;
    setTimeout(() => {
      this.orderSearch.nativeElement.focus();
    });
  }

  onOrderSearch() {
    const order = new OrderFullSizeDto();
    order.id = parseInt(this.searchId);
    this.orderSearchBoxOpen = false;
    this.onOrderSelect(order);
  }

  onOrderSelect(order: OrderFullSizeDto) {
    this.selectedOrder = order;
    this.router.navigate([
      '/orders',
      { outlets: { order: [order.id.toString()] } },
    ]);
  }

  onResetOrder() {
    this.selectedOrder = new OrderFullSizeDto();
    if (this.composeOrderComponent) {
      this.composeOrderComponent.onReset();
    }
  }

  setStatusId(statusId: number) {
    this.statusId = statusId;
    this.filter();
  }

  filter() {
    const orderTypeIds: number[] = [];

    if (this.showDeliveries) {
      orderTypeIds.push(0);
    }

    if (this.showCollections) {
      orderTypeIds.push(1);
    }

    this.filteredOrders = this.orders
      .filter((order) => order.fkStatus === this.statusId)
      .filter((order) =>
        this.showSearch ? order.camp.name.toLowerCase().includes(this.q.toLowerCase())
          || order.camp.street?.toLowerCase().includes(this.q.toLowerCase()) : order
      )
      .filter((order) => {
        return !this.enableFilers
          ? order
          : orderTypeIds.includes(order.fkType) &&
          (this.showPrioOnly ? order.prio : true);
      });
  }

  updateOrders() {
    this.loading = true;
    this.orderSrv.findAll().subscribe((orders) => {
      this.orders = orders;
      this.filter();
      this.loading = false;
    });
  }

  ngOnInit(): void {
    this.route.children[0]?.params.subscribe((params) => {
      console.log(params);  
      if (!params['orderId'] || !this.selectedOrder) {
        return;
      }
      this.selectedOrder.id = +params['orderId'];
    });
    
    this.updateOrders();
    this.orderSrv.updateListTrigger.subscribe(() => {
      this.updateOrders();
    });
  }

  update() {
    this.filter();
  }

  ngOnChanges() {
    this.update();
  }
}
