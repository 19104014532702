import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ArticleFullSizeDto, GenderLagerFullSizeDto } from '../../../../../shared/dtos';
import { BoxFullSizeDto } from '../../../../../shared/dtos';
import { CategoryFullSizeDto } from '../../../../../shared/dtos';
import { GenderFullSizeDto } from '../../../../../shared/dtos';
import { SizeFullSizeDto } from '../../../../../shared/dtos';
import { SubArticleFullSizeDto } from '../../../../../shared/dtos';
import { ArticleService } from '../../services/article.service';
import { BoxService } from '../../services/box.service';

@Component({
  selector: 'app-box-register',
  templateUrl: './box-register.component.html',
  styleUrls: ['./box-register.component.scss'],
})
export class BoxRegisterComponent implements OnInit {
  articles: ArticleFullSizeDto[] = [];
  filteredArticles: ArticleFullSizeDto[] = [];
  selectedGender: GenderLagerFullSizeDto = new GenderLagerFullSizeDto();
  selectedCategory: CategoryFullSizeDto = new CategoryFullSizeDto();
  selectedSize: SizeFullSizeDto = new SizeFullSizeDto();
  selectedArticle: ArticleFullSizeDto = new ArticleFullSizeDto();
  selectedSubArticle: SubArticleFullSizeDto = new SubArticleFullSizeDto();
  lastBoxes: BoxFullSizeDto[] = [];
  printUrl: string = '';
  loading: boolean = false;

  constructor(
    private articleSrv: ArticleService,
    private boxSrv: BoxService
  ) {}

  onSearchUpdate(articles: ArticleFullSizeDto[]) {
    this.articles = articles;
    this.filterArticles();
  }

  filterArticles() {
    this.filteredArticles = this.articles.filter((article) => {
      return (
        (this.selectedGender.id === 0 ||
          article.gender?.id === this.selectedGender.id) &&
        (this.selectedCategory.id === 0 ||
          article.category?.id === this.selectedCategory.id) &&
        (this.selectedSize.id === 0 ||
          article.size?.id === this.selectedSize.id)
      );
    });
  }

  onArticleSelect(article: ArticleFullSizeDto) {
    this.selectedArticle = article;
    this.selectedSubArticle = new SubArticleFullSizeDto();
  }

  onSubArticleSelect(subArticle: SubArticleFullSizeDto) {
    this.selectedSubArticle = subArticle;
    this.selectedArticle = subArticle.article;
  }

  onBoxCreate(box: BoxFullSizeDto) {
    //this.lastBoxes.push(box);
    this.boxSrv.reloadBoxList.next();
    this.printUrl = `${environment.apiRoot}box/${box.id}.pdf`;
  }

  ngOnInit(): void {
    this.loading = true;
    this.articleSrv
      .findByName('', {
        showDisabled: false,
        showOnlyAddable: true,
        showOnlyNotAddable: false,
      })
      .subscribe((articles) => {
        this.loading = false;
        this.articles = articles;
        this.filteredArticles = articles;
      });
  }
}
