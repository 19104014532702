<app-loader *ngIf="loading"></app-loader>
<div
  *ngIf="!loading"
  class="animate-fadeIn flex flex-col space-y-4 pt-4 h-full overflow-y-auto"
>
  <div *ngFor="let warehouse of warehouses">
    <h2 class="font-bold uppercase text-lg text-gray-700 sticky">
      {{ warehouse.name }}
    </h2>
    <div class="mt-2">
      <app-block-grid
        (blockSelected)="onBlockSelected($event)"
        [warehouse]="warehouse"
      ></app-block-grid>
    </div>
  </div>
</div>
