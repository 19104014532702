<app-loader *ngIf="loading"></app-loader>
<div
  class="flex flex-col space-y-6"
  *ngIf="!loading && hashtagRow && hashtagRow.id"
>
  <app-hashtag-row-details
    (palletSelected)="pallet = $event; box = null"
    [rowId]="hashtagRow.id"
  ></app-hashtag-row-details>

  <app-pallet-details
    *ngIf="pallet && pallet.id"
    [pallet]="pallet"
    (boxSelected)="box = $event"
  ></app-pallet-details>

  <app-box-details *ngIf="box && box.id" [box]="box"></app-box-details>
</div>
