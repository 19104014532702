import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DepartmentFullSizeDto,
  UpdatePersonDto,
} from '../../../../../shared/dtos';
import { PersonFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { PersonService } from '../../services/person.service';
import { TransformerWrapper } from '../../../../../shared/src/transformer';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-person-edit',
  templateUrl: './person-edit.component.html',
  styleUrls: ['./person-edit.component.scss'],
})
export class PersonEditComponent implements OnInit {
  @Input() person: PersonFullSizeDto = new PersonFullSizeDto();
  @Output() personChange: EventEmitter<PersonFullSizeDto> =
    new EventEmitter<PersonFullSizeDto>();
  @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  loading: boolean = false;
  departments: DepartmentFullSizeDto[] = [];
  duplicateEmail: boolean | null = null;
  formValid: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private personSrv: PersonService,
    private alertSrv: AlertService,
  ) {}

  validate() {
    this.formValid =
      this.person.firstName?.length > 0 &&
      this.person.lastName?.length > 0 &&
      !this.duplicateEmail;
    this.isValid.emit(this.formValid);
  }

  async checkDuplicateEmail() {
    if (this.person.eMail_1) {
      try {
        const existingPerson = await firstValueFrom(
          this.personSrv.findByEmail(this.person.eMail_1),
        );
        this.duplicateEmail =
          existingPerson && existingPerson.id !== this.person.id;
      } catch (err) {
        this.duplicateEmail = false;
      }
    } else {
      this.duplicateEmail = null;
    }
    this.validate();
  }

  onSave() {
    if (this.person.id) {
      this.personSrv
        .update(
          this.person.id,
          TransformerWrapper.plainToInstanceExclude(
            UpdatePersonDto,
            this.person,
          ),
        )
        .subscribe(
          (person) => {
            this.person = person;
            this.alertSrv.alert.next({
              text: 'Die Person wurde gespeichert.',
              linkText: '',
            });
            this.personSrv.updateTrigger.next();
            this.router.navigate([
              '/persons',
              { outlets: { person: [person.id.toString()] } },
            ]);
          },
          (err) => {
            this.alertSrv.alert.next({
              text: 'Fehler beim Speichern der Person.',
              linkText: '',
            });
          },
        );
    } else {
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params.personId) {
        this.loading = true;
        this.personSrv.findById(params.personId).subscribe((person) => {
          this.person = person;
          this.loading = false;
        });
      }
    });
  }
}
