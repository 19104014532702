import { IsArray, IsDateString, IsNumber, IsOptional, IsString, ValidateNested } from "class-validator";
import { GroupRightFullSizeDto } from "./group-right.full-size.dto";
import { PersonGroupFullSizeDto } from "./person-group.full-size.dto";
import { Exclude, Expose, Type } from "class-transformer";

export class GroupFullSizeDto {

  @IsNumber()
  @Expose()
  id: number = 0;

  @IsDateString()
  @Exclude()
  createdAt: Date;

  @IsDateString()
  @Exclude()
  updatedAt: Date;

  @IsString()
  @Expose()
  name: string = '';

  @IsString()
  @Expose()
  description: string = '';

  @Exclude()
  @IsString()
  @IsOptional()
  internalName: string | null

  @IsString()
  @Expose()
  homepage: string = '';

  @IsArray()
  @Type(() => GroupRightFullSizeDto)
  @ValidateNested()
  @Expose()
  groupRights: GroupRightFullSizeDto[] = [];

  @IsArray()
  @Type(() => PersonGroupFullSizeDto)
  @ValidateNested()
  @Expose()
  personGroups: PersonGroupFullSizeDto[] = [];
}
