import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { PersonFullSizeDto } from '../../../../../shared/dtos';

@Component({
  selector: 'app-person-details',
  templateUrl: './person-details.component.html',
  styleUrls: ['./person-details.component.scss'],
})
export class PersonDetailsComponent implements OnInit, OnChanges {
  @Input() person: PersonFullSizeDto = new PersonFullSizeDto();
  @Output() personChange: EventEmitter<PersonFullSizeDto> = new EventEmitter<PersonFullSizeDto>();
  personData: { key: string; val: string }[] = [];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.personData = [
      { key: 'Telefon 1', val: this.person?.phone_1 || '' },
      { key: 'Telefon 2', val: this.person?.phone_2 || '' },
      { key: 'Account-E-Mail', val: this.person?.eMail_1 || '' },
      { key: 'alternative E-Mail-Adresse', val: this.person?.eMail_2 || '' },
      {
        key: 'Anschrift',
        val:
        this.person?.street || this.person?.postcode || this.person?.city ?
          `${this.person?.street || ''}<br />${this.person.postcode || ''}<br />${this.person.city || ''}` ||
          '' : '',
      },
      { key: 'ID', val: this.person?.id?.toString() || '' },
      { key: 'Keycloak-ID', val: this.person?.keycloakId || '' },
    ].filter((obj) => obj.val && obj.val.trim().length > 0);
  }
}
