import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PersonFullSizeDto } from '../../../../../shared/dtos';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-person-list',
  templateUrl: './person-list.component.html',
  styleUrls: ['./person-list.component.scss'],
})
export class PersonListComponent implements OnInit {
  @Input() selectedPersonId: number = -1;
  @Output() personSelect: EventEmitter<PersonFullSizeDto> = new EventEmitter();
  @Input() persons: PersonFullSizeDto[] = [];

  constructor(private menuSrv: MenuService) {}

  onOpenPersonTab(person: PersonFullSizeDto) {
    this.menuSrv.addTabAndOpen({
      text: `${person.firstName} ${person.lastName}`,
      route: ['persons', person?.id.toString()!],
      exact: true,
    });
  }

  onPersonSelect(person: PersonFullSizeDto) {
    this.personSelect.emit(person);
  }

  ngOnInit(): void {}
}
