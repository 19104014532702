import { jwtDecode } from "jwt-decode";
import { KeycloakTokenParsed } from "keycloak-js";
import { PersonFullSizeDto } from "./people/person.full-size.dto";

export class TokenAuthContext {
  person: PersonFullSizeDto = new PersonFullSizeDto();
  token: string;
  roles: string[] = [];

  static fromKeycloakToken(token: string) {
    const tokenParsed: KeycloakTokenParsed = jwtDecode(token);
    return TokenAuthContext.fromParsedKeycloakToken(token, tokenParsed);
  }

  static fromParsedKeycloakToken(token: string, tokenParsed: KeycloakTokenParsed): TokenAuthContext {
    const authContext = new TokenAuthContext();
    authContext.person.id = parseInt(tokenParsed.logistik_user_id);
    authContext.person.keycloakId = tokenParsed.sub as string;
    authContext.person.eMail_1 = tokenParsed.email;
    authContext.person.firstName = tokenParsed.given_name;
    authContext.person.lastName = tokenParsed.family_name;
    authContext.token = token;
    authContext.roles = tokenParsed.realm_access?.roles
      .filter(r => r.startsWith('LOGISTIK_'))
      .map(r => r.replace('LOGISTIK_', '').toLowerCase()) || [];

    return authContext;
  }
}