import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { BlockFullSizeDto } from '../../../../../shared/dtos';
import { HashtagRowFullSizeDto } from '../../../../../shared/dtos';
import { PalletFullSizeDto } from '../../../../../shared/dtos';
import { BlockService } from '../../services/block.service';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-hashtag-row-list',
  templateUrl: './hashtag-row-list.component.html',
  styleUrls: ['./hashtag-row-list.component.scss'],
})
export class HashtagRowListComponent implements OnChanges {
  @Input() block: BlockFullSizeDto = new BlockFullSizeDto();
  @Output() palletSelected: EventEmitter<PalletFullSizeDto> = new EventEmitter();

  constructor(private blockSrv: BlockService, private menuSrv: MenuService) {}

  onPalletSelect(pallet: PalletFullSizeDto) {
    this.palletSelected.emit(pallet);
  }

  onOpenHashtagRowTab(row: HashtagRowFullSizeDto) {
    this.menuSrv.addTabAndOpen({
      text: `Reihe ${this.block.name}${row.row}`,
      route: ['hashtagRows', row?.id.toString()!],
      exact: true,
    });
  }

  getExtraSlots(row: HashtagRowFullSizeDto): number[] {
    return row.pallets?.length < row.length
      ? [...Array(row.length - row.pallets.length).keys()]
      : [];
  }

  ngOnChanges(): void {
    this.blockSrv.findById(this.block.id).subscribe((block) => {
      this.block = block;
    });
  }
}
