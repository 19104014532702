<section>
  <div class="mt-2">
    <span class="text-gray-600 text-sm">Geschlecht filtern:</span>
    <div class="flex flex-row gap-2 flex-wrap">
      <div
        (click)="onGenderSelect(gender)"
        class="
          button-animation
          select-none
          uppercase
          font-bold
          py-2
          px-4
          rounded-lg
          shadow-md
          cursor-pointer
        "
        [ngClass]="{
          'bg-white text-primary': selectedGender.id !== gender.id,
          'bg-primary text-white': selectedGender.id === gender.id
        }"
        *ngFor="let gender of genders"
      >
        {{ gender.nameDe }}
      </div>
    </div>
  </div>
  <div class="mt-2">
    <span class="text-gray-600 text-sm">Kategorie filtern:</span>
    <div class="flex flex-row gap-2 flex-wrap">
      <div
        (click)="onCategorySelect(category)"
        class="
          button-animation
          select-none
          uppercase
          font-bold
          py-2
          px-4
          rounded-lg
          shadow-md
          cursor-pointer
        "
        [ngClass]="{
          'bg-white text-primary': selectedCategory.id !== category.id,
          'bg-primary text-white': selectedCategory.id === category.id
        }"
        *ngFor="let category of categories"
      >
        {{ category.nameDe }}
      </div>
    </div>
  </div>
  <div class="mt-2">
    <span class="text-gray-600 text-sm">Größe filtern:</span>
    <div class="flex flex-row gap-2 flex-wrap" *ngIf="sizes.length > 0">
      <div
        (click)="onSizeSelect(size)"
        class="
          button-animation
          select-none
          uppercase
          font-bold
          py-2
          px-4
          rounded-lg
          shadow-md
          cursor-pointer
        "
        [ngClass]="{
          'bg-white text-primary': selectedSize.id !== size.id,
          'bg-primary text-white': selectedSize.id === size.id
        }"
        *ngFor="let size of sizes"
      >
        {{ size.nameDe }}
      </div>
    </div>
    <div
      class="relative flex flex-row flex-wrap gap-2"
      *ngIf="sizes.length === 0"
    >
      <div
        class="
          button-animation
          select-none
          bg-gray-100
          py-2
          px-12
          rounded-lg
          shadow-md
          overflow-hidden
        "
        *ngFor="let num of [0, 1, 2, 3, 4, 5]"
      >
        &nbsp;
      </div>
      <div
        class="
          lg:absolute
          inset-0
          flex
          pl-56
          items-center
          text-gray-600 text-sm
        "
      >
        erst Kategorie auswählen
      </div>
    </div>
  </div>
</section>
