<div class="overflow-y-hidden">
  <ul class="overflow-y-auto">
    <li *ngFor="let group of groups" class="flex gap-2 px-4 py-4 sm:px-6 items-center justify-between animate-fadeIn"
      [ngClass]="{ 'hover:bg-gray-200 cursor-pointer': mode === 'select', 'bg-gray-100': selectedGroupId === group.id && mode === 'select' }"
      (click)="mode === 'select' && onGroupSelect(group)">
      <div class="flex flex-col w-full">
        <div class="flex space-x-2 w-full justify-between">
          <span class="text-sm text-primary font-medium line-clamp-1">{{ group.name }}</span>
          <button *ngIf="mode === 'select'" tooltip="in neuem Tab öffnen" (click)="onOpenGroupTab(group); $event.stopPropagation()">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg>
          </button>
        </div>
        <span class="text-sm text-gray-500 line-clamp-2">{{ group.description }}</span>
      </div>
      <button [tooltip]="'Gruppe ' + (mode === 'add' ? 'hinzufügen' : 'entfernen')"
        [ngClass]="mode !== 'select' ?'bg-gray-100 hover:bg-gray-200 rounded-full border border-gray-200 p-1' : ''"
        (click)="onGroupSelect(group)">
        <svg *ngIf="mode === 'add'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
          stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
        </svg>
        <svg *ngIf="mode === 'remove'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
          viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 12H6" />
        </svg>
      </button>
    </li>
  </ul>
  <div *ngIf="!groups || groups.length === 0" class="mt-16 flex justify-center items-center">
    keine Gruppe
  </div>
</div>
