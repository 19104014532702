import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GroupFullSizeDto } from '../../../../../shared/dtos';
import { PersonFullSizeDto } from '../../../../../shared/dtos';
import { RightFullSizeDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { GroupService } from '../../services/group.service';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
})
export class GroupComponent implements OnInit {
  @Input() group: GroupFullSizeDto= new GroupFullSizeDto();
  @Output() groupChange: EventEmitter<GroupFullSizeDto> = new EventEmitter<GroupFullSizeDto>();
  persons: PersonFullSizeDto[] = [];
  rights: RightFullSizeDto[] = [];
  view: string = 'persons';
  selectedPerson: PersonFullSizeDto = new PersonFullSizeDto();
  loading: boolean = false;

  constructor(
    private router: ActivatedRoute,
    private groupSrv: GroupService,
    private alertSrv: AlertService,
    private menuSrv: MenuService
  ) {}

  onOpenGroupTab() {
    this.menuSrv.addTabAndOpen({
      text: `${this.group.name}`,
      route: ['groups', this.group?.id.toString()!],
      exact: true,
    });
  }

  onPersonSelect(person: PersonFullSizeDto) {
    this.selectedPerson = person;
  }

  update() {
    this.router.params.subscribe((params) => {
      if (params.groupId) {
        this.loading = true;
        this.groupSrv.findByIdWithPersons(params.groupId).subscribe((group) => {
          this.group = group;
          this.persons = group.personGroups.map(
            (personGroup) => personGroup.person
          );
          this.rights = group.groupRights.map((groupRight) => groupRight.right);
          this.loading = false;
        });
      }
    });
  }

  ngOnInit(): void {
    this.update();
  }
}
