<div class="mt-2 flex space-x-4">
  <div class="flex-1 min-w-0">
    <label for="search" class="sr-only">Suchen</label>
    <div class="relative rounded-md shadow-sm">
      <div class="
          absolute
          inset-y-0
          left-0
          pl-3
          flex
          items-center
          pointer-events-none
        ">
        <!-- Heroicon name: solid/search -->
        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
          aria-hidden="true">
          <path fill-rule="evenodd"
            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            clip-rule="evenodd" />
        </svg>
      </div>
      <input type="search" name="search" id="search" [(ngModel)]="q" (keyup)="onSearch(q)" class="
          focus:ring-primary focus:border-primary
          block
          w-full
          pl-10
          sm:text-sm
          border-gray-300
          rounded-md
        " placeholder="Suchen" />

    </div>
  </div>
  <button [tooltip]="'Filter ' + (showFilters ? 'ausblenden' : 'einblenden')" [ngClass]="{ 'bg-gray-200': showFilters }"
    (click)="toggleFilter()" class="
      inline-flex
      justify-center
      px-3.5
      py-2
      border border-gray-300
      shadow-sm
      text-sm
      font-medium
      rounded-md
      text-gray-700
      bg-white
      hover:bg-gray-300
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary
    ">
    <!-- Heroicon name: solid/filter -->
    <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
      aria-hidden="true">
      <path fill-rule="evenodd"
        d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
        clip-rule="evenodd" />
    </svg>
    <span class="sr-only">Filter</span>
  </button>
</div>
<div class="grid grid-cols-2 gap-2 mt-4" *ngIf="showFilters">
  <div class="relative flex items-start">
    <div class="flex items-center h-5">
      <input id="showArchived" name="showArchived" type="checkbox" [(ngModel)]="showArchived"
        (ngModelChange)="onSearch(q)" class="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" />
    </div>
    <div class="ml-3 text-sm">
      <label for="showArchived" class="font-medium text-gray-700">archivierte Orgas anzeigen</label>
    </div>
  </div>
</div>
