import { IsBoolean, IsNumber, IsOptional, IsString, Min, ValidateNested } from "class-validator";
import { Exclude, Expose } from "class-transformer";

export class CreateArticleDto {

  @IsString()
  @Expose()
  nameDe: string;

  @IsString()
  @Expose()
  @IsOptional()
  nameEn?: string;

  @IsString()
  @Expose()
  @IsOptional()
  nameKur?: string;

  @IsString()
  @Expose()
  @IsOptional()
  description?: string;

  @IsNumber()
  @Expose()
  @Min(1)
  componentCount: number;

  @IsNumber()
  @Exclude()
  @IsOptional()
  minRequired?: number;

  @IsBoolean()
  @Expose()
  @IsOptional()
  showInList: boolean = true;

  @IsBoolean()
  @Expose()
  @IsOptional()
  addable: boolean = true;

  @IsNumber()
  @Expose()
  @IsOptional()
  fkCategory?: number;

  @IsNumber()
  @Expose()
  @IsOptional()
  fkGender?: number;

  @IsNumber()
  @Expose()
  @IsOptional()
  fkSize?: number;

  @IsNumber()
  @Expose()
  @IsOptional()
  fkArticleGroup?: number;

  @IsNumber()
  @Expose()
  @IsOptional()
  fkExportArticle?: number;

  @IsNumber()
  @Expose()
  @IsOptional()
  fkDepartment?: number;

}
