import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ArticleFullSizeDto } from '../../../../../shared/dtos';

@Component({
  selector: 'app-article-statistics',
  templateUrl: './article-statistics.component.html',
  styleUrls: ['./article-statistics.component.scss'],
})
export class ArticleStatisticsComponent implements OnInit {
  @Input() article: ArticleFullSizeDto = new ArticleFullSizeDto();
  @Output() articleChange: EventEmitter<ArticleFullSizeDto> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
