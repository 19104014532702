import { Component, OnInit, ViewChild } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Observable } from 'rxjs';
import { HhHttpService } from 'src/app/services/hh-http.service';
import { OrderService } from 'src/app/services/order.service';
import { OrderFullSizeDto, StatusFullSizeDto } from '../../../../../shared/dtos';
import { StatusService } from 'src/app/services/status.service';

@Component({
  selector: 'app-orders-map',
  templateUrl: './orders-map.component.html',
  styleUrls: ['./orders-map.component.scss'],
})
export class OrdersMapComponent implements OnInit {
  allOrders: OrderFullSizeDto[] = [];
  orders: OrderFullSizeDto[] = [];
  loading = false;
  selectedOrder: OrderFullSizeDto = new OrderFullSizeDto();
  status: StatusFullSizeDto[] = [];

  constructor(
    private orderSrv: OrderService,
    private http: HhHttpService,
    private statusSrv: StatusService,
  ) {}

  locationElbe264: google.maps.LatLngLiteral = {
    lat: 53.544738911201044,
    lng: 9.932603983533888,
  };

  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow | undefined;
  directionsResults$:
    | Observable<google.maps.DirectionsResult | undefined>
    | undefined;

  openInfoWindow(marker: MapMarker) {
    if (this.infoWindow) {
      this.infoWindow.open(marker);
    }
  }

  onDetailsClose() {
    this.selectedOrder = new OrderFullSizeDto();
  }

  onOrderSelect(order: OrderFullSizeDto) {
    this.selectedOrder = order;
  }

  filterOrders() {
    this.orders = this.allOrders.filter(
      (order) => order.fkType === 0 
      && this.status.filter(s => s.checked).map(s => s.id).includes(order.fkStatus)
    );
    this.orders.filter(o => !o.location || !o.location.lat).forEach((order) => {
      this.http.get<any>(`geocode/${order.id}`).subscribe((data) => {
        order.location = data.results[0].geometry.location;
      });
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.statusSrv.findAll().subscribe((status) => {
      this.status = status;
      const readyStatus = this.status.find(s => s.id === 3);
      if (readyStatus) {
        readyStatus.checked = true;
      }
    });

    this.orderSrv.findAll().subscribe((orders) => {
      this.allOrders = orders;
      this.filterOrders();

      this.loading = false;
    });
  }
}
