import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class HhHttpService {
  static root = environment.apiRoot;

  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  public get<T>(path: string, options?: any) {
    options = options ? options : {};
    options = Object.assign(options, this.httpOptions);
    return this.http.get<T>(HhHttpService.root + path);
  }

  post<T>(path: string, body: any, options?: any) {
    options = options ? options : {};
    options = Object.assign(options, this.httpOptions);
    return this.http.post<T>(`${HhHttpService.root}${path}`, body, options);
  }

  put<T>(path: string, body: any, options?: any) {
    options = options ? options : {};
    options = Object.assign(options, this.httpOptions);
    return this.http.put<T>(`${HhHttpService.root}${path}`, body, options);
  }

  patch<T>(path: string, body: any, options?: any) {
    options = options ? options : {};
    options = Object.assign(options, this.httpOptions);
    return this.http.patch<T>(`${HhHttpService.root}${path}`, body, options);
  }

  delete<T>(path: string, options?: any) {
    options = options ? options : {};
    options = Object.assign(options, this.httpOptions);
    return this.http.delete<T>(`${HhHttpService.root}${path}`, options);
  }
}
